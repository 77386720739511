import React from 'react';
import styled from 'styled-components';
import cx from 'classnames/bind';
import { cs, Svg } from 'object/src';
// import { URL } from 'svc/Enum';

const StyledObject = styled.div`{
  &.menu-bar {
    ${cs.noliststyle} ${cs.w.full} ${cs.disp.block} ${cs.h.get(40)} ${cs.border.bottom} 
    ${cs.bg.box} ${cs.border.dark} ${cs.noselect}
    
    .mb-ul {
      ${cs.w.full} ${cs.disp.block} ${cs.m.center()} ${cs.p.h20} ${cs.p.v5} ${cs.font.thickbold}
      ${cs.min.wlg} ${cs.over.hidden} 

      .mb-li { ${cs.w.fit} ${cs.m.h10} ${cs.font.sm} ${cs.font.line(26)} ${cs.font.gray}
        ${cs.font.left} ${cs.mouse.pointer} ${cs.disp.inblock} ${cs.p.h5} ${cs.radius.top(3)}
        ${cs.border.set('border-bottom: 4px solid transparent')}

        &:hover { ${cs.font.underline} ${cs.font.dark} }
        &.active { ${cs.font.orange} ${cs.border.orange} ${cs.anim.slideup('300ms', '-50px', '0')} }

        .icon { }
        .name { }
      }
    }

    &.xl { .mb-ul { ${cs.max.wxl} } }
    &.lg { .mb-ul { ${cs.max.wlg} } }
    &.md { .mb-ul { ${cs.max.wmd} } }

    &.left { .mb-ul { ${cs.font.left} } }
    &.right { .mb-ul { ${cs.font.right} } }
    &.center { .mb-ul { ${cs.font.center} } }

    @media screen and (max-width : 1024px) { 
      ${cs.over.hidden} ${cs.over.xauto} ${cs.scrollbar.t0(cs.color.lightprimary, '5px')} 
      ${cs.min.h(45)} ${cs.h.fit}
      
      &.xl { .mb-ul { ${cs.min.width(400)} ${cs.max.wmd} ${cs.size.fit} } }
      &.lg { .mb-ul { ${cs.min.width(400)} ${cs.max.wmd} ${cs.size.fit} } }
      &.md { .mb-ul { ${cs.min.width(400)} ${cs.max.wmd} ${cs.size.fit} } }
    }
  
    @media screen and (max-width : 600px) { 
  
    }
  }
}`;

// const API = URL.API.PAGE;

const Menubar = (props) => {
  const { list, className, size = 'xl', align = 'center', pos = 0, select = null } = props;

  const onClick = (eid, e, item) => {
    e.stopPropagation();
    props.onClick && props.onClick(eid, e, item);
  }

  return <StyledObject className={cx('menu-bar', className, size, align)}>
    <ul className={'mb-ul'}>
      {list && list.map((item, i) => {
        const { icon, title, id = i } = item;
        return <li className={cx('mb-li', (i === pos || id === select) && 'active')} key={id} onClick={(e) => onClick(id, e, item)}>
          {icon && <Svg className={'icon'} icon={icon} />}
          <span className={'name'}>{title}</span>
        </li>
      })}
    </ul>
  </StyledObject>
};

export default Menubar;