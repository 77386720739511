import React from 'react';
import axios from 'axios';
import { Editbox, Button, cs, Storage } from 'object/src';
import { CODE, EID, KEY, URL } from 'svc/Enum';
import { ST } from 'svc/Lang';
import styled from 'styled-components';
import cx from 'classnames/bind';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.login-frame {
    ${cs.size.full} ${cs.bg.frame} ${cs.h.get('100%')} ${cs.min.h('100vh')} ${cs.noselect}

    .login-body { ${cs.w.get(500)} ${cs.m.center(50, 50)} 
      ${cs.font.center} ${cs.p.a30} ${cs.p.get('60px 100px')} ${cs.bg.white} ${cs.border.none} ${cs.border.trans}

      .login-tl { ${cs.font.size(22)} ${cs.font.thickbold} ${cs.font.himelody} }
      .noti { ${cs.font.red} ${cs.font.sm} ${cs.z.get(10)} ${cs.m.t10} }
      .login-id { ${cs.m.b10} }

      .line { ${cs.m.v30} ${cs.border.bottom} ${cs.pos.relative} ${cs.font.gray}
        .l-txt { ${cs.bg.white} ${cs.p.h20} ${cs.font.sm} ${cs.align.xcenter} ${cs.top(-5)} } 
      }
      
      .button { ${cs.font.thickbold} ${cs.border.radius(5)} }
      .button.login { ${cs.m.t10} }
      .kakao { ${cs.bg.get("#FFE300")} }
      .naver { ${cs.bg.get("#1ec800")} ${cs.font.white} }
      .facebook { ${cs.bg.get("#2f52ad")} ${cs.font.white} }

      .login-find { ${cs.font.line(20)} ${cs.font.center} ${cs.m.v5}
        .f-btn { ${cs.font.primaryhover} ${cs.font.md} ${cs.p.h10} ${cs.mouse.pointer}
          &:hover { ${cs.font.primary} ${cs.font.underline} }
        } 
      }

      @media screen and (max-width : 600px) {
        .login { ${cs.top(0)} ${cs.p.t20} ${cs.w.full} ${cs.border.radius(0)} ${cs.border.none}
          ${cs.bg.trans} ${cs.border.trans}
        }

        .login-box { ${cs.bg.trans} ${cs.border.none} ${cs.border.trans} }
      }
    }
  }
    
  &.tail-bar {
    ${cs.bg.trans} ${cs.font.gray} ${cs.font.md} ${cs.font.center} 
    ${cs.p.v20} ${cs.h.get('auto')} ${cs.w.full} 
  }

  &.head-bar {
    ${cs.bg.get("rgba(255, 255, 255, 0.9);")} ${cs.font.gray} ${cs.font.md} ${cs.font.center} ${cs.font.line(60)} ${cs.h.get(60)} 
    ${cs.w.full} ${cs.font.t4} ${cs.font.eastsea}
  }
}`;

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { noti_text: '', userid: '', pwd: '', focus: false };
  };

  onKeyPressed = (e) => {
    if (e.key === KEY.ENTER) {
      this.onClicked(EID.OK, e);
    }
  }

  onClicked = (eid, e) => {
    const params = { userid: this.refUserid.getValue(), password: this.refPassword.getValue() };
    axios.post(URL.ADMIN.LOGIN, params).then((result) => {
      const { data } = result;
      if (data && data.code === CODE.SUCCESS) {
        // 로그인 정보를 세션에 저장
        Storage.setLocalItem(Storage.key.userinfo, data.value);
        this.doUpdateCart(data);
        
      } else {
        this.setState({ noti_text: ST.LOGIN.NOTI_LOGIN });
      }
    }).catch((err) => {
      this.setState({ noti_text: ST.LOGIN.NOTI_LOGIN });
    });
  }

  doUpdateCart = (data, callback = null) => {
    // 카트에 담겨있는 정보를 업데이트하자..
    // 현재 PC의 고유아이디 guid에 해당하는 정보를 로그인한 userid로 업데이트
    const { userid } = data && data.value;
    const guid = Storage.getLocalItem('guid');
    actions.doUpdate(URL.API.CART_CLEAR, { userid, guid }, null).then(({ result }) => {
      callback ? callback(result) : window.location.href = URL.ADMIN.MAIN;
    });
  }

  componentDidMount() {
    var pathname = this.props.location.pathname;
    if (pathname.indexOf("error") >= 0) {
      this.setState({ noti_text: ST.LOGIN.NOTI_LOGIN })
    }

    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      this.setState({ company: json, loaded: true });
    });

    this.props.onLoaded(true);
  };

  render() {
    const { userid, pwd, company } = this.state;

    return (
      <StyledObject className={"login-frame"}>
        <Headbar title={company && company.name} />

        <div className="login-body sm" method="post" onKeyPress={this.onKeyPressed}>
          <div className="login-tl">{ST.ADMIN.LOGIN.WELCOME}</div>
          <div className={cx("line")} />

          <Editbox ref={ref => { this.refUserid = ref }} className="login-id trans border radius lg" type="text" guide={ST.ID} value={userid} focus={true} />
          <Editbox ref={ref => { this.refPassword = ref }} className="login-pwd trans border radius lg" type="password" guide={ST.PASSWORD} value={pwd} />
          <div className="noti">{this.state.noti_text}</div>
          <Button className="primary full login lg" title={ST.LOGIN.TITLE} onClick={this.onClicked} eid={EID.OK} />

          <div className="login-find">
            <span className={"f-btn"} onClick={() => this.props.history.push(URL.PASSWORD)}>{ST.ADMIN.MENU.PASSWORD_FIND}</span>
          </div>
        </div>

        {company && <Tailbar title={ST.COPYRIGHT(company.name_eng || company.name, company.year)} />}
      </StyledObject>
    )
  };
};

export const Headbar = ({ title = '' }) => {
  return <StyledObject className={"head-bar"}>
    <span>{title}</span>
  </StyledObject>
}

export const Tailbar = ({ title = '' }) => {
  return <StyledObject className="tail-bar">
    <span>{title}</span>
  </StyledObject>
}

export default Login;