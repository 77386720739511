import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Nodata, Carousel, Uploadbox, TabButton, Combobox } from 'object/src';
import { EID } from 'object/src/Config';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.ad-home { ${cs.bg.trans} ${cs.p.a20} ${cs.pos.relative}
    .h-frame { ${cs.max.wlg} ${cs.w.full} ${cs.pos.relative}
      p.title { ${cs.font.t0} ${cs.m.v10} }
    }

    .h-box { ${cs.pos.relative} ${cs.m.t50}
      .new { ${cs.top(-20)} }
      p.noti { ${cs.font.md} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} }
      .p-line { ${cs.border.bottom} }
      p.mt { ${cs.m.t30} }

      .ul { ${cs.m.b50}
        .li { ${cs.pos.relative} ${cs.w.r50} ${cs.disp.inblock} ${cs.p.a10} ${cs.font.center}
          & > .button { ${cs.disp.block} ${cs.font.right} ${cs.m.t5}
            & > span.button {
              ${cs.font.center} ${cs.disp.inblock} ${cs.w.get(100)} ${cs.m.l10} ${cs.pos.relative} 

              &.url {
                ${cs.align.left} ${cs.left(0)} ${cs.font.dark} ${cs.font.sm} ${cs.font.left} 
                ${cs.w.calc('100% - 240px')} 
                .btn-label { ${cs.font.left} ${cs.font.gray} }
              }
            } 
          }

          .up-frame {
            .upv-delete { ${cs.disp.none} }
          }
        }
      }
    }
  }

  &.link-box {
    ${cs.w.full} ${cs.max.w(400)} ${cs.min.h(100)} ${cs.box.line} ${cs.box.radius} ${cs.h.fit}
    ${cs.bg.dark} ${cs.p.a20} ${cs.pos.absolute} ${cs.left(20)} ${cs.bottom(20)} ${cs.z.popup}

    .tab-button { ${cs.w.full} ${cs.m.b10} .tab-frame { ${cs.w.full} .tab-btn { ${cs.w.r50} } } }
    .combo-box { ${cs.m.b30} ${cs.m.t5} ${cs.font.md} .cb-ul { ${cs.min.w(280)}}}
    .foot { ${cs.font.right} & > .button { ${cs.m.l10} } }
  }

  @media screen and (max-width : 1024px) { 
    &.ad-home .h-box .ul .li { ${cs.w.full} ${cs.p.h0} ${cs.m.b30}
      & > .button { ${cs.h.get(70)} }
      .button > span.button.url { ${cs.m.l0} ${cs.w.full} ${cs.disp.block} }
      .button > span.button.delete { ${cs.align.left} ${cs.bottom(0)} ${cs.left(0)} ${cs.m.l0} }
      .button > span.button.upload { ${cs.align.right} ${cs.bottom(0)} }
    }
  }

  @media screen and (max-width : 800px) {
    &.link-box { ${cs.align.unset} ${cs.align.center} ${cs.pos.fixed} ${cs.w.calc(('100% - 20px'))} }
  }
}`;

const API = URL.API.ADMIN;

const Home = (props) => {
  const [list, setList] = useState(null);
  const [home, setHome] = useState(0);
  const [menulist, setMenus] = useState(null);
  const [products, setProducts] = useState(null);

  useEffect(() => {
    doReload();
    return () => { }
  }, [props])

  const doReload = () => {
    setList(null);
    actions.doList(API.HOME, {}, true).then(({ result, menus }) => {
      const item = menus.find(a => a.ishome === 'Y');
      const array = result.list && result.list.filter(a => a.menuid === item.rowid).map(a => {
        a.label = a.title;
        a.title = '';
        a.url = a.thumb;
        return a;
      });

      setHome(item);
      setList(array);
    });

    actions.doList(API.PRODUCT, {}, true).then(({ result, menus }) => {
      result && result.list.map(a => {
        a['id'] = a.rowid;
        a['name'] = `${a.title}`;
        return null;
      });
      menus && menus.map(a => {
        a['id'] = a.rowid;
        a['name'] = `${a.title}`;
        return null;
      })

      setMenus(menus);
      setProducts(result && result.list);
    })
  }

  const onClick = (e, item, index) => {
    if (!item || !item.stext) return;

    const temps = item.stext.split("#");
    if (!temps || temps.length < 1) return;
    actions.go(temps[0], { rowid: temps[1] })
  }

  const onNew = () => {
    const temps = list ? [...list] : [];
    setList([...temps, { url: '', title: '', menuid: home.rowid }])
  }

  if (!list) return null;
  return <StyledObject className={cx('ad-home')}>
    <div className={'h-frame'}>
      <p className={'title'}>{ST.PREVIEW}</p>
      {list && list.length > 0 ? <Carousel className={"full"} size={"fwide"} list={list} anim={false} onSelect={onClick} /> : <Nodata />}

      <div className={'h-box'}>
        <p className={'noti mt'}>{ST.ADMIN.HOME.NOTI}</p>
        <Button className={'green top right new'} title={ST.ADD} eid={EID.NEW} onClick={onNew} />
        <div className={'p-line'} />
        <ul className={'ul'}>
          {list && list.length > 0 ? list.map((item, i) =>
            <Slidebox key={i} item={item} openConfirm={props.openConfirm} doReload={doReload} showAlert={props.showAlert} menulist={menulist} products={products} />
          ) : <Nodata />}
        </ul>
      </div>
    </div>
  </StyledObject>
};

export default Home;

const Slidebox = (props) => {
  var thumb = null;
  const { item } = props;
  const [modified, setModified] = useState(false);
  const [opts, setOpts] = useState(item && item.opts ? JSON.parse(item.opts) : null);
  const [show, setShow] = useState(false);

  const onClickLink = (e, eid, rowid) => {
    setShow(!show);
  }

  const onDelete = (eid, e) => {
    props.openConfirm({
      type: 'info', size: 'xs', className: 'gray', onClicked: (isOk) => {
        (isOk) && actions.doDelete(URL.API.ADMIN.PRODUCT, { rowid: item.rowid }, null).then(({ code, result }) => {
          props.doReload && props.doReload(code);
        });
      }
    });
  }

  const onUpload = () => {
    const image = thumb.getValue(); //base64

    const value = { menuid: item.menuid, rowid: item ? item.rowid : null, title: item.title, image: image, tags: 'BNR' }

    actions.doInsert(URL.API.ADMIN.UPLOAD_PRODUCT, value).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });
  }

  const onSelect = (eid, value) => {
    setShow(false);

    if (value && item) {
      setOpts(value);
      actions.doUpdate(URL.API.ADMIN.PRODUCT_OPTS, { rowid: item.rowid, opts: JSON.stringify(value) }).then(({ code, result }) => {
      });
    }
  }

  return <li className={'li'}>
    <Uploadbox ref={(ref) => thumb = ref} className={cx('xwide')} fit={'fill'} size={'md'} inline={false} onChange={() => setModified(true)}
      value={item.url} title={ST.ADMIN.HOME.TITLE} eid={'Uploadbox'} image_check={{ width: 1900, height: 1200 }} />
    <span className={'button'}>
      <Button className={'white border url'} title={opts ? opts.title : ST.ADMIN.HOME.LINK} eid={'url'} onClick={onClickLink} />
      <Button className={'black delete'} title={ST.DELETE} eid={'delete'} onClick={onDelete} />
      <Button className={'red upload'} title={ST.ADMIN.UPLOAD} eid={'upload'} onClick={onUpload} disabled={!modified} />
    </span>
    <Linkbox show={show} onClick={onSelect} value={opts} menulist={props.menulist} products={props.products} />
  </li>
}

const Linkbox = (props) => {
  const { value = null, menulist, products } = props;
  const [select, setSelect] = useState(value ? value.url.replace("/", "") : 'menu');
  const [item, setItem] = useState(null);

  const onClick = (eid) => {
    const list = select === 'menu' ? menulist : products;
    const sitem = item || list[0];
    const title = sitem && `(${sitem.title}) ${select === 'menu' ? ST.ADMIN.HOME.MENUS : ST.ADMIN.HOME.PRODUCTS}`
    const param = sitem ? { url: `/${select}`, rowid: sitem.rowid, title } : null;
    props.onClick && props.onClick(eid, param);
  }

  const onChange = (eid, e, v, pos) => {
    setItem(v);
  }

  if (!props.show || !menulist || !products) return null;
  const combos = select === 'menu' ? menulist : products;
  const index = value && combos.findIndex(a => value.rowid === a.rowid);
  const pos = index >= 0 ? index : 0;

  return <StyledObject className={'link-box'}>
    <TabButton size={'md'} list={[{ id: 'menu', title: ST.ADMIN.HOME.MENUS }, { id: 'product', title: ST.ADMIN.HOME.PRODUCTS }]}
      onClick={(eid) => setSelect(eid)} select={select} />
    <Combobox className={'full dark md'} options={{ text: { align: 'left' } }} list={combos}
      onChange={onChange} pos={pos} />
    <div className={'foot'}>
      <Button className={'sm gd-gray cancel'} title={ST.CANCEL} onClick={() => onClick(EID.CANCEL)} />
      <Button className={'sm red ok'} title={ST.OK} onClick={() => onClick(EID.OK)} />
    </div>
  </StyledObject>
}