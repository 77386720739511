import React from 'react';
import styled from 'styled-components';
import cx from 'classnames/bind';
import { Svglist } from 'object/src';

const StyledObject = styled.div`{

}`

const Icons = (props) => {
  return <StyledObject className={cx('daily-list')}>
    <Svglist className={"svg-list"} show={true} />
  </StyledObject>
};

export default Icons;