import * as OBJ from "object/src/Config";

const KOR = {
  ...OBJ.ST,

  START_YEAR: "2020",
  COMPANY_NAME: "COMPANY",
  COMPANY_KOR: "회사명",
  COPYRIGHT: (name = '', y = 2020, isfull = false) => isfull ? `Copyright © ${name} ${y && String(y).substr(0, 4)}, All rights reserved.` : `© ${y && String(y).substr(0, 4)} ${name}`,
  CARDVIEW: "카드보기",
  TABLEVIEW: "리스트보기",
  MEM_COUNT: "명",
  YESTERDAY: "어제",
  TODAY: "오늘",
  WEEK: "주일",
  MONTH: "개월",
  MEMBER_TOTAL: "전체",
  T_SEARCH: "배송조회",
  SEND: '보내기',
  SHORTCAT: '바로가기',
  BACK: '뒤로',
  SELECT: '선택',
  HELPER: '도움말',
  TFORMAT: 'YYYY-MM-DD HH:mm분',
  SUBJECT: "제목",
  NOADMIN: "이 화면은 관리자권한으로 접근이 제한되었습니다.",
  LOGINADMIN: "관리자 권한으로 로그인해야 합니다.\n관리자 로그인 화면으로 이동합니다.",
  LOGINSYSADMIN: "시스템 관리자 권한이 필요합니다.\n시스템 관리자로 다시 로그인하세요.",
  ADMIN_LOGIN: "처음사용을 위해 시스템을 설정해야합니다. 시스템관리자로 로그인하세요\n(아이디:sysadmin, 초기비밀번호:0000).",
  YES: "예",
  NO: "아니오",
  WRITE: "글쓰기",
  MOBILE_NOTI: "휴대폰에서는 정상적으로 보이지 않을수 있습니다.",
  HOUR: "시",
  MINITE: "분",
  SECOND: "초",
  REST: "기타",

  PASSWORD: "비밀번호",
  PASSWORD_EDIT: "비밀번호변경",
  OR: "또는",
  WON: "원",
  NOLIST: (v) => `${v} 내역이 없습니다.`,
  BOARD_TITLE: (v = '') => `${v ? v + '에서 ' : ''}알려드립니다.`,
  REQUMENT_ERROR: (v = '') => `${v}는 필수 입력사항입니다.`,
  PREVPAGE: '이전 페이지',
  NEXTPAGE: '다음 페이지',
  MAXLEN: (t = '', v = 20) => `${t}을 ${v}글자 이내로 작성하세요.`,

  QNA: {
    TITLE: "문의하실 내용이 있으면 아래에 글을 남겨주세요.",
    NO_DELETE: "답변이 달린 글은 편집하거나 삭제할 수 없습니다.\n\n편집 또는 삭제를 원할경우 고객센터에 문의하세요.",
    REQUEST: '답글',
    REQUEST_OK: '답글확인',
    NEW: '새글',
    REQ_NEW: '답글쓰기',
    REQ_EDIT: '답글편집',
    REQ_DELETE: '답글삭제',
    ISDELETE: '답글을 정말로 삭제 하시겠습니까?',
    NO_REQ_TEXT: "아직 답글이 없습니다.",
    SUBJECT: "제목",
    TEXT: "내용",
    HELP_MAX: (v = 100, t = '') => `${t ? t + '은 ' : ''}${v}자까지 입력할수 있습니다.`,
    HELP: (t = 100, v = 1000) => `제목은 ${t}자, 내용은 ${v}자까지 입력할수 있습니다.`,
  },

  INFO: {
    PRI: (v) => `${v} 서비스를 이용하셨다면 ${v} 아래의 개인정보처리방침이 적용됩니다.`,
    PUB: (v) => `${v} 서비스를 이용하셨다면 ${v} 아래의 이용약관이 적용됩니다.`,
  },

  COMPANY: {
    INFO: '회사소개',
    BOSS: '대표이사',
    BOARD: '공지사항',
    QNA: '문의하기',
    PRIVATE: '개인정보취급방침',
    PUBLIC: '이용약관',
    LICENSE: '사업자등록번호: ',
    BIZ_LINCESE: '통신판매업신고: ',
    BIZ_URL: '(사업자정보확인)',
    TEL: '고객센터: ',
    EMAIL: '이메일: ',
    FAX: '팩스: ',
    KAKAO: '카카오톡 옐로아이디: 비움반찬',
    BANK: '무통장입금 계좌번호',
  },

  BOARD: {
    TITLE: '공지사항',
    POPUP: '공지팝업관리',
    IMAGE: '이미지 추가',
    SUBJECT: '제목',
    TEXT: '내용',
    ENABLE: '팝업보이기',
    DISABLE: '팝업숨기기',
    LINK: '상세 공지사항 선택하기(연결하기)',
    LINKAT: (v) => `[${v}]로 연결합니다`,
    LINK_H: '클릭시 이동할 공지사항이 있으면 선택하세요',
    CLOSE: '더이상 보지않기',
    IMAGE_SAVE: '이미지가 변경되었습니다.\n이미지업로드를 하시겠습니까?',
  },

  MENU: {
    HOME: '홈',
    SIGNIN: '로그인',
    SIGNUP: '회원가입',
    CART: '장바구니',
    HISTORY: '후기/재구매',
    MYPAGE: '마이페이지',
    LOGOUT: '로그아웃',
    BOARD: '공지사항',
    QNA: '문의하기',
    PAYMENT: '결제',
    ORDER: '결제진행',
    DETAIL: '주문/배송 상세',
    PRODUCT: '상품',
  },

  TAG: {
    SALE: "세일",
    ON: "판매중",
    OFF: "판매종료",
    HOLD: "일시품절",
  },

  MYINFO: {
    TITLE: '회원정보',
    USERID: "아이디(회원번호)",
    ACCOUNTID: "사용자아이디",
    NAME: "이름",
    NAME_IN: "배송을 위해 이름(닉네임)을 입력해주세요",
    PASSWORD: "비밀번호 변경",
    PASSWORD_H: "* [SNS 간편 회원가입]을 통해 가입하신 경우 초기 비밀번호가 자동으로 생성됩니다. 이 경우 [비번찾기]로 가셔서 등록하신 이메일을 통해 비밀번호를 재발급받으신 다음, 원하시는 비밀번호로 변경하실 수 있습니다.",
    PREPASS: "기존 비밀번호",
    PREPASS_FIND: "비밀번호 찾기",
    PREPASS_H: "현재 사용중인 비밀번호를 입력하세요",
    NEWPASS: "새 비밀번호",
    NEWPASS_H: "새로운 비밀번호를 여기에 입력하세요",
    NEWPASSCHK: "새 비밀번호 확인",
    NEWPASSCHK_H: "새비밀번호를 확인을 위해 다시한번 입력하세요",
    PHONE: "휴대폰 번호",
    PHONE_IN: "휴대폰 번호를 입력해주세요",
    PHONE_H: "* 사이트 정책상 인증받은 휴대폰 번호는 변경이 불가능합니다.",
    PHONE_CODE: "인증코드 입력",
    PHONE_CODE_H: "인증코드 입력(6자리)",
    PHONE_CHANGE: "휴대폰 번호 변경",
    PHONE_FAIL: "인증코드를 재발급하여 다시 시도하세요.",
    EMAIL: "이메일",
    EMAIL_IN: "이메일 주소를 입력해주세요",
    EMAIL_CODE: "인증코드",
    EMAIL_CODE_H: "인증코드 입력(5자리)",
    EMAIL_CHANGE: "이메일 변경",
    CODE_ERROR: "인증코드를 확인하세요.",
    CODE_SUCCESS: "인증코드가 확인되었습니다.",
    CODE_FAIL: "인증코드를 확인후 다시 시도하세요.",
    TIME_OUT: "입력시간이 초과 되었습니다.",
    EMAIL_F: "* 메일을 변경하려면",
    EMAIL_M: "'여기를'",
    EMAIL_L: "클릭하세요",
    PHONE_F: "* 휴대폰을 변경하려면",
    PHONE_M: "'여기를'",
    PHONE_L: "클릭하세요",
    SDATE: "가입일",
    KAKAO: "카카오계정으로 가입",
    FACEBOOK: "페이스북계정으로 가입",
    NAVER: "네이버계정으로 가입",
    MAIL_TITLE: "인증번호가 발송되었습니다.",
  },

  ADMIN: {
    PREVIEW: '미리보기',
    TITLE: "제목",
    UPLOAD: "업로드",
    UPLOAD_NOTI: "업로드 파일을 선택하세요",
    RESET_PWD: "기본 비밀번호는 보안에 취약합니다.\n관리자 비밀번호를 재발급하세요.",

    NOTI: {
      CLEAR: (v) => `${v}건 모두삭제`,
    },

    SYSINIT: {
      GUIDE: '설정 가이드 바로가기',
      CONFIRM: "처음방문을 환영합니다.\n\n시스템 처음 사용을 위한 설정을 시작하세요.",
      TITLE: "시스템 처음 사용을 위한 설정 안내입니다.",
      GBOOK: "가이드북을 다운받으시려면 여기를 클릭하세요.",
      POP_CLOSE: "더이상 이 팝업 보지않기.",
      LINK: "바로가기",
      HEAD: "사전준비",
      HEAD_G: "아래의 항목을 클릭하면 해당 웹사이트로 이동합니다.",
      GMAIL: "GMAIL에 회원가입하세요(메일 보내기 서버 설정에 필요합니다)",
      NAVER: "NAVER에 회원가입하세요(네이버 간편로그인에 필요합니다.)",
      KAKAO: "KAKAO에 회원가입하세요(카카오 간편로그인에 필요합니다.)",
      NCP: "Naver Cloud Platform에 회원가입하세요(SMS 발송을 위해 필요합니다)",
      IAMPORT: "IAMPORT에 회원가입하세요(PG사 결제 연동에 필요합니다)",
      ABOUT: "회사소개",
      ABOUT_H: "대표이미지/슬로건/경영철학/오프라인 매장 정보 등을 설정하세요.",
      ABOUT_G: " - 대표이미지: 회사소개 페이지에 작게 보여질 대표 이미지(아이콘)을 설정하세요.\n"
        + " - 슬로건: 회사를 소개할 문장을 간결하게 입력하세요.\n"
        + " - 경영철학: 경영철학이 있다면 여기에 입력하세요.\n"
        + " - 오프라인매장: 오프라인 매장의 주소 및 위치를 입력합니다.",
      WEBAPP: "앱연동설정",
      WEBAPP_H: "카카오 로그인/네이버 로그인/문자 서비스/결제 서비스 등 외부 시스템 연동을 위한 설정을 진행합니다.",
      WEBAPP_G: " - 카카오 로그인: 카카오 간편 로그인을 사용하려면 설정해야 합니다(설정하지 않으면 로그인 화면에서 보이지 않습니다).\n"
        + " - 네이버 로그인: 네이버 간편 로그인을 사용하려면 설정해야 합니다(설정하지 않으면 로그인 화면에서 보이지 않습니다).\n"
        + " - 문자 서비스: 사용자의 휴대폰 정보를 입력받으려면 이 설정을 해야 합니다(설정하지 않으면 비활성화됩니다).\n"
        + " - 결제 서비스: 주문/결제를 위해 반드시 설정해야 합니다(결제시스템은 iamport와 연동합니다).\n",
      SETTING: "기본설정",
      SETTING_H: "기본정보/회사정보/배송일반/배송상태/최근추가항목관리/개인정보취급방침/이용약관 등을 설정하세요",
      SETTING_G: " - 기본정보: 배송,교환,환불,상품후기,배송안내,배송시요청사항 등을 설정해야합니다.\n"
        + " - 회사정보: 웹페이지 하단 및 회사 정보가 필요한 곳에 표시할 회사이름,고객센터,사업자등록번호 등, 회사 정보를 입력해야합니다.\n"
        + " - 배송일반: 기본배송비, 배송비 무료 금액, 택배사 목록 등을 설정해야합니다.\n"
        + " - 배송상태: 입금대기중, 결제완료, 배송중, 배송완료 등 배송상태를 설정해야합니다.\n"
        + " - 최근추가항목관리: 상품등록시 편리성을위해 설정합니다(선택사항으로 나중에 설정해도됩니다).\n"
        + " - 개인정보취급방침: 필수항목으로 정보통신법(?)에 의하여 개인정보취급방침을 입력해야합니다.\n"
        + " - 이용약관: 필수항목으로 정보통신법(?)에 의하여 이용약관을 입력해야합니다.\n",
      MAILER: "메일관리",
      MAILER_H: "기본설정/이메일변경/비밀번호찾기/주문,배송안내 등에 대한 보내기 메일 정보를 설정하세요.",
      MAILER_G: " - 기본정보: 웹시스템이 사용자에게 메일을 보내기 위해 보내기 메일 서버 설정을 해야합니다.\n"
        + " - 이메일변경: 사용자가 내정보에서 자신의 이메일을 변경하기 위해 인증코드를 발송하기 위한 메일 폼(FORM)을 구성합니다.\n"
        + " - 비밀번호찾기: 사용자가 비밀번호 분실시 재발급된 비밀번호를 발송하기 위한 메일 폼(FORM)을 구성합니다.\n"
        + " - 주문,배송안내: 주문/결제시 안내 메일을 사용자에게 보내기 위한 메일 폼(FORM)을 구성합니다.\n",
      PASS: "비밀번호 재설정 및 시스템관리",
      PASS_H: "관리자 비밀번호를 재설정하세요. 초기발급된 비밀번호는 모두에게 공개된 상태이므로 보안에 취약합니다.",
      PASS_G: " - 관리자: 메뉴관리 및 상품 등록/수정/삭제, 주문, 공지사항 등을 관리할수 있습니다.\n"
        + " - 시스템관리자: 관리자의 권한에 추가적으로 시스템 설정을 위한 모든 권한을 가지는 슈퍼관리자입니다.\n"
        + " - 관리자설정: 일반 관리자를 추가 등록/삭제/수정할수 있습니다(시스템관리자만 가능합니다).\n"
        + " - 테이블관리: 웹시스템에 사용된 테이블들의 데이터 사용량을 표시하고, 필요에따라 정리 기능을 제공합니다.\n"
        + " - 로그인로그: 사용자 또는 관리자의 로그인 기록을 표시합니다.\n"
        + " - 쿼리로그: 시스템 이상 또는 문제 발생시 참조하기 위한 로그를 기록합니다.\n",
    },

    MAIN: {
      NOTIFY: '새로운 알림',
      USERS: '사용자 통계',
      ORDERS: '주문/배송 통계',
      ORDERING: '주문/배송 대기중(최근 3일)',
    },

    MGMT: {
      TITLE: '시스템 관리',
      INITS: '시스템 설정',
      TABLES: '테이블 관리',
      ADMINS: '관리자 설정',
      WEBSET: '웹앱 설정',
      SEARCH: '검색 사이트 등록',
      LOGINS: '로그인 로그',
      QUERYS: '쿼리 로그',
      USER: '사용자',
      ADMIN: '관리자',

      NO_ENGLISH: '계정은 영문 및 숫자만 입력할수 있습니다.',
      PASSWORD: "특수문자/영문/숫자를 조합하여 8~20자로 입력하세요",
      NO_DELETE_ADMIN: "기본 관리자는 삭제할수 없습니다.",
      PASS_RESET: (v) => `비밀번호가 ${v}로 변경되었습니다.`,

      ROWID: '고유아이디',
      USERID: '회원번호',
      ACCOUNTID: '계정아이디(권한)',
      ACCOUNT: '계정아이디',
      AUTH: '권한',
      CONTENT: '내용',
      LOGINTIME: '마지막로그인시간',

      TYPE: '타입',
      QUERY: '쿼리 내용',
      ERROR: '에러 내용',

      TABEL: '테이블명',
      ROWS: '레코드수',
      COMMENT: '설명',
      SIZE: '사용량(MB)',
      CLEAR: '내역정리',
      CLEAR_NOTI: '정리가 필요한 테이블은 [내역정리]에 정리버튼이 활성화됩니다.\n6개월 주기마다 필요에따라 자동정리되므로 정리버튼이 활성화되도 정리가 필수 사항은 아닙니다.',
      TABLE_INIT: '데이터베이스 정보를 재설정하고 시스템을 리부팅힙니다.\n설정이 잘못될 경우, 웹서버에 오류가 발생할 수 있다는 것에 주의하세요.\n웹 시스템을 관리하는 관리자만 이 정보를 설정/관리하시기 바랍니다.',

      THREE: '3개월',
      FOUR: '4개월',
      FIVE: '5개월',
      CLEARL_MSG: '선택한 기간 이전데이터를\n모두 정리(삭제) 하시겠습니까?',

      HOST_ONLY: '특수문자(. - _) 및 영/숫자만 입력할수 있습니다.',
      INPUT_ONLY: 'database/host/user 입력란에는 특수문자(. - _) 및 영/숫자만 입력할수 있습니다.',
      DAB_SAVE: 'DB 설정을 변경 하시겠습니까?\n\n변경후 장애가 발생되면 이후 이 화면에서는 설정이 불가능합니다.',
      CREATEDB: 'DB 생성',
      CREATE_DB_PASS: 'DB 생성을 위한 인증코드를 입력하세요.',
      CREATE_DB_PCODE: 'DB 권한 생성을 위한 비밀번호를 입력하세요.',
      CONN_TEST: '연결테스트',
      CONN_SUCCESS: "DB 연결에 성공하였습니다.\n저장후 리부팅하세요.",
      CONN_FAIL: "DB 연결에 실패하였습니다.\n설정 정보를 확인후 다시 시도하세요.",
      INIT_SUCCESS: (v = 10) => `${v}초후 시스템이 재시작됩니다.\n재로그인을위해 관리자 로그인 화면으로 이동합니다.`,

      SEARCH_SAVE: '검색 등록을 위한 설정 정보를 변경 하시겠습니까?\n\n변경후 웹서버가 재부팅되는데 수초의 시간이 필요합니다.',
      SEARCH_NAVER: 'HTML 메타 태그를 여기에 붙여넣으세요.',

      WEBSET_SAVE: '웹앱설정을 변경 하시겠습니까?\n\n변경후 웹서버가 재부팅되는데 수초의 시간이 필요합니다.',
      WEBSET_DOMAIN_RE: (s = '', t = '') => `현재 설정된 URL(${s})과 접속한 URL(${t})이 다릅니다.\n접속한 URL로 재설정 하시겠습니까?`,
      WEBSET_URL_H: '접속중인 홈페이지 URL(https://www.oooooo.co.kr)을 입력하세요.',
      WEBSET_TITLE_H: '웹사이트의 이름을 입력하세요(GOOGLE/NAVER/DAUM 등 검색엔진이 이 값을 참고합니다)',
      WEBSET_DESC_H: '웹사이트의 간단한 설명 입력하세요(GOOGLE/NAVER/DAUM 등 검색엔진이 이 값을 참고합니다)',
      WEBSET_MAIN_H: '웹페이지 표시(로딩)전 상단탭에 표시되는 제목입니다(로딩이 완료되면 회사명으로 자동 표시됩니다)',
      WEBSET_ICON_H: '웹페이지 상단탭 앞에 표시되는 아이콘입니다\n아이콘 변경시 화면을 완전히 종료후 다시 접속하면\n수정한 아이콘을 확인할수 있습니다.',
      WEBSET_IMAGE_H: '웹페이지를 대표하는 이미지입니다.\n일반적으로 카카오톡 등에 URL 링크 공유시 표시되는 이미지입니다.\n(최소 200*200 이상, 최대 1200*630, 권장: 600*300)',

      NOTI: {
        NOPASS: '인증코드 및 비밀번호를 입력하세요',
        FAIL: '처리중 오류가 발생했습니다.',
        SUCCESS: '정상적으로 처리중입니다.',
      }
    },

    TRANSSTAT: [
      { id: 1, title: '입금대기중', text: '결제를 완료해 주세요.' },
      { id: 2, title: '결제완료', text: '판매자가 주문정보를 확인하기 전입니다.' },
      { id: 3, title: '배송준비중', text: '판매자가 주문을 확인하고 배송을 준비중입니다.' },
      { id: 4, title: '배송중', text: '물품이 발송되어 고객님께 배송중입니다.' },
      { id: 5, title: '배송완료', text: '상품수량후 구매확정을 선택하시면 거래가 종료됩니다.' },
    ],

    PRODHIST: [
      { title: '원재료 및 함량', value: '결제를 완료해 주세요.' },
      { title: '알레르기', value: '알레르기를 일으키는 재료가 사용되지 않았습니다.' },
      { title: '알레르기가 있다면 주의하세요', value: '아래 재료가 포함되어 조리되었습니다.' },
    ],

    TRANSLIST: [
      {
        id: 1, main: false, title: 'CJ대한통운', link: 'https://www.cjlogistics.com/ko/main', phone: '1588-1255',
        url: 'http://nexs.cjgls.com/web/service02_01.jsp?slipno=', date: '20201110'
      },
      {
        id: 2, main: false, title: '로젠택배', link: 'http://www.ilogen.com', phone: '1588-9988',
        url: 'https://www.ilogen.com/web/personal/trace/', date: '20201110'
      },
      {
        id: 3, main: false, title: '우체국택배', link: 'http://www.epost.go.kr', phone: '1588-1300',
        url: 'https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=', date: '20201110'
      },
      {
        id: 4, main: false, title: '편의점택배', link: 'http://was.cvsnet.co.kr', phone: '1577-1287',
        url: 'http://was.cvsnet.co.kr/reservation-inquiry/delivery/index.do?dlvry_type=domestic&invoice_no=', date: '20201110'
      },
      {
        id: 5, main: false, title: '한진택배', link: 'http://hanex.hanjin.co.kr', phone: '1588-0011',
        url: 'http://www.hanjin.co.kr/Delivery_html/inquiry/result_waybill.jsp?wbl_num=', date: '20201110'
      },
    ],

    MAILER: {
      TITLE: '메일 설정',
      DEFULTSET: '기본설정',
      IMPORT: '샘플 불러오기',
      // service = 'gmail', host = 'smtp.gmail.com', port = 587, secure = false, user = 'test', pass = 'test'
      SERVICE: '메일 서비스(서버명)',
      SERVICE_H: 'GMAIL의 경우 "gmail"을 입력하세요.',
      SERVICE_N: '영/숫자 및 특수문자[- _ .]만 입력가능합니다.',
      HOST: '호스트(SMTP)',
      HOST_H: 'GMAIL의 경우 "smtp.gmail.com"을 입력하세요.',
      HOST_N: '영/숫자 및 특수문자[.]만 입력가능합니다.',
      PORT: '포트',
      PORT_H: 'GMAIL의 경우 "587"일 입력하세요',
      PORT_N: '숫자만 입력가능합니다.',
      USER: '사용자 ID',
      USER_H: 'GMAIL의 경우 "email@gmail.com"과 같이 입력됩니다.',
      USER_N: '이메일 형식으로 입력하세요.',
      PWD: '비밀번호',
      PWD_H: '이메일 계정에 대한 비밀번호 입력하세요',
      PWD_N: '비밀번호에 " 는 입력할 수 없습니다.',
      TEST_EMAIL: (v) => `"${v}" 으로 테스트 메일을 보냈습니다.\n잠시후 메일을 정상적으로 받았는지 확인해보세요.\n만약 메일을 수신하지 못했다면, 메일 설정을 다시 한번 확인해보시기 바랍니다.`,
      MAIL: 'GMAIL 계정을 생성후 여기에 계정을 입력하세요.',
      MEIL_SET_ERROR: '"기본설정" 탭에서 보내는 메일을 먼저 설정하세요',
      EMAIL_LIMIT: '이메일 최대 개수를 초과하였습니다.',
      TIME_SET: '알림 제외 시간을 입력하세요.',

      // SET_GUIDE_TITLE: '# 메일 전송용 Gmail 설정하기',
      // SET_GUIDE_TEXT: `* 웹 서버에서 메일 전송을 위한 Gmail 설정 방법.\n* 보안 수준이 낮은 앱 엑세스 허용\n\n* 'Google 계정 관리 -> 보안 -> 보안 수준이 낮은 앱의 액세스' 를 사용으로 변경`,
      // SET_GUIDE_1: "- 아래 링크에 접속하여 '보안 수준이 낮은 앱 허용: 사용'으로 설정하거나",
      // SET_GUIDE_2: "- 아래 링크에 접속하여 '계속' 클릭하세요",
      // GOOGLE_URL_1: 'https://myaccount.google.com/lesssecureapps',
      // GOOGLE_URL_2: 'https://accounts.google.com/DisplayUnlockCaptcha',
      PREVIEW: '미리보기',
      TEST: '테스트 메일을 발송하였습니다.',

      EMAIL: {
        TITLE: '이메일 변경',
        GUIDE: '사용자가 이메일 변경시 인증관련 메일을 보내기 위한 폼입니다.',
        SUBJECT: '제목',
        TEXT: '내용',
        LABEL: '라벨',
        COMMENT: '하단 알림글',

        DEFAULT: {
          title: '인증코드',
          subject: '인증코드를 알려드립니다.',
          text: (t = '') => `안녕하세요. ${t}입니다.\n항상 ${t}를 이용해 주시는 회원님께 감사드립니다.\n\n아래의 인증코드를 web에서 인증번호란에 입력하고 확인 버튼을 클릭 하세요.`,
          comment: (t = '') => `언제나 ${t}를 이용해주시는 고객님께 감사드립니다.\n고객님이 만족하실 때까지 최선의 서비스로 보답하겠습니다.\n본 메일은 발신전용으로 회신되지 않습니다.`,
        }
      },

      PASS: {
        TITLE: "비밀번호 찾기",
        GUIDE: '비밀번호 찾기시 임시비밀번호 발급용 메일을 보내기 위한 폼입니다.',
        SUBJECT: '제목',
        TEXT: '내용',
        LABEL: '라벨',
        COMMENT: '하단 알림글',

        DEFAULT: {
          title: '임시비밀번호',
          subject: '임시비밀번호가 새로 발급되었습니다.',
          text: (t = '') => `안녕하세요. ${t}입니다.\n항상 ${t}를 이용해 주시는 회원님께 감사드립니다.\n\n비밀번호 찾기를 통해 발급된 아래의 임시비밀번호를 로그인시 이용하시면됩니다.\n\n로그인 후 마이페이지 -> 회원정보에서 비밀번호를 꼭 변경하세요.`,
          comment: (t = '') => `언제나 ${t}를 이용해주시는 고객님께 감사드립니다.\n고객님이 만족하실 때까지 최선의 서비스로 보답하겠습니다.\n본 메일은 발신전용으로 회신되지 않습니다.`,
        }
      },

      ALARM: {
        TITLE: "신규 주문 및 알림",
        GUIDE: '신규 주문 및 알림 발생시 관리자에게 통지 메일을 보내기 위한 폼입니다.',
        SUBJECT: '제목',
        TEXT: '내용',
        LABEL: '라벨',
        COMMENT: '하단 알림글',
        CYCLE: '알림주기를 선택하세요(선택한 알림주기마다 체크하여 신규알림 발생시 등록된 메일주소로 통지 메일을 보냅니다).',
        STIME: '5분',
        DTIME: '10분',
        MTIME: '20분',
        LTIME: '30분',
        TIMEON: 'ON',
        TIMEOFF: 'OFF',
        FROM: '부터',
        TO: '까지 알림 금지',
        EMAIL: '최대 5개까지 등록할수 있습니다(이메일주소 끝에 세미콜론[;] 을 꼭 추가하세요).',
        OFFTIME: '알람 제외시간을 설정하면 해당 시간에는 알림 통지 메일을 보내지 않습니다.\n시간은 24시 표기법으로 0~23시까지 설정할 수 있습니다.',
        OFFSTART: '알람제외 시작시간',
        OFFEND: '알람제외 종료시간',

        DEFAULT: {
          title: '신규 주문 및 알림',
          subject: '신규 주문 및 알림',
          text: `신규 주문 및 알림이 발생했습니다.`,
        }
      },

      ORDER: {
        TITLE: '주문/배송 안내 메일',
        GUIDE: '고객이 주문한 상품에 대하여 배송 안내 메일을 보내기 위한 폼입니다.',
        SUBJECT: '제목',
        LABEL: '라벨',
        TEXT: '내용',
        COMMENT: '하단 알림글',

        DEFAULT: {
          title: '주문/배송 안내',
          subject: '주문하신 상품이 배송을 시작하였습니다.',
          text: (t = '') => `안녕하세요. ${t}입니다.\n항상 ${t}를 이용해 주시는 회원님께 감사드립니다.\n\n아래의 상품에 대하여 발송이 시작되었습니다.`,
          comment: (t = '') => `언제나 ${t}를 이용해주시는 고객님께 감사드립니다.\n고객님이 만족하실 때까지 최선의 서비스로 보답하겠습니다.\n본 메일은 발신전용으로 회신되지 않습니다.`,
        }
      }
      // EMAIL_SET: '',
    },

    APPSET: {
      DEFAULT: '기본정보',
      COMPANY: '회사정보',
      TRANS: '배송일반',
      TRANSTAT: '배송상태',
      TRANINIT: '배송상태 기본설정',
      PRODHIST: '최근추가항목관리',
      TRANLIST: '배송(택배)사 목록',
      COMPANY_NAME: '회사이름',
      COMPANY_YEAR: '설립년도',
      COMPANY_NAME_ENG: '회사이름(영문)',
      // COMPANY_NAME_ENG_H: '영문/숫자만 입력할 수 있습니다.',
      COMPANY_PHONE: '고객센터 대표번호',
      COMPANY_EMAIL: '고객센터 이메일',
      COMPANY_ADDR: '회사주소',
      COMPANY_ADDR_REST: '상세주소',
      COMPANY_BOSS: '대표이사',
      COMPANY_LICENSE: '사업자등록번호',
      COMPANY_BIZ_LICENSE: '통신판매업신고',
      COMPANY_BIZ_URL: '공정거래위워회 사이트 라이선스(URL)',
      COMPANY_FAX: '팩스번호',
      COMPANY_BANK: '은행 계좌번호',
      REFUND: '배송/교환/환불안내 메시지를 입력하세요',
      REFUND_NOTI: '상품 최하단에 노출되는 기본 배송/교환/환불안내 메시지입니다.',
      COMMENT_NOTI: '상품후기에 대한 안내 메시지를 입력하세요',
      DELIVERY_NOTI: '[배송시 요청사항 선택하기] 항목을 아래에 추가하세요.',
      DELIVERY_HELP: '사용자에게 선택가능한 항목들을 표시합니다. 항목은 줄 단위로 구분되어 표시됩니다.\n제일 마지막 문장은 "직접입력" 또는 "기타"를 입력하세요.',
      IMPORT: '샘플 불러오기',
      EVENT_NOTI: (v = '*****', s = 0) => `배송비 무료 이벤트 금액(구매 금액 ${v}원 이상이면 배송비 무료)을 설정하세요\n - 0원을 입력할 경우 모든 고객의 주문/결제금액에 기본 배송비(${s}원)가 추가됩니다.`,
      COMMENT_H: "후기를 매주 추첨을 통해 소정의 상품의 드립니다",
      PRIVACY: '개인정보취급방침 안내문을 아래에 입력하세요.\n샘플 불러오기를 통해 가져올 경우 내용을 모두 살펴보신후 본인에 맞도록 수정후 사용하시기 바랍니다.\n특히 ***** 등으로 표시된 부분은 꼭 수정하시기 바랍니다.',
      PUBLIC: '이용약관 안내문을 아래에 입력하세요\n샘플 불러오기를 통해 가져올 경우 내용을 모두 살펴보신후 본인에 맞도록 수정후 사용하시기 바랍니다.\n특히 ***** 등으로 표시된 부분은 꼭 수정하시기 바랍니다.',
      DELIVERY_DEFAULT: '부재시 경비실에 맡겨주세요.\n빠른 배송 부탁드립니다.\n부재시 핸드폰으로 연락바랍니다.\n배송 전 연락바랍니다.\n벨 누르지 말고 현관문앞에 놓아두세요.\n직접입력',
      PACKAGE_NOTI: '[결제정보 화면]의 배송지 아래 안내로 표시될 배송안내 문구를 입력하세요.',
      PACKAGE_DEFAULT: '오후 3시 이전에 주문시 기본적으로 다음날 도착 예정입니다.\n정확한 시간은 택배 배송 상황에 따라 다를 수 있습니다.\n새벽배송 가능지역(서울, 경기, 인천)의 경우 새벽배송을 선택하시면 새벽에 배송됩니다.',
      TRANSTAT_NOTI: '* 배송 안내에 대한 상태 정보를 추가하세요.\n - [불러오기] 버튼을 클릭하면 기본 정보로 설정됩니다.',
      TRANLIST_NOTI: '* 배송(택배)사를 추가/삭제할 수 있습니다.\n - [불러오기] 버튼을 클릭하면 기본 목록을 가져옵니다.',
      TRANSTAT_TITLE: '제목을 10자 이내로 작성',
      TRANSTAT_DESC: '간단한 설명을 30자 이내로 작성',
      TRANSTAT_HELP: '비어있는 곳을 모두 채워야합니다',
      TRANLIST_HELP: '더이상 삭제할 수 없습니다.',
      TRANINIT_HELP: '* 주문/결제 상태에서 배송정보 입력시 기본상태 값을 여기서 변경할 수 있습니다.',
      NO_CHANGE: '변경/삭제할수 없습니다.',
      NOT_TEXT: `특수문자 " \\ 는 입력할 수 없습니다.`,
      PRODHIST_HELP: '제목은 필수로 입력해야 합니다.',
      PRODHIST_NOTI: '* 상품 등록시 추가한 원재료, 알레르기, 주의사항에 대한 최근 추가내역입니다.\n - 상품 등록시 [빠른설정]을 위해 최대 10개까지 최근에 등록한 순서로 자동 저장되었습니다.',
      REFUND_DEFAULT: `1) 주문변경 및 취소가능시간\n - 주문 당일 배송전 전액[100%] 환불해드립니다.\n\n2) 배송시간\n - 평일 기준 5시이전 주문시 당일발송합니다.\n - 토요일 및 휴일은 배송업무를 진행하지 않습니다.\n - 당일발송/익일배송을 기본으로하나 택배상의 사정에따라 배송지연이 발생할수 있는점 양해바랍니다. \n\n3) 교환/환불/반품\n - 제품 하자에 의한 교환/환불/반품은 100% 보장합니다.\n - 고객님의 단순 변심에 의한 교환/환불은 상황에따라 조치가 달랍니다.\n\n * 문제가 없도록 최선의 노력을 기울이고 있습니다.`,
      COMMENT_DEFAULT: `후기를 남겨주시면, 최대한 고객님의 마음에 보답하여 정성을 다하도록 노력하겠습니다.`,
      TRANS_DEFAULT: `오늘도 고객님의 사랑에 감사드리며, 항상 최선의 다하겠습니다. 감사합니다.`,
    },

    WEBSET: {
      TITLE: '웹앱 설정',
      KAKAO: '카카오 연동',
      NAVER: '네이버 연동',
      NAVERSMS: '문자서비스 연동',
      NCPSMS: '네이버클라우드플랫폼 문자서비스 연동',
      PAYMENT: '결제서비스 연동',
      PG_ID: '아임포트 가맹점 식별코드',
      PG_ID_NOTI: '아임포트 등록후 관리자 화면의 가맹점 식별코드를 입력하세요(상품 결제를 위한 아임포트(PG사) 연동을 위해 필요합니다)',
      PG_AKEY: '아임포트 REST API 키',
      PG_AKEY_NOTI: '아임포트 등록후 관리자 화면의 REST API 키를 입력하세요(상품 결제를 위해 사용됩니다)',
      PG_SKEY: '아임포트 REST API secret 키',
      PG_SKEY_NOTI: '아임포트 등록후 관리자 화면의 REST API secret 키를 입력하세요(상품 결제를 위해 사용됩니다)',
      KAKAO_KEY: '카카오 개발자 [REST API 키]',
      KAKAO_KEY_NOTI: '카카오 개발자 등록후 발급된 REST API 키를 입력하세요(카카오 맵 및 카카오 주소록 이용시 사용됩니다)',
      KAKAO_KEY_SCRIPT: '카카오 개발자 [JavaScript 키]',
      KAKAO_KEY_NOTI_SCRIPT: '카카오 개발자 등록후 발급된 JavaScript 키를 입력하세요(카카오 로그인에 사용됩니다.)',
      NAVER_CLIENT_ID: '네이버 개발자 [Client ID]',
      NAVER_CLIENT_ID_NOTI: '네이버 개발자 등록후 발급된 Client ID를 입력하세요(네이버 로그인에 사용됩니다)',
      NAVER_CLIENT_SECRET: '네이버 개발자 [Client Secret]',
      NAVER_CLIENT_SECRET_NOTI: '네이버 개발자 등록후 발급된 Client Secret를 입력하세요(네이버 로그인에 사용됩니다)',
      NAVER_LOGIN_TEST: '네이버 로그인 테스트',
      NAVER_TEST_SUCCESS: '정상적으로 설정되었다면 팝업화면에 네이버 로그인 화면이나 웹하면이 보여야 합니다.\n페이지를 찾을수 없습니다. 화면이 표시된다면 설정을 다시 확인후 재설정하시기 바랍니다.\n\n관리자로 재로그인 하세요.',
      NAVER_TEST_FAIL: '네이버 로그인 정보를 다시 확인후 재설정하세요.',
      NAVER_URL_COPY: (v) => `${v}\n위 문자를 복사하여 서비스 URL에 붙여넣으세요.`,
      NAVER_CALL_COPY: (v) => `${v}\n위 문자를 복사하여 네이버아이디로로그인 Callback URL에 붙여넣으세요.`,
      NCP_SERVICE_NOTI: '네이버 클라우드 플랫폼의 문자서비스를 이용해 사용자 휴대폰 등록시 필요한 문자인증 서비스를 제공합니다.\n발신번호 등록시 등록된 번호와 입력한 번호가 일치해야 하는 것에 주의하세요.',
      NCP_SERVICE_ID: 'NCP(네이버 클라우드 플랫폼) [Service ID]',
      NCP_SERVICE_ID_NOTI: 'NCP 등록후 발급된 Service ID를 입력하세요',
      NCP_ACCESS: 'NCP(네이버 클라우드 플랫폼) [Access Key]',
      NCP_ACCESS_NOTI: 'NCP 등록후 발급된 Access Key를 입력하세요',
      NCP_SECRET: 'NCP(네이버 클라우드 플랫폼) [Secret Key]',
      NCP_SECRET_NOTI: 'NCP 등록후 발급된 Secret Key를 입력하세요',
      NCP_PHONE: 'NCP(네이버 클라우드 플랫폼) [발신번호]',
      NCP_PHONE_NOTI: 'NCP 등록시 저장한 발신번호를 여기에 입력하세요\n* 발신번호는 숫자만 입력하고 NCP에 등록한 번호와 동일해야 합니다.',
      NCP_TEST: 'SMS 테스트',
      NCP_TEST_PHONE: '문자를 수신받을 휴대폰번호(숫자만)를 입력하세요.',
      NCP_TEST_SUCCESS: 'SMS 문자를 전송하였습니다.\n문자가 수신되었는지 확인하세요.',
      NCP_TEST_FAIL: '알수없는 오류로 문자서비스를 이용할 수 없습니다.\n설정 정보를 다시 한번 확인하세요.',
      SERVER: '서버 설정',
      DOMAIN: '도메인설정(ex : http://daum.net)',
      DOMAIN_G: '서버의 url(domain)을 설정하세요. 관리자의 알림 push 등에 사용됩니다(ex : http://daum.net)',
      DOMAIN_H: '도메인설정(ex : http://daum.net)',
      DOMAIN_TEST: '알림 테스트',
      DOMAIN_TEST_T: '도메인 설정 알림 테스트',
      DOMAIN_BTN: '도메인 자동설정',
      DOMAIN_SET: (v = '') => `도메인이 아직 설정되어 있지 않습니다.\n도메인을 [${v}]로 자동 설정하시겠습니까?`,
      DOMAIN_RESET: (v = '') => `도메인을 [${v}]로 재설정 하시겠습니까?`,
      KAKAO_MAP_TEST: '카카오 맵 테스트',
      KAKAO_LOGIN_TEST: '카카오 로그인 테스트',
      KAKAO_LOGIN_SUCCESS: (v) => `카카오 로그인이 성공했습니다.\n\n* (${v})로 로그인 하셨습니다`,
      KAKAO_LOGIN_FAIL: '카카오 로그인이 실패하였습니다.\n카카오 개발자 [JavaScript 키]가 올바른지 확인하고, 인터넷 연결이 정상적인지 확인하세요.',
      PAYMENT_TEST: '결제 연동 테스트',
      PAYMENT_TEST_FAIL: '결제 연동 테스트에 실패하였습니다.\n설정 정보를 다시 한번 확인하세요.',
      DOMAIN_TEST_MSG: '몇초 내에 우측상단에 알림아이콘이 표시되어야 합니다.\n알림 아이콘을 클릭하면 "도메인 설정 알림 테스트" 메시지가 표시되어야 합니다.',
      NOT_URL: 'http or https 로 시작하는 URL 형식을 입력하세요.',
    },

    TRANS: {
      PRICE: '기본 배송비',
      PRICE_H: '기본 배송비를 입력하세요',
      MAX: '배송비 무료 금액',
      MAX_H: '설정 금액 이상 구매시 무료',
      NAME: '택배사명',
      LINK: '홈페이지',
      URL: '배송(송장)조회',
      PHONE: '연락처',
      MAIN: '주요거래처'
    },

    ABOUT: {
      TITLE: '회사 정보 설정',
      INFO: '회사소개 화면 설정',
      SLOGAN: '경영철학 화면 설정',
      STORE: '오프라인 매장 화면 설정',
      LABEL: '회사 슬로건 또는 타이틀(20자이내)',
      SUMMARY: '회사 철학 또는 이력 등',
      COMPANY: '회사명',
      ICON_IMG: '회사 메인 아이콘(중간 아이콘)',
      IMG_SIZE: '권장사이즈(120 * 120)',
      BOSS_IMG: '대표 이미지(썸네일)',
      BACK_IMG: '회사소개 배경 이미지',
      BACK_IMG_NOTI: '여기에 이미지를 업로드하면 회사소개 화면의 흰바탕화면을 배경이미지로 교체할 수 있습니다.\n최대 16:9 비율의 ([1600 * 900 or 1280 * 720] 사이즈를 권장합니다. 현재 보이는 사이즈는 [640 * 320]입니다.',
      STORE_IMG: '대표매장 이미지(썸네일)',
      ADDRESS: '매장 주소',
      STORE_TITLE: '지점명(본점 or 1호점 or 강남점 등)',
      PHONE: '매장 대표연락처',
      OPEN: '매장 영업시간 (예시: 11시 ~ 20시 [토요일 19시 / 일요일 휴무])',
      STORE_NOTI: '본사 연락처 및 주소는 [회사설정] 메뉴에서 수정할 수 있습니다.',
    },

    NAVI: {
      MAIN: '메인',
      MENU: '메뉴관리',
      MEMBER: '회원관리',
      HOME: '메인상품설정',
      PRODUCT: '상품관리',
      CONTENT: '제품',
      PAYMENT: '결제정보',
      BOARD: '공지사항',
      ABOUT: '회사소개',
      SETTING: '기본설정',
      MAILER: '메일관리',
      SYSINIT: '시스템초기화',
      WEBSET: '앱연동설정',
      MANAGEMENT: '시스템관리',
      GUIDE: '도움말',
      ORDER: '주문/배송',
      HISTORY: '구매이력',
      USER: '관리자',
      LOGIN: '로그인',
      QNA: '문의하기',
      LOGOUT: '로그아웃',
    },

    LOGIN: {
      TITLE: "로그인",
      WELCOME: `관리자 로그인`,
      PASSWORD_FIND: "비밀번호 찾기",
      NOTI_LOGIN: "로그인 정보를 정확히 입력하세요.",
    },

    MEMBER: {
      TITLE: '회원',
      NO: "번호",
      USERID: "회원번호",
      ACCOUNTID: "사용자아이디",
      AUTH: "권한",
      NAME: "이름(닉네임)",
      PHONE: "연락처",
      EMAIL: "이메일",
      SDATE: "가입일",
      PASSWORD: "비밀번호",
    },

    HISTORY: {
      TITLE: '회원',
      USERID: "회원번호",
      ACCOUNTID: "사용자아이디",
      AUTH: "권한",
      NAME: "이름(닉네임)",
      PHONE: "연락처",
      EMAIL: "이메일",
      SDATE: "가입일",
      PASSWORD: "비밀번호",
      COUNT: "구매개수",
      COUNT_H: "구매개수는 [오늘/일주전/전체] 순으로 표시됩니다.",
      LINK_ORDER: "항목을 클릭하면 주문/배송 화면으로 이동하여, 상세정보를 볼 수 있습니다.",
      ORDERNO: "주문번호",
      PRICE: "금액",
      PRODUCT: "주문내역",
      STATUS: "상태",
      ODATE: "구매일",
      TNAME: '택배사',
      TNO: '송장번호',
    },

    HOME: {
      TITLE: '슬라이드 항목 설정',
      NOTI: '슬라이드할 목록을 아래에 등록하세요(등록 가능한 이미지 최대 사이즈는 1900 * 1200 입니다).',
      LINK: '여기를 클릭해서 이 페이지와 연결될 URL를 설정하세요.',
      MENUS: '메뉴로 바로가기',
      PRODUCTS: '상품으로 바로가기',
    },

    PRODUCT: {
      TITLE: '상품',
      NO: "번호",
      MENU: "메뉴명",
      MENUID: "메뉴코드",
      PRODID: "제품코드",
      CONTENT: "상세정보",
      // EDIT: "상세정보",
      ORDER: "순번",
      SUBJECT: "상품명",
      NAME: "상품명을 입력하세요",
      SALE: "세일",
      COST: "판매가",
      PRICE: "세일가",
      // RCOUNT: "재고/판매",
      RCOUNT: "재고량",
      SCOUNT: "판매량",
      PRICE_ADD: "세일가 추가",
      PRICE_CLEAR: "세일가 삭제",
      PRICE_G: "* 세일가를 추가하면 아이템이 세일 상태로 표시됩니다.",
      SALE_G: "세일가격이 판매가격 보다 작아야합니다.",
      COUNT: "구매수량",
      PAYMENT: "결제금액",
      CARTIN: "장바구니 담기",
      CARTGO: "장바구니 보기",
      CARTSHOW: "장바구니에 담았습니다.",
      CAPACITY: "용량/개수",
      CAPACITY_H: "(100g/1인분 등)",
      CAPACITY_G: "용량 또는 판매개수 등을 입력하세요(100g/1인분/1개 등)",
      LIFETIME: "유통기한",
      LABEL: "상품 꾸밈 문장을 짧게 입력하세요",
      TITLE_CHANGE: "제목을 변경하려면 클릭하세요",
      THUMB: "썸네일",
      THUMB_G: "* 상품목록(홈) 화면에서 표시될 대표 이미지를 추가하세요",
      STEXT: "소개",
      IMAGES: "상품이미지",
      REFUND: "배송/교환/환불안내",
      REFUND_LABEL: "* 주문 전 반드시 확인하세요!",
      NEW_OPTS: "옵션 추가",
      OPT_LABEL: "옵션명(제목)",
      OPT_VALUE: "옵션값",
      OPT_LIMIT: "옵션은 최대 3개입니다.",
      OPT_GUIDE: "* [옵션]사용자에게 표시할 내용을 추가할 수 있습니다.",
      COMMENT: "상품후기",
      COMMENT_H: "후기는 상품을 구매해야 남길수 있습니다.",
      COMMENT_G: "고객님의 소중한 상품후기를 남겨주세요(최대 200자/5줄).",
      COMMENT_BTN: '로그인하고 상품후기 남기기',
      MAX_LINE: (v = 4) => `최대 ${v}줄까지만 입력할수 있습니다.`,
      MAX_LENGTH: (v = 200) => `최대 ${v}자까지만 입력할수 있습니다.`,

      GUIDE: '* 원가 보다 판매가가 낮을경우 세일 상태로 아이템을 표시합니다.',
      NOTI: (v) => `${v}을 입력하세요.`,
      BATCH: '재고량 일괄입력',
      NO_MENUS: '메뉴관리에서 메뉴를 먼저 추가해야 합니다.',
      HELP: '* 재고량을 0이면 [일시품절]로 1이상이면 [판매중] 상태로 자동 변경됩니다.\n* 순번을 변경하려면 우측의 화살표를 드래그앤 드롭하여 변경하세요.\n - 또한 순번은 순번을 클릭하여 팝업창을 통해 변경도 가능합니다.\n* 재고량을 새로 입력하려면 재고량 항목을 클릭하세요.',
      RCOUNT_NOTI: (v = 0) => `현재 재고량이 부족해 더이상 구매할수 없습니다.\n최대구매가능 수량은 ${v}개 입니다.`,
      RCOUNT_ZERO: (v = 0) => `해당 상품은 재고량이 모두 소진되어 장바구니에서 삭제합니다.\n빠른 시일내에 재입고하도록 하겠습니다.`,
      ORDER_CHANGE: (v) => `${v}의 순번을`,
      ORDER_CHANGE_L: `번으로 변경합니다.`,
      ORDER_NOTI: (v) => `마지막 순번은 ${v}번 입니다.`,
    },

    ORDER: {
      PGLINK: 'PG사 결제내역',
      ODRID: '주문번호',
      NAME: '수령인',
      PRICE: '결제금액',
      PHONE: '연락처',
      ODRNO: '결제번호',
      ZIPCODE: '우편번호',
      ADDRESS: '배송주소',
      STEXT: '배송시 주의사항',
      PRODUCT: '주문상품',
      DATETIME: '주문일',
      STATUS: '배송상태',
      TNAME: '택배사',
      TID: '택배사번호',
      TURL: '배송조회',
      THOME: '택배사 홈페이지 바로가기',
      TURL_LINK: '배송조회 바로가기',
      TLINK: '홈페이지',
      TNO: '송장번호',
      TPHONE: '대표번호',
      TCOST: '배송비',
      TRANS_INIT_STATE: (v = '') => `이후로 배송정보 입력시 상태를 [${v}]로 기본설정하도록 하시겠습니까?\n\n이후 변경은 기본설정에서 변경할수 있습니다.`,
      DETAIL: '주문상세',
      CANCEL_REQ: '주문 취소요청',
      CANCEL_DTL: '취소정보보기',
      CANCEL: '결제/주문취소',
      IS_CANCEL: (n, v, p) => `"${n}"님의 "${v}"\n${p} 결제 건을 취소하시겠습니까?`,
      ADMIN_CANCEL: '관리자에 의해 결제 취소되었습니다.',
      TRANS_IN: '배송정보입력',
      TRANS_SEL: '배송(택배)사를 선택하세요',
      TRANS_R: '배송준비중',
      TRANS_S: '배송중',
      SENDMAIL: '주문/배송 메일을 고객에게 전송합니다.',
      TRANS_MAIL: '주문/배송 정보가 발송되었습니다.',
      CANCEL_NOTI: '주문/취소된 상품입니다.',
      PAYMENT_DETAIL: '결제상세정보',
      DIRECT_PAY: '일시불',
      LINK_URL: 'URL 바로가기',
      LINK_IAMPORT: 'PG 연동 페이지(아임포트) 바로가기',
      TRANS_NOTI: (v) => `${v}된 상품입니다.`,
      BATCH: '배송일괄등록',
      BATCH_TRANS: "배송사 일괄 선택 : ",
      BATCH_ERROR: (v = '송장번호') => `${v}가 비어있는 항목이 존재합니다.`,
      BATCH_CTRANS: '배송사 선택',
      BATCH_CSTATUS: '배송상태 선택',
      BATCH_MAX: '* 일괄등록은 한번에 최대 10개까지 등록할 수 있습니다.',
    },

    CONTENT: {
      THUMB_NOITEM: "여기를 클릭해 이미지를 추가하세요.\n이미지는 1200 * 800을 사용하세요.\n900 * 600 이상의 3:2 비율을 권장합니다.",
      THUMB_GUIDE: '* 이미지는 1200 * 800을 사용하세요.\n900 * 600 이상의 3:2 비율을 권장합니다.',
      NO_CONFIG: '원재료, 알레르기, 주의사항 등을 추가하려면 여기를 클릭하세요.',
      NO_CONTENT: '제품에 대한 이미지 및 설명을 추가하려면 여기를 클릭하세요.',
      LABEL_GUIDE: "필요시 이미지에 대한 라벨을 아래에 입력하세요(강조 문자로 굵고 큰 문자로 표시됩니다)",
      STEXT_GUIDE: "필요시 이미지에 대한 글을 아래에 입력하세요(이미지만 등록할 경우 비워둘수 있습니다)",
      IMAGE_GUIDE: "이미지를 등록하세요(이미지를 등록하지 않으면 소개글만 표시됩니다.)",
      DELETE_IMAGE: "이미지를 삭제하시겠습니까?",
      MAX_CONFIG: (v = 5) => `최대 ${v}개까지만 추가할 수 있습니다.`,
      REFUND: "배송/환불 안내 수정하기",
      TITLE: '제목',
      TEXT: '내용',
      SELECT: '최근내용 선택하기',
      // eslint-disable-next-line no-useless-escape
      OPT_ERROR: `괄호안의 특수문자( \\ ' \" ) 세개는 입력할 수 없습니다.`,
    },

    MENU: {
      TITLE: '메뉴',
      GUIDE: '* [메인홈]은 사용자가 홈페이지를 방문할 경우 바로 보여지는 홈 화면입니다.',
      VISIBLE: "메뉴 비활성화",
      VISIBLE_G: "체크를 해제하면 메뉴를 표시하지 않습니다.",
      LABEL: "소개문구",
      LABEL_G: "50자 이내로 간단한 설명을 추가하세요.",
      HOME: "전체보기",
      PRODUCTS: "상품개수",
      SELECT: '선택변경',
      HOME_G: "메인(전체보기) 화면으로 표시됩니다.",
      MAX_NOTI: "메뉴는 최대 10개까지만 등록할수 있습니다.",
      DELETE_NOTI: (m, v) => `[${m}] 메뉴에 등록된 상품이 ${v}개 있습니다.\n그래도 삭제하길 원하시면 "delete" 문자를 아래에 입력하세요.`,
    },
  },

  HOME: {
    TITLE: "전체",
    MAIN: "전체",
  },

  CART: {
    TITLE: "장바구니",
    CLEAR: '전체비우기',
    SHOPING: '쇼핑 계속하기',
    CARTPAY: '모두 주문하기',
    PRODUCT: '상품',
    NO: '번호',
    COUNT: '수량',
    PRICE: '상품금액',
    PAYMENT: '결제금액',
    CARTIN: '장바구니 담기',
    NODATA: '장바구니가 비었습니다.',
    EVENT_T: '배송비 무료 이벤트',
    UPDATE: '변경',
    COMMENT: '후기남기기',
    TRANS_COST: '배송비',
    SUM: '합계',
    EVENT_L: (v, c) => `구매 금액 ${v}원 이상이면, 배송비 (${c}원) 무료`,
    EVENT_D: (v = '20,000') => `이벤트 적용까지 ${v}원 부족해요`,
    TOTAL: (v = 0) => `총 ${v} 개`,
    DUP_NOTI: `중복된 상품입니다. 중복된 해당 상품은 삭제됩니다. 상품 삭제후 구매수량을 확인하세요.`,
  },

  PAYMENT: {
    TITLE: "주문/결제",
    PRODUCT: '상품정보',
    COUNT: '수량',
    PRICE: '합계금액',
    PAYMENT: '결제금액',
    ADDRESS: '배송지 정보',
    BANK: (v) => `무통장입금(계좌: ${v})`,
    CARD: '신용카드/간편결제',
    KAKAO: '카카오페이',
    PHONE: '휴대폰소액결제',
    SUM: (v) => `상품합계 ${v}원`,
    TRANS: (v) => `배송료 ${v}원`,
    POINT: (v) => `적립금사용 ${v}원`,
    TOTAL: '총 결제금액 :',
    NEXT: '다음단계',
    CANCEL: '취소하기',
    DELIVERY: '배송시 요청사항 선택하기',
    REQUIRED: '필수 입력사항입니다.',
    NEW_ADDRESS: '+ 배송지 새로 입력하기',
    SEL_ADDRESS: '* 이전 배송지 선택하기',
    LAST_ORDER: '최근배송지',
    EMAIL_SET: '이메일 주소가 없습니다. 이메일 주소를 설정해주세요\n주문/결제 및 배송정보를 이메일로 보내드립니다.\n지금 이메일을 설정 하시겠습니까?',
    EMAIL_NO: '다음부터 이알림 보지않기',
    ETC: (v = 1) => `외 ${v}개`,
  },

  PORDER: {
    TITLE: "주문/결제",
    CART: "장바구니",
    PROGRESS: "결제 진행중...",
    SUCCESS: "주문/결제 완료",
    FAIL: "결제 실패",
    FINISH: "주문완료",
    NO: "주문번호",
    NAME: "주문자",
    PHONE: "연락처",
    ITEM: "주문상품",
    PRICE: "결제금액",
    ADDRESS: "배송주소",
    CANCEL: "주문취소",
    CANCEL_OK: "주문/결제 취소",
    CANCEL_READY: '결제취소중...',
    CANCEL_MSG: (v) => `"${v}"을 취소(요청) 하시겠습니까?\n\n* 담당자 확인후 배송전이면 바로처리해 드리겠습니다.`,
    CANCEL_REASON: "고객이 주문 취소를 요청하였습니다.",
    NOTI: "오류가 발생하였습니다.\n잠시후 다시 시도해보시기 바랍니다.",
    BACK: "주문/결제 화면으로 돌아가기",
    PAY_ERROR: (v) => `결제에 실패하였습니다.\n문의사항은 고객센터(${v})로 연락바랍니다.`,
    KEY_ERROR: (v) => `결제 시스템에 일시적 오류가 발생하였습니다.\n문의사항은 고객센터(${v})로 연락바랍니다.`,
  },

  MYPAGE: {
    HISTORY: '주문/배송 조회',
    REFUND: '취소/교환/반품',
    ADDRESS: '배송주소록',
    MEMBER: '회원정보',
    CASHING: '적립금 내역',
    COMMENT: '후기/재구매',
    COUPON: '쿠폰 등록/사용',
    FIND_DATE: '조회기간',
    CARTNO: '결제일(주문번호)',
    PRODUCT: '상품정보',
    PRICE: '총결제금액',
    STATUS: '주문상태',
    REC_USER: '수령인',
    REC_ADDR: '주소',
    REC_PHONE: '연락처',
    REC_SET: '관리',
    REC_ADD: '기본배송지 설정',
    REC_DEL: '삭제',
    REC_NEW: '새 배송지 등록',
    CSH_TITLE: '내가 사용할 수 있는 적립금',
    CSH_LIST: '과거 내역 확인하기',
    CSH_DATE: '날자',
    CSH_LIMIT: '만료일',
    CSH_PRICE: '금액',
    CSH_COUNT: '수량',
    CSH_STATUS: '상태',
    CSH_DESC: '내용',
    TOTAL: '합계금액',
    RES_USER: '받으시는분',
    POST_CODE: '우편번호',
    ADDR_F: '배송주소',
    ADDR_D: '상세주소',
    PHONE: '휴대폰번호(숫자만)',
    POST_FIND: '우편번호 검색',
    DETAIL: '상세보기',
    HISTORY_DETAIL: '주문/배송 상세보기',
    PAYINFO: '결제 정보',
    NOPAYINFO: '결제 정보를 찾지 못했습니다.',
    PROINFO: '주문 상품 정보',
    ORDERNO: "주문번호",
    TPRICE: "상품금액",
    TCOUNT: "상품수량",
    TCOST: "배송비",
    ADDRINFO: "배송 정보",
    TSTATUS: "배송 상태",
    CANCEL: '결제/주문 취소',
    CANCELED: '주문/결제가 취소되었습니다',
    ADDRESS_NOTI: '우편번호 검색으로 주소를 입력하세요',
    NAME_ERROR: '이름 입력에 특수문자는 사용할수 없습니다.',
    PASSWORD_SUCCESS: '비밀번호가 정상적으로 변경되었습니다',
    PASSWORD_FAIL: '비밀번호 변경에 실패하였습니다.\n이전 비밀번호를 확인후 다시 이용하세요.',
    SMS_SUCCESS: '인증코드가 발송되었습니다.',
    SMS_FAIL: '인증코드 발송에 실패하였습니다.\n문제가 계속 발생할 경우 관리자에게 문의주세요.',
    ADDRESS_MAX: "배송지는 최대 10개까지 등록할수 있습니다.\n삭제후 등록해주세요.",
    NO_IAMKEY: "일시적 오류로 사용이 불가능합니다.\n오류가 지속되면 관리자에게 문의주시기 바랍니다."
  },

  ABOUT: {
    TITLE: '회사소개',
    INFO: '회사소개',
    SLOGAN: '경영철학',
    STORE: '오프라인 매장 안내',
    SHOP: '쇼핑몰가기',
    LABEL: '레이블.....',
    SUMMARY: `소개 내용.....`,
    STORE_COME: '매장 오시는길',
    STORE_OPEN: '영업시간',
    PHONE: '고객선터 대표번호',
    ADDR: '본사주소',
  },

  LOGIN: {
    TITLE: "로그인",
    WELCOME: `안녕하세요! 누리숍입니다.`,
    KAKAO: "카카오계정으로 바로 이용하기",
    NAVER: "네이버로 로그인",
    FACEBOOK: "페이스북으로 로그인",
    PASSWORD_FIND: "아이디/비밀번호 찾기",
    NOTI_LOGIN: "로그인 정보를 정확히 입력하세요.",
    REST_LOGIN_ERROR: "일시적 오류가 발생하였습니다.\n잠시후 다시 이용해주세요",
    SIGNUP_ERROR: "회원가입을 먼저 해주세요.\n회원가입 화면으로 이동합니다.",
    SIGNUP_TIME: (v) => `회원가입을 먼저 해주세요.\n${v}초후 회원가입 화면으로 이동합니다.`,
  },

  SIGNUP: {
    TITLE: "회원가입",
    WELCOME: (v = '') => `${v ? v + '에 ' : ''}지금바로 가입하세요.`,
    KAKAO: "카카오계정으로 바로 이용하기",
    NAVER: "네이버로 회원가입",
    FACEBOOK: "페이스북으로 회원가입",
    MEMBER: "이메일로 회원가입",
    PASSWORD_NOTI: "비밀번호가 일치하지 않습니다.",
    DUPUSERID: "이미 사용자 ID 입니다.",
    DUPMEMBER: "이미 가입하셨습니다.\n로그인 화면으로 이동합니다.",
    REGIST_MSG: "회원가입을 축하드립니다.\n로그인 화면으로 이동합니다.",
    POLICY_ALL: "약관에 모두 동의합니다.",
    POLICY_PRI: "개인 정보 수집 및 이용에 동의(필수)",
    POLICY_PUB: "이용 약관 동의(필수)",
    POLICY_OPT: "주요 알림 및 각종 혜택 알림을 수신합니다(선택)",
    POLICY_NOTI: "(필수)약관에 모두 동의해야합니다.",
    DESC: "'회원가입'을 클릭하면 서비스약관 및 개인정보보호 정책에 동의하는 것입니다.",
    ID: "아이디(email@email.com)",
    PASSWORD_CHECK: "비밀번호 확인",
    REGIST: "이미 회원이신가요?",
    LOGIN: "로그인하기",
    NAME: "사용자 이름",

    H: {
      ID: "이메일 형식의 아이디를 입력하세요.",
      PASSWORD: "최소 1개이상 특수문자/영문/숫자를 조합하여 8~20자로 입력하세요",
      PHONE: "[-]없이 입력하세요(예:01012341234)",
    },
  },

  SIGNTO: {
    TITLE: "아이디/비밀번호 찾기",
    FINDID: "아이디 찾기",
    FINDPWD: "비밀번호 찾기",
    GOSIGNIN: "로그인으로 바로가기",

    ID_TIT: "내정보에서 입력한 연락처(휴대폰)를 입력하세요",
    ID_TXT: (v) => `내정보에서 연락처를 등록하지 않으셨다면,\n고객센터(${v})로 연락주시기 바랍니다.`,
    ID_GUIDE: "연락처(휴대폰번호)를 여기에 입력하세요.",
    ID_NOTI: "연락처는 숫자만 입력할 수 있습니다.",
    ID_FAIL: (v) => `[${v}]으로 등록된\n사용자아이디가 없습니다.`,

    PWD_TIT: "아이디[이메일형식]를 입력해주세요.",
    PWD_TXT: "아이디[이메일형식]로 임시비밀번호를 발급하여 보내드립니다.\n발급된 임시비밀번호로 로그인 후, [마이페이지 -> 내정보]에서 비밀번호를 꼭 변경하시기 바랍니다.\n\n* 간편로그인을 통해 가입하신 경우, 이메일이 등록되어 있지않으면, [내정보]에서 이메일을 먼저 등록하세요.",
    PWD_GUIDE: "아이디(이메일형식)를 여기에 입력하세요.",
    PWD_FAIL: (v) => `[${v}]으로 등록된\n아이디 또는 이메일이 없습니다.`,
    PWD_SEND: '아래의 이메일을 클릭하세요.',
    PWD_EMAIL_REG: '이메일을 먼저 등록해야합니다.',
    PWD_REST: '간편로그인을 이용해 등록된 사용자입니다',
    PWD_REST_F: (v) => `(으)로 등록된 아이디(${v})입니다.`,
    PWD_REST_S: '이용하기를 통해 로그인하시기 바랍니다.',

    EMAIL: "이메일을 입력하세요.",
    EMAIL_TITLE: "임시비밀번호",
    EMAIL_SUBJECT: "임시 비밀번호가 새로 발급되었습니다",
    SENDEMAIL: '위의 메일로 임시 비밀번호를 발송하였습니다.',
  },
}

export const ST = KOR;

export const PMATHOD = {
  'point': '카카오(네이버) 페이 또는 포인트 결제',
  'card': '카드 결제',
  'cash': '현금 결제',
  'phone': '핸드폰 결제',
}

export const PAY = {
  "imp_uid": { title: '결제고유번호', show: true, unit: '' },
  "merchant_uid": { title: '주문고유번호', show: true, unit: '' },
  "pay_method": { title: '결제방식', show: true, unit: '' },
  "channel": { title: '결제시스템', show: true, unit: '' },
  "pg_provider": { title: 'PG사', show: true, unit: '' },
  "pg_tid": { title: 'PG사 승인번호', show: true, unit: '' },
  "pg_id": { title: 'PG 아이디', show: true, unit: '' },
  "escrow": { title: '에스크로 결제여부', show: false, unit: '' },
  "apply_num": { title: '카드사 승인번호', show: true, unit: '' },
  "bank_code": { title: '은행코드', show: true, unit: '' },
  "bank_name": { title: '은행명', show: true, unit: '' },
  "card_code": { title: '카드사 코드', show: true, unit: '' },
  "card_name": { title: '카드명', show: true, unit: '' },
  "card_quota": { title: '할부개월수', show: true, unit: '개월', type: 'number' },
  "card_number": { title: '카드번호', show: true, unit: '' },
  "card_type": { title: '카드종류', show: false, unit: '' },
  "name": { title: '주문명', show: true, unit: '' },
  "amount": { title: '결제할 금액', show: true, unit: '원', type: 'number' },
  "currency": { title: '화폐단위', show: false, unit: '원' },
  "buyer_name": { title: '주문자 이름', show: true, unit: '' },
  "buyer_email": { title: '주문자 이메일', show: true, unit: '', type: 'email' },
  "buyer_tel": { title: '주문자 연락처', show: true, unit: '', type: 'phone' },
  "buyer_addr": { title: '주문자 주소', show: true, unit: '' },
  "buyer_postcode": { title: '주문자 우편번호', show: false, unit: '' },
  "custom_data": { title: '가맹점 임의 지정 데이터', show: false, unit: '' },
  "user_agent": { title: '사용자 웹정보', show: false, unit: '' },
  "status": {
    title: '결제상태', show: true, unit: '', child:
      { 'cancelled': '결제취소', 'failed': '결제실패', 'ready': '결제시작', 'paid': '결제완료' }
  },
  "started_at": { title: '결제요청시각', show: false, unit: '' },
  "paid_at": { title: '결제승인시각', show: true, unit: '', type: 'date' },
  "failed_at": { title: '실패시각', show: true, unit: '', type: 'date' },
  "fail_reason": { title: '실패사유', show: true, unit: '' },
  "receipt_url": { title: 'PG사 매출전표 URL', show: true, unit: '', type: 'url' },
  "cancelled_at": { title: '취소시각', show: true, unit: '', type: 'date', cancel: true },
  "cancel_amount": { title: '취소한 금액', show: true, unit: '원', type: 'number', cancel: true },
  "cancel_reason": { title: '취소사유', show: true, unit: '', cancel: true },
  "cancel_history": {
    title: '취소내역', show: false, unit: '', type: 'array', cancel: true,
    child: {
      "pg_tid": { title: '아이디', show: true, unit: '' },
      "amount": { title: '금액', show: true, unit: '원', type: 'number' },
      "cancelled_at": { title: '시각', show: true, unit: '', type: 'date' },
      "reason": { title: '이유', show: true, unit: '' },
      "receipt_url": { title: 'URL', show: true, unit: '', type: 'url' },
    }
  },
  "cancel_receipt_urls": { title: '취소 영수증 URL', show: true, unit: '', type: 'array', cancel: true },
  "cash_receipt_issued": { title: '현금결제사유', show: false, unit: '' },
  "customer_uid": { title: '고객고유아이디', show: false, unit: '' },
  "customer_uid_usage": { title: '고객고유아이디 사용유무', show: false, unit: '' },
  "vbank_code": { title: '가상계좌 은행코드', show: false, unit: '' },
  "vbank_name": { title: '가상계좌 은행명', show: false, unit: '' },
  "vbank_num": { title: '가상계좌 입금계좌번호', show: false, unit: '' },
  "vbank_holder": { title: '가상계좌 예금주', show: false, unit: '' },
  "vbank_date": { title: '가상계좌 입금기한', show: false, unit: '' },
  "vbank_issued_at": { title: '가상계좌 이슈', show: false, unit: '' },
}


/**
 * 이미지들에 대한 정의
 */
export const IMG = {
  IAMPORT_1: require('../images/iamport_1.jpg'),
  IAMPORT_2: require('../images/iamport_2.jpg'),
  IAMPORT_3: require('../images/iamport_3.jpg'),
  IAMPORT_4: require('../images/iamport_4.jpg'),
  IAMPORT_5: require('../images/iamport_5.jpg'),
  IAMPORT_6: require('../images/iamport_6.jpg'),
  IAMPORT_7: require('../images/iamport_7.jpg'),
  IAMPORT_8: require('../images/iamport_8.jpg'),
  IAMPORT_9: require('../images/iamport_9.jpg'),
  IAMPORT_10: require('../images/iamport_10.jpg'),
  IAMPORT_11: require('../images/iamport_11.jpg'),
  IAMPORT_12: require('../images/iamport_12.jpg'),
  IAMPORT_13: require('../images/iamport_13.jpg'),
  IAMPORT_14: require('../images/iamport_14.jpg'),
  IAMPORT_14_1: require('../images/iamport_14_1.jpg'),
  IAMPORT_14_2: require('../images/iamport_14_2.jpg'),
  IAMPORT_14_3: require('../images/iamport_14_3.jpg'),
  IAMPORT_15: require('../images/iamport_15.jpg'),
  IAMPORT_16: require('../images/iamport_16.jpg'),
  IAMPORT_17: require('../images/iamport_17.jpg'),
  IAMPORT_18: require('../images/iamport_18.jpg'),
  IAMPORT_99: require('../images/iamport_99.jpg'),

  KAKAO_1: require('../images/kakao_1.jpg'),
  KAKAO_2: require('../images/kakao_2.jpg'),
  KAKAO_3: require('../images/kakao_3.jpg'),
  KAKAO_4: require('../images/kakao_4.jpg'),
  KAKAO_5: require('../images/kakao_5.jpg'),
  KAKAO_6: require('../images/kakao_6.jpg'),
  KAKAO_7: require('../images/kakao_7.jpg'),
  KAKAO_8: require('../images/kakao_8.jpg'),
  KAKAO_9: require('../images/kakao_9.jpg'),
  KAKAO_10: require('../images/kakao_10.jpg'),
  KAKAO_11: require('../images/kakao_11.jpg'),
  KAKAO_12: require('../images/kakao_12.jpg'),

  NAVER_1: require('../images/naver_1.jpg'),
  NAVER_2: require('../images/naver_2.jpg'),
  NAVER_3: require('../images/naver_3.jpg'),
  NAVER_4: require('../images/naver_4.jpg'),
  NAVER_5: require('../images/naver_5.jpg'),
  NAVER_6: require('../images/naver_6.jpg'),
  NAVER_7: require('../images/naver_7.jpg'),
  NAVER_8: require('../images/naver_8.jpg'),
  NAVER_9: require('../images/naver_9.jpg'),
  NAVER_10: require('../images/naver_10.jpg'),
  NAVER_11: require('../images/naver_11.jpg'),

  GMAIL_1: require('../images/gmail_1.jpg'),
  GMAIL_2: require('../images/gmail_2.jpg'),
  GMAIL_3: require('../images/gmail_3.jpg'),
  GMAIL_4: require('../images/gmail_4.jpg'),
  GMAIL_5: require('../images/gmail_5.jpg'),
  GMAIL_6: require('../images/gmail_6.jpg'),
  GMAIL_7: require('../images/gmail_7.jpg'),
  GMAIL_8: require('../images/gmail_8.jpg'),
  GMAIL_9: require('../images/gmail_9.jpg'),
  GMAIL_10: require('../images/gmail_10.jpg'),

  NCP_1: require('../images/ncp_1.jpg'),
  NCP_2: require('../images/ncp_2.jpg'),
  NCP_3: require('../images/ncp_3.jpg'),
  NCP_4: require('../images/ncp_4.jpg'),
  NCP_5: require('../images/ncp_5.jpg'),
  NCP_6: require('../images/ncp_6.jpg'),
  NCP_7: require('../images/ncp_7.jpg'),
  NCP_8: require('../images/ncp_8.jpg'),
  NCP_9: require('../images/ncp_9.jpg'),
  NCP_10: require('../images/ncp_10.jpg'),
  NCP_11: require('../images/ncp_11.jpg'),
  NCP_12: require('../images/ncp_12.jpg'),
  NCP_13: require('../images/ncp_13.jpg'),
  NCP_14: require('../images/ncp_14.jpg'),
  NCP_15: require('../images/ncp_15.jpg'),
  NCP_16: require('../images/ncp_16.jpg'),
  NCP_17: require('../images/ncp_17.jpg'),
  NCP_18: require('../images/ncp_18.jpg'),
  NCP_19: require('../images/ncp_19.jpg'),

  NAVER_S_1: require('../images/naver_s_1.jpg'),
  NAVER_S_2: require('../images/naver_s_2.jpg'),
  NAVER_S_3: require('../images/naver_s_3.jpg'),
  NAVER_S_4: require('../images/naver_s_4.jpg'),
  NAVER_S_5: require('../images/naver_s_5.jpg'),
  NAVER_S_6: require('../images/naver_s_6.jpg'),
  NAVER_S_7: require('../images/naver_s_7.jpg'),
}

export const KAKAO_HELP = [
  { type: 'text', value: '1. 카카오 개발자 사이트 (https://developers.kakao.com)를 방문하세요.', url: 'https://developers.kakao.com' },
  { type: 'image', value: IMG.KAKAO_1, url: 'https://developers.kakao.com' },
  { type: 'desc', value: '카카오톡 로그인 연동을 위해 카카오 계정을 만들어야 합니다.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '2. 로그인을 합니다.', url: 'https://developers.kakao.com/login' },
  { type: 'image', value: IMG.KAKAO_2, url: 'https://developers.kakao.com/login' },
  { type: 'desc', value: '카카오 계정으로 카카오 개발자 페이지 로그인도 가능합니다.\n회원가입이 되어 있지 않을경우 회원가입을 먼저 하세요.' },
  { type: 'desc', value: '회원가입에 대한 보다 상세한 내용은 여기를 참고하세요.', url: 'https://ai-creator.tistory.com/20' },
  { type: 'image', value: IMG.KAKAO_3 },
  { type: 'line', value: '2px' },
  { type: 'text', value: '3. 개발자 등록을 해야 합니다.', url: 'https://developers.kakao.com/login' },
  { type: 'image', value: IMG.KAKAO_4, url: 'https://developers.kakao.com/login' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '4. 앱 만들기(=API 키 얻기).', url: 'https://developers.kakao.com/console/app' },
  { type: 'image', value: IMG.KAKAO_5, url: 'https://developers.kakao.com/console/app' },
  { type: 'desc', value: '앱을 등록하고나면 해당 만들어진 새로운 앱이 하단에 생성되면, 해당 앱을 클릭하여 진입하세요.', url: 'https://developers.kakao.com/docs/latest/ko/getting-started/app' },
  { type: 'desc', value: '앱 만들기에 대한 보다 상세한 내용은 여기를 참고하세요.', url: 'https://developers.kakao.com/docs/latest/ko/getting-started/app' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '5. 앱 만들기를 통해 만들어진 앱을 들어가서 API 키를 얻어야 합니다.', url: 'https://developers.kakao.com/console/app' },
  { type: 'image', value: IMG.KAKAO_6, url: 'https://developers.kakao.com/console/app' },
  { type: 'desc', value: '위와 같이 만들어진 앱 키중 REST API 키와 JavaScript 키를 복사하여 아래의 입력란에 등록하세요.' },
  { type: 'desc', value: '6. 카카오 로그인 활성화', url: 'https://developers.kakao.com/console/app' },
  { type: 'image', value: IMG.KAKAO_7 },
  { type: 'desc', value: '카카오 로그인 기능을 이용하기 위해서 [카카오 로그인] 활성화 설정을 해야하니다.' },
  { type: 'image', value: IMG.KAKAO_8 },
  { type: 'desc', value: '마지막으로 [플랫폼 -> Web] 항목에 도메인명을 입력하세요.\n도메인은 웹주소 [https://www.xxxxxx.co.kr] 등과 같이 입력해야합니다.' },
  { type: 'line', value: '5px', color: 'dark' },
  { type: 'desc', value: '\n* [JavaScript 키]를 올바로 등록한 후 [카카오 로그인 테스트]를 수행하면 아래와 같은 화면이 표시됩니다.' },
  { type: 'image', value: IMG.KAKAO_9 },
  { type: 'desc', value: '회원가입한 카카오 아이디를 통해 로그인을 수행합니다.\n키가 올바로 입력되고 로그인이 성공하면 위와 같이 팝업 화면이 표시됩니다.\n이미 카카오로 로그인되어 있는 상태에서 버튼을 클릭했다면, 우측의 팝업 화면만 표시됩니다.' },
  { type: 'image', value: IMG.KAKAO_10 },
  { type: 'desc', value: '만일 키가 올바르지 않게 입력되었다면. 로그인 시도후 위와 같은 화면을 보게 될것입니다.\n이 경우 키를 확인하세요.' },
  { type: 'image', value: IMG.KAKAO_11 },
  { type: 'desc', value: '만일 위와같이 오류 코드가 KOE004가 표시된다면, 카카오 로그인을 활성화하세요.', url: 'https://developers.kakao.com/console/app' },
  { type: 'line', value: '5px', color: 'dark' },
  { type: 'desc', value: '\n* [REST API 키]를 올바로 등록한 후 [카카오 맵 테스트]를 수행하면 아래와 같은 화면이 표시됩니다.' },
  { type: 'image', value: IMG.KAKAO_12 },
  { type: 'desc', value: '키가 올바로 입력되고 정상적으로 테스트 될 경우 왼쪽과 같이 표시되고, 실패할 경우 오른쪽과 같이 표시됩니다.\n실패할 경우 키가 올바로 입력되었는지, 인터넷 접속이 정상적인지 확인하세요.' },
];


export const NAVER_HELP = [
  { type: 'text', value: '1. 네이버 개발자 사이트 (https://developers.naver.com/)를 방문하세요.', url: 'https://developers.naver.com/' },
  { type: 'image', value: IMG.NAVER_1, url: 'https://developers.naver.com/' },
  { type: 'desc', value: '네이버 로그인 연동을 위해 네이버 개발자 계정을 만들어야 합니다.\n위 화면에서 [Application 등록] 버튼을 클릭하세요.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '2. 애플리케이션 정보를 입력하세요.', url: 'https://developers.naver.com/apps/#/register' },
  { type: 'image', value: IMG.NAVER_2, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'desc', value: '애플리케이션 이름을 상점 이름으로 등록하세요.\n사용 API를 [네아로 (네이버 아이디로 로그인)]을 선택합니다.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '3. 사용 API 정보를 설정하세요.', url: 'https://developers.naver.com/apps/#/register' },
  { type: 'image', value: IMG.NAVER_3, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'desc', value: '위 화면과 같이 회원이름, 이메일, 별명은 필수 선택사항입니다.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '4. 로그인 오픈 API 서비스 환경 정보를 입력하세요.', url: 'https://developers.naver.com/apps/#/register' },
  { type: 'image', value: IMG.NAVER_4, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'desc', value: '위 화면과 같이 환경 추가 버튼을 클릭하고 [PC 웹]을 선택하세요.' },
  { type: 'image', value: IMG.NAVER_5, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'desc', value: '서비스 URL에 상점의 실제 도메인(URL) 입력하세요.\n위의 파란색 박스 부분을 http://localhost => http://shopurl.co.kr 과 같이 서비스 URL을 입력하세요' },
  { type: 'desc', value: '네이버아이디로로그인 Callback URL (최대 5개)에 위와 동일하게 도메인을 입력하고, 뒤에 ?naver=true를 입력하세요.' },
  { type: 'desc', value: '모든 입력이 끝났으면, [등록하기] 버튼으로 정보를 저장하세요.' },
  { type: 'text', value: '5. 애플리케이션 정보를 복사해서 붙여넣으세요.', url: 'https://developers.naver.com/apps/#/register' },
  { type: 'image', value: IMG.NAVER_6, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'desc', value: 'Client ID를 복사하여 네이버 개발자 [Client ID] 부분에 붙여넣으세요.\nClient Secret를 복사하여 네이버 개발자 [Client Secret] 부분에 붙여넣으세요.\nClient Secret를 복사할때 [보기] 버튼을 클릭하여 문자가 보이면 복사할 수 있습니다.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '6. 마무리.', url: 'https://developers.naver.com/apps/#/register' },
  { type: 'image', value: IMG.NAVER_7, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'desc', value: '위와 같이 Client ID/Client Secret를 저장하고나면 [네이버 로그인 테스트] 버튼인 표시됩니다.\n테스트 버튼을 클릭하면 아래와 같은 팝업 화면이 정상적으로 표시되어야 합니다.\n이 화면에서 OK를 클릭하면 상점의 메인 화면이 보여야 합니다.' },
  { type: 'image', value: IMG.NAVER_8, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'line', value: '2px' },
  { type: 'desc', value: '만일 아래와 같은 화면이 표시된다면 도움말 4번의 서비스 URL 및 Callback URL을 다시 한번 확인하세요' },
  { type: 'image', value: IMG.NAVER_9, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'line', value: '2px' },
  { type: 'desc', value: '만일 아래와 같은 화면이 표시된다면 도움말 5번의 Client ID/Client Secret를 일치하게 복사하였는지 확인하세요' },
  { type: 'image', value: IMG.NAVER_10, url: 'https://developers.naver.com/apps/#/register' },
  { type: 'line', value: '5px' },
  { type: 'text', value: '7. 검수요청 및 로고 이미지.', url: 'https://developers.naver.com/apps/#/register' },
  { type: 'desc', value: '정상적으로 동작을 확인하였다면 정식 서비스를 위해 네이버에 검수요청을 해야 합니다.\n위 화면에서 [검수요청 하러 가기] 버튼을 클릭하여 검수 요청을 수행하세요.\n필요하다면 로고 이미지도 삽입하세요.' },
  { type: 'image', value: IMG.NAVER_11, url: 'https://developers.naver.com/apps/#/register' },
];


export const NCP_HELP = [
  { type: 'text', value: '1. 네이버 클라우드 플랫폼 사이트 (https://www.ncloud.com/product/applicationService/sens/)를 방문하세요.', url: 'https://www.ncloud.com/product/applicationService/sens/' },
  { type: 'image', value: IMG.NCP_1, url: 'https://www.ncloud.com/product/applicationService/sens/' },
  { type: 'desc', value: '네이버 클라우드 플랫폼 사이트를 방문하여 로그인을 수행합니다.\n2020년 기준 SMS는 월 50 건 무료에 건당 9원 제공됩니다.' },
  { type: 'line', value: '2px' },
  { type: 'desc', value: '회원가입이 필요할 경우 절차에따라 회원가입을 먼저해야 합니다.' },
  { type: 'image', value: IMG.NCP_2, url: 'https://www.ncloud.com/join/type' },
  { type: 'desc', value: '회원가입시 필요한 정보를 입력하고, 결제수단 등록을 완료하세요.' },
  { type: 'image', value: IMG.NCP_3, url: 'https://www.ncloud.com/join/type' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '2. 로그인 및 결제수단 등록.', url: 'https://auth.ncloud.com/login' },
  { type: 'image', value: IMG.NCP_4, url: 'https://auth.ncloud.com/login' },
  { type: 'desc', value: '필요시 결제수단을 등록해야 합니다.' },
  { type: 'image', value: IMG.NCP_5, url: 'https://www.ncloud.com/join/type' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '3. 문자 서비스를 위한 인증키를 복사하세요.', url: 'https://www.ncloud.com/mypage/manage/authkey' },
  { type: 'image', value: IMG.NCP_6, url: 'https://www.ncloud.com/mypage/manage/authkey' },
  { type: 'desc', value: '마이페이지 => 인증키 관리 메뉴로 이동하세요.\n인증키 관리 메뉴가 보이지 않을경우 결제수단 등록을 먼저 수행한 후 확인하세요' },
  { type: 'image', value: IMG.NCP_7, url: 'https://www.ncloud.com/mypage/manage/authkey' },
  { type: 'desc', value: '[신규 API 인증키 생성] 버튼을 클릭하면 위와 같이 API 인증키 관리 목록에 하나의 항목이 생성됩니다.' },
  { type: 'image', value: IMG.NCP_8, url: 'https://www.ncloud.com/mypage/manage/authkey' },
  { type: 'desc', value: '[Access Key ID]를 [Access Key] 입력란에 복사하여 붙여넣으세요.' },
  { type: 'desc', value: '[Secret Key]를 [Secret Key] 입력란에 복사하여 붙여넣으세요. [보기] 버튼을 클릭하면 문자를 확인할 수 있습니다.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '4. SMS 서비스를 등록 및 서비스 아이디 생성.', url: 'https://www.ncloud.com/product/applicationService/sens' },
  { type: 'desc', value: '[서비스 -> Simple & Easy Notification Service] 메뉴를 선택하세요' },
  { type: 'image', value: IMG.NCP_9, url: 'https://www.ncloud.com/product/applicationService/sens' },
  { type: 'image', value: IMG.NCP_10, url: 'https://www.ncloud.com/product/applicationService/sens' },
  { type: 'desc', value: '프로젝트 대시보드창이 열리면 프로젝트를 생성합니다.' },
  { type: 'image', value: IMG.NCP_11, url: 'https://console.ncloud.com/sens/project' },
  { type: 'desc', value: '프로젝트 생성을 위해 서비스 타입 및 이름/설명을 아래와 같이 입력하세요. 설명은 필수사항은 아니며 생략해도 무방합니다.' },
  { type: 'image', value: IMG.NCP_12, url: 'https://console.ncloud.com/sens/project' },
  { type: 'desc', value: '아래와 같이 프로젝트가 하나 생성되면, 우측의 서비스 ID에 있는 아이콘을 클릭하세요.' },
  { type: 'image', value: IMG.NCP_13, url: 'https://console.ncloud.com/sens/project' },
  { type: 'desc', value: '팝업에 표시된 항목에서 ID 항목을 복사하여 [Service ID] 입력란에 복사하여 붙여넣으세요.' },
  { type: 'image', value: IMG.NCP_14, url: 'https://console.ncloud.com/sens/project' },
  { type: 'line', value: '5px' },
  { type: 'text', value: '5. 아래와 같이 각 항목에 맞도록 복사한후 저장을 클릭하세요.', url: '/admin/webset' },
  { type: 'image', value: IMG.NCP_15, url: '/admin/webset' },
  { type: 'text', value: '* SMS 메시지를 보내기 위해서는 마지막으로 [발신번호 사전등록제] 통신사업법에 따라 발신번호 등록을 해야합니다.', url: 'https://docs.ncloud.com/ko/sens/sens-1-3.html#%EB%B0%9C%EC%8B%A0%EB%B2%88%ED%98%B8-%EB%93%B1%EB%A1%9D%EC%A1%B0%ED%9A%8C%ED%95%98%EA%B8%B0' },
  { type: 'image', value: IMG.NCP_16, url: 'https://console.ncloud.com/sens/sms-calling-number' },
  { type: 'text', value: '위와 같이 두가지 선택사항중 하나를 선택하여 발신 번호를 등록하세요.', url: 'https://console.ncloud.com/sens/sms-calling-number' },
  { type: 'text', value: '등록한 발신번호를 복사하여 발신번호 입력란에 붙여넣으세요.', url: 'https://console.ncloud.com/sens/sms-calling-number' },
  { type: 'image', value: IMG.NCP_17, url: 'https://console.ncloud.com/sens/sms-calling-number' },
  { type: 'line', value: '5px' },
  { type: 'text', value: '* 만약 휴대폰이 아닌 일반전화로 등록하고자 할경우 [서류인증요청(1:1문의하기)] 를 클릭하세요.', url: 'https://www.ncloud.com/support/question/service' },
  { type: 'image', value: IMG.NCP_18, url: 'https://www.ncloud.com/support/question/service' },
  { type: 'text', value: '문의 유형에 APPLICATION SERVICE > Simple & Easy Notification Service를 선택하고,' },
  { type: 'text', value: '내용에 회원ID, 등록하려는 SMS 서비스ID 또는 프로젝트명, 발신번호, SMS 사용목적, 월평균 예상사용량(건) 정보를 입력하시기 바랍니다.' },
  { type: 'image', value: IMG.NCP_19, url: 'https://www.ncloud.com/support/question/service' },
];

export const HELP = [
  { type: 'text', value: '', url: '' },
  { type: 'image', value: '', url: '' },
  { type: 'desc', value: '' },
  { type: 'line', value: '2px' },
];

export const IAMPORT_HELP = [
  { type: 'text', value: '1. 아임포트 사이트(https://www.iamport.kr/)를 방문하세요.', url: 'https://www.iamport.kr/' },
  { type: 'image', value: IMG.IAMPORT_1, url: 'https://www.iamport.kr/' },
  { type: 'desc', value: '결제 연동을 위해 아임포트 계정을 만들어야 합니다.\n우측 상단에 대시보드를 클릭하여 대시보드로 진입하세요.\n회원가입이 되어 있지 않다면 회원가입을 먼저 해야합니다.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '2. 회원가입한 아이디로 [로그인]하세요.', url: 'https://admin.iamport.kr/users/login' },
  { type: 'image', value: IMG.IAMPORT_2, url: 'https://admin.iamport.kr/users/login' },
  { type: 'desc', value: '결제 연동을 위해 아임포트 계정을 만들어야 합니다.\n우측 상단에 대시보드를 클릭하여 대시보드로 진입하세요.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '3. [시스템설정] 탭으로 이동하여 필요한 정보를 복사합니다.', url: 'https://admin.iamport.kr/settings#tab_profile' },
  { type: 'image', value: IMG.IAMPORT_3, url: 'https://admin.iamport.kr/settings#tab_profile' },
  { type: 'desc', value: '[가맹점 식별코드]를 첫번째 입력란에 복사합니다.\n[REST API 키]를 두번재 입력란에 복사합니다.\n[REST API secret 키]를 세번째 입력란에 복사합니다.' },
  { type: 'text', value: '4. [PG설정] 탭으로 이동하여 기본 PG사를 설정해야 합니다.', url: 'https://admin.iamport.kr/settings#tab_pg' },
  { type: 'image', value: IMG.IAMPORT_4, url: 'https://admin.iamport.kr/settings#tab_pg' },
  { type: 'desc', value: ' [시스템설정 -> PG설정(일반결제 및 정기결제)] 탭으로 이동하여 기본 PG사를 설정 하세요.\nKG이니시스(웹표준결제창) 또는 다날-신용카드/계좌이체/가상계좌 둘중 하나를 선택하세요.\n테스트모드(ON)으로 설정하여 테스트를 수행후 테스트모드(OFF)로 설정하세요.' },
  { type: 'line', value: '5px', color: 'dark' },
  { type: 'text', value: '* [저정]이 완료된후 [결제 연동 테스트]를 수행하여 확인하세요', url: 'https://admin.iamport.kr/settings#tab_profile' },
  { type: 'image', value: IMG.IAMPORT_5, url: '' },
  { type: 'desc', value: '정상적으로 설정되었다면 위와 같은 화면이 팝업으로 표시되어야 합니다.' },
  { type: 'desc', value: '\n* 만약 아무 화면도 표시되지 않고 아래와 같이 웹화면 상단에 [팝업 차단됨] 표시가 보인다면, 팝업 차단을 해제해주세요.' },
  { type: 'image', value: IMG.IAMPORT_6, url: '' },
  { type: 'line', value: '5px' },
  { type: 'text', value: '\n\n# 테스트가 정상적으로 끝났다면, 다음엔 PG 서비스를 정식 등록해야 합니다.\n아래의 절차를 따라하면 쉽게 하실수 있습니다.\n', url: 'https://www.iamport.kr/pg' },
  { type: 'text', value: '1. 아래의 화면에서 [PG가입 신청하기]를 클릭하세요', url: 'https://www.iamport.kr/pg' },
  { type: 'image', value: IMG.IAMPORT_7, url: '' },
  { type: 'text', value: '2. 아래의 화면에서 연동하기 원하는 PG사를 선택하세요.\n일반적으로 KG이니지스 또는 다날을 선택하시면 됩니다.', url: 'https://www.iamport.kr/pgForm' },
  { type: 'image', value: IMG.IAMPORT_8, url: '' },
  { type: 'text', value: '3. PG 서비스 신청을 위해 사업자에 대한 일반 정보를 입력해야 합니다.\n(아래부터는 KG 이니시스를 예로 설명합니다)', url: 'https://www.iamport.kr/pgForm' },
  { type: 'image', value: IMG.IAMPORT_9, url: '' },
  { type: 'text', value: '4. 계속해서 담당자 정보, PG사별 추가정보, KG이니시스 추가정보를 입력하세요.', url: 'https://www.iamport.kr/pgForm' },
  { type: 'image', value: IMG.IAMPORT_10, url: '' },
  { type: 'text', value: '5. 마자막으로 개인정보 취급방침에 동의하신후 하단의 [PG가입신청서 제출하기]를 클릭하세요.', url: 'https://www.iamport.kr/pgForm' },
  { type: 'image', value: IMG.IAMPORT_11, url: '' },
  { type: 'text', value: '6. 정상적으로 신청서가 접수되었다면 아래와 같은 화면을 보실수 있습니다.', url: 'https://www.iamport.kr/pg' },
  { type: 'image', value: IMG.IAMPORT_12, url: '' },
  { type: 'text', value: '7. 신청서 접수가 완료되면 아래와 같은 절차들을 진행하게됩니다. PG 서비스 가입시 참고하세요.', url: 'https://www.iamport.kr/pg' },
  { type: 'image', value: IMG.IAMPORT_13, url: '' },
  { type: 'text', value: '8. 가입심사 및 전화인터뷰 진행하기와 구비서류 제출 및 등록비(가입비) 남부하기', url: 'https://www.iamport.kr/pg' },
  { type: 'image', value: IMG.IAMPORT_14, url: '' },
  { type: 'desc', value: '가입심사 및 전화인터뷰가 진행된후 아래와 같은 이메일을 받을수 있습니다.' },
  { type: 'image', value: IMG.IAMPORT_14_1, url: '' },
  { type: 'desc', value: '이메일에 가맹점ID와 비밀번호를 확인후 PG사 홈페이지에 방문하여 정보를 입력하거나, 가맹비를 납입하세요.' },
  { type: 'desc', value: 'KG이니시스의 경우 아래와 같은 화면을 통해 가맹비를 납입하기 위한 전자계약을 진행하세요.' },
  { type: 'image', value: IMG.IAMPORT_14_2, url: 'https://iniweb.inicis.com/nxaview/index.jsp' },
  { type: 'desc', value: '- iniweb.inicis.com 로그인 → 고객지원 → 전자계약 → 전자계약하기' },
  { type: 'desc', value: '※ 전자계약은 Internet explorer 에서 진행 바랍니다!' },
  { type: 'desc', value: '전자계약이 정상적으로 완료되었다면 아래와 같은 화면을 보실수 있습니다.' },
  { type: 'image', value: IMG.IAMPORT_14_3, url: 'https://iniweb.inicis.com/nxaview/index.jsp' },

  { type: 'line', value: '2px' },
  { type: 'desc', value: '이후 진행되는 상세한 내용은 수신받은 이메일에 자세히 안내되어 있습니다.' },
  { type: 'desc', value: '가입심사 및 인터뷰가 완료되면, 위와 같은 구비서류를 준비한후 제출하세요.' },
  { type: 'text', value: '9. 보증보험 가입하기', url: 'https://www.iamport.kr/pg' },
  { type: 'image', value: IMG.IAMPORT_15, url: '' },
  { type: 'text', value: '10. PG사 계약 완료 및 상점정보 입력하기', url: 'https://www.iamport.kr/pg' },
  { type: 'image', value: IMG.IAMPORT_16, url: '' },
  { type: 'desc', value: 'PG사에서 발급받은 상점아이디(MID)를 아임포트 대시보드에서 아래와 같이 입력하세요.\nPG설정에서 테스트모드를 OFF 해야 입력폼이 표시됩니다.' },
  { type: 'desc', value: 'KG 이니시스의 경우 이메일로 받은 KEY파일을 아임포트 이메일(cs@iamport.kr)로 보내야 합니다.\n1) Key파일 압축파일, 2) 아임포트에 가입한 이메일 계정' },
  { type: 'image', value: IMG.IAMPORT_17, url: '' },
  { type: 'text', value: '11. 수고하셨습니다. 이제 마지막 심사만 남았습니다.', url: 'https://www.iamport.kr/pg' },
  { type: 'image', value: IMG.IAMPORT_18, url: '' },
  

  { type: 'text', value: '* PG사 가입시 가입비 및 결제 수수료는 아래의 이미지를 참고하세요.', url: 'https://www.iamport.kr/pg' },
  { type: 'image', value: IMG.IAMPORT_99, url: '' },
];

export const GMAIL_HELP = [
  { type: 'text', value: '1. 보내기 메일 GMAIL 설정 방법', url: 'https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Ftab%3Dwm%26ogbl&ltmpl=default&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp&hl=ko' },
  { type: 'image', value: IMG.GMAIL_1, url: 'https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Ftab%3Dwm%26ogbl&ltmpl=default&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp&hl=ko' },
  { type: 'desc', value: '보내기 메일을 무료 메일인 GMAIL로 설정하기위해서 GMAIL(https://www.google.com/gmail/)을 방문하여 먼저 가입하세요' },
  { type: 'desc', value: '만약 사용중인 GMAIL이 있다면 GMAIL(https://myaccount.google.com/lesssecureapps)에 방문하여 로그인을 하세요' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '2. 웹 서버에서 메일 전송을 위한 Gmail 설정 방법', url: 'https://support.google.com/accounts/answer/185833?hl=ko' },
  { type: 'image', value: IMG.GMAIL_2, url: 'https://support.google.com/accounts/answer/185833?hl=ko' },
  { type: 'desc', value: 'GMAIL 보내기 메일 서버 설정을 위해서 [앱 비밀번호]를 생성해야 합니다' },
  { type: 'desc', value: '상세 내용을 확인하고 싶다면 여기(https://support.google.com/accounts/answer/185833?hl=ko)를 방문하세요.', url: 'https://support.google.com/accounts/answer/185833?hl=ko' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '3. [앱 비밀번호] 설정하기', url: 'https://myaccount.google.com/security' },
  { type: 'image', value: IMG.GMAIL_3, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(1) [2단계 인증]을 활설화 해야합니다.' },
  { type: 'image', value: IMG.GMAIL_4, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(2) [2단계 인증]을 시작하여, 따라하면 2단계 인증을 활성화 할수 있습니다.' },
  { type: 'image', value: IMG.GMAIL_5, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(3) [2단계 인증]을 시작하여, 따라하면 2단계 인증을 활성화 할수 있습니다.' },
  { type: 'image', value: IMG.GMAIL_6, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(4) 비밀번호 입력창이 표시되면 사용중인 이메일 계정의 비밀번호를 입력하고 [다음]을 클릭하세요.' },
  { type: 'image', value: IMG.GMAIL_7, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(5) [앱 선택]에서 [메일]을 선택하고 [기기 선택]에서 [Windows 컴퓨터]를 선택하세요.\n - 맞춤 이름을 선택하여 사용자가 직접 입력해도 됩니다.' },
  { type: 'image', value: IMG.GMAIL_8, url: 'https://myaccount.google.com/security' },
  { type: 'desc', value: '(6) 생성된 [앱 비밀번호] 16자를 복사합니다.' },
  { type: 'line', value: '2px' },
  { type: 'text', value: '4. 복사한 [앱 비밀번호]를 해당 입력란에 붙여넣으세요', url: '/admin/mailer' },
  { type: 'image', value: IMG.GMAIL_7, url: '/admin/mailer' },
  { type: 'desc', value: '비밀번호를 붙여넣을때 중간에 공백이 없어야 합니다(복사붙여넣기를 하면 공백은 복사되지 않습니다).' },
  { type: 'text', value: '5. [EMAIL TEST] 버튼을 클릭하면 잠시후 다음과 같은 화면이 표시되어야 합니다.', url: '/admin/mailer' },
  { type: 'image', value: IMG.GMAIL_8, url: '/admin/mailer' },
  { type: 'desc', value: '위와 같은 메시지가 표시되면 해당 메일이 도착했는지 이메일을 확인해보시기 바랍니다. 메일을 정상적으로 받았다면 메일 설정이 정상적으로 동작하는 것입니다.' },
  { type: 'line', value: '5px' },
  { type: 'text', value: '*만약 웹 서버를 설정하였으나, 메일 테스트시 정상적으로 동작하지 않는다면 아래의 내용을 확인해 보시기 바랍니다.', url: 'https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Ftab%3Dwm%26ogbl&ltmpl=default&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp' },
  { type: 'desc', value: '보안 수준이 낮은 앱 엑세스 허용하기 위해 아래의 메뉴로 진입하세요' },
  { type: 'desc', value: '[Google 계정 관리 -> 보안 -> 보안 수준이 낮은 앱의 액세스]를 사용으로 변경하세요' },
  { type: 'desc', value: '여기(https://myaccount.google.com/lesssecureapps)를 접속하여 [보안 수준이 낮은 앱 허용: 사용]으로 설정하거나', url: 'https://myaccount.google.com/lesssecureapps' },
  { type: 'desc', value: '여기(https://accounts.google.com/DisplayUnlockCaptcha)를 접속하여 절차에따라 [계속]을 클릭하세요', url: 'https://accounts.google.com/DisplayUnlockCaptcha' },
];


export const NAVER_S_HELP = [
  { type: 'text', value: '1. 네이버 사이트 등록 방법', url: 'https://searchadvisor.naver.com/' },
  
  { type: 'text', value: '2. 웹마스터도구로 접속', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_S_1, url: 'https://searchadvisor.naver.com/' },
  { type: 'desc', value: '네이버 사이트 검색 등록을 위해서 네이버(https://searchadvisor.naver.com/)을 방문하여 먼저 가입하세요' },

  { type: 'text', value: '3. 사용 중인 도메인 주소 입력', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_S_2 },
  { type: 'desc', value: '사이트 정보 입력에서는 사용하고 있는 도메인의 주소를 입력하세요' },

  { type: 'text', value: '4. 사이트 소유확인', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_S_3 },
  { type: 'desc', value: '검색 등록을 위해 사이트 소유확인이 필요하며 두번째의 HTML 태그 방식을 선택하여 아래의 내용을 복사하세요' },

  { type: 'text', value: '4. 사이트 소유확인', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_S_4 },
  { type: 'desc', value: '검색 등록을 위해 사이트 소유확인이 필요하며 두번째의 HTML 태그 방식을 선택하여 아래의 내용을 복사하세요' },
  { type: 'desc', value: '등록한 내용을 시스템에 적용하기위해 약 10초후 웹시스템이 재시작됩니다.' },

  { type: 'text', value: '5. 마지막 등록 확인', url: 'https://searchadvisor.naver.com/' },
  { type: 'image', value: IMG.NAVER_S_5 },
  { type: 'desc', value: '위와 같이 보안코드가 표시되고, 보안 코드를 정상적으로 등록했다면' },
  { type: 'image', value: IMG.NAVER_S_6 },
  { type: 'desc', value: '등록이 완료되었다는 메시지를 확인할수 있습니다.' },

  { type: 'line', value: '5px' },

  { type: 'text', value: '# 사이트 간단 체크', url: 'https://searchadvisor.naver.com/tools/sitecheck' },
  { type: 'image', value: IMG.NAVER_S_7 },
  { type: 'desc', value: '사이트 간단 체크를 사용하여 등록된 웹사이트의 상태를 체크할 수 있습니다.' },
];