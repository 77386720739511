/* eslint-disable react/no-direct-mutation-state */
import React, { useEffect, useState } from 'react';
import { STAT, URL, EID } from 'svc/Enum';
import { ST } from 'svc/Lang';
import { Util, Tablebox, Loading, Editbox, TabButton, cs, Button, Formgroup, Svg, Uploadbox, Combobox } from 'object/src';
import * as actions from 'object/src/actor/Action';
import styled from 'styled-components';
import cx from 'classnames/bind';

const StyledObject = styled.div`{
  &.ad-product { ${cs.pos.relative} ${cs.bg.dark} ${cs.p.a10} ${cs.noselect} 
    .s-body { ${cs.p.h20} ${cs.m.t10} ${cs.pos.relative}
      .tb-body {
        .tb-col.edit { 
          .svg-icon { ${cs.m.h5} }
        }
      }
      
      .tb-head { ${cs.font.sm} }
      
      .btn-tab { ${cs.align.ltop} ${cs.left(20)} ${cs.top(5)} }

      .table-box { ${cs.pos.relative} ${cs.p.t50}
        .search-frame { ${cs.min.w(400)} ${cs.max.w(600)} ${cs.align.rtop} ${cs.w.r50} .search-box { ${cs.w.full} ${cs.m.b10} } }
        .tb-row .tb-col {
          &.rcount { ${cs.over.get('initial')} ${cs.w.get(100)} ${cs.disp.flex('none !important')} ${cs.z.over}
            ${cs.p.a0} ${cs.p.h2}
            .rcount-btn { ${cs.mouse.pointer} ${cs.z.popup} ${cs.pos.relative} 
              ${cs.p.h3} ${cs.bg.black} ${cs.border.radius(3)}
              &:hover { ${cs.bg.gray} }
            }

            
            .rcount-edit { .nb-help { ${cs.disp.none} } }
          }

          .t-odr { ${cs.disp.inblock} ${cs.min.w(40)} 
            ${cs.w.calc('100% - 30px')} ${cs.m.l20} 
            &:hover { ${cs.bg.black} ${cs.border.gray} ${cs.border.radius(5)} }
          }
        }
      }
    }

    .s-foot { ${cs.m.t0} ${cs.font.right} ${cs.p.h20} ${cs.pos.relative} 
      .btn-save { ${cs.m.r20} }
      .btn-batch { ${cs.m.right(100)} }
    }

    .s-guide { ${cs.align.lbottom} ${cs.top(-20)} ${cs.font.yellow} ${cs.font.sm} ${cs.p.h20} 
      ${cs.font.prewrap} ${cs.font.left} ${cs.font.line(20)} ${cs.m.t10}
      &.lg { ${cs.font.lg} ${cs.p.t20} ${cs.font.red} }
    }
  }
  
  &.prod-modal {
    .mt { ${cs.m.t10} }

    .combo-box { ${cs.m.b10}}

    .title, .prodid { 
      ${cs.m.t5} ${cs.font.md}
      .label { ${cs.font.gray} ${cs.font.sm} } 
    }

    .thumb-box { ${cs.pos.relative} ${cs.size.get(240)} ${cs.m.t5} ${cs.m.b30} }
    .thumb-giude { ${cs.font.sm} ${cs.font.lightgray} ${cs.font.bold} ${cs.m.t20} 
      &.noti { ${cs.anim.flicking('500ms', 0.2, 1, 3)} ${cs.font.yellow} }
    }

    .inbox { ${cs.m.t10} }
  }

  &.order-modal { 
    .title { ${cs.disp.inblock} ${cs.font.sm} ${cs.font.line(10)} }
    .odr-edit { ${cs.w.get(60)} ${cs.disp.inblock} ${cs.m.h5} }
    .m-noti { ${cs.font.sm} ${cs.m.t20} ${cs.font.yellow} ${cs.font.left}
      &.active { ${cs.anim.flicking('500ms', 0.2, 1, 3, 'odr-noti')} } 
    }
  }

  &.count-box { ${cs.bg.black} ${cs.border.radius(3)} ${cs.box.line} ${cs.w.fit} ${cs.border.shadow()}
    ${cs.align.xcenter} ${cs.top(0)} ${cs.font.center} ${cs.m.l50} ${cs.group()}
    .btn{ &:hover { ${cs.bg.dark} } }
    .line { ${cs.border.right} ${cs.m.h3} }
    & > div { ${cs.w.get(40)} ${cs.disp.inblock} ${cs.font.center} ${cs.font.white} 
      &.edit { ${cs.w.get(70)} ${cs.p.h2} .box { ${cs.float.left} ${cs.top(5)} } }
      .nb-help { ${cs.disp.none} }
    }
  }

  @media screen and (max-width : 1024px) {
    &.ad-product { 
      .s-body { ${cs.p.h0} .btn-move { ${cs.disp.hide} } 
        .btn-tab { ${cs.pos.relative} ${cs.left(0)} ${cs.top(5)} }
        
        .table-box { ${cs.pos.relative} ${cs.p.t20}
          .search-frame { ${cs.max.w('100%')} ${cs.w.full} ${cs.pos.relative} ${cs.m.l5} }
        }
      }
      .s-foot { ${cs.p.h0}
        .btn-batch { ${cs.align.left} ${cs.left(0)} }
        .s-guide { ${cs.align.relative} ${cs.top(50)} ${cs.p.h0} }
      }
    }
  }

  @media screen and (max-width : 860px) {
    &.ad-product { 
      .s-foot { ${cs.p.h0}
        .btn-batch { ${cs.align.left} ${cs.left(0)} }
        .s-guide { ${cs.align.relative} ${cs.top(50)} ${cs.p.h0} }
      }
    }

    &.count-box { ${cs.align.unset} ${cs.align.right} ${cs.top(0)} ${cs.right(-80)} 
      .ml { ${cs.disp.hide} }
    }
  }

  @media screen and (max-width : 600px) {
  }
}`;

const API = URL.API.ADMIN;
const STPR = ST.ADMIN.PRODUCT;

const Product = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [modified, setModified] = useState(false);
  const [draging, setDraging] = useState(false);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [order, setOrder] = useState('odr asc');
  const [menus, setMenus] = useState(null);
  const [selmenu, setSelmenu] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [batch, setBatch] = useState(false);
  const [disable, setDisable] = useState(false);
  const [running, setRunning] = useState(false);
  // const [orderbox, setOrderbox] = useState(null);

  const statuslist = [
    { id: 'on', title: ST.TAG.ON, color: 'green' },
    { id: 'hold', title: ST.TAG.HOLD, color: 'red' },
    { id: 'off', title: ST.TAG.OFF, color: 'black' },
  ]

  const head = [
    // { key: 'no', title: ST.NUM, flex: '1 1 60px' },
    {
      key: 'odr', title: STPR.ORDER, flex: '1 1 40px', mobile: 'hide',
      formatter: (v, item, pos) => {
        const onClickOrder = (e) => {
          e.stopPropagation();
          props.openModal({
            title: `${STPR.ORDER}`, state: STAT.I, children: OrderModal,
            data: { ...item, max: total }, className: 'dark', size: 'sm',
            onOk: (data) => {
              const { prev, odr, rowid, menuid } = data;
              const start = odr < prev ? Number(odr) : Number(prev) + 1;
              const end = odr < prev ? Number(prev) - 1 : Number(odr);
              const type = odr < prev ? 'plus' : 'minus';
              const value = { start, end, odr: Number(odr), type, rowid, menuid };
              (data != null) && actions.doUpdate(API.PRODUCT_ORDERING, value).then(({ code, result }) => {
                Util.showAlert(props, code);
                // 마지막 페이지로 이동하기 위해서.
                const p = Math.ceil(Number(odr) / limit).toFixed(0);
                doReload(p);
              });
            }
          });
        }

        return <span className="t-odr" onClick={onClickOrder}>{v}</span>
      }
    },
    { key: 'rowid', title: STPR.PRODID, flex: '1 1 100px', mobile: 'hide' },
    { key: 'title', title: STPR.SUBJECT, flex: '5 1 200px', align: 'left' },
    { key: 'capacity', title: STPR.CAPACITY, flex: '1 1 80px', tablet: 'hide' },
    { key: 'cost', title: STPR.COST, flex: '2 1 80px', tablet: 'hide', align: 'right', type: 'number', unit: ST.WON },
    { key: 'price', title: STPR.PRICE, flex: '2 1 80px', tablet: 'hide', align: 'right', type: 'number', unit: ST.WON },
    {
      key: 'rcount', title: STPR.RCOUNT, flex: '1 1 60px', align: 'right', type: 'number', unit: ST.COUNT,
      color: (v) => v <= 10 ? cs.color.red : cs.color.lightgreen,
      formatter: (cell, item, pos) => {

        if (batch) {
          const onChangeCount = (data, value, e) => {
            const temp = list.find(a => a.rowid === data.rowid);
            temp.rcount = value;
            temp['modified'] = true;
            temp['status'] = Number(value) < 1 ? 'hold' : 'on';
            setList([...list]);
            setModified(true);
          };

          return <div className={'rcount-edit'}>
            <Editbox className={'md edit right'} type='number' onChange={(v) => onChangeCount(item, v)} value={cell} maxLength={5} min={0} max={99999} />
          </div >
        } else {

          const onClickCount = (v, data, index, e) => {
            e.stopPropagation();
            if (draging) return;

            list.map(a => a['showcount'] = false);
            data['showcount'] = true;
            setList([...list]);
          }

          const onClickBox = (eid, count, e) => {
            e.stopPropagation();
            if (draging) return;

            if (eid === 'ok') {
              const temp = list.find(a => String(a.rowid) === String(item.rowid));
              temp.rcount = count;
              temp.status = count < 1 ? 'hold' : 'on';

              const value = { count: item.rcount, rowid: item.rowid, status: item.status };
              actions.doUpdate(API.PRODUCT_COUNT, value).then(({ code, result }) => {
                Util.showAlert(props, code);
              });
            }

            list.map(a => a['showcount'] = false);
            setList([...list]);
          }

          const { showcount = false } = item;
          return <div className={'rcount-btn'} onClick={(e) => onClickCount(cell, item, pos, e)}>
            < span > {Util.commas(cell)}</span >
            {showcount && <Countbox onClick={(eid, count, e) => onClickBox(eid, count, e)} value={cell} />}
          </div >
        }
      }
    },
    {
      key: 'scount', title: STPR.SCOUNT, flex: '1 1 60px', align: 'right', tablet: 'hide', type: 'number', unit: ST.COUNT,
    },
    {
      key: 'status', title: ST.STATUS, flex: '3 1 240px', mobile: 'hide',
      formatter: (v, item, pos) => {
        return <div>
          <TabButton className={"tab-status"} size={'sm'} onClick={(eid, p, e) => onClick(eid, item, e)} list={statuslist}
            select={item.status} color={'gd-gray'} disabled={disable} />
        </div>
      }
    },
    {
      key: 'edit', title: ST.EDIT, flex: '2 1 100px',
      formatter: (v, item, pos) => {
        return <div>
          <Svg className={'edit md'} icon={EID.EDIT} onClick={(eid, e) => onClickEdit(e, item)} disabled={disable} />
          <Svg className={'delete md'} icon={EID.DELETE} onClick={(eid, e) => onClickDelete(e, item)} disabled={disable} />
        </div>
      }
    },
  ];

  const searchs = [
    { name: STPR.SUBJECT, id: 'title', check: false },
    { name: STPR.PRODID, id: 'prodid', check: false },
    { name: STPR.ORDER, id: 'odr', check: true },
    // { name: STPR.PHONE, id: 'title', check: false },
  ];

  useEffect(() => {
    actions.doList(API.PRODUCT, { limit }, true).then((res) => {
      const { result } = res;

      setTotal(result.page && result.page.total)
      setPage(result.page && result.page.current);
      setMax(result.page && result.page.max);
      setMenus(res.menus);
      setSelmenu(res.menus && res.menus[0]);
      setList(result.list && result.list.length > 0 && result.list);
      setLoaded(true);
    })
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (p = page, s = search, o = order, l = limit, menu = selmenu, callback = null) => {
    setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;
    value['menuid'] = menu.rowid;

    actions.doList(API.PRODUCT_LIST, value, true).then(({ result }) => {
      setTotal(result.page && result.page.total)
      setPage(result.page && result.page.current);
      setMax(result.page && result.page.max);

      setSelmenu(menu);
      setSearch(s);

      setList(result.list && result.list.length > 0 && result.list);
      setRunning(false);

      callback && callback(result.page)
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    // setPage(Number(p));
    doReload(p);
    // setTimeout(() => doReload(), 500);
  }

  const onClickNew = (eid, id) => {
    if (!selmenu || !menus) return;

    props.openModal({
      title: `${STPR.MENU}:${selmenu.title}`, state: STAT.I, children: Modal,
      data: { selmenu, menus }, className: 'dark big', size: 'sm',
      onOk: (data) => {
        (data != null) && actions.doInsert(API.PRODUCT, data).then(({ code, result }) => {
          Util.showAlert(props, code);
          // 마지막 페이지로 이동하기 위해서.
          const p = Math.ceil((total + 1) / limit).toFixed(0);
          doReload(p);
        });
      }
    });
  }

  const onClickDelete = (e, item) => {
    // let data = getItem(id);
    e.stopPropagation();
    props.openConfirm({
      className: 'dark', size: 'sm',
      onClicked: (isOk) => {
        if (isOk) {
          const { rowid, menuid, odr } = item;
          actions.doDelete(API.PRODUCT, { rowid, menuid, odr }).then(({ code, result }) => {
            Util.showAlert(props, code);
            // 현재 페이지의 항목이 1개인 상태에서 삭제하면 이전 페이지로 이동.
            const p = list.length <= 1 ? Number(page) - 1 : Number(page);
            doReload(p <= 1 ? 1 : p);
          });
        }
      },
    });
  }

  const getItem = (id) => list.find(item => Number(item.rowid) === Number(id));

  const onSelect = (id) => {
    const data = getItem(id);
    const menu = menus.find(a => a.rowid === data.menuid);

    if (menu && menu.ishome === 'Y') {
      actions.go(URL.ADMIN.HOME, { rowid: data.rowid });
    } else {
      actions.go(URL.ADMIN.CONTENT, { rowid: data.rowid });
    }
  }

  const onDragDrop = (rowid, array) => {
    if (batch) return;

    const start = (page - 1) * limit;
    array.map((a, i) => a.odr = start + i + 1);
    setDraging(true);
    setDisable(true);
    setModified(true);
    setList([...array]);
  }

  const onClickSave = () => {
    if (batch) {
      let array = [];
      list.map(a => {
        if (a.modified) array.push({ rowid: a.rowid, count: a.rcount, status: a.status });
        return null;
      });

      actions.doUpdate(API.PRODUCT_COUNT_BATCH, array).then(({ code, result }) => {
        Util.showAlert(props, code);
        setBatch(false);
        setDisable(false);
        setModified(false);
        setDraging(false);

        doReload();
      });
    } else if (draging) {
      let array = [];
      list.map(a => {
        array.push({ rowid: a.rowid, odr: a.odr });
        return null;
      });

      actions.doUpdate(API.PRODUCT_ORDERING, array).then(({ code, result }) => {
        Util.showAlert(props, code);
        setBatch(false);
        setDisable(false);
        setModified(false);
        setDraging(false);

        doReload();
      });
    }
  }

  const onChangeMenu = (e, item) => {
    const t = menus.find(a => String(a.rowid) === String(item.id));
    setLoaded(false);
    doReload(1, null, order, limit, t, () => setLoaded(true));
  }

  const onClickBatch = () => {
    setBatch(true);
    setDisable(true);
  }

  const onCancel = () => {
    setBatch(false);
    setModified(false);
    setDisable(false);
    setDraging(false);

    if (modified) doReload();
  }

  const onClickEdit = (e, item) => {
    e.stopPropagation();
    props.openModal({
      title: `${STPR.MENU}:${selmenu.title}`, className: 'dark big', size: 'sm',
      children: Modal, data: { ...item, selmenu, menus }, onOk: (data) => {
        // data = { 'state': STAT.D, 'rowid': String(data.rowid) };
        actions.doUpdate(API.PRODUCT, data).then(({ code, result }) => {
          Util.showAlert(props, code);
          doReload();
        });
      },
    });
  }

  const onClick = (eid, item, e) => {
    e.stopPropagation();

    actions.doUpdate(API.PRODUCT_STATUS, { rowid: item.rowid, status: eid }).then(({ code, result }) => {
      Util.showAlert(props, code);
      doReload();
    });
  }

  const onClickBody = () => {
    if (!list || list.length < 1) return;

    list.map(a => a['showcount'] = false);
    setList([...list]);
  }

  if (loaded) {
    let items = list && list.filter(a => a.menuid === selmenu.rowid);
    items = items ? items.map((a, i) => { a.no = i + 1; return a }) : [];
    const ismenu = menus && menus.length > 0;

    return <StyledObject className="ad-product" onClick={onClickBody}>
      <div className={'s-body dark'}>
        {!ismenu ? <div className={'s-guide lg'}>{STPR.NO_MENUS}</div> :
          <TabButton className={"btn-tab"} onClick={onChangeMenu} list={menus.map(a => { return { id: a.rowid, title: a.title } })}
            select={selmenu && selmenu.rowid} color={'gd-gray'} disabled={disable} />
        }

        <Tablebox className={cx('dark')} naviClass={'white'} head={head} list={items} searchkey={search ? search.key : searchs[0].id}
          onSelect={!disable && onSelect} onClickSearch={onClickSearch} onClickPage={disable ? null : onClickPage}
          onDragDrop={(!disable || draging) && onDragDrop} pos={page} max={max} total={total} searchs={searchs} rowid="rowid" />
      </div>

      <div className={'s-foot'}>
        <div className={'s-guide'}><p>{STPR.HELP}</p></div>
        {(!disable) && <Button className={"btn-batch primary"} onClick={onClickBatch} title={STPR.BATCH} />}
        <Button className={"btn-save red"} onClick={onClickSave} title={ST.SAVE} eid={EID.SAVE} disable={!modified} />
        {disable && <Button className={"btn-cancel gd-gray"} onClick={onCancel} title={ST.CANCEL} />}
        {!disable && <Button className={"btn-new gd-gray"} onClick={onClickNew} title={ST.ADD} eid={EID.ADD} disable={!ismenu} />}
      </div>

      {running && <Loading className={'fixed'} type={'ring'} />}
    </StyledObject>
  } else {
    return <Loading />;
  };
};

export default Product;

const Countbox = (props) => {
  const { value } = props;
  const [count, setCount] = useState(value || 0);

  const onChangeCount = (v, e) => {
    setCount(v);
  }

  const onOkCount = (eid, e) => {
    props.onClick && props.onClick(eid, count, e);
  }

  const onCount = (v) => {
    const min = 0, max = 99999;
    let temp = count + v;
    if (temp <= min) temp = min;
    else if (temp >= max) temp = max;

    setCount(temp);
  }

  return <StyledObject className={'count-box'}>
    <div className={'num btn ml'} onClick={() => onCount(-100)}>-100</div>
    <span className={'line ml'} />
    <div className={'num btn'} onClick={() => onCount(-10)}>-10</div>
    <span className={'line'} />
    <div className={'num btn'} onClick={() => onCount(-1)}>-1</div>
    <Editbox className={'sm edit center'} type='number' onChange={onChangeCount} value={count} maxLength={5} min={0} max={99999} />
    <div className={'num btn'} onClick={() => onCount(1)}>1</div>
    <span className={'line'} />
    <div className={'num btn'} onClick={() => onCount(10)}>10</div>
    <span className={'line'} />
    <div className={'num btn ml'} onClick={() => onCount(100)}>100</div>
    <span className={'line ml'} />
    <div className={'btn'} onClick={(e) => onOkCount('cancel', e)}>{ST.CANCEL}</div>
    <span className={'line'} />
    <div className={'btn'} onClick={(e) => onOkCount('ok', e)}>{ST.OK}</div>
  </StyledObject>
};


/*******************************************************************
 Popup
*******************************************************************/
const OrderModal = (props) => {
  const [noti, setNoti] = useState(null);
  var refs = {};
  const { data, state } = props;
  const { max = 1, odr = 0, rowid = 0, title } = data;

  props.act.getData = (checkValidate) => {

    // const isvalidate = Object.keys(refs).every((key) => key === 'thumb' ? true : refs[key].isValidate());
    // if (!isvalidate) return false;
    const value = refs.odr.getValue();
    if (value > max) {
      setNoti(true);
      return false;
    }

    const item = { 'state': state, odr: value, prev: odr, rowid: rowid, menuid: data.menuid };
    return item;
  }

  return (
    <StyledObject className="order-modal">
      <span className={'title'}>{`${STPR.ORDER_CHANGE(title)}`}</span>
      <Editbox ref={(ref) => { refs.odr = ref }} className={cx("odr-edit sm center")} value={odr} name="odr"
        type="number" guide={STPR.ORDER} validate={true} focus={true} min={1} max={max} />
      <span className={'title'}>{`${STPR.ORDER_CHANGE_L}`}</span>
      <div className={cx('m-noti', noti && 'active')}>{STPR.ORDER_NOTI(max)}</div>
    </StyledObject>
  );
}

const Modal = (props) => {
  const [noti, setNoti] = useState(null);
  const [select, setSelect] = useState(props.data && (props.data.selmenu || props.data.menus[0]));
  var refs = {};

  props.act.getData = (checkValidate) => {
    const isvalidate = Object.keys(refs).every((key) => key === 'thumb' ? true : refs[key].isValidate());
    if (!isvalidate) return false;

    if (!refs.thumb.isValidate()) {
      setNoti(true);
      return false;
    }

    const { data, state } = props;
    let datas = {};
    Object.keys(refs).map(key => datas[key] = refs[key].getValue());
    const item = { 'state': state, ...datas, menuid: select.rowid, rowid: data ? data.rowid : 0 };

    return item;
  }

  const onDelete = () => {
  }

  const onChange = (eid, e, item, index) => {
    setSelect(item);
  }

  if (!props.data) return null;

  const { cost, title, label, capacity, thumb, menus = [] } = props.data;
  // const menuid = selmenu.rowid || menus[0].rowid;

  // if (!menus || !selmenu) return null;

  return (
    <StyledObject className="prod-modal">
      <Combobox className={'full dark md'} options={{ text: { align: 'left' } }} list={menus.map(a => { return { id: a.rowid, name: a.title, rowid: a.rowid } })}
        onChange={onChange} pos={menus.findIndex(a => String(a.rowid) === String(select.rowid))} />
      <Editbox ref={(ref) => { refs.title = ref }} className={cx("name mt")} value={title} name="title"
        type="text" label={STPR.NAME} validate={true} focus={true} />
      <Editbox ref={(ref) => { refs.label = ref }} className={"label mt"} value={label} name="label"
        type="text" label={STPR.LABEL} />
      <Formgroup inline={true} >
        <Editbox ref={(ref) => { refs.capacity = ref }} className={"label mt"} value={capacity} name="capacity"
          type="text" label={`${STPR.CAPACITY}${STPR.CAPACITY_H}`} validate={true} />
        <Editbox ref={(ref) => { refs.cost = ref }} className={"label right mt"} value={cost || '0'} name="cost"
          type="number" min={0} max={999999999}
          label={STPR.COST} validate={true} />
      </Formgroup>
      <p className={cx('thumb-giude', { noti })}>{STPR.THUMB_G}</p>
      <div className={"thumb-box"}>
        <Uploadbox ref={(ref) => refs.thumb = ref} className={'img thumb'} fit={'cover'} size={'sm'} inline={false}
          onChange={() => { }} value={thumb} title={ST.ADMIN.HOME.TITLE} eid={'Uploadbox'} height={'100%'}
          image_check={{ width: 800, height: 800 }} onDelete={onDelete} />
        {/* <div className={'thumb-noti'}>{ST.ADMIN.UPLOAD_NOTI}</div> */}
      </div>
    </StyledObject>
  );
}