// /*global kakao*/
import React, { useEffect } from 'react';
import axios from 'axios';
import { Editbox, Button, cs, Storage, ConfirmActor, Util } from 'object/src';
import { CODE, EID, KEY, URL } from 'svc/Enum';
import { ST } from 'svc/Lang';
import styled from 'styled-components';
import cx from 'classnames/bind';
import * as actions from 'object/src/actor/Action';
import KaKaoLogin from 'react-kakao-login';

const StyledObject = styled.div`{
  &.login-frame {
    ${cs.size.full} ${cs.bg.frame} ${cs.h.get('100%')} ${cs.min.h('100vh')} ${cs.noselect}

    .login-body { ${cs.m.center(50, 50)} ${cs.w.full} ${cs.max.w(500)}
      ${cs.font.center} ${cs.p.a30} ${cs.p.get('60px 100px')} ${cs.bg.white} ${cs.border.none} ${cs.border.trans}

      .login-tl { ${cs.font.size(22)} ${cs.font.thickbold} ${cs.font.himelody} }
      .l-noti { ${cs.font.red} ${cs.font.sm} ${cs.z.get(10)} ${cs.m.v10} ${cs.anim.showin()} ${cs.font.prewrap} }
      .login-id { ${cs.m.b10} }

      .line { ${cs.m.v30} ${cs.border.bottom} ${cs.pos.relative} ${cs.font.gray}
        .l-txt { ${cs.bg.white} ${cs.p.h20} ${cs.font.sm} ${cs.align.xcenter} ${cs.top(-5)} } 
      }
      
      .button { ${cs.font.thickbold} ${cs.border.radius(5)} }
      .button.login { ${cs.m.t10} }
      .kakao {
        ${cs.bg.get("#FFE300")} ${cs.w.get('100% !important')} ${cs.h.get('40px !important')} 
        ${cs.font.line('40px !important')} ${cs.mouse.pointer}
      }

      .facebook { ${cs.bg.get("#2f52ad")} ${cs.font.white} }
      
      .login-find { ${cs.font.line(20)} ${cs.font.center} ${cs.m.v5}
        .f-btn { ${cs.font.primaryhover} ${cs.font.md} ${cs.p.h10} ${cs.mouse.pointer}
          &:hover { ${cs.font.primary} ${cs.font.underline} }
        } 
      }

      @media screen and (max-width : 600px) {
        .login { ${cs.top(0)} ${cs.p.t20} ${cs.w.full} ${cs.border.radius(0)} ${cs.border.none}
          ${cs.bg.trans} ${cs.border.trans}
        }

        .login-box { ${cs.bg.trans} ${cs.border.none} ${cs.border.trans} }
      }
    }
  }
    
  &.tail-bar {
    ${cs.bg.trans} ${cs.font.gray} ${cs.font.md} ${cs.font.center} 
    ${cs.p.v20} ${cs.h.get('auto')} ${cs.w.full} 
  }

  &.head-bar {
    ${cs.bg.get("rgba(255, 255, 255, 0.9);")} ${cs.font.gray} ${cs.font.md} ${cs.font.center} ${cs.font.line(60)} ${cs.h.get(60)} 
    ${cs.w.full} ${cs.font.t4} ${cs.font.eastsea}
    & > span { ${cs.mouse.pointer} }
  }

  @media screen and (max-width : 1024px) { }
  
  @media screen and (max-width : 600px) { 
    &.login-frame { ${cs.bg.white} ${cs.h.get('calc(100vh - 100px)')} ${cs.min.h('calc(100vh - 100px)')}
      .login-body { ${cs.m.center(0)} ${cs.min.h('calc(100vh - 200px)')} ${cs.p.get('10px 20px')} }
    }
  }

  @media screen and (max-width : 480px) { }
}`;

const { Kakao } = window;
class Signin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { noti_text: '', userid: '', pwd: '', focus: false, kakaokey: '', type: 'member', company: null, loaded: false }
    this.doReload();
  }

  doReload = () => {
    const checkParam = () => {
      const param = actions.popParam();
      if (param) {
        const { type, tag, time } = param;
        let count = time;
        if (type === 'naver') {
          // 네이버 로그인 실패시 회원가입 화면으로 이동
          if (tag === 'signup') {
            this.setState({ noti_text: ST.LOGIN.SIGNUP_TIME(time), type });
            const timer = setInterval(() => {
              count--;
              this.setState({ noti_text: ST.LOGIN.SIGNUP_TIME(count) });

              if (count <= 1) {
                clearInterval(timer);
                window.location.href = URL.SIGNUP;
              }

            }, 1000);
          } else {
            // 네이버 로그인이 알수 없는 오류시 오류 표시
            this.setState({ noti_text: ST.LOGIN.REST_LOGIN_ERROR, type });
          }
        }
      }
    }

    actions.doSelect(URL.API.SIGNIN).then((result) => {
      const { kakao, company, naver } = result;
      let json = {}
      company.map(a => json[a.skey] = a.sval);
      const clientid = naver && naver.find(a => a.skey === 'naverclientid');
      // this.setState({ kakaokey: null, company: json, clientid: null, loaded: true });
      this.setState({ kakaokey: kakao && kakao.sval, company: json, clientid: clientid && clientid.sval, loaded: true });
      if (kakao) {
        Kakao.init(kakao.sval);
        Kakao.isInitialized();
      }

      // naver login check
      checkParam();

      if (!result || !result.company || result.company.length < 1) {
        this.setState({
          confirm: {
            show:true, type: 'info', cancel: false, msg: ST.ADMIN_LOGIN, size: 'sm',
            onClicked: (isOk) => {
              if(isOk) {
                Util.logout();
                window.location.href = URL.ADMIN.ROOT;
              };
            }
          }
        });
      };
    });
  };

  onKeyPressed = (e) => {
    if (e.key === KEY.ENTER) {
      this.onClicked(EID.OK, e);
    }
  }

  onClicked = (eid, e) => {
    const params = { userid: this.refUserid.getValue(), password: this.refPassword.getValue() };
    axios.post(URL.SIGNIN, params).then((result) => {
      const { data } = result;
      if (data && data.code === CODE.SUCCESS) {
        // 로그인 정보를 세션에 저장
        Storage.setLocalItem(Storage.key.userinfo, data.value);
        this.doUpdateCart(data);
        // window.location.href = URL.ROOT;
      } else {
        this.setState({ noti_text: ST.LOGIN.NOTI_LOGIN });
      }
    }).catch((err) => {
      this.setState({ noti_text: ST.LOGIN.NOTI_LOGIN });
    });
  }

  doUpdateCart = (data, callback = null) => {
    // 카트에 담겨있는 정보를 업데이트하자..
    // 현재 PC의 고유아이디 guid에 해당하는 정보를 로그인한 userid로 업데이트
    const { userid } = data && data.value;
    const guid = Storage.getLocalItem('guid');
    actions.doUpdate(URL.API.CART_CLEAR, { userid, guid }, null).then(({ result }) => {
      callback ? callback(result) : window.location.href = URL.ROOT;
    });
  }

  componentDidMount() {
    const { pathname = null } = window.location;
    if (pathname.indexOf("error") >= 0) {
      this.setState({ noti_text: ST.LOGIN.NOTI_LOGIN })
    }

    this.props.onLoaded(true);
  };

  onSuccess = (res) => {
    if (!res) return;
    const { profile, response } = res;

    const token = response && response.access_token;
    const value = { type: 'kakao', userid: profile.id, password: token, token };
    if (value.token) {
      axios.post(URL.SIGNIN, value).then((result) => {
        const { data } = result;
        if (data && data.code === CODE.SUCCESS) {
          Storage.setLocalItem(Storage.key.userinfo, data.value);
          this.doUpdateCart(data);
          // window.location.href = URL.ROOT;
        } else {
          this.setState({ noti_text: ST.LOGIN.NOTI_LOGIN, type: value.type });
        }
      }).catch((err) => {
        this.setState({
          confirm: {
            show: true, msg: ST.LOGIN.SIGNUP_ERROR, type: 'info', cancel: false, size: 'sm',
            onClicked: (isOk) => this.props.history.push(URL.SIGNUP)
          }
        });
      });
    }
  }

  onFail = (res) => {
  }

  onClickNaver = () => {
  }

  render() {
    const { userid, pwd, kakaokey, type = 'member', noti_text, company = null, clientid = null } = this.state;

    return (
      <StyledObject className={"login-frame"}>
        <Headbar title={company && company.name} />

        <div className="login-body sm" method="post" onKeyPress={this.onKeyPressed}>
          <div className="login-tl" onClick={() => this.props.history.push(`${URL.ROOT}`)}>{ST.LOGIN.WELCOME}</div>
          <div className={cx("line")} />

          <Editbox ref={ref => { this.refUserid = ref }} className="login-id trans border radius lg" type="text" guide={ST.ID} value={userid} focus={true} />
          <Editbox ref={ref => { this.refPassword = ref }} className="login-pwd trans border radius lg" type="password" guide={ST.PASSWORD} value={pwd} />
          {type === 'member' && <div className="l-noti">{noti_text}</div>}
          <Button className="primary full login-btn lg" title={ST.LOGIN.TITLE} onClick={this.onClicked} eid={EID.OK} />

          <div className="login-find">
            <span className={"f-btn"} onClick={() => this.props.history.push(URL.SIGNTO)}>{ST.LOGIN.PASSWORD_FIND}</span>
            <span>|</span>
            <span className={"f-btn"} onClick={() => this.props.history.push(URL.SIGNUP)}>{ST.SIGNUP.TITLE}</span>
          </div>

          <div className={cx("line")} />
          {type === 'kakao' && <div className="l-noti">{noti_text}</div>}
          {kakaokey && <KaKaoLogin className={'button kakao'} jsKey={kakaokey} buttonText={ST.SIGNUP.KAKAO}
            onSuccess={this.onSuccess} onFailure={this.onFail} getProfile={true} />}

          {clientid && <NaverLogin clientid={clientid} />}
          {type === 'naver' && <div className="l-noti">{noti_text}</div>}

          {/* <div className={cx("line")} ><span className={cx("l-txt")}>{ST.OR}</span></div>
          {(type === 'naver' || type === 'facebook') && <div className="l-noti">{noti_text}</div>}
          <Button className="full facebook lg" title={ST.LOGIN.FACEBOOK} onClick={this.onClicked} eid={'facebook'} /> */}
        </div>

        {company && <Tailbar title={ST.COPYRIGHT(company.name_eng || company.name, company.year)} />}
        <ConfirmActor {...this.state.confirm} />
      </StyledObject>
    )
  };
};

export const Headbar = ({ title = '' }) => {
  const StyledComp = styled.div`{
    &.head-bar {
      ${cs.bg.get("rgba(255, 255, 255, 0.9);")} ${cs.font.gray} ${cs.font.md} 
      ${cs.font.center} ${cs.font.line(60)} ${cs.h.get(60)} ${cs.w.full} ${cs.font.t4} 
      ${cs.font.eastsea} ${cs.pos.sticky} ${cs.top(0)} ${cs.bg.white} ${cs.z.over} 
      ${cs.border.bottom} ${cs.border.lightwhite}
      & > span { ${cs.mouse.pointer} }
    }
  }`;

  return <StyledComp className={"head-bar"}>
    <span onClick={() => window.location.href = URL.ROOT}>{title}</span>
  </StyledComp>
}

export const Tailbar = ({ title = '' }) => {
  const StyledComp = styled.div`{
    &.tail-bar {
      ${cs.bg.trans} ${cs.font.gray} ${cs.font.md} ${cs.font.center} 
      ${cs.p.v20} ${cs.h.get('auto')} ${cs.w.full} 
    }
  }`;

  return <StyledComp className="tail-bar">
    <span>{title}</span>
  </StyledComp>
}

export default Signin;

export const NaverLogin = (props) => {
  // const [data, setData] = useState(props.data);

  const StyledComp = styled.div`{
    &.naver-login { ${cs.bg.get("#1ec800")} ${cs.font.white} ${cs.m.t10} ${cs.pos.relative}
      .naver-btn {  }
      #naverIdLogin { ${cs.disp.hidden} ${cs.align.left} }
      #naverIdLogin_loginButton > img { ${cs.w.full} ${cs.p.h40} }
    }
  }`;

  useEffect(() => {
    const initNaver = () => {
      const { origin } = window.location;
      var naverLogin = new global.naver.LoginWithNaverId(
        {
          clientId: props.clientid,
          callbackUrl: `${origin}?naver=true`,
          isPopup: props.ispopup || false, /* 팝업을 통한 연동처리 여부 */
          loginButton: { color: "green", type: 3, height: 38 } /* 로그인 버튼의 타입을 지정 */
        }
      );

      /* 설정정보를 초기화하고 연동을 준비 */
      naverLogin.init();
    }

    setTimeout(() => {
      initNaver();
    }, 500);
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = (eid, e) => {
    const object = document.getElementById('naverIdLogin_loginButton');
    object && object.click();

    props.onClick && props.onClick();
  }

  return <StyledComp className={'naver-login'}>
    <Button className="full naver-btn lg" title={props.title || ST.LOGIN.NAVER} onClick={onClick} eid={'naver'} />
    <div id="naverIdLogin"></div>
  </StyledComp>
}