/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { STAT, URL, EID } from 'svc/Enum';
import { ST } from 'svc/Lang';
import { Util, Editbox, cs, TabButton, Button, Tablebox, Loading } from 'object/src';
import styled from 'styled-components';
import cx from 'classnames/bind';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';

const API = URL.API.ADMIN;

const StyledObject = styled.div`{
  &.ad-menu { ${cs.bg.trans} ${cs.pos.relative} ${cs.p.a10} ${cs.max.wlg}

    .s-head { ${cs.m.t20} ${cs.pos.relative}
      .prev-txt { ${cs.font.gray} ${cs.font.t0} ${cs.m.v10} }
      .guide { ${cs.pos.relative} ${cs.m.l20} ${cs.font.yellow} ${cs.font.bold} ${cs.m.v10} }
    }

    .s-body { ${cs.p.h20} 
      .tb-body {
        .tb-col {
          .home-btn { ${cs.disp.block} ${cs.size.full} ${cs.font.sm} ${cs.opac.get(0)} &:hover { ${cs.bg.black} ${cs.opac.show} } }
        }
      }
    }

    .s-foot { ${cs.m.t10} ${cs.font.right} ${cs.p.r20}
      .btn-new { }
      .btn-save { ${cs.m.r20} }
    }
  }

  &.menu-modal {
    .title, .label { ${cs.m.b20} }
    .check { ${cs.m.t5} }
  }

  &.check-modal {
    .noti { ${cs.m.b10} ${cs.font.sm} ${cs.font.yellow} ${cs.font.prewrap} ${cs.disp.block} }
  }

  @media screen and (max-width : 600px) { 
    &.ad-menu { .s-head .guide { ${cs.m.l5} } .s-body { ${cs.p.h5} } .s-foot { ${cs.p.r10} } }
  }
}`

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false, list: [], search: '', modified: false, running: false, draging: false,
      order: 'ctime desc', page: { current: 1, total: 1, max: 1 },
      head: [
        { key: 'odr', title: ST.NUM, flex: '1 1 60px', },
        { key: 'title', title: ST.TITLE, flex: '10 1 140px', align: 'left' },
        { key: 'count', title: ST.ADMIN.MENU.PRODUCTS, flex: '1 1 80px', align: 'center', mobile: 'show' },
        {
          key: 'visible', title: ST.VISIBLE, flex: '2 1 130px', mobile: 'hide',
          formatter: (v, item, pos) => {
            const tabs = [
              { id: 'Y', title: ST.YES, color: 'green' },
              { id: 'N', title: ST.NO, color: 'black' },
            ];

            const onClick = (v, item, e) => {
              e.stopPropagation();
              const value = { visible: v, rowid: item.rowid };

              actions.doUpdate(API.MENU, value).then(({ code, result }) => {
                Util.showAlert(this.props, code);
              });
            }

            return <TabButton className={"tab-visible"} size={'sm'} list={tabs} select={v} color={'gray'}
              onClick={(eid, p, e) => onClick(eid, item, e)} />
          }
        },
        {
          key: 'ishome', title: ST.ISHOME, flex: '1 1 80px', mobile: 'hide',
          formatter: (v, item, pos) => {
            const onClick = (item, e) => {
              e.stopPropagation();
              const value = { ishome: "Y", rowid: item.rowid };
              actions.doUpdate(API.MENU, value).then(({ code, result }) => {
                Util.showAlert(this.props, code);
                this.doReload({});
              });
            }

            if (v === 'Y') return <span className={'home-label'}>{'yes'}</span>
            else return <span className={'home-btn'} onClick={(e) => onClick(item, e)}>{ST.ADMIN.MENU.SELECT}</span>;
          }
        },
        {
          key: 'utime', title: ST.UTIME, flex: '2 1 120px', type: 'datetime', tablet: 'hide',
          formatter: (v) => moment(v).format('YY/MM/DD hh:mm'),
        }
      ],
    }
  }

  componentDidMount(e) {
    this.doReload({});
  }

  doReload = (value) => {
    actions.doList(API.MENU, value, true).then(({ result }) => {
      const { data } = result;
      this.setState({ list: data, loaded: true, move: false, modified: false, draging: false });
    })
  }

  onClickSearch = (value) => {
    this.state.search = value;
    this.doReload({});
  }

  onClickHeader = (eid, e) => {
    if (eid === "name") {
      const { order } = this.state;
      const label = "name";
      if (order.lastIndexOf("asc") > 0) {
        this.state.order = label + " desc";
      } else { // if(order.lastIndexOf("asc") > 0) {
        this.state.order = label + " asc";
      }

      this.doReload({});
    }
  }

  getItem = (id) => this.state.list.find(item => Number(item.rowid) === Number(id));

  onClickNew = (eid, id) => {
    const { list } = this.state;
    if (list.length >= 10) {
      this.props.openConfirm({
        title: ST.ALARM, msg: ST.ADMIN.MENU.MAX_NOTI, className: 'dark', size: 'sm', cancel: false,
        onClicked: (isOk) => { },
      });
      return;
    }

    this.props.openModal({
      title: ST.ADD, state: STAT.I, children: Modal, data: {}, className: 'dark',
      onOk: (data) => {
        (data != null) && actions.doInsert(API.MENU, data, list).then(({ code, result }) => {
          Util.showAlert(this.props, code);
          this.doReload({});
        });
      }
    });
  }

  onClickCancel = (eid, e) => {
    this.doReload({});
  }

  onClickDelete = (id) => {
    let data = this.getItem(id);
    if (data.count > 0) {
      this.props.openModal({
        title: ST.ALARM, state: STAT.U, children: CheckModal, data: { ...data }, className: 'dark', size: 'xs',
        onOk: (data) => {
          (data != null) && actions.doDelete(API.MENU, { rowid: data.rowid }).then(({ code, result }) => {
            Util.showAlert(this.props, code);
            this.doReload({});
          });
        }
      });
    } else {
      this.props.openConfirm({
        className: 'dark', size: 'sm',
        onClicked: (isOk) => {
          if (isOk) {
            actions.doDelete(API.MENU, { rowid: data.rowid }).then(({ code, result }) => {
              Util.showAlert(this.props, code);
              this.doReload({});
            });
          }
        },
      });
    }

  }

  onSelect = (id) => {
    let data = this.getItem(id);
    this.props.openModal({
      title: ST.UPDATE, state: STAT.U, children: Modal, data: { ...data }, className: 'dark',
      onOk: (data) => {
        (data != null) && actions.doUpdate(API.MENU, data).then(({ code, result }) => {
          Util.showAlert(this.props, code);
          this.doReload({});
        });
      }
    });
  }

  onDragDrop = (rowid, array) => {
    array.map((a, i) => a.odr = i + 1);
    this.setState({ modified: true, move: true, list: [...array], draging: true });
  }

  onClickSave = (eid, e) => {
    if (eid === EID.SAVE) {
      this.setState({ modified: false });
      actions.doUpdate(API.MENU_ORDER, { list: this.state.list }, null).then(({ code, result }) => {
        Util.showAlert(this.props, code);
      });
    } else {
      this.doReload({});
    }
  }

  render() {
    const { list, loaded, modified, draging, head } = this.state;
    const total = this.state.list.length;

    if (loaded) {
      return <StyledObject className="ad-menu">
        <div className={'s-head'}>
          <div className={"guide"}><span>{ST.ADMIN.MENU.GUIDE}</span></div>
        </div>

        <div className={'s-body'}>
          {loaded ? <Tablebox className={cx("dark md")}
            head={head} list={list} onClickDelete={this.onClickDelete} onSelect={this.onSelect}
            onDragDrop={this.onDragDrop} pos={1} max={1} total={total} rowid="rowid" /> : null}
        </div>

        <div className={'s-foot'}>
          <Button className={"btn-save red"} onClick={this.onClickSave} title={ST.SAVE} disable={!modified} />
          {!draging && <Button className={"btn-new red gd-gray"} onClick={this.onClickNew} title={ST.ADD} />}
          {draging && <Button className={"btn-cancel red gd-gray"} onClick={this.onClickCancel} title={ST.CANCEL} />}
        </div>
      </StyledObject>
    } else {
      return <Loading className={'fixed'} />;
    }
  };
};


/*******************************************************************
 Popup
*******************************************************************/
const Modal = (props) => {
  var refs = {};

  props.act.getData = (checkValidate) => {
    const datas = checkValidate(refs);
    if (datas === 'modified') return null;
    if (datas === 'notvalid') return false;

    const { data, state } = props;
    const item = { 'state': state, ...datas };
    if (item.state === STAT.U) item.rowid = data.rowid;

    return item;
  }

  const { data } = props;
  const { title, label } = data;

  setTimeout(() => refs.title != null && refs.title.focus(), 300);

  return (
    <StyledObject className="menu-modal">
      <Editbox ref={(ref) => { refs.title = ref }} className={"title"} value={title}
        name="title" type="text" label={ST.ADMIN.MENU.TITLE} validate={true} maxLength={10}
        helper={ST.MAXLEN(ST.ADMIN.MENU.TITLE, 10)} />
      <Editbox ref={(ref) => { refs.label = ref }} className={"label"} value={label}
        name="label" type="text" label={ST.ADMIN.MENU.LABEL} guide={ST.ADMIN.LABEL_G} maxLength={30}
        helper={ST.MAXLEN(ST.ADMIN.MENU.TITLE, 30)} />
    </StyledObject>
  );
}


/*******************************************************************
 Popup
*******************************************************************/
const CheckModal = (props) => {
  var refs = {};

  props.act.getData = (checkValidate) => {
    const value = refs.check.getValue();

    if (value !== 'delete') return false;
    return { rowid: data.rowid };
  }

  const { data } = props;
  const { title, count } = data;

  setTimeout(() => refs.title != null && refs.title.focus(), 300);

  return (
    <StyledObject className="check-modal">
      <span className={'noti'}>{ST.ADMIN.MENU.DELETE_NOTI(title, count)}</span>
      <Editbox ref={(ref) => { refs.check = ref }} className={"check"} value={''} type="text" validate={true} />
    </StyledObject>
  );
}