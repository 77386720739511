/* eslint-disable react/no-direct-mutation-state */
import React, { useEffect, useState } from 'react';
import { URL } from 'svc/Enum';
import { ST } from 'svc/Lang';
import { Util, Tablebox, Loading, cs } from 'object/src';
import * as actions from 'object/src/actor/Action';
import styled from 'styled-components';

const StyledObject = styled.div`{
  &.ad-history { ${cs.pos.relative} ${cs.bg.dark} ${cs.p.a10} ${cs.max.wxl} ${cs.p.b50}
    
    .s-head { ${cs.m.t20} ${cs.pos.relative}
      .btn-mode { ${cs.align.xcenter} ${cs.pos.relative} .button { ${cs.min.w(120)} } }
    }

    .s-body { ${cs.p.h20} ${cs.m.t20} 
      .table-box .tb-line .tb-row .tb-col {
        .uid { ${cs.font.xs} ${cs.font.gray} }
      }
      .noti { ${cs.font.yellow} }
    }

    .s-foot { ${cs.m.t0} ${cs.font.right} ${cs.p.r20}
      .btn-new { }
      .btn-save { }
    }    

    @media screen and (max-width : 1024px) {
      .card-list .tline .trow {
        ${cs.w.calc('50% - 10px')}
      }
      .search-frame .search-box { ${cs.w.full} }
    }

    @media screen and (max-width : 600px) {
      .card-list .tline .trow {
        ${cs.w.full} ${cs.m.b30}
      }
    }
  }
  
  &.member-modal {
    .inbox { ${cs.m.t10} }
  }
}`;

const API = URL.API.ADMIN;
const STAH = ST.ADMIN.HISTORY;

const History = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: '', value: '' });
  const [order, ] = useState('count desc');
  const [limit, ] = useState(15);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    doReload(1, search, order, limit, () => setLoaded(true));
    return () => {
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "%${Util.replaceAll(s.value, '.', '')}%"`;
      }
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.HISTORY, value, true).then(({ result }) => {
      if (!result.page) return setRunning(false);

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setRunning(false);

      callback && callback();
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onSelect = (id) => {
    actions.go(URL.ADMIN.HISTORY_ORDER, { rowid: id });
  }

  if (loaded) {
    const head = [
      { key: 'no', title: ST.NUM, flex: '1 1 60px' },
      { key: 'accountid', title: STAH.ACCOUNTID, flex: '3 1 160px', align: 'left' },
      { key: 'userid', title: STAH.USERID, flex: '2 1 100px', mobile: 'hide', align: 'center' },
      { key: 'name', title: STAH.NAME, flex: '2 1 100px', mobile: 'hide', align: 'center' },
      {
        key: 'count', title: STAH.COUNT, flex: '2 1 100px', type: 'number',
        formatter: (v, item) => {
          return <div>{`${item.today}/${item.week}/${item.count}`}</div>
        }
      },
      { key: 'ctime', title: STAH.SDATE, flex: '2 1 80px', mobile: 'hide', type: 'datetime', format: 'YYYY.MM.DD' }
    ];

    const searchs = [
      { name: STAH.USERID, id: 'userid', check: true },
      { name: STAH.ACCOUNTID, id: 'accountid', check: false },
      { name: STAH.NAME, id: 'name', check: false },
      { name: STAH.PHONE, id: 'phone', check: false },
      { name: STAH.SDATE, id: 'ctime', check: false },
    ];

    return <StyledObject className="ad-history">
      <div className={'s-head'}>
      </div>

      <div className={'s-body'}>
        <Tablebox className={'dark'} head={head} list={list} searchkey={(search && search.key) || searchs[0].id}
          naviClass={'white'} onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
          pos={page} max={max} total={total} searchs={searchs} rowid="userid" />
        <div className={'noti'}>{`* ${STAH.COUNT_H}`}</div>
      </div>
      {running && <Loading type='ring' />}
    </StyledObject>
  } else {
    return <Loading />;
  }
};

export default History;