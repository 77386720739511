import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Util, Loading } from 'object/src';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.order { ${cs.pos.relative} ${cs.m.center()} ${cs.size.full} ${cs.max.wlg} ${cs.font.md} ${cs.noliststyle}
    ${cs.noselect}

    .od-head { ${cs.h.get(40)} ${cs.pos.relative} ${cs.m.t40} ${cs.m.b10} ${cs.border.bottom}
      ${cs.border.width(2)} ${cs.border.dark}
      .title { ${cs.font.t2} ${cs.font.himelody} } 
      .button { ${cs.font.darkgray} } 
    }

    .od-body { ${cs.h.fit} ${cs.bg.white} ${cs.w.full} ${cs.h.fit} ${cs.m.b30} ${cs.p.a30}
      .od-frame {
        ${cs.w.get(400)} ${cs.m.center(50)} ${cs.pos.relative} ${cs.m.b50}
      }

      .p-line { ${cs.font.line(30)} ${cs.m.t10} ${cs.h.get(30)}
        .tit { ${cs.disp.inblock} ${cs.w.get(80)} ${cs.h.full} ${cs.font.thickbold} ${cs.font.darkgray} }
        .val { ${cs.disp.inblock} ${cs.h.full} ${cs.border.bottom} ${cs.w.calc('100% - 90px')} ${cs.float.right} 
          ${cs.p.h10} ${cs.font.ellipsis} ${cs.font.sm}
        }
      }

      .button { }
    }

    .title { ${cs.font.lg} ${cs.font.thickbold} ${cs.m.b5} } 
    .d-line { ${cs.border.bottom} ${cs.border.width(2)} ${cs.border.gray} ${cs.m.b20} }

    .noti { ${cs.bg.alphablack} ${cs.pos.fixed} ${cs.top(0)} ${cs.left(0)} ${cs.size.full} ${cs.anim.showin()}
      & > span { ${cs.pos.relative}
        ${cs.w.get(380)} ${cs.h.get(160)} ${cs.bg.dark} ${cs.p.a20}
        ${cs.align.center} ${cs.p.a10} ${cs.font.prewrap} 

        .title {
          ${cs.font.yellow} ${cs.font.thickbold} ${cs.border.radius(5)} ${cs.align.center} 
          ${cs.font.center} ${cs.font.line(25)} ${cs.w.full} ${cs.p.h20} ${cs.font.md}
        }
        .back { ${cs.align.cbottom} ${cs.font.lightgray} ${cs.bottom(10)} ${cs.mouse.pointer} ${cs.font.underline}
          &:hover { ${cs.font.primary} }
        }
      }
    }

    @media screen and (max-width : 600px) { 
      ${cs.p.h10}

      .od-head {
        .title { ${cs.font.center} }
      }
      .od-body { ${cs.p.h10}
        .od-frame { ${cs.p.h10} ${cs.w.full} 
          .tit { ${cs.font.xs} ${cs.w.get(60)} }
          .val { ${cs.font.sm} ${cs.w.calc('100% - 65px')} }
        }
        .button { ${cs.w.calc('100% - 200px')} ${cs.min.w(240)} ${cs.max.w(400)} ${cs.font.md} }
      }
    }
  }
}`;

const API = URL.API.PAGE;
const Order = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [pdata, setData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  // const [puser, setUser] = useState(null);
  const [pcompany, setCompany] = useState(null);
  // const [status, setStatus] = useState('S');
  const [loading, setLoading] = useState(false);
  const [noti, setNoti] = useState(null);
  const [label, setLabel] = useState(ST.PORDER.PROGRESS);

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = () => {
    const { rowid } = actions.getParam();

    actions.doSelect(API.ORDER, { rowid }, true).then(({ result, company, user, key }) => {
      setData(result);
      let json = {};
      company.map(a => json[a.skey] = a.sval);
      setCompany(json);
      // setUser(user);
      setLoaded(true);
      setLoading(false);

      // key && window.IMP.init(key);
      if (key) {
        window.IMP.init(key);
      } else {
        setNoti(ST.PORDER.KEY_ERROR(company.phone))
        return;
      }

      setTimeout(() => {
        // onPayment(null, null);
        onPayment(result, user, json);
      }, 500);
    });
  }

  const onPayment = (data, user, company) => {
    const { rowid } = actions.getParam();

    if (!data && !user) {
      // setTimeout(() => {
      //   setNoti(ST.PORDER.NOTI);
      // }, 500);
      setNoti(ST.PORDER.NOTI);
      setTimeout(() => {
        props.history.go(-1);
      }, 2000);
      return;
    }
    const { zipcode, tkind = 'card', product, price, odrid, name, phone, address } = data;
    const { email } = user;

    //'samsung':삼성페이, 'card':신용카드, 'trans':실시간계좌이체, 'vbank':가상계좌, 'phone':휴대폰소액결제
    window.IMP.request_pay({
      pg: 'inicis', // version 1.1.0부터 지원.
      pay_method: tkind,
      merchant_uid: `ODR${odrid}`,
      name: product,
      amount: price,
      buyer_email: email,
      buyer_name: name,
      buyer_tel: phone,
      buyer_addr: address,
      buyer_postcode: zipcode,
      m_redirect_url: URL.PAYCOMP, //`${window.location.pathname}/paycomp/${odrid}`,
    }, function (rsp) {
      const { success, imp_uid, merchant_uid, error_msg, paid_amount, apply_num } = rsp;
      if (success) {
        const value = { rowid, status: '2', mid: merchant_uid, odrno: imp_uid, price: paid_amount, cardno: apply_num, msg: ST.PORDER.SUCCESS, ok: true };
        actions.doUpdate(API.ORDER, value).then(({ code, result }) => {
          if (code === CODE.SUCCESS) {
            setLabel(ST.PORDER.SUCCESS);
            // setStatus('2');
            setTimeout(() => {
              actions.go(URL.MYPAGE, { page: 'history' });
              setLoading(false);
            }, 1000);
          } else {
            setLoading(false);
          }
        });
      } else {
        console.error(`결제에 실패하였습니다. \n에러내용 : ${error_msg}`);
        setNoti(ST.PORDER.PAY_ERROR(company.phone));
        const value = { rowid, status: '99', mid: merchant_uid, odrno: imp_uid, price: paid_amount, cardno: apply_num, msg: error_msg, ok: false };
        actions.doUpdate(API.ORDER, value).then(({ code, result }) => {
          // setNoti(ST.PORDER.PAY_ERROR(company.phone));
          // if (code === CODE.SUCCESS) {
          //   setLabel(ST.PORDER.SUCCESS);
          //   // setStatus('2');
          //   setTimeout(() => {
          //     actions.go(URL.MYPAGE, { page: 'history' });
          //     setLoading(false);
          //   }, 1000);
          // } else {
          //   setLoading(false);
          // }
        });

      }
    });
  }

  const onBack = () => {
    // setTimeout()
    actions.go(URL.PAYMENT);
  }

  if (!loaded || !pdata || !pcompany) return null;

  return <StyledObject className={cx('order')}>
    <div className={'od-head'}>
      <p className={'title'}>{pcompany.name}</p>
    </div>
    <div className={'od-body'}>
      <p className={'title'}>{ST.PORDER.TITLE}</p>
      <div className={'d-line'} />
      <div className={'od-frame'}>
        <Line title={ST.PORDER.NO} value={pdata.odrid} />
        <Line title={ST.PORDER.NAME} value={pdata.name} />
        <Line title={ST.PORDER.PHONE} value={pdata.phone} />
        <Line title={ST.PORDER.ITEM} value={pdata.product} />
        <Line title={ST.PORDER.PRICE} value={`${Util.commas(pdata.price)} ${ST.WON}`} />
        <Line title={ST.PORDER.ADDRESS} value={pdata.address} />
      </div>

      <Button className={'orange center xl'} icon={'credit'} title={label} />
    </div>
    {loading && <Loading className={'fixed'} type='ring' />}
    {noti && <div className={'noti'} onClick={onBack}>
      <span>
        <p className={'title'}>{noti}</p>
        <p className={'back'}>{ST.PORDER.BACK}</p>
      </span>
    </div>}
  </StyledObject >
};

export default Order;

const Line = ({ title, value }) => {
  return <div className={'p-line'}>
    <p className={'tit'}>{title}</p>
    <p className={'val'}>{value}</p>
  </div>
}