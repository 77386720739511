import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST, PMATHOD } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Util, Tablebox, Svg } from 'object/src';
import { URL } from 'svc/Enum';
import moment from 'moment';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.hist-detail { ${cs.pos.relative} ${cs.m.center()} ${cs.size.full} ${cs.max.wlg} ${cs.font.md} ${cs.noliststyle}
    ${cs.noselect}

    .hd-head { ${cs.h.get(40)} ${cs.pos.relative} ${cs.m.t40} ${cs.m.b10} 
      ${cs.border.bottom} ${cs.border.width(3)} ${cs.border.dark}
      .title { ${cs.font.t0} ${cs.disp.inblock} }
      .label { ${cs.font.gray} ${cs.disp.inblock} ${cs.font.sm} ${cs.p.l10} }
    }

    .hd-body { ${cs.h.fit} ${cs.bg.white} ${cs.w.full} ${cs.h.fit} ${cs.m.b30} ${cs.p.h30} ${cs.min.h(600)}
      .b-line { ${cs.font.line(18)} ${cs.font.prewrap} ${cs.disp.table} ${cs.w.full}
        & > span { ${cs.disp.tcell} ${cs.align.vertical('middle')} ${cs.p.v10} }
      
        .tit { ${cs.w.get(140)} ${cs.h.full} ${cs.font.thickbold} ${cs.border.right} ${cs.bg.frame} ${cs.p.l20} }
        .val { ${cs.w.get(140)} ${cs.h.full} ${cs.font.right} ${cs.p.h10} ${cs.border.right} }
        .desc { ${cs.font.left} ${cs.p.h20} ${cs.w.auto} }

      }
      
      .b-total { ${cs.border.top} ${cs.border.bottom} ${cs.border.darkgray} ${cs.disp.block} ${cs.pos.relative}
        .pri { ${cs.border.bottom} }
        .canceled { ${cs.font.gray} }
        .p-cancel { ${cs.font.redhover} ${cs.align.rbottom} ${cs.bg.get('#ffe6e6')} ${cs.p.h10} ${cs.p.v2} ${cs.border.radius(3)} }
      }

      .b-list {
        .tb-head .tb-row { ${cs.bg.frame} ${cs.border.none} ${cs.border.top} ${cs.border.dark} } 
        .tb-body .tb-row { ${cs.p.v10} }
        .btn-cmt { ${cs.align.rbottom} }
      }

      .b-deliv { ${cs.border.bottom}  ${cs.border.top} ${cs.border.dark} 
        .val { ${cs.font.center} }
        .pl { ${cs.font.line(20)} ${cs.font.md} 
          .svg-icon { ${cs.m.bottom(-2)} ${cs.m.r5} } 
          &.last { ${cs.m.t5} ${cs.font.dark} }
        }
      }

      & > .title { ${cs.font.lg} ${cs.font.thickbold} ${cs.m.t30} ${cs.m.b5} 
        .p-stat { ${cs.w.fit} ${cs.disp.inblock} }
        .t-stat { ${cs.font.sm} ${cs.m.l10} ${cs.w.fit} ${cs.disp.inblock} ${cs.bg.frame}
          ${cs.mouse.pointer} ${cs.p.h10} ${cs.p.v3} ${cs.box.light} ${cs.border.radius(3)}
          ${cs.font.dark} ${cs.font.thin} ${cs.font.primaryhover} ${cs.font.underline}
          &:hove { ${cs.font.orange} }
        }
      }
    }

    .hd-foot {
      ${cs.p.v20} ${cs.border.top} ${cs.border.width(3)}
    }

    @media screen and (max-width : 600px) { 
      .hd-head {
        .title { ${cs.p.l10} }
      }
      .hd-body { ${cs.p.h10} 
        .b-line { 
          .tit { ${cs.font.sm} ${cs.p.l10} ${cs.w.get(80)} }
          .val { ${cs.font.sm} ${cs.w.get(100)} }
          .desc { ${cs.font.sm} .pl { ${cs.p.t10} } }
        }

        .b-deliv {
          // .desc .pl { ${cs.font.xs} }
        }

        .b-list {
          .tb-body .tb-row { ${cs.p.t5} ${cs.p.b25} }
          .btn-cmt { ${cs.align.rbottom} ${cs.w.get(100)} }
        }

        .b-total {
          .p-cancel { ${cs.top(-30)} ${cs.right(0)} }
        }
      }
    }
  }
}`;

// const API = URL.API.PAGE;

const HistoryDetail = (props) => {
  const [list, setList] = useState(null);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [trans, setTrans] = useState(null);
  const [pay, setPay] = useState(null);
  const [payinfo, setPayinfo] = useState('');
  const [loaded, setLoaded] = useState(false);

  const head = [
    {
      key: 'no', title: ST.CART.NO, flex: '1 1 100px', align: 'center', mobile: 'hide',
      formatter: (cell, item, i) => `${i + 1}`
    },
    { key: 'title', title: ST.CART.PRODUCT, flex: '3 1 200px', align: 'left' },
    { key: 'count', title: ST.CART.COUNT, flex: '1 1 80px', align: 'center' },
    {
      key: 'price', title: ST.CART.PRICE, flex: '2 1 120px', align: 'center',
      formatter: (v, item) => {
        const onClickComment = () => {
          window.location.href = `${URL.PRODUCT}/comment#${item.menuid}&${item.prodid}`;
        }
        return <div>
          <span>{Util.commas(v) + ST.WON}</span>
          <Button className={'gd-gray xs btn-cmt'} title={ST.CART.COMMENT} onClick={onClickComment} />
        </div>
      }
    },
  ]

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = () => {
    const param = actions.getParam();
    const rowid = (param && param.rowid) || props.rowid;

    actions.doSelect(URL.API.PAGE.DETAIL, { rowid }, true).then((result) => {
      if (!result) { setLoaded(true); return; }

      const { tstate, value } = result;
      let tot = 0;
      const array = result && result.list && result.list.map(a => {
        a.total = a.price * a.count;
        tot += a.total;
        a.modified = false;
        return a;
      });

      if (result.pay) {
        let info = result.pay && `${PMATHOD[result.pay.pay_method]}`;
        if (result.pay.pay_method === 'card') {
          if (result.pay.card_name && result.pay.card_number && result.pay.paid_at) {
            info = `${result.pay.card_name} (${result.pay.card_number})\n${moment(Number(result.pay.paid_at) * 1000).format(ST.TFORMAT)}`;
            setPayinfo(info);
          }
        }
      }

      setPay(result.pay);
      setList(array);
      setData(value);
      setTrans(tstate && tstate.sval ? JSON.parse(tstate.sval) : null);
      setTotal(tot + (value.tcost || 0));
      setLoaded(true);
      setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
    })
  }

  const onClickStatus = (item) => {
    const d = trans && trans.find(a => String(item.tid) === String(a.id));
    let url = null;
    if (d) {
      url = d.url + item.tno;
    } else {
      url = URL.TRANS.NAVER(`${item.tname}+${ST.T_SEARCH}&no=${item.tno}`);
    }

    window.open(url);
  };

  if (!loaded || !list) return null;
  const canceled = pay && pay.canceled ? true : Number(data.status) >= 90 ? true : false;
  return <StyledObject className={cx('hist-detail')}>
    <div className={'hd-head'}>
      <p className={'title'}>{ST.MYPAGE.HISTORY_DETAIL}</p>
      <p className={'label'}>{`(${ST.MYPAGE.ORDERNO}: ${data.odrid})`}</p>
    </div>
    <div className={'hd-body'}>
      <p className={'title'}>{ST.MYPAGE.PAYINFO}</p>
      <div className={cx('b-total')}>
        <Line className={cx('pri', { canceled })} title={ST.CART.PRICE} value={`${Util.commas(total)} ${ST.WON}`}
          desc={`${ST.MYPAGE.TPRICE} ${Util.commas(total - data.tcost)}${ST.WON} + ${ST.MYPAGE.TCOST} ${Util.commas(data.tcost)}${ST.WON}`} />
        <Line className={cx('pay', { canceled })} title={ST.CART.PAYMENT} value={`${Util.commas(total)} ${ST.WON}`}
          desc={payinfo || ST.MYPAGE.NOPAYINFO} />
        {canceled && <span className={'p-cancel'}>{ST.MYPAGE.CANCELED}</span>}
        {/* <Line title={ST.CART.TRANS_COST} value={`${Util.commas(data.tcost)} ${ST.WON}`} desc={''}/> */}
      </div>

      <p className={'title'}>{ST.MYPAGE.PROINFO}</p>
      <Tablebox className={cx('b-list md trans')} max={1} head={head} list={list} rowid={'rowid'} />

      <p className={'title'}>{ST.MYPAGE.ADDRINFO}</p>
      <Line className={'b-deliv'} title={ST.MYPAGE.RES_USER} value={`${data.name}`}
        desc={`(${data.zipcode})${data.address}\n${data.phone}\n${data.stext}`}>
        <div className={'pl'}><Svg className={'xs icon'} icon={'shiping'} />{`(${data.zipcode})${data.address}`}</div>
        <div className={'pl'}><Svg className={'xs icon'} icon={'phone'} />{`${Util.toStringPhone(data.phone)}`}</div>
        <div className={'pl last'}>{`${data.stext}`}</div>
      </Line>

      <div className={'title'}>
        <p className={'p-stat'}>{ST.MYPAGE.TSTATUS}</p>
        {data.tno && <p className={cx('t-stat')} onClick={() => onClickStatus(data)}>{`${data.tname}(${data.tno})`}</p>}
      </div>
      {trans && <TransBox list={trans} status={data.status} />}
    </div>
    <div className={'hd-foot'}>
      <Button className={'shoping left lg'} title={ST.PREVPAGE} onClick={() => props.history.go(-1)} />
    </div>
  </StyledObject >
};

export default HistoryDetail;

const StyledTrans = styled.ul`{
  &.trans-box {
    ${cs.box.line} ${cs.p.a20} ${cs.disp.block} ${cs.h.get(100)} ${cs.m.b40} ${cs.disp.table}
    ${cs.w.full} ${cs.bg.white}
    & > li { ${cs.disp.tcell} ${cs.p.h10} ${cs.pos.relative} ${cs.p.right(30)} ${cs.h.full} ${cs.p.t20}
      .tit { ${cs.disp.block} ${cs.font.thickbold} ${cs.font.lg} ${cs.font.alphablack} }
      .txt { ${cs.disp.block} ${cs.font.darkgray} ${cs.font.sm} ${cs.m.t10} }
      &.active {
        .tit { ${cs.font.orangehover} }
        .txt { ${cs.font.color(cs.color.red)} }
        .svg-icon svg .svg-path { ${cs.fill.orange} }
      }
    }
    
    @media screen and (max-width : 600px) { 
      ${cs.disp.block} ${cs.h.fit} ${cs.p.b20}

      & > li { ${cs.disp.block} ${cs.w.get('100% !important')} ${cs.h.get(60)} ${cs.p.v5} ${cs.h.fit}
      .tit { ${cs.font.md} ${cs.w.full} ${cs.disp.block} }
      .txt { ${cs.font.sm}${cs.w.full} ${cs.disp.block} ${cs.m.t2} ${cs.p.l15} }
      .svg-icon { ${cs.disp.none} }
    }

  }
}`

export const TransBox = ({ list, status = null }) => {
  return <StyledTrans className={'trans-box'}>
    {list.map((item, i) => {
      const active = status ? (String(item.id) === String(status)) : false;
      const cell = 100 / list.length;
      const islast = i === list.length - 1;
      return <li key={item.id} className={cx({ active })} style={{ width: `${cell}%` }}>
        <p className={cx('tit')}>{`${i + 1}. ${item.title}`}</p>
        <p className={'txt'}>{item.text}</p>
        {!islast && <Svg className={'lg right middle'} icon={'right'} />}
      </li>
    })}
  </StyledTrans>
}

const Line = (props) => {
  const { title, value, desc, className, children = null } = props;
  return <div className={cx('b-line', className)}>
    <span className={'tit'}><p>{title}</p></span>
    <span className={'val'}><p>{value}</p></span>
    <span className={'desc'}>
      {children ? <div>{children}</div> : <p>{desc}</p>}
    </span>
  </div>
}