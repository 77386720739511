import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { URL } from 'svc/Enum';
import { cs } from 'object/src';
import cx from 'classnames/bind';
import Menubar from './Menubar';
import Content from './Content';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.product { ${cs.bg.white}
    .p-frame { }
  }
}`;

const Product = (props) => {
  // const param = actions.getParam();
  const [menus, setMenus] = useState(null);
  const [pos, setPos] = useState(0);
  const [item, setItem] = useState(null);
  const [list, setList] = useState(null);

  useEffect(() => {
    let param = actions.getParam();
    if (!param) {
      const { hash = null } = window.location;
      if (hash) {
        const t = hash.replace("#", "").split("&");
        param = { menuid: t[0], rowid: t[1] };
      }
    }

    if (!param) return;

    actions.doList(URL.API.MENU).then(({ result }) => {
      const { data } = result;
      const index = param && param.menuid ? data.findIndex(a => String(a.rowid) === String(param.menuid)) : 0;
      setPos(index)
      setMenus(data);
    });

    actions.doSelect(URL.API.PRODUCT, { rowid: param.rowid }, true).then(({ result }) => {
      setItem(result);
    });

    actions.doList(URL.API.CONTENT, { rowid: param.rowid }, true).then(({ result }) => {
      setList(result ? result.data : null);

      setTimeout(() => {
        props.onLoaded && props.onLoaded(true);
      }, 1000);
    });

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickMenu = (eid, e, item) => {
    // const item = menus.find(a => a.rowid === eid);
    (item) && actions.go(URL.HOME, { rowid: item.rowid });
  }

  return <StyledObject className={cx('product')}>
    <Menubar list={menus} pos={pos} onClick={onClickMenu} />
    <div className={'p-frame'}>
      <Content item={item} list={list} openConfirm={props.openConfirm} />
    </div>
  </StyledObject>
};

export default Product;