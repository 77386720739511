import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Editbox, Button, Util, TabButton, Kakaomap, Error } from 'object/src';
import { URL, EID, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import KaKaoLogin from 'react-kakao-login';
import { NaverLogin } from '../view/Signin';
// import ReactDOMServer from "react-dom/server";

const StyledObject = styled.div`{
  .p-line { ${cs.w.full} ${cs.border.top} ${cs.border.darkgray} ${cs.h.get(1)} ${cs.max.wmd} ${cs.align.bottom} }

  &.ad-webset { ${cs.bg.trans}
    .tab-grp { ${cs.m.t30} ${cs.p.l20} }
    .hp-frame { ${cs.p.a20} }
  }

  &.sect {
    ${cs.m.v20} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wmd} ${cs.w.full}
    .edit-box { ${cs.m.t10} .guide { ${cs.font.xs} ${cs.font.lightprimary} ${cs.m.t3} } }
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .sec-box { ${cs.p.a10} }
    p.noti { ${cs.font.sm} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} ${cs.font.line(16)}
      ${cs.font.prewrap}
      &.hover:hover { ${cs.font.underline} ${cs.mouse.pointer} } 
    }

    .g-btn { ${cs.align.rtop} ${cs.top(0)} }
  }

  &.ws-kakao {
    .map { ${cs.left(200)} }
    .map-box { ${cs.align.ltop} ${cs.left(40)} ${cs.top(70)} ${cs.w.get(500)} ${cs.h.get(300)} ${cs.bg.white} ${cs.z.over} 
      ${cs.box.shadow}
      .map-close { ${cs.z.over} ${cs.right(5)} ${cs.bottom(5)} }
    }
    .kakao-btn { ${cs.disp.inblock} ${cs.right(0)} ${cs.align.lbottom} ${cs.bottom(0)} 
      ${cs.w.get('180px !important')} ${cs.h.get('34px !important')} ${cs.font.line('34px !important')}
      ${cs.mouse.pointer}
    }
  }

  &.ws-naver {
    .test-btn { ${cs.m.l10} ${cs.w.get(200)} }
    .copy { ${cs.m.t10} ${cs.font.md} ${cs.font.white} ${cs.font.line(20)} ${cs.font.prewrap} ${cs.font.nanumgothic} }
    .mt { ${cs.m.t30} }
  }

  &.ws-ncp {
    .test-box { ${cs.m.t20} ${cs.align.bottom}
      .test-btn { ${cs.m.l10} ${cs.w.get(200)} }
      .phone { ${cs.w.get(200)} ${cs.disp.inblock} ${cs.m.r20} ${cs.m.l10} ${cs.m.t0} ${cs.float.left} ${cs.top(0)} }
    }
  }

  &.ws-pg { }

  @media screen and (max-width : 600px) {
    &.ad-webset .hp-frame { ${cs.p.h10} }
    .tab-button { ${cs.p.l10} .tab-frame .tab-btn { ${cs.p.h5} } }
    .button .btn-label { ${cs.p.h5} ${cs.font.xs} }
    &.sect { .save { ${cs.m.r10} } .test-btn { ${cs.m.l10} } }
    &.ws-kakao {
      .save { ${cs.align.rbottom} ${cs.bottom(-50)} ${cs.w.calc('100% - 20px')} ${cs.m.r5} }
      .map { ${cs.align.right} ${cs.right(10)} }
      .kakao-btn { ${cs.m.l5} }
    }
  }
}`;

const STW = ST.ADMIN.WEBSET;
const API = URL.API.ADMIN;

const Webset = (props) => {
  const [refresh, setRefresh] = useState(new Date());
  const [tab, setTab] = useState('kakao');

  const doReload = (code) => {
    Util.showAlert(props, code);
    setRefresh(new Date());
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
  }

  const TABS = [
    // { id: 'server', title: STW.SERVER },
    { id: 'kakao', title: STW.KAKAO },
    { id: 'naver', title: STW.NAVER },
    { id: 'naversms', title: STW.NAVERSMS },
    { id: 'payment', title: STW.PAYMENT },
  ];

  const issys = Util.isSystemAdmin();
  if (!issys) return <Error className={'t3'} title={ST.LOGINSYSADMIN} />

  return <StyledObject className={cx('ad-webset')}>
    <TabButton className={"tab-grp"} size={'md'} onClick={onClick}
      list={TABS} select={tab} color={'gd-gray'} />
    {/* {tab === 'server' && <div className={cx('hp-frame', tab)}>
      <SetServer doReload={doReload} refresh={refresh} openConfirm={props.openConfirm} />
    </div>} */}
    {tab === 'kakao' && <div className={cx('hp-frame', tab)}>
      <SetKakao doReload={doReload} refresh={refresh} openConfirm={props.openConfirm} />
    </div>}
    {tab === 'naver' && <div className={cx('hp-frame', tab)}>
      <SetNaver doReload={doReload} refresh={refresh} openConfirm={props.openConfirm} />
    </div>}
    {tab === 'naversms' && <div className={cx('hp-frame', tab)}>
      <SetNaverSms doReload={doReload} refresh={refresh} openConfirm={props.openConfirm} />
    </div>}
    {tab === 'payment' && <div className={cx('hp-frame', tab)}>
      <SetPayment doReload={doReload} refresh={refresh} openConfirm={props.openConfirm} />
    </div>}
  </StyledObject>
};

export default Webset;

const SetKakao = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [mapbox, setMapbox] = useState(false);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setList(result && result.length > 0 ? result : null);
      setData(json);
      setModified(false);

      window.Kakao.init(json && json.kakaokey_script);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    if (!isvalidate) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      return { rowid: item ? item.cfgid : null, stag: 'app', skey: key, sval: refs[key].getValue() }
    });

    actions.doInsert(API.SETTING, array).then(({ code, result }) => {
      props.doReload && props.doReload(code);
      setModified(false);
    });

    return;
  }

  const onTest = (eid) => {
    if (eid === 'map') setMapbox(true);
  }

  const onSuccess = (res) => {
    if (!res) return;
    const { profile, response } = res;

    const token = response && response.access_token;
    const value = { type: 'kakao', userid: profile.id, password: token, token };

    props.openConfirm({
      title: ST.ALARM, type: 'info', msg: `${STW.KAKAO_LOGIN_SUCCESS(value.userid)}`, size: 'sm', className: 'gray', cancel: false,
      onClicked: (isOk) => { }
    });
  }


  const onFail = (res) => {
    props.openConfirm({
      title: ST.ALARM, type: 'info', msg: `${STW.KAKAO_LOGIN_FAIL}`, size: 'sm', className: 'gray', cancel: false,
      onClicked: (isOk) => { }
    });
  }

  if (!data) return null;

  return <StyledObject className={cx('ws-kakao sect')}>
    <p className={'title'}>{STW.KAKAO}</p>
    {/* <GuideBox className={'right top'} list={KAKAO_HELP} /> */}
    <Button className={'md gd-gray g-btn'} title={ST.HELPER} onClick={() => window.open(`${URL.ADMIN.GUIDE}/kakao`)} />
    <div className={'sec-box'}>
      <Editbox ref={(ref) => { refs.kakaokey_script = ref }} className={"kakaokey_script"} value={data.kakaokey_script}
        name="kakaokey_script" type="text" label={STW.KAKAO_KEY_SCRIPT} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.KAKAO_DEVELOP)}>{`* ${STW.KAKAO_KEY_NOTI_SCRIPT}`}</p>
      <Editbox ref={(ref) => { refs.kakaokey = ref }} className={"kakaokey"} value={data.kakaokey}
        name="kakaokey" type="text" label={STW.KAKAO_KEY} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.KAKAO_DEVELOP)}>{`* ${STW.KAKAO_KEY_NOTI}`}</p>
    </div>
    {mapbox && <span className={'map-box'}>
      <Kakaomap position={[37.39502206386185, 127.11097633336094]} kakaokey={data.kakaokey} />
      <Button className={'trans right bottom gd-gray map-close'} title={ST.CLOSE} onClick={() => setMapbox(false)} />
    </span>}

    <KaKaoLogin className={'kakao-btn'} jsKey={data.kakaokey_script} buttonText={ST.SIGNUP.KAKAO}
      onSuccess={onSuccess} onFailure={onFail} getProfile={true} />
    <Button className={'green bottom left map test'} title={STW.KAKAO_MAP_TEST} onClick={() => onTest('map')} disabled={!(data && data.kakaokey)} />
    <Button className={'red bottom right save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetNaver = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setList(result && result.length > 0 ? result : null);
      setData(json);
      setModified(false);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    if (!isvalidate) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      return { rowid: item ? item.cfgid : null, stag: 'app', skey: key, sval: refs[key].getValue() }
    });

    actions.doInsert(API.SETTING, array).then(({ code, result }) => {
      props.doReload && props.doReload(code);
      setModified(false);
    });

    return;
  }

  const onClick = () => {
    props.openConfirm({
      title: ST.ALARM, type: 'info', msg: `${STW.NAVER_TEST_SUCCESS}`, size: 'md', className: 'gray', cancel: false,
      onClicked: (isOk) => {
        Util.logout();
        window.location.href = URL.ADMIN.ROOT;
      }
    });
  }

  if (!data) return null;
  const { origin } = window.location;

  return <StyledObject className={cx('ws-naver sect')}>
    <p className={'title'}>{STW.NAVER}</p>
    {/* <GuideBox className={'right top'} list={NAVER_HELP} /> */}
    <Button className={'md gd-gray g-btn'} title={ST.HELPER} onClick={() => window.open(`${URL.ADMIN.GUIDE}/naver`)} />
    <div className={'sec-box'}>
      <Editbox ref={(ref) => { refs.naverclientid = ref }} className={"naverclientid"} value={data.naverclientid}
        name="naverclientid" type="text" label={STW.NAVER_CLIENT_ID} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.NAVER_DEVELOP)}>{`* ${STW.NAVER_CLIENT_ID_NOTI}`}</p>
      <Editbox ref={(ref) => { refs.naverclientsecret = ref }} className={"naverclientsecret"} value={data.naverclientsecret}
        name="naverclientsecret" type="text" label={STW.NAVER_CLIENT_SECRET} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.NAVER_DEVELOP)}>{`* ${STW.NAVER_CLIENT_SECRET_NOTI}`}</p>

      <p className={'copy mt'} >{`${STW.NAVER_URL_COPY(origin)}`}</p>
      <p className={'copy'} >{`${STW.NAVER_CALL_COPY(`${origin}?naver=true`)}`}</p>
    </div>
    {data.naverclientid && <div className={'test-btn'}><NaverLogin clientid={data.naverclientid} title={STW.NAVER_LOGIN_TEST} ispopup={true} onClick={onClick} /></div>}
    <Button className={'red bottom right save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetNaverSms = (props) => {
  var refs = {}, objs = {};
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setList(result && result.length > 0 ? result : null);
      setData(json);
      setModified(false);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    if (!isvalidate) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      return { rowid: item ? item.cfgid : null, stag: 'app', skey: key, sval: refs[key].getValue() }
    });

    actions.doInsert(API.SETTING, array).then(({ code, result }) => {
      props.doReload && props.doReload(code);
      setModified(false);
    });

    return;
  }

  const doSendMessage = (phone) => {
    actions.doInsert(URL.API.PAGE.PHONEREGIST, { phone }).then(({ code, result }) => {
      if (code === CODE.SUCCESS) {
        const { status = 0, statusCode = 0, errorMessage = '', errors = null, error = null } = result;
        if (Number(status) > 0) {  // 400 or 404
          // 발송이 실패할 경우
          let text = '';
          if (errors) {
            text = errors instanceof Array && errors.length > 0 ? errors[0] : errors;
          } else {
            text = errorMessage;
          }

          if (!text && error) {
            let { message = '', path = '' } = result;
            text = message + '\n' + path;
          }

          props.openConfirm({
            title: 'ERROR', type: 'warn', msg: text, size: 'md', className: 'gray', cancel: false,
          });
        } else if (Number(statusCode) === 202) {
          // 발송이 성공할 경우
          props.openConfirm({
            title: ST.ALARM, type: 'info', msg: `${STW.NCP_TEST_SUCCESS}`, size: 'sm', className: 'gray', cancel: false,
          });
        } else if (error) {
          const { details = '' } = error;
          props.openConfirm({
            title: 'ERROR', type: 'err', msg: details, size: 'sm', className: 'gray', cancel: false,
          });
        }
      } else {
        props.openConfirm({
          title: 'ERROR', type: 'err', msg: `${STW.NCP_TEST_FAIL}`, size: 'sm', className: 'gray', cancel: false,
        });
      }
    });
  }

  const onClick = () => {
    // const phone = refs.ncpstel.getValue();
    // if (!refs.ncpstel.isValidate()) {
    //   return;
    // }

    var phone = '';
    props.openConfirm({
      size: 'sm', type: 'info', className: 'dark', title: ST.ALARM, cancel: false,
      children: () => {
        const StyledComp = styled.div`{
          &.phone-pop {
            .title { ${cs.m.t10}}
            .label { ${cs.m.t5} ${cs.m.b20} ${cs.font.sm} ${cs.font.yellow} } 
          }
        }`;

        return <StyledComp className={'phone-pop'}>
          <Editbox ref={(ref) => { objs.phone = ref }} className={"title"} value={phone}
            type="phone" validate={true} onChange={(v) => phone = v} focus={true} />
          <p className={'label'}>{STW.NCP_TEST_PHONE}</p>
        </StyledComp>
      },
      onClicked: (isOk) => {
        if (isOk) {
          if (!objs.phone.isValidate() || !phone || phone.length < 1) {
            return false;
          }
          doSendMessage(phone);
        }
      }
    });
  }

  if (!data) return null;
  const istest = data && data.ncpsid && data.ncpakey && data.ncpskey && data.ncpstel;

  return <StyledObject className={cx('ws-ncp sect')}>
    <p className={'title'}>{STW.NCPSMS}</p>
    {/* <GuideBox className={'right top'} list={NCP_HELP} /> */}
    <Button className={'md gd-gray g-btn'} title={ST.HELPER} onClick={() => window.open(`${URL.ADMIN.GUIDE}/ncp`)} />
    <div className={'sec-box'}>
      <p className={'noti hover'} onClick={() => window.open(URL.NCP_DEVELOP)}>{`* ${STW.NCP_SERVICE_NOTI}`}</p>
      <Editbox ref={(ref) => { refs.ncpsid = ref }} className={"ncpsid"} value={data.ncpsid}
        name="ncpsid" type="text" label={STW.NCP_SERVICE_ID} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.NCP_DEVELOP)}>{`* ${STW.NCP_SERVICE_ID_NOTI}`}</p>
      <Editbox ref={(ref) => { refs.ncpakey = ref }} className={"ncpakey"} value={data.ncpakey}
        name="ncpakey" type="text" label={STW.NCP_ACCESS} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.NCP_DEVELOP)}>{`* ${STW.NCP_ACCESS_NOTI}`}</p>
      <Editbox ref={(ref) => { refs.ncpskey = ref }} className={"ncpskey"} value={data.ncpskey}
        name="ncpskey" type="text" label={STW.NCP_SECRET} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.NCP_DEVELOP)}>{`* ${STW.NCP_SECRET_NOTI}`}</p>
      <Editbox ref={(ref) => { refs.ncpstel = ref }} className={"phone"} value={data.ncpstel}
        name="ncpstel" type="text" label={STW.NCP_PHONE} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.NCP_DEVELOP)}>{`* ${STW.NCP_PHONE_NOTI}`}</p>
    </div>
    <div className={'test-box'}>
      <Button className={'green test-btn sm'} title={STW.NCP_TEST} onClick={onClick} disabled={!istest} />
    </div>
    <Button className={'red bottom right save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetPayment = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setList(result && result.length > 0 ? result : null);
      setData(json);
      setModified(false);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    if (!isvalidate) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      return { rowid: item ? item.cfgid : null, stag: 'app', skey: key, sval: refs[key].getValue() }
    });

    actions.doInsert(API.SETTING, array).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onTest = () => {
    //'samsung':삼성페이, 'card':신용카드, 'trans':실시간계좌이체, 'vbank':가상계좌, 'phone':휴대폰소액결제
    const key = refs.pgid.getValue();
    window.IMP.init(key);
    window.IMP.request_pay({
      pg: 'inicis', // version 1.1.0부터 지원.
      pay_method: 'card',
      merchant_uid: `ODR00000000`,
      name: '결제 연동 테스트',
      amount: '100',
      buyer_email: 'test@test.com',
      buyer_name: '테스트',
      buyer_tel: '010-0000-0000',
      buyer_addr: '서울시 강남구 ...',
      buyer_postcode: '00000',
      m_redirect_url: `${window.location.pathname}/admin/webset`,
    }, function (rsp) {
      const { error_msg, error_code } = rsp;
      if (error_code === "NOT_READY") {
        props.openConfirm({
          title: ST.ALARM, type: 'info', msg: `${STW.PAYMENT_TEST_FAIL}\n\n*${error_msg}`, size: 'sm', className: 'gray', cancel: false,
          onClicked: (isOk) => { }
        });
      } else {
        console.error(`결제에 실패하였습니다. \n에러내용 : ${error_msg}`);
      }
    });
  }

  if (!data) return null;

  const istest = data && data.pgid && data.pgakey && data.pgskey;
  return <StyledObject className={cx('ws-payment sect')}>
    <p className={'title'}>{STW.PAYMENT}</p>
    <Button className={'md gd-gray g-btn'} title={ST.HELPER} onClick={() => window.open(`${URL.ADMIN.GUIDE}/iamport`)} />
    {/* <GuideBox className={'right top'} list={IAMPORT_HELP} /> */}
    <div className={'sec-box'}>
      <Editbox ref={(ref) => { refs.pgid = ref }} className={"pgid"} value={data.pgid}
        name="pgid" type="text" label={STW.PG_ID} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.IAMPORT_DEVELOP)}>{`* ${STW.PG_ID_NOTI}`}</p>
      <Editbox ref={(ref) => { refs.pgakey = ref }} className={"pgakey"} value={data.pgakey}
        name="pgakey" type="text" label={STW.PG_AKEY} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.IAMPORT_DEVELOP)}>{`* ${STW.PG_AKEY_NOTI}`}</p>
      <Editbox ref={(ref) => { refs.pgskey = ref }} className={"pgskey"} value={data.pgskey}
        name="pgskey" type="text" label={STW.PG_SKEY} validate={true} onChange={() => setModified(true)} />
      <p className={'noti hover'} onClick={() => window.open(URL.IAMPORT_DEVELOP)}>{`* ${STW.PG_SKEY_NOTI}`}</p>
    </div>
    <Button className={'green bottom left test-btn'} title={STW.PAYMENT_TEST} onClick={onTest} disabled={!istest} />
    <Button className={'red bottom right save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}