import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Util, Thumbbox, Storage, Formgroup, Editbox, Combobox, Postcode } from 'object/src';
import { CODE } from 'object/src/Config';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.payment { ${cs.pos.relative} ${cs.m.center()} ${cs.size.full} ${cs.max.wlg} ${cs.font.md} ${cs.noliststyle}
    ${cs.noselect}

    .pm-head { ${cs.h.get(40)} ${cs.pos.relative} ${cs.m.t40} ${cs.m.b10} 
      .title { ${cs.font.t0} } 
      .button { ${cs.font.darkgray} } 
    }

    .pm-body { ${cs.h.fit} ${cs.bg.white} ${cs.w.full} ${cs.h.fit} ${cs.m.b30} ${cs.p.a30}
      .pm-title { ${cs.disp.block} ${cs.border.bottom} ${cs.border.width(3)} ${cs.border.dark}
        & > span { ${cs.disp.inblock} ${cs.font.line(30)} ${cs.font.center} ${cs.font.thickbold} }
      }

      .product { ${cs.w.calc('100% - 500px')} ${cs.font.left} ${cs.disp.inblock} }
      .count { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.font.center} }
      .price, .total { ${cs.disp.inblock} ${cs.w.get(200)} ${cs.font.center} }

      .pm-total { ${cs.h.get(60)} ${cs.font.bold} ${cs.font.orangehover} ${cs.font.line(48)} ${cs.p.t10}
        .count { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.p.l20} ${cs.font.xl} }
        .price { ${cs.disp.inblock} ${cs.w.calc('100% - 120px')} ${cs.float.right} ${cs.p.r20} ${cs.font.t1} ${cs.font.right} }
      }

      .pm-btn {
        ${cs.p.v20}
        .pb-types { & > .button { ${cs.m.r10} ${cs.font.sm} } }
        .shoping { &:hover { .btn-label { ${cs.font.primaryhover} ${cs.font.underline} } } }
        .cartpay { ${cs.align.right} ${cs.right(40)} }
        .p-total { ${cs.font.right} ${cs.m.t30} ${cs.p.r20} .sum { ${cs.m.l10} ${cs.font.t0} ${cs.font.orange} } }
        .pb-foot { ${cs.m.t10} }
      }

      .pm-nodata { ${cs.min.h(400)} ${cs.pos.relative}
        .txt { ${cs.align.xcenter} ${cs.font.lg} ${cs.font.gray} ${cs.top(80)} }
        .shoping { ${cs.top(140)} }
      }
    }

    .title { ${cs.font.lg} ${cs.font.thickbold} ${cs.m.b5} } 
    .p-line { ${cs.border.bottom} ${cs.border.width(2)} ${cs.border.dark} ${cs.m.b20} }
  }

  &.p-list { ${cs.pos.relative} ${cs.min.h(300)} ${cs.p.b50}
    .ul { ${cs.h.calc('100% - 60px')}
      .li { ${cs.disp.block} ${cs.pos.relative} ${cs.disp.autoflex}
        ${cs.h.get(90)} ${cs.border.bottom} ${cs.p.v10}

        .product { ${cs.p.l10} ${cs.h.full}
          .thumb-box { ${cs.w.get(70)} }
          .li-r { ${cs.disp.inblock} ${cs.float.right} ${cs.w.calc('100% - 80px')} ${cs.p.l10} }
        }
        .count { ${cs.h.full} ${cs.pos.relative} ${cs.font.line(80)}}
        .price, .total { ${cs.h.full} ${cs.font.lg} ${cs.font.right} ${cs.p.r50} ${cs.font.red} ${cs.pos.relative} 
          ${cs.font.line(80)} ${cs.font.thickbold}
          .pr-sale { ${cs.font.line(20)} ${cs.w.fit} ${cs.align.rtop} ${cs.right(45)} ${cs.top(5)}
            .ps-label { ${cs.font.sm} ${cs.bg.red} ${cs.font.white} ${cs.border.radius(3)} ${cs.p.h5} ${cs.p.v2} }
            .ps-box { ${cs.w.fit} ${cs.pos.relative} ${cs.p.l10} ${cs.font.gray} ${cs.font.sm} ${cs.font.cancelline} ${cs.p.h10} ${cs.p.v2} }
          }
        }
        .total { ${cs.font.orangehover} ${cs.font.xl} }
      }
    }
  }

  &.addr-box { ${cs.p.b20} ${cs.pos.relative}
    .last { ${cs.align.right} ${cs.top(-5)} }
    .p-frame { ${cs.pos.relative}
      .combo-box { ${cs.z.over} ${cs.max.w(480)} ${cs.float.none} }
      .form-grp { ${cs.over.unset} }
      .edit-box { ${cs.border.bottom} 
        &.address .box .input.readonly { ${cs.bg.white} }
      }

      .h-box { ${cs.m.v10} ${cs.pos.relative} ${cs.h.fit} ${cs.z.menu}
        .hl { ${cs.w.get('70%')} ${cs.disp.inblock} }
        .hr { ${cs.w.get('30%')} ${cs.disp.inblock} ${cs.float.right} ${cs.font.right} & > .button { ${cs.float.right} } }
        .combo-box { ${cs.max.w('100%')} }
        .zipcode { ${cs.disp.inblock} ${cs.min.w(100)} ${cs.w.calc('100% - 120px')} 
          ${cs.m.h10} ${cs.border.bottom} ${cs.font.line(28)} ${cs.font.left} ${cs.h.get(30)}
        }
      }
      .p-box { ${cs.m.t20} ${cs.border.radius(5)} ${cs.bg.frame} ${cs.p.a20} 
        .p-guide { ${cs.font.prewrap} ${cs.font.line(20)} ${cs.font.dark} }
      }
      .h-dev { ${cs.m.t10} & > div { ${cs.w.r50} } .etc { ${cs.float.right} } }
      .p-noti { ${cs.align.ltop} ${cs.top(20)} ${cs.w.get(340)} ${cs.h.fit} ${cs.p.a10} ${cs.border.radius(5)}
        ${cs.box.shadow} ${cs.bg.frame}
      }

      .post-box { ${cs.p.a20} }
    }
  }

  &.pm-noti {
    ${cs.min.h(100)} ${cs.box.light} ${cs.bg.color(cs.color.frame)} ${cs.p.a20} 
    ${cs.font.gray} ${cs.font.md} ${cs.font.line(30)} ${cs.m.b30}
    .title { ${cs.font.lg} ${cs.font.dark} ${cs.font.thickbold} }
    .label { ${cs.m.l20} }
    .desc { ${cs.font.orange} ${cs.m.l20} }

    &.nodata { ${cs.align.bottom} ${cs.w.full} ${cs.bottom(0)}}
  }

  
  @media screen and (max-width : 1024px) { }
  
  @media screen and (max-width : 800px) { 
    &.payment {
      .pm-head { ${cs.p.l10} ${cs.font.sm}
        .title { ${cs.font.md} } 
        .count { ${cs.disp.none} }
      }
      .pm-body { ${cs.p.h10}
        .product { ${cs.w.get('calc(100% - 400px)')} }
        .pm-title { .count { ${cs.disp.none} } }
        .ul .li { 
          ${cs.p.t40} ${cs.h.get(120)}
          .price, .total { ${cs.font.sm} } 
          .count { ${cs.disp.none} }
          .li-r .title { ${cs.align.ltop} ${cs.left(10)} }
        }
      }
    }

    &.addr-box { 
      .p-frame {
        .h-box {
          .hl { ${cs.w.full} ${cs.disp.block} }
          .hr { ${cs.w.full} ${cs.disp.block} ${cs.font.left} ${cs.m.t5} ${cs.m.b10}
            .zipcode { ${cs.m.l0} ${cs.w.calc('100% - 220px')} ${cs.p.l10} } 
            .button { ${cs.w.get(200)} }
          }
        }

        .post-box { ${cs.p.a5} ${cs.p.t30} }
      }
      .p-guide { ${cs.font.sm} }
    }
  }

  @media screen and (max-width : 600px) { 
    &.payment {
      .pm-body { ${cs.p.h10} ${cs.m.b0}
        .pm-title > span { ${cs.font.right} }
        .pm-title .product { ${cs.font.left} ${cs.p.l10} }
        .product { ${cs.w.get('calc(100% - 300px)')} }
        .price { ${cs.w.get(180)} } 
        .total { ${cs.w.get(120)} ${cs.p.r10} } 
        .ul .li { .price, .total { ${cs.p.r10} } .pr-sale { ${cs.right(0)} } }

        .p-total { ${cs.font.sm} ${cs.pos.relative} ${cs.p.b50} ${cs.font.center}
          .sum { ${cs.align.rtop} ${cs.top(30)} ${cs.right(10)} }
        }

        .pm-btn .cartpay { ${cs.right(10)} }
        .shoping { ${cs.p.h10} }
      }
    }
  }
}`;

const API = URL.API.PAGE;
const Payment = (props) => {
  const [list, setList] = useState(null);
  const [total, setTotal] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState({});
  const [select, setSelect] = useState('card');
  const [param, setParam] = useState(null);
  const [noti, setNoti] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [bank, setBank] = useState('');
  const [tcost, setTcost] = useState(4000);
  const [user, setUser] = useState(null);
  // const [trans, setTrans] = useState(null);
  // const [tmax, setTmax] = useState(0);
  // const [lastorder, setLastorder] = useState(0);

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = () => {
    const guid = Storage.getLocalItem('guid');
    actions.doSelect(API.PAYMENT, { limit: 10, guid }, true).then((result) => {
      const { address, delivery, guide, bank, lastorder, cartlist, translist, user } = result;
      setParam({ address, delivery, guide, lastorder });
      setBank(bank && bank.sval);

      // 주소록
      const item = address && address.find(a => a.prim === 'Y');
      let value = {};
      value['addr'] = item ? item.addr : '';
      value['addr_rest'] = item ? item.addr_rest : '';
      value['phone'] = item ? item.phone : '';
      value['zipcode'] = item ? item.zipcode : '';
      value['name'] = item ? item.name : '';
      value['text'] = '';
      setValue(value);

      // 쇼핑카트 목록
      let total = 0;
      const array = cartlist && cartlist.map(a => {
        a.total = a.price * a.count;
        total += a.total;
        a.modified = false;
        return a;
      });

      if (!array || array.length < 1) {
        actions.go(URL.CART);
        return;
      }

      setList(array);
      setTotal(total);

      // 택배 정보 설정 및 금액 설정
      let json = {}
      translist && translist.map(a => json[a.skey] = a.sval);
      const tmax = Number((json && json.tmax) || 0);
      const tcost = tmax > 0 && total >= tmax ? 0 : Number((json && json.tcost) || 4000);
      setTcost(tcost);

      // 사용자 정보
      setUser(user);

      setLoaded(true);
      setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
      // setLastorder(lastorder);
    });
  }

  const onClickType = (eid, e) => {
    setSelect(eid);
  }

  const onClickNext = () => {
    const doNext = () => {
      if (!value.addr) return setNoti('addr');
      if (!value.addr_rest) return setNoti('addr_rest');
      if (!value.name) return setNoti('name');
      if (!value.phone) return setNoti('phone');

      if (!value.zipcode) {
        console.dir("zipcode error");
        return;
      };

      const guid = Storage.getLocalItem('guid');
      const product = `${list[0].title} ${ST.PAYMENT.ETC(list.length)}`;
      const data = {
        ...value, guid, address: `${value.addr} ${value.addr_rest}`,
        price: Number(total) + Number(tcost), product, tcost, tkind: select
      };
      actions.doInsert(API.ORDER, data).then(({ code, result }) => {
        if (code === CODE.SUCCESS) {
          actions.go(URL.ORDER, { rowid: result });
        } else {
          props.showAlert({ msg: ST.NOTI.FAILUER, type: 'err' });
        }
      });
    }

    const { email = null, noemail = false } = user;
    if (!email && noemail !== 'N') {
      props.openConfirm({
        size: 'md', type: 'info', title: ST.MYPAGE.MEMBER, msg: ST.PAYMENT.EMAIL_SET,
        ok: ST.YES, cancel: ST.NO,
        children: () => {
          const StyledObject = styled.div`{
            &.email-set { .label { ${cs.m.b20} } .check { ${cs.font.underline} ${cs.font.primary} &:hover { ${cs.font.primaryhover} }  } }
          }`;
          return <StyledObject className={'email-set'}>
            <p className={'label'}>{ST.PAYMENT.EMAIL_SET}</p>
            <p className={'check'} onClick={() => onClickNoemail(() => doNext())}>{ST.PAYMENT.EMAIL_NO}</p>
          </StyledObject>
        },
        onClicked: (isOk) => {
          if (isOk) {
            actions.go(URL.MYPAGE, { page: 'member' });
          } else {
            // 일단 현재 한번만 넘어가도록 설정
            setUser({ ...user, noemail: 'N' });
            doNext();
          }
        }
      });
    } else {
      doNext();
    }
  }

  const onClickNoemail = (callback = null) => {
    setUser({ ...user, noemail: 'N' });
    actions.doUpdate(URL.API.PAGE.MYINFO, { noemail: 'N' }).then(({ code, result }) => callback && callback());
  }

  const onRefresh = () => {
    const current = { ...value };
    setValue({});
    setTimeout(() => {
      setValue({ ...current });
    }, 200);
  }

  const icon = (s, v) => s === v ? 'radio' : 'unradio';
  const color = (s, v) => s === v ? 'yellow' : 'white';

  if (!loaded || !list) return null;

  // const tmax = Number((trans && trans.tmax) || 0);
  // const tcost = tmax > 0 && total >= tmax ? 0 : Number((trans && trans.tcost) || 4000);
  return <StyledObject className={cx('payment')}>
    <div className={'pm-head'}>
      <p className={'title'}>{ST.PAYMENT.TITLE}</p>
    </div>
    <div className={'pm-body'}>
      <div className={'pm-title'}>
        <span className={'product'}>{ST.CART.PRODUCT}</span>
        <span className={'count'}>{ST.CART.COUNT}</span>
        <span className={'price'}>{ST.CART.PRICE}</span>
        <span className={'total'}>{ST.CART.SUM}</span>
      </div>

      <Listbox list={list} />
      <Addrbox value={value} {...param} noti={noti} onRefresh={onRefresh} />

      <div className={'pm-btn'}>
        <p className={'title'}>{ST.PAYMENT.TITLE}</p>
        <div className={'p-line'} />
        <div className={'pb-types'}>
          <Button className={cx('border lg', color(select, 'card'))} title={ST.PAYMENT.CARD}
            eid={'card'} icon={icon(select, 'card')} onClick={onClickType} />
          {/* <Button className={cx('border lg', color(select, 'phone'))} title={ST.PAYMENT.PHONE}
            eid={'phone'} icon={icon(select, 'phone')} onClick={onClickType} />
          <Button className={cx('border lg', color(select, 'trans'))} title={ST.PAYMENT.BANK(bank)}
            eid={'trans'} icon={icon(select, 'trans')} onClick={onClickType} /> */}
        </div>
        <div className={'p-total'}>
          <span>{`${ST.PAYMENT.SUM(Util.commas(total))}`}</span>
          <span>{` + ${ST.PAYMENT.TRANS(Util.commas(tcost))}`}</span>
          {/* <span>{` - ${ST.PAYMENT.POINT(0)}`}</span> */}
          <span>{` = ${ST.PAYMENT.TOTAL}`}</span>
          <span className={'sum'}>{`${Util.commas(total + tcost)} ${ST.WON}`}</span>
        </div>
        <div className={'pb-foot'}>
          <Button className={'shoping left lg'} title={ST.PAYMENT.CANCEL} onClick={() => props.history.go(-1)} />
          <Button className={'cartpay orange box radius lg'} title={ST.PAYMENT.NEXT} onClick={onClickNext} />
        </div>
      </div>
    </div>
  </StyledObject >
};

export default Payment;

const Listbox = (props) => {
  const { list } = props;

  if (!list) return null;

  return <StyledObject className={'p-list'}>
    <ul className={'ul'}>
      {list.map(item => {
        const issale = item.price < item.cost;
        return <li key={item.rowid} className={'li'}>
          <span className={'product'}>
            <Thumbbox src={item.thumb} width={'70px'} height={'70px'} objectFit={'cover'} />
            <span className={'li-r'}>
              <span className={'title'}>{item.title}</span>
              <span className={'guide'}>{''}</span>
            </span>
          </span>
          <span className={'count'}>
            <span className={'value'}>{item.count}</span>
          </span>
          <span className={'price'}>
            {issale && <div className={'pr-sale'}>
              <span className={'ps-label'}>{ST.TAG.SALE}</span>
              <span className={'ps-box'}>{`${Util.commas(item.cost)} ${ST.WON}`}</span>
            </div>}
            <span className={'pv'}>{`${Util.commas(item.price)}`}</span>
            <span className={'pu'}>{ST.WON}</span>
          </span>
          <span className={'total'}>
            <span className={'pv'}>{`${Util.commas(item.price * item.count)}`}</span>
            <span className={'pu'}>{ST.WON}</span>
          </span>
        </li>
      })}
    </ul>
  </StyledObject>
}

const Addrbox = (props) => {
  var refs = {};
  // const [modified, setModified] = useState(false);
  const [list, setList] = useState(null);
  // const [data, setData] = useState(null);
  const [delivery, setDelivery] = useState(null);
  // const [request, setRequest] = useState(null);
  const [guide, setGuide] = useState('');
  const [ext, setExt] = useState(false);
  const [post, setPost] = useState(false);

  useEffect(() => {
    const { address = null, delivery = null, guide = null } = props;

    setList(address && address.length > 0 ? address : null);
    let arrays = delivery && delivery.sval ? delivery.sval.split('#') : [];
    setDelivery([{ id: -1, name: ST.PAYMENT.DELIVERY }, ...arrays.map((a, i) => { return { id: i, name: a } })]);
    setGuide(guide && guide.sval);

    return () => {
    }
  }, [props]);

  useEffect(() => {
    if (props.noti) refs[props.noti] && refs[props.noti].showNoti(ST.PAYMENT.REQUIRED);
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.noti])

  const onRequest = (eid, e, item, pos) => {
    if (pos === delivery.length - 1) {
      setExt(true);
    } else if (pos === 0) {
      props.value['text'] = '';
      // setRequest('');
    } else {
      setExt(false);
      props.value['text'] = item.name;
      // setRequest(item.name);
    }
  }

  const onAddress = (eid, e, item, pos) => {
    setPost(false);
    if (item.id <= 0) { // 배송지 추가
      Object.keys(props.value).map(key => props.value[key] = '');
    } else {
      const value = list.find(a => a.rowid === item.id);
      Object.keys(props.value).map(key => props.value[key] = value[key]);
      props.value['text'] = '';
    }

    props.onRefresh && props.onRefresh();
  }

  const onChange = (value, label) => {
    props.value[label] = value;
    // setModified(true);
  }

  const onClickPost = () => {
    setPost(true);
  }

  const onComplete = (address, item) => {
    // Object.keys(props.value).map(key => props.value[key] = '');
    setTimeout(() => {
      setPost(false);
      props.value['addr'] = address;
      props.value['zipcode'] = item.zonecode;
      props.onRefresh && props.onRefresh();
    }, 200);
  }

  const onClickLast = () => {
    const { lastorder } = props;
    if (lastorder) {
      const item = list.sort((a, b) => a.ctime - b.ctime).find(a => a.zipcode === lastorder.zipcode);
      props.value['addr'] = item ? item.addr : lastorder.address;
      props.value['addr_rest'] = item ? item.addr_rest : ' ';
      props.value['zipcode'] = item ? item.zipcode : lastorder.zipcode;
      props.value['phone'] = item ? item.phone : lastorder.phone;
      props.value['name'] = item ? item.name : lastorder.name;
      props.onRefresh && props.onRefresh();
    }
  }

  const newaddr = { id: -1, name: ST.PAYMENT.SEL_ADDRESS };
  const { rowid = null, addr, addr_rest, phone, name, zipcode, text } = props.value;
  const addrlist = list ? [newaddr, ...list.map(a => { return { id: a.rowid, name: `${a.addr} ${a.addr_rest}` } })] : [newaddr];
  const pos = addrlist && rowid ? addrlist.findIndex(a => String(a.id) === String(rowid)) : 0;
  const { lastorder = null } = props;

  return <StyledObject className={'addr-box'}>
    <p className={'title'}>{ST.PAYMENT.ADDRESS}</p>
    {lastorder && <Button className={cx('border sm gd-gray last')} title={ST.PAYMENT.LAST_ORDER} icon={''} onClick={onClickLast} />}
    <div className={'p-line'} />
    <div className={'p-frame'}>
      <div className={'h-box'}>
        <div className={'hl'}>
          <Combobox className={'sm white full'} list={addrlist} options={{ text: { align: 'left' } }}
            pos={pos} onChange={onAddress} />
        </div>
        <div className={'hr'}>
          <p className={'zipcode'}>{zipcode}</p>
          <Button className={cx('border sm')} title={ST.MYPAGE.POST_FIND}
            eid={'zipcode'} onClick={onClickPost} />
        </div>
      </div>
      {post && <div className={'post-box'}>
        <Postcode onComplete={onComplete} onCancel={() => setPost(false)} />
      </div>}
      <Formgroup inline={true}>
        <div onClick={onClickPost}>
          <Editbox ref={(ref) => { refs.addr = ref }} className={'md white address'} placeholder={ST.MYPAGE.ADDR_F}
            guide={ST.MYPAGE.ADDR_F} value={addr} onChange={(v) => onChange(v, 'addr')} readonly />
        </div>
        {/* <p className={cx('edit-box address', !addr && 'guide')} onClick={onClickPost}>{addr || ST.MYPAGE.ADDR_F}</p> */}
        <Editbox ref={(ref) => { refs.addr_rest = ref }} className={'md white'} guide={ST.MYPAGE.ADDR_D}
          value={addr_rest} onChange={(v) => onChange(v, 'addr_rest')} />
      </Formgroup>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.name = ref }} className={'md white'} focus={true}
          guide={ST.MYPAGE.RES_USER} value={name} onChange={(v) => onChange(v, 'name')} />
        <Editbox ref={(ref) => { refs.phone = ref }} className={'md white'} type={'phone'}
          guide={ST.MYPAGE.PHONE} value={phone} onChange={(v) => onChange(v, 'phone')} />
      </Formgroup>
      <div className={'h-dev'}>
        <Combobox className={'sm white full'} list={delivery} options={{ text: { align: 'left' } }} onChange={onRequest} />
        {ext && <Editbox className={'md white etc'} value={text} onChange={(v) => onChange(v, 'text')} />}
      </div>

      <div className={'p-box'}>
        <p className={'p-guide'}>{guide}</p>
      </div>
    </div>
  </StyledObject>
}