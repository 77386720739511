/* eslint-disable react/no-direct-mutation-state */
import React, { useEffect, useState } from 'react';
import { URL } from 'svc/Enum';
import { ST } from 'svc/Lang';
import { Util, Tablebox, Loading, cs } from 'object/src';
import * as actions from 'object/src/actor/Action';
import styled from 'styled-components';
import cx from 'classnames/bind';

const StyledObject = styled.div`{
  &.ad-historder { ${cs.pos.relative} ${cs.bg.dark} ${cs.p.a10} ${cs.max.wxl} ${cs.p.b50}
    
    .s-head { ${cs.m.t20} ${cs.pos.relative}
      .btn-mode { ${cs.align.xcenter} ${cs.pos.relative} .button { ${cs.min.w(120)} } }
    }

    .s-body { ${cs.p.h20} ${cs.m.t20} 
      .table-box .tb-line .tb-row .tb-col {
        .uid { ${cs.font.xs} ${cs.font.gray} }
      }
      .noti { ${cs.font.yellow} }
    }

    .s-foot { ${cs.m.t0} ${cs.font.right} ${cs.p.r20}
      .btn-new { }
      .btn-save { }
    }    

    @media screen and (max-width : 1024px) {
      .card-list .tline .trow {
        ${cs.w.calc('50% - 10px')}
      }
      .search-frame .search-box { ${cs.w.full} }
    }

    @media screen and (max-width : 600px) {
      .card-list .tline .trow {
        ${cs.w.full} ${cs.m.b30}
      }
    }
  }
  
  &.member-modal {
    .inbox { ${cs.m.t10} }
  }
}`;

const API = URL.API.ADMIN;
const STAH = ST.ADMIN.HISTORY;

const HistoryOrder = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: '', value: '' });
  const [order, ] = useState('ctime desc');
  const [limit, ] = useState(15);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  // const [trans, setTrans] = useState(null);
  const [running, setRunning] = useState(false);

  const param = actions.getParam();
  const { rowid } = param;  //userid

  useEffect(() => {
    doReload(1, search, order, limit, () => setLoaded(true));
    return () => {
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "%${Util.replaceAll(s.value, '.', '')}%"`;
      }

      value['where'] = value['where'] + ` and userid='${rowid}'`
    } else {
      value['where'] = `userid='${rowid}'`
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.HISTORY_ORDER, value, true).then(({ result, tstatus }) => {
      if (!result.page) return setRunning(false);

      const json = tstatus && tstatus.sval;
      if (json) {
        const arrays = JSON.parse(json);
        // setTrans(arrays);

        result.list && result.list.map(a => {
          const temp = arrays.find(b => String(b.id) === String(a.status));
          a['state'] = temp ? temp.title : ST.ADMIN.ORDER.CANCEL;
          return null;
        })
      }

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setRunning(false);

      callback && callback();
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  // const getItem = (id) => list.find(item => Number(item.rowid) === Number(id));

  const onSelect = (id) => {
    // let data = getItem(id);
    actions.go(URL.ADMIN.ORDER, { odrid: id });
  }

  if (loaded) {
    const head = [
      { key: 'no', title: ST.NUM, flex: '1 1 60px', mobile: 'hide' },
      { key: 'rowid', title: STAH.ORDERNO, flex: '2 1 100px', align: 'center' },
      { key: 'product', title: STAH.PRODUCT, flex: '4 1 180px', tablet: 'hide', align: 'left' },
      { key: 'price', title: STAH.PRICE, flex: '1 1 100px', align: 'center', type: 'number' },
      {
        key: 'state', title: STAH.STATUS, flex: '1 1 60px', align: 'center',
        formatter: (v, item) => {
          let color = 'white';
          const status = Number(item.status);
          if (status >= 90) color = cs.color.gray;

          return <div className={cx('')} style={{ color }}>{v}</div>
        }
      },
      {
        key: 'tname', title: `${STAH.TNAME}(${STAH.TNO})`, flex: '2 1 100px', align: 'center', mobile: 'hide',
        formatter: (v, item, pos) => {
          if (item.status > "90") {
            return null;
          } else {
            return <div className={'r-trans'}>
              {item.tno ? <p className={'link'}>{`${item.tname}(${item.tno})`}</p> : '-'}
            </div>
          }
        }
      },
      { key: 'ctime', title: STAH.ODATE, flex: '2 1 80px', type: 'datetime', mobile: 'hide', format: 'YYYY.MM.DD' }
    ];

    const searchs = [
      { name: STAH.ORDERNO, id: 'odrid', check: true },
      { name: STAH.PRODUCT, id: 'product', check: false },
      // { name: STAH.NAME, id: 'name', check: false },
      { name: STAH.ODATE, id: 'ctime', check: false },
    ];

    return <StyledObject className="ad-historder">
      <div className={'s-head'}>
      </div>

      <div className={'s-body'}>
        <Tablebox className={'dark'} head={head} list={list} searchkey={(search && search.key) || searchs[0].id}
          naviClass={'white'} onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
          pos={page} max={max} total={total} searchs={searchs} rowid="rowid" />
        <div className={'noti'}>{`* ${STAH.LINK_ORDER}`}</div>
      </div>
      {running && <Loading type='ring' />}
    </StyledObject>
  } else {
    return <Loading />;
  }
};

export default HistoryOrder;