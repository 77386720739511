import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Svg, Util, Storage, Imagebox, Thumblist, Thumbbox, Editbox } from 'object/src';
import { URL, EID } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';

const StyledObject = styled.div`{
  &.page-content { ${cs.bg.trans} ${cs.w.full} ${cs.p.a20} ${cs.noselect}
    .p-line { ${cs.disp.block} ${cs.w.full} ${cs.h.get(1)} ${cs.border.bottom} ${cs.m.v5} }
    .cwl { ${cs.m.center(0)} ${cs.max.wlg} ${cs.h.full} ${cs.pos.relative} ${cs.disp.block} }
  }

  &.page-head { ${cs.p.b40}
    .pl { ${cs.disp.inblock} ${cs.pos.relative} ${cs.w.full} ${cs.h.full} ${cs.w.get('70%')}
      .pl-img { ${cs.min.h(320)} ${cs.p.r30} 
        .image-box { ${cs.h.get(420)} ${cs.anim.showin('200ms')} }
        .img { ${cs.size.full} }
      }

      .pl-list { ${cs.disp.block} ${cs.w.full} ${cs.h.fit} ${cs.pos.relative} }
    }

    .pr { ${cs.disp.inblock} ${cs.w.get('30%')} ${cs.pos.relative} ${cs.float.right}
      .pr-d { ${cs.disp.block} ${cs.w.full} ${cs.font.line(44)} ${cs.h.get(44)} 
        .prd-tl { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.font.gray} ${cs.font.sm} }
        .prd-val { ${cs.disp.inblock} ${cs.w.calc('100% - 100px')} ${cs.font.right} ${cs.font.lg} ${cs.font.bold} }
        .num { ${cs.font.t0} }
        .unit { ${cs.p.l5} ${cs.font.sm} ${cs.opac.get(0.7)} }

        &.pr-label { ${cs.font.line(20)} }
        &.pr-title { ${cs.font.t0} }
        &.pr-cap { .tag { ${cs.bg.lightgray} ${cs.font.dark} ${cs.border.radius(3)} ${cs.p.h5} } }
        &.pr-count { ${cs.pos.relative}
          .prd-val { ${cs.group(5)}
            & > span { ${cs.w.get(30)} ${cs.h.get(30)} ${cs.font.line(30)} ${cs.disp.inblock} ${cs.font.center} ${cs.box.line} 
              &.icon { ${cs.bg.lightgray} ${cs.font.dark} ${cs.font.md} ${cs.noselect} ${cs.mouse.pointer} ${cs.z.front} }
            }
          }
        }
        &.pr-cost { ${cs.pos.relative} &.mt { ${cs.p.t10} } }
        &.pr-button { ${cs.m.t20} ${cs.pos.relative} }
      }

      

      .pr-sale { ${cs.font.line(20)} ${cs.w.fit} ${cs.align.rtop} ${cs.right(-10)} ${cs.top(-2)}
        .ps-label { ${cs.font.sm} ${cs.bg.red} ${cs.font.white} ${cs.border.radius(3)} ${cs.p.h5} ${cs.p.v2} }
        .ps-box { ${cs.w.fit} ${cs.pos.relative} ${cs.p.l10} ${cs.font.gray} ${cs.font.sm} ${cs.font.cancelline} ${cs.p.h10} ${cs.p.v2} }
      }
    }
  }

  &.page-review {
  }

  &.page-body {
    ${cs.h.fit} ${cs.p.v50}
    .frame .ul {
      .li { ${cs.font.center}
        .txt { ${cs.font.lg} ${cs.font.preline} ${cs.m.b30} ${cs.font.center}
          .label { ${cs.font.bold} ${cs.border.bottom} ${cs.border.width(3)} ${cs.font.color(cs.color.orange)}
            ${cs.w.fit} ${cs.m.center(0)} ${cs.m.b30} ${cs.border.red} ${cs.font.t0}
          }
        }
        .cont { ${cs.max.wmd} ${cs.w.full} ${cs.m.center()}
          .image-box { ${cs.m.b20} }
        }
      }
    }
  }

  &.page-comment {
    ${cs.p.a30}
    .title { ${cs.font.t0} }
    .p-label { ${cs.font.sm} ${cs.p.l5} ${cs.font.gray} }
    .frame {
      .g-box { ${cs.bg.frame} ${cs.border.radius(5)} ${cs.font.prewrap} ${cs.font.center} ${cs.p.a20} 
        ${cs.font.darkgray} ${cs.font.line(20)} ${cs.font.sm} ${cs.m.t10}
        .label { ${cs.m.b20} ${cs.font.prewrap} }
      }

      .in-form { ${cs.p.a10} ${cs.m.t10} ${cs.bg.frame} ${cs.pos.relative}
        .foot { ${cs.disp.block} ${cs.font.right}
          .button { ${cs.m.t10} ${cs.align.right} ${cs.pos.relative} }
        }
      }

      .p-ul {}
      .p-li { ${cs.disp.block} ${cs.box.light} ${cs.border.radius(5)} ${cs.p.a20} 
        ${cs.m.v10} ${cs.font.md} ${cs.pos.relative} 
        .p-tit { ${cs.m.b5} ${cs.font.sm} }
        .p-txt { ${cs.font.prewrap} ${cs.p.l5} }
        .p-date { ${cs.m.l5} ${cs.font.gray} ${cs.font.xs} }
        .p-btn { ${cs.align.rbottom} ${cs.right(0)} ${cs.bottom(-2)} .button .btn-label { ${cs.font.gray} } }
      }

      .p-more { ${cs.radius.all(5)} }
    }
  }

  &.page-help {
  }

  &.page-refund {
    ${cs.p.a30}
    .frame {
      .title { ${cs.font.prewrap} ${cs.font.t0} ${cs.m.b30} }
      .label { ${cs.font.prewrap} ${cs.font.bold} ${cs.m.b20} }
      .txt { ${cs.font.prewrap} ${cs.font.md} }
    }
  }
  
  &.page-cart {
    ${cs.align.top} ${cs.left(0)} ${cs.bg.trans} ${cs.z.top} ${cs.size.full} ${cs.anim.showin()} 
    ${cs.over.hidden} ${cs.pos.fixed} ${cs.noselect}
    .bg { ${cs.bg.alphablack} ${cs.align.top} ${cs.left(0)} ${cs.size.full} ${cs.opac.hide} }
    .frame {
      ${cs.w.get(400)} ${cs.align.right} ${cs.bg.frame} ${cs.h.full} ${cs.anim.slidein('200ms', '100%', '0')}
      .head {
        ${cs.h.get(90)} ${cs.m.b10} ${cs.bg.white} ${cs.p.a10} ${cs.font.bold} ${cs.font.t0} ${cs.font.line(70)}
        ${cs.pos.relative}
        .svg-icon { ${cs.right(20)} }
      }
      .body {
        ${cs.h.calc('100% - 240px')} ${cs.scrollbar.t1} ${cs.over.yauto} ${cs.bg.white}
        .ul { ${cs.p.h10} ${cs.h.fit}
          .li {
            ${cs.h.get(90)} ${cs.border.bottom} ${cs.p.v10} ${cs.pos.relative}
            .pl { ${cs.disp.inblock} ${cs.w.get(70)} }
            .pr { ${cs.disp.inblock} ${cs.w.calc('100% - 80px')} ${cs.float.right} ${cs.top(0)} }

            .li-itm { ${cs.m.h10} ${cs.font.line(30)}
              .title { ${cs.font.lg} }
              .price { ${cs.font.t0} ${cs.p.t10} ${cs.p.l5} }
              .count { ${cs.font.lg} ${cs.float.right} ${cs.right(10)} }
              .pu { ${cs.font.gray} ${cs.font.sm} ${cs.m.l3} }
            }

            .pr-noti { ${cs.top(15)} ${cs.w.full} }
          }
        }        
      }
      .total { ${cs.h.get(40)} ${cs.border.top} ${cs.font.bold} ${cs.font.orangehover} ${cs.font.line(40)} ${cs.p.t0}
        .count { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.p.l20} ${cs.font.xl} }
        .price { ${cs.disp.inblock} ${cs.w.calc('100% - 120px')} ${cs.float.right} ${cs.p.r20} ${cs.font.t1} ${cs.font.right} }
      }
      .foot {
        ${cs.h.get(100)} ${cs.m.t1} ${cs.bg.white} ${cs.p.a20}
        .btn-cart { ${cs.border.red} ${cs.font.red} ${cs.font.thickbold} ${cs.h.get(60)} }
      }
    }
  }

  &.pr-noti { ${cs.align.ltop} ${cs.bg.get('#eaeaeaf0')} ${cs.border.shadow()} ${cs.anim.showin()} 
    ${cs.font.sm} ${cs.p.a10} ${cs.font.orange} ${cs.border.radius('5px !important')} ${cs.left(0)} ${cs.top(15)}
    ${cs.z.over} ${cs.font.line(16)} ${cs.font.left} ${cs.max.width('calc(100% - 4px)')} ${cs.w.full} 
    ${cs.mouse.pointer}
    & > span { ${cs.font.xs} ${cs.float.right} ${cs.bottom(0)} ${cs.font.darkgray} ${cs.font.underline} }
  }

  @media screen and (max-width : 1024px) { }
  @media screen and (max-width : 600px) { 
    &.page-head { 
      .pr, .pl { ${cs.disp.block} ${cs.w.full} }
      .pl { .pl-img { ${cs.p.r0} .image-box { ${cs.h.get(320)} } } }
      .pr {}
    }
    &.page-comment, &.page-refund {
      ${cs.p.a0} ${cs.p.v20}
    }
  }
  @media screen and (max-width : 480px) { }
}`;

const STPR = ST.ADMIN.PRODUCT;

const Content = (props) => {
  const { item, list } = props;
  const [cart, setCart] = useState(false);
  const [overid, setOverid] = useState(null);

  const onClickCart = (eid, e, overid = null) => {
    overid && setOverid(overid);
    setCart(true);
  }

  if (!item || !list) return null;

  return <StyledObject className={cx('page-content')}>
    <PageHead item={item} list={list && list.filter(a => a.stype === 'thumb')} onClick={onClickCart} />
    <div className={'p-line'} />
    {item && item.cfgs && <Fragment><PagePrefix item={item} /> <div className={'p-line'} /></Fragment>}
    {/* <PageReview />
    <div className={'p-line'} /> */}
    <PageBody item={item} list={list && list.filter(a => a.stype === 'desc')} />
    <div className={'p-line'} />
    <PageComment prodid={item.rowid} openConfirm={props.openConfirm} />
    {/* <div className={'p-line'} />
    <PageHelp /> */}
    <div className={'p-line'} />
    <PageRefund item={item} />
    {cart && <PageCart onClick={() => setCart(false)} overid={overid} />}
  </StyledObject>
};

export default Content;

export const PageHead = (props) => {
  const [thumb, setThumb] = useState(null);
  const [count, setCount] = useState(1);
  const [noti, setNoti] = useState(null);

  const head = [
    { key: 'url', title: 'url', flex: '4 1 200px', align: 'left' },
    { key: 'title', title: 'title', flex: '3 1 100px', align: 'left' },
  ];

  useEffect(() => {
    if (!props.item || !props.list) return;
    setThumb(props.list ? props.list[0] : null);

    return () => {
    }
  }, [props.item, props.list])

  const Label = ({ className, label, value, children }) => {
    return <div className={cx('pr-d', className)}><span className={'prd-tl'}>{label}</span><span className={'prd-val'}>{children || value}</span></div>
  }

  const onClick = (eid, e) => {
    e.stopPropagation();

    // 남은 재고량
    const { rcount = 0 } = props.item;
    let value = eid === '+' ? count + 1 : count - 1;
    if (value <= 1) value = 1;
    if (value >= 99) value = 99;
    if (value > rcount) {
      value = rcount;
      setNoti(STPR.RCOUNT_NOTI(rcount));
    }

    setCount(value);
  }

  const onClickSave = (eid, e) => {
    e.stopPropagation();
    const guid = Storage.getLocalItem('guid');
    const { rowid, price, cost } = props.item;
    const data = { prodid: rowid, price: Number(price) > 0 ? price : cost, cost: cost, count, guid };
    actions.doInsert(URL.API.CART, data).then((result) => {
      const { isover = false, value = 0 } = result;
      props.onClick && props.onClick('cart', e, isover && value);
    });
  }

  const onSelect = (rowid, e, item) => {
    e.stopPropagation();
    setThumb(item);
  }

  const { item, list } = props;
  if (!item) return null;
  const opts = item.opts ? JSON.parse(item.opts) : null;
  const price = Number(item.price || "0");

  return <StyledObject className={'cwl page-head'}>
    <div className={'pl'}>
      <div className={'pl-img'}>
        {thumb && <Imagebox className={""} size={"full"} src={thumb.url} />}
      </div>
    </div>
    <div className={'pr'}>
      <div className={'pr-d pr-label'}><p>{item.label}</p></div>
      <div className={'pr-d pr-title'}>{item.title}</div>
      <div className={'pr-d pr-cap'}><span className={'tag'}>{item.capacity}</span></div>
      <div className={'p-line'} />
      <Label className={cx('pr-cost', price > 0 && 'mt')} label={STPR.COST} value={1}>
        {price > 0 && <div className={'pr-sale'}>
          <span className={'ps-label'}>{STPR.SALE}</span>
          <span className={'ps-box'}>{`${Util.commas(item.cost)} ${ST.WON}`}</span>
        </div>}
        <span className={'num'}>{Util.commas(price > 0 ? price : item.cost)}</span><span className={'unit'}>{ST.WON}</span>
      </Label>
      <div className={'p-line'} />
      {opts && opts instanceof Array && opts.map((r, i) => {
        return <Label key={i} className={'pr-opt'} label={r.title} value={r.value}></Label>
      })}
      {/* <Label className={'pr-life'} label={STPR.CAPACITY} value={item.lifetime}></Label> */}
      <Label className={'pr-count'} label={STPR.COUNT}>
        {noti && <Notibox item={item} onClose={() => setNoti(null)} />}
        <span className={'icon'} onClick={(e) => onClick("-", e)}>{"-"}</span>
        <span className={'count'}>{count}</span>
        <span className={'icon'} onClick={(e) => onClick("+", e)}>{"+"}</span>
      </Label>
      <Label className={'pr-price'} label={STPR.PAYMENT}>
        <span className={'num'}>{Util.commas((price > 0 ? price : item.cost) * count)}</span><span className={'unit'}>{ST.WON}</span>
      </Label>
      <div className={'pr-d pr-button'}>
        {/* {cartshow && <CartPopup />} */}
        {item.status === 'on' ?
          <Button className={'gd-gray full'} title={STPR.CARTIN} onClick={onClickSave} /> :
          <Button className={'red full'} title={item.status === 'off' ? ST.TAG.OFF : ST.TAG.HOLD} disabled={true} />
        }
      </div>
      <div className={'pr-d pr-rest'}></div>
    </div>
    <div className={'pb'}>
      {list && <Thumblist className={''} itemClassName={""} uuid={'1'} list={list} head={head} rowid={"rowid"} size={'md'}
        onSelect={onSelect} />}
    </div>
  </StyledObject>
}

export const PageCart = (props) => {
  const [list, setList] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const guid = Storage.getLocalItem('guid');
    actions.doList(URL.API.CART, { guid }, true).then(({ result }) => {
      let value = 0;
      const array = result && result.data.map(a => {
        a.total = Number(a.price) * Number(a.count);
        value += a.total;
        a['over'] = props.overid === a.rowid;
        return a;
      });
      setList(array);
      setTotal(value);
    })
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    actions.go(URL.CART);
  }

  const onClose = (item) => {
    const temp = list.find(a => a.rowid === item.rowid);
    temp.over = false;
    setList([...list]);
  }

  // const total = list ? list.reduce((a, b) => (a.total + b.total)) : 0;

  return <StyledObject className={'page-cart'}>
    <div className={'bg'} onClick={(e) => props.onClick && props.onClick(e)} />
    <div className={'frame'}>
      <div className={'head'}>
        <span className="p-title">{STPR.CARTSHOW}</span>
        <Svg className={'xl right middle'} icon={'cancel'} onClick={(e) => props.onClick && props.onClick(e)} />
      </div>
      <div className={'body'}>
        <ul className={'ul'}>
          {list && list.map((item, i) => {
            return <li key={i} className={'li'}>
              <div className={'pl'}>
                <Thumbbox src={item.thumb} width={'70px'} height={'70px'} objectFit={'cover'} />
              </div>
              <div className={'pr'}>
                <div className={'li-itm'}>
                  <span className={'title'}>{item.title}</span>
                </div>
                <div className={'li-itm'}>
                  <span className={'price'}>
                    <span className={'pv'}>{`${Util.commas(item.price)}`}</span>
                    <span className={'pu'}>{ST.WON}</span>
                  </span>
                  <span className={'count'}>
                    <span className={'pv'}>{`${item.count}`}</span>
                    <span className={'pu'}>{ST.COUNT}</span>
                  </span>
                </div>
              </div>
              {item.over && <Notibox item={item} onClose={onClose} />}
            </li>
          })}
        </ul>
      </div>
      <div className={'total'}>
        <span className={'count'}>{`${ST.CART.TOTAL(list ? list.length : 0)}`}</span>
        <span className={'price'}>{`${Util.commas(total)} ${ST.WON}`}</span>
      </div>
      <div className={'foot'}>
        <Button className={'btn-cart white full border lg'} title={STPR.CARTGO} onClick={onClick} />
      </div>
    </div>
  </StyledObject>
}

const Notibox = ({ item, onClose }) => {
  return <StyledObject className={'pr-noti'} onClick={(e) => onClose && onClose(item)}>
    <p>{STPR.RCOUNT_NOTI(item && item.count)}</p><span>{ST.CLOSE}</span>
  </StyledObject>
}

// const CartPopup = (props) => {
//   return <StyledObject className={'cart-popup'}>
//     <div><span className="p-title">{STPR.CARTSHOW}</span></div>
//     <div className="p-line" />
//     <div><Button className={'white full border'} title={STPR.CARTGO}/></div>
//   </StyledObject>
// }

export const PagePrefix = (props) => {
  const StyledObject = styled.div`{
    &.page-prefix {
      ${cs.p.v20} ${cs.m.center(0)} ${cs.max.wlg} ${cs.h.full} ${cs.pos.relative} ${cs.disp.block}
      .frame { 
        ${cs.bg.frame} ${cs.border.radius(5)} ${cs.font.dark} ${cs.p.b20}
        .p-li { ${cs.w.full} ${cs.p.h30} ${cs.disp.inblock}
          .p-tit { ${cs.disp.inblock} ${cs.m.t20} ${cs.p.h10} ${cs.p.v3} ${cs.font.md} 
            ${cs.font.thickbold} ${cs.box.line} ${cs.border.radius(30)} ${cs.bg.gray} ${cs.font.white}
          }
          .p-txt { ${cs.disp.block} ${cs.m.t5} ${cs.p.l20} ${cs.disp.block} ${cs.font.md} ${cs.font.prewrap} }
        }
      }
    }
  }`;

  const [data, setData] = useState(props.item && props.item.cfgs && JSON.parse(props.item.cfgs));

  useEffect(() => {
    if (props.item && props.item.cfgs) {
      setData(JSON.parse(props.item.cfgs));
    };

    return () => { }
  }, [props.item]);

  // const { item } = props;
  if (!data) return null;

  return <StyledObject className={'cwl page-prefix'}>
    <ul className={"frame"}>
      {data && data.map((item, i) => {
        const { title, value } = item;
        return <li className={'p-li'} key={i}>
          <span className={'p-tit'}>{title}</span>
          <p className={'p-txt'}>{value}</p>
        </li>
      })}
    </ul>
  </StyledObject>
}

// const PageReview = (props) => {
//   return <StyledObject className={'cwl page-review'}>

//   </StyledObject>
// }

export const PageBody = (props) => {
  const { list } = props;

  if (!list) return null;
  return <StyledObject className={'cwl page-body'}>
    <div className={'frame'}>
      <ul className={'ul'}>
        {list && list.map(item => {
          return <li key={item.rowid} className={'li'}>
            <div className={'txt'}>
              {item.label && <p className={'label'}>{item.label}</p>}
              {item.stext && <p className={'desc'}>{item.stext}</p>}
            </div>
            <div className={'cont'}>
              {item.url && <Imagebox className={"img"} size={"full"} src={item.url} />}
            </div>
          </li>
        })}
      </ul>
    </div>
  </StyledObject>
}

const PageComment = (props) => {
  var refs = {};
  const [comment, setComment] = useState('');
  const [list, setList] = useState(null);
  const [hist, setHist] = useState(null);
  const [self, setSelf] = useState(null);
  const [ismore, setMore] = useState(false);

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = () => {
    actions.doSelect(URL.API.COMMENT, { prodid: props.prodid }).then((result) => {
      console.dir(result);
      setComment(result && result.comment && result.comment.sval);
      setList(result.value);
      setSelf(result && result.self);
      setHist(result.hist && result.hist.length > 0 && result.hist);
      setMore(result.value && result.value.length >= 5);
    });
  }

  const onSave = () => {
    // if (!value) return refs.comment.showNoti();
    if (!refs.comment.isValidateJson()) return;
    const value = refs.comment.getValue();

    if (value && value.length >= 200) {
      refs.comment.showNoti(STPR.MAX_LENGTH(200));
      return;
    }

    // 최대 4줄
    const lines = value.split('\n');
    if (lines && lines.length > 5) {
      refs.comment.showNoti(STPR.MAX_LINE(5));
      return;
    }

    actions.doInsert(URL.API.COMMENT, { prodid: props.prodid, value }).then((result) => {
      doReload();
    });
  }

  const onMore = () => {
    const start = list ? list.length : 0;
    actions.doSelect(URL.API.COMMENT_MORE, { prodid: props.prodid, start }).then((result) => {
      if (!result || !result.value || result.value.length < 1) {
        setMore(false);
      } else {
        const array = [...list, ...result.value];
        setList(array);
      }
    });
  }

  const onClick = (eid) => {
    if (eid === EID.DELETE) {
      props.openConfirm({
        className: 'white', size: 'xs', onClicked: (isOk) => {
          if (isOk) {
            actions.doDelete(URL.API.COMMENT, { rowid: self.rowid }).then((result) => {
              doReload();
            });
          }
        },
      });
    } else if (eid === EID.EDIT) {
      // actions.doUpdate(URL.API.COMMENT, { prodid: props.prodid, value }).then((result) => {
      //   doReload();
      // });
    }
  }

  const isSignin = Storage.getToken();

  return <StyledObject className={'cwl page-comment'}>
    <p className={'title'}>{STPR.COMMENT}</p>
    {!isSignin && <p className={'p-label'}>{STPR.COMMENT_H}</p>}
    <div className={"frame"}>
      <div className={'g-box'}>
        <p className={'label'}>{comment}</p>
        {!isSignin && <Button className={'dark md'} title={STPR.COMMENT_BTN} border={{ radius: '10px' }} onClick={() => actions.go(URL.SIGNIN)} />}
      </div>

      {/* 로그인하고, 구매이력있고, 후기작성 전이면 후기작성 가능*/}
      {isSignin && hist && !self && <div className={'in-form'}>
        <Editbox ref={(ref) => { refs.comment = ref }} className={"comment white"} value={''}
          name="comment" type="text" label={STPR.COMMENT} validate={true} guide={STPR.COMMENT_G}
          multi={true} height={80} minheigh={80} maxheight={200} maxLength={200} />
        <div className={'foot'}>
          <Button className={'gd-gray'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} />
        </div>
      </div>}

      {/* 로그인하고, 후기작성을 했으면 후기 수정 및 삭제 가능 */}
      {isSignin && self && <div>
        <li className={'p-li'}>
          <div className={'p-tit'}>
            <span>{self.mtitle}</span>
            <span className={'p-date'}>{moment(self.ctime).format("YY-MM-DD HH:mm")}</span>
          </div>
          <div className={'p-txt'}>{self.mtext}</div>
          <span className={'p-btn'}>
            {/* <Button className={'p-edit sm trans'} title={ST.EDIT} onClick={onClick} eid={EID.EDIT} /> */}
            <Button className={'p-del sm trans'} title={ST.DELETE} onClick={onClick} eid={EID.DELETE} />
          </span>
        </li>
      </div>}

      {/* 후기 리스트 */}
      {list && <ul className={'p-ul'}>
        {list.map((item, i) => {
          return <li className={'p-li'} key={i}>
            <div className={'p-tit'}>
              <span>{item.mtitle}</span>
              <span className={'p-date'}>{moment(item.ctime).format("YY-MM-DD HH:mm")}</span>
            </div>
            <div className={'p-txt'}>{item.mtext}</div>
          </li>
        })}
      </ul>}
      {ismore && <Button className={'p-more full lightgray'} title={ST.MORE} onClick={onMore}/>}
    </div>
  </StyledObject>
}

// const PageHelp = (props) => {
//   return <StyledObject className={'cwl page-help'}>

//   </StyledObject>
// }

export const PageRefund = (props) => {
  const [refund, setRefund] = useState('');

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'refund' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      setRefund(json.refund);
    });
    return () => {
    }
  }, []);

  return <StyledObject className={'cwl page-refund'}>
    <div className={'frame'}>
      <p className={'title'}>{STPR.REFUND}</p>
      <p className={'label'}>{STPR.REFUND_LABEL}</p>
      <p className={'txt'}>{refund}</p>
    </div>
  </StyledObject>
}