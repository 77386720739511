import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Formgroup, Widgetbox, Loading, Storage, Button } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';
// import socketio from 'socket.io-client';

const StyledObject = styled.div`{
  &.ad-main { ${cs.bg.trans} ${cs.p.h30} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wxl} ${cs.w.full} ${cs.noselect}

    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} ${cs.m.b20} ${cs.m.t30} }
  }

  &.stat-box {
    ${cs.w.full}
    .stat-body { ${cs.size.full} ${cs.disp.block} ${cs.pos.relative} 
      .shortcat { ${cs.align.rbottom} ${cs.z.over} ${cs.font.sm} ${cs.font.gray} ${cs.font.underline} 
        ${cs.mouse.pointer} ${cs.bg.black} ${cs.p.a3} ${cs.p.h10} ${cs.border.radius(3)}
      }
    }
  }

  @media screen and (max-width : 600px) { 
    .widget-box .t-label { ${cs.min.h(120)} ${cs.h.get(120)} }
    .widget-box.md { ${cs.min.h(120)} ${cs.h.get(120)} }
  }
}`;

const API = URL.API;
const STAM = ST.ADMIN.MAIN;

const Mainpage = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState(null);
  const [orders, setOrders] = useState(null);
  // eslint-disable-next-line no-unused-vars
  // const [notify, setNotify] = useState(null);
  const [ordering, setOrdering] = useState(null);

  useEffect(() => {
    doReload();

    actions.doSelect(URL.API.ADMIN.ADMINS, {}).then(({ result }) => {
      if (result && result.length > 0) {
        const user = Storage.getLocalItem(Storage.key.userinfo);
        const item = result.find(a => a.accountid === user.accountid);
        const ispwd = item && item.reset_pwd === 'N';
        if (!ispwd) {
          props.openConfirm({
            type: 'warn', msg: ST.ADMIN.RESET_PWD, size: 'sm', cancel: false, className: 'black',
            onClicked: (isOk) => (isOk) && (window.location.href = URL.ADMIN.MANAGEMENT)
          });
        }
      }
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'system', skey: 'init' }).then(({ result }) => {
      const onClosePopup = () => {
        actions.doUpdate(URL.API.ADMIN.SETTING, { stag: 'system', skey: 'init', sval: 'popclose' }).then(() => {
          window.location.href = URL.ADMIN.MAIN;
        });
      }

      const StyledComp = styled.div`{
        &.sys-init { } 
          .close-btn { ${cs.align.lbottom} ${cs.bottom(15)} ${cs.left(0)} ${cs.font.gray} } 
        }
      }`;

      const { sval } = result && result.length > 0 && result[0];
      if (sval === 'ready') {
        setTimeout(() => {
          props.openConfirm({
            type: 'info', size: 'md', cancel: false, className: 'black',
            children: () => {
              return <StyledComp className={'sys-init'}>
                <span>{ST.ADMIN.SYSINIT.CONFIRM}</span>
                <Button className={'close-btn'} title={ST.ADMIN.SYSINIT.POP_CLOSE} onClick={onClosePopup} />
              </StyledComp>
            },
            onClicked: (isOk) => {
              if (isOk) {
                // 시스템 초기 가이 화면으로 이동.
                // window.location.href = URL.ADMIN.SYSINIT
                onSystemInit(() => window.location.href = URL.ADMIN.SYSINIT);
              }
            }
          });
        }, 200);
      }
    });

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.noti) return;
    doReload();
    return () => {
    }
  }, [props.noti]);

  const onSystemInit = (callback = null) => {
    actions.doSelect(URL.API.APPINFO, { stag: 'server', skey: 'domain' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      // 도메인 설정 확인후 설정이 안되어있으면 도메인을 설정하자..
      if (!json.domain) {
        const { origin } = window.location;
        actions.doInsert(URL.API.ADMIN.SETTING, { rowid: null, stag: 'server', skey: 'domain', sval: origin }).then(() => {
          actions.doInsert(URL.API.ADMIN.RELOAD, { reboot: true }).then(({ code }) => {
            setTimeout(() => {
              callback ? callback() : window.location.reload();
            }, 1000);
          });
        });
      } else {
        callback && callback();
      }
    });
  }

  // const onSystemInit = (callback = null) => {
  //   actions.doSelect(URL.API.APPINFO, { stag: 'server', skey: 'domain' }).then(({ result }) => {
  //     let json = {};
  //     result.map(a => json[a.skey] = a.sval);
  //     // 도메인 설정 확인후 설정이 안되어있으면 도메인을 설정하자..
  //     if (!json.domain) {
  //       actions.doInsert(URL.API.ADMIN.SETTING, { rowid: null, stag: 'server', skey: 'domain', sval: origin }).then(() => {
  //         actions.doInsert(URL.API.ADMIN.RELOAD, { reboot: true }).then(({ code }) => {
  //           props.doReload && props.doReload(code);
  //           setTimeout(() => {
  //             callback ? callback() : window.location.reload();
  //           }, 1000);
  //         });
  //       });
  //     } else {
  //       callback && callback();
  //     }
  //   });
  // }

  const doReload = () => {
    actions.doSelect(URL.API.APPINFO, { stag: 'trans', skey: 'status' }).then(({ result }) => {
      const { sval } = result && result.length > 0 && result[0];
      const temps = sval && JSON.parse(sval);
      const start = moment().add(-3, 'day').format('YYYYMMDD');
      actions.doSelect(API.ADMIN.MAIN, { start }).then((res) => {
        setLoaded(true);
        setUsers(res.result);
        setOrders(res.order);
        // setNotify(res.notify);

        const list = res.ordering || null;
        const array = list && list.map(a => {
          const t = temps && temps.find(b => String(b.id) === String(a.label));
          a['title'] = t && t.title;
          return a;
        });
        const newitem = { group: 'order', type: 'count', title: ST.ADMIN.MAIN.NOTIFY, count: res.notify ? res.notify.count : 0, icon: 'alarm' };

        setOrdering([newitem, ...array]);
      })
    });
  }

  const onClick = (item, e) => {
    actions.go(URL.ADMIN.ORDER, { rowid: item.label });
  }

  if (loaded) {
    return <StyledObject className="ad-main">
      <p className={'title'}>{STAM.ORDERING}</p>
      {ordering && <StatsComp className={""} list={ordering} icon={'cart'} onClick={onClick} />}

      <p className={'title'}>{STAM.ORDERS}</p>
      {orders && <StatsComp className={""} list={orders} icon={'cart'} />}

      <p className={'title'}>{STAM.USERS}</p>
      {users && <StatsComp className={""} list={users} />}

    </StyledObject >
  } else {
    return <Loading />;
  }
};

export default Mainpage;

const StatsComp = (props) => {
  const { list, onClick = null, icon = null } = props;

  const countList = list.filter(item => item.type === "count");

  // widgetgroup에 삽입하기 위한 widgetlist를 만들자.
  const widgetlist = countList.map((item, index) => {
    let count = item.count;
    if (count > 999) {
      count = (count / 1000).toFixed(1) + "K";
    }
    return { ...item, id: index, name: item.label, value: count || '0', icon: item.icon || icon || 'user', title: item.title || null }
  });

  // 한글로 변경
  widgetlist.map(item => {
    if (item.title) {
      item.name = item.title;
      return null;
    }

    let title = '';
    switch (item.name) {
      case 'prev': title = ST.YESTERDAY; break;
      case 'week': title = `1${ST.WEEK}`; break;
      case 'month': title = `1${ST.MONTH}`; break;
      case 'total': title = ST.MEMBER_TOTAL; break;
      case 'today': title = ST.TODAY; break;
      default: title = item.name; break;
    }
    item.name = title;
    return 0;
  });

  return <StyledObject className={cx("stat-box", props.className)}>
    <Formgroup className={'stat-grp'} inline={true} >
      {widgetlist && widgetlist.map((item, i) => {
        const { name, value } = item;
        return <div className={'stat-body'} key={i}>
          <Widgetbox icon={icon} className={'full'} key={i} name={name} value={value} onClick={onClick ? (n, e) => onClick(item, e) : null} />
          {onClick && <span className={'shortcat'} onClick={(n, e) => onClick(item, e)}>{ST.SHORTCAT}</span>}
        </div>
      })}
    </Formgroup>
  </StyledObject >
}