import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Svg, Editbox, Util } from 'object/src';
import { URL } from 'svc/Enum';
import { default as QnaFrame } from '../view/Qna';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.qna-list { ${cs.bg.trans} ${cs.max.wlg} ${cs.w.full} ${cs.m.t30}
    ${cs.m.left(0)} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(30)}

    .q-box { ${cs.pos.relative} }
    .tool-bar {
      ${cs.pos.absolute} ${cs.right(80)} ${cs.font.right} ${cs.bottom(10)} ${cs.p.a30}
      ${cs.bg.dark} ${cs.w.get(300)}
      .button { ${cs.m.l10} } 
    }
    .q-scroll { ${cs.pos.sticky} ${cs.bottom(40)} ${cs.float.left} ${cs.m.l50} }

    .qna { ${cs.bg.dark} ${cs.font.white} ${cs.m.t30}
      .qna-frame { ${cs.min.h('calc(100% - 200px)')}
        & > .title { ${cs.disp.hide} } 
        .list .lbx-body .lbx-li { ${cs.font.white} 
          .l-req { ${cs.font.orange} } 
          &:hover { ${cs.bg.darkhover} }
        }
        .edit-frame { ${cs.bg.dark}
          &.readonly {
            ${cs.bg.dark} ${cs.textselect}
            .btn-bar {
              .btn-back { ${cs.font.lightprimary} }
              .btn-edit, .btn-delete { ${cs.disp.hide} }
            }
            .cont {
              .r-text { ${cs.bg.darkhover} }
              .r-line { & > span { ${cs.bg.dark} } }
            }
          }
        }
      }
    }

    .req-box { ${cs.max.w(800)} ${cs.m.center()} ${cs.border.top} ${cs.m.t20} ${cs.p.t20} 
      & > .btn-bar { ${cs.m.t10} ${cs.font.right} .button { ${cs.m.l10} } }
    }
  }
  
  @media screen and (max-width : 1024px) { 
    &.qna-list { 
      .tool-bar { ${cs.right(20)} } 
      .q-scroll { ${cs.m.l0} ${cs.left(0)} ${cs.bottom(10)} }
    }    
  }

  @media screen and (max-width : 800px) {
    &.qna-list .tool-bar { ${cs.p.r0} }
  }
}`;

const STQ = ST.QNA;
// const UA = URL.ADMIN;

var frame = null;
const Qna = (props) => {
  const [item, setItem] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [isreq, setReq] = useState(false);  // 답변유무(true,false);
  const [icon, setIcon] = useState('bottom');
  const [select, setSelect] = useState(null);
  const [reitem, setReitem] = useState(null);

  useEffect(() => {
    const node = document.getElementsByClassName('admin-frame');
    if (!node || node.length < 1) return;

    const onScroll = (e) => {
      const i = frame && frame.scrollTop <= 10 ? 'bottom' : 'top';
      setIcon(i)
    }

    node[0].addEventListener("scroll", onScroll);
    frame = node[0];
    return () => {
      node[0].removeEventListener("scroll", onScroll);
      frame = null;
    };
  }, []);

  // const onClick = () => {
  // }

  const onDelete = () => {
    // 답글삭제일경우 답글을 공백으로 상태를 check로 변경
    props.openConfirm({
      msg: STQ.ISDELETE, className: 'gray', size: 'sm',
      onClicked: (isOk) => {
        isOk && actions.doUpdate(URL.API.QNA_REQ, { rowid: item.rowid, rtext: '', status: 'check' }).then(() => {
          setReitem({ ...item, rtext: '', status: 'check' });
          setReq(false);
        });
      },
    });
  }

  const onNew = () => {
    setSelect(item);

    setTimeout(() => {
      frame && frame.scrollTo({ top: frame.scrollHeight, behavior: 'smooth' });
    }, 500);
  }

  const onEdit = () => {
    setSelect(item);

    setTimeout(() => {
      frame && frame.scrollTo({ top: frame.scrollHeight, behavior: 'smooth' });
    }, 500);
  }

  const onClickItem = (v) => {
    setItem(v);

    setLoaded(false);
    if (!v || !v.rowid) return;

    setReq(Boolean(v.status === 'req' || v.rtext));

    setTimeout(() => setLoaded(true), 500);

    if (v.status === 'new') {
      actions.doUpdate(URL.API.QNA_CHECK, { rowid: v.rowid }).then(() => {
        setReitem({ ...v, status: 'check' });
      });
    }
  }

  const onClickScroll = () => {
    if (frame) {
      if (frame.scrollTop <= 10) {
        frame.scrollTo({ top: frame.scrollHeight, behavior: 'smooth' });
      } else {
        frame.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }

  const onClickEditor = (eid, value = {}) => {
    if (eid === 'save') {
      setItem({ ...item, ...value });
      setReitem({ ...item, ...value });
      setReq(true);
    }

    setSelect(null);
  }

  const isedit = item && item.rtext && select;
  return <StyledObject className={cx('qna-list')}>
    <div className={'q-box'}>
      <QnaFrame onClick={onClickItem} reitem={reitem} />
      {item && loaded && <Svg className={'lg white q-scroll'} icon={icon} onClick={onClickScroll} />}
      {item && loaded && <div className={'tool-bar'}>
        {!isreq && <Button className={'sm gd-gray t-new'} title={STQ.REQ_NEW} onClick={() => onNew()} disabled={select} />}
        {isreq && <Button className={'sm gd-gray t-delete'} title={STQ.REQ_DELETE} onClick={() => onDelete()} disabled={isedit} />}
        {isreq && <Button className={'sm gd-gray t-edit'} title={STQ.REQ_EDIT} onClick={() => onEdit()} disabled={isedit} />}
        {/* <Button className={'sm gd-gray btn-back'} title={ST.PREV} onClick={() => onClick('cancel')} /> */}
      </div>}
    </div>
    {select && <EditorBox onClick={onClickEditor} data={select} />}
  </StyledObject>
};

export default Qna;

const MAXTEXT = 3000;
const EditorBox = (props) => {
  const refs = {};
  const [text, setText] = useState((props.data && props.data.rtext) || '');
  const [modified, setModified] = useState(false);

  useEffect(() => {
    if (!props.data) return;

    setText(props.data.rtext);
    return () => { }
  }, [props.data])

  const onChange = () => {
    const value = Util.toText(refs.rtext.getValue());
    refs.rtext.setValue(value);
    setText(value);
    setModified(true);
  }

  const onCancel = () => {
    props.onClick && props.onClick('cancel');
    setModified(false);
  }

  const onSave = () => {
    // props.onClick && props.onClick('save');
    // setModified(false);

    if (props.data.rowid) {
      const status = 'req';
      const rtext = text;
      actions.doUpdate(URL.API.QNA_REQ, { rowid: props.data.rowid, rtext, status }).then(({ code, result }) => {
        props.onClick && props.onClick('save', { rtext, status });
        setModified(false);
      });
    } else {
      props.onClick && props.onClick('cancel');
      setModified(false);
    }
  }

  return <StyledObject className={'req-box'}>
    <Editbox ref={(ref) => { refs.rtext = ref }} className={'cont md sizefix'} value={text} focus={true}
      multi={true} maxheight={300} minheight={300} height={300} onChange={onChange} maxLength={MAXTEXT} />
    <div className={'btn-bar'}>
      <Button className={'sm gd-gray btn-cancel'} title={ST.CANCEL} onClick={onCancel} />
      <Button className={'sm gd-gray btn-save'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
    </div>
  </StyledObject>
}