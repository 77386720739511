import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { URL, EID } from 'svc/Enum';
import { ST } from 'svc/Lang';
import { cs, Button, Util, Uploadbox, Thumblist, Loading, Editbox, Imagebox, Svg, Formgroup, Combobox } from 'object/src';
import cx from 'classnames/bind';
import * as View from '../view/Content';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.product-detail { ${cs.bg.dark}
    ${cs.bg.trans} ${cs.w.full} ${cs.p.a20}
    .preview { ${cs.pos.sticky} ${cs.bottom(20)} }
    .p-line { ${cs.disp.block} ${cs.w.full} ${cs.h.get(1)} ${cs.border.bottom} ${cs.m.v5} }
    .cwl { ${cs.m.center(0)} ${cs.max.wlg} ${cs.h.full} ${cs.pos.relative} ${cs.disp.block} }

    .d-layer { ${cs.pos.relative} }

    .p-noti { ${cs.font.yellow} ${cs.font.sm} ${cs.font.thickbold} ${cs.font.line(20)} ${cs.disp.block} 
      &.sm { ${cs.font.line(10)} }
    }
    .p-guide { ${cs.font.yellow} ${cs.font.md} ${cs.font.thickbold} ${cs.font.line(26)} }

    .btn-bar { ${cs.w.full} .button { ${cs.m.r20} } }
    .loading-box { ${cs.pos.fixed} ${cs.top(0)} ${cs.left(0)} }

    .no-item-box {
      ${cs.align.top} ${cs.left(0)} ${cs.z.front} ${cs.mouse.pointer} ${cs.pos.relative} ${cs.h.full} ${cs.min.h(160)}
      .no-item { ${cs.h.full} ${cs.size.full} ${cs.bg.alphagray} & > p { ${cs.align.center} ${cs.font.center} } }
    }

    .prod-head { ${cs.p.b40} ${cs.h.full} ${cs.min.h(480)}
      .pl { ${cs.disp.inblock} ${cs.pos.relative} ${cs.w.full} ${cs.h.full} ${cs.w.get('70%')} ${cs.p.r30}
        .pl-img { ${cs.min.h(320)} ${cs.p.r30} ${cs.pos.relative}
          .image-box { ${cs.h.get(420)} }
          .img { ${cs.size.full} }
          .uploader { ${cs.h.get(420)} .up-frame .upf-preview .upv-img { ${cs.border.radius(0)} } }
          .delete { ${cs.align.rbottom} }
        }
        .pl-list { ${cs.disp.block} ${cs.w.full} ${cs.h.fit} ${cs.pos.relative} .thumb-box { ${cs.bg.gray} } }
      }

      .no-thumb { ${cs.h.get(400)} ${cs.w.full} ${cs.font.center} ${cs.mouse.pointer} ${cs.pos.relative}
        ${cs.box.line} ${cs.border.radius(5)}
        .no-item { ${cs.align.center} ${cs.font.yellow} ${cs.font.md} ${cs.font.prewrap} ${cs.font.thickbold} 
          ${cs.font.line(26)}
        }
      }

      .pr { ${cs.disp.inblock} ${cs.w.get('30%')} ${cs.pos.relative} ${cs.float.right}
        .in-val { ${cs.w.get(80)} ${cs.disp.inblock} ${cs.font.line(22)} }
        div.guide { ${cs.font.yellow} }

        .pr-d { ${cs.disp.block} ${cs.w.full} ${cs.font.line(44)} ${cs.h.get(44)} 
          .prd-tl { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.font.gray} ${cs.font.sm} }
          .prd-val { ${cs.disp.inblock} ${cs.w.calc('100% - 100px')} ${cs.font.right} ${cs.font.lg} ${cs.font.bold} }
          .num { ${cs.font.t0} }
          .unit { ${cs.p.l5} ${cs.font.sm} ${cs.opac.get(0.7)} }

          &.pr-label { ${cs.font.line(20)} }
          &.pr-title { ${cs.font.t5} ${cs.font.line(20)} }
          &.pr-cap { ${cs.font.line(60)}
            .in-val { ${cs.bg.lightgray} ${cs.font.dark} ${cs.border.radius(3)} } 
            .p-noti { ${cs.m.t20} ${cs.font.line(14)} ${cs.disp.inblock} ${cs.w.calc('100% - 100px')} ${cs.float.right} } 
          }
          &.pr-count { 
            .prd-val { ${cs.group(5)} 
              & > span { ${cs.w.get(30)} ${cs.h.get(30)} ${cs.font.line(30)} ${cs.disp.inblock} ${cs.font.center} ${cs.box.line} 
                &.icon { ${cs.bg.lightgray} ${cs.font.dark} ${cs.font.md} ${cs.noselect} ${cs.mouse.pointer} }
              }
            }
          }
          &.pr-price { ${cs.pos.relative} }
          &.pr-button { ${cs.m.t20} ${cs.pos.relative} }
        }

        .sale-clear, .sale-add { ${cs.align.lbottom} ${cs.left(0)} ${cs.z.front} }

        .pr-opt-itm { ${cs.pos.relative}
          .in-val.prd-val { ${cs.p.left('10px')} }
          .btns { ${cs.align.right} ${cs.right(-40)} ${cs.top(5)} }
        }
      }

      .btn-bar { ${cs.m.t20} ${cs.font.left} ${cs.p.r10} .new { ${cs.m.r30} } .cancel { ${cs.m.r50} } }
    }

    .prod-refund {
      .btn-bar { ${cs.p.l30} }
    }
  }

  &.page-body {
    ${cs.h.fit} ${cs.p.v50} ${cs.h.fit} ${cs.w.full} ${cs.pos.relative} ${cs.min.h(160)} ${cs.pos.relative}
    .b-frame { ${cs.max.wmd} ${cs.w.full} ${cs.pos.relative} ${cs.align.xcenter} ${cs.pos.relative}
      .edit-form { ${cs.w.full} ${cs.bg.alphablack} ${cs.p.a30}
        .ef-label { }
        .ef-text { ${cs.m.t30} }
        .ef-image { ${cs.pos.relative} ${cs.m.t30} ${cs.m.b50} .upload { ${cs.align.lbottom} ${cs.bottom(10)} } }
        .btn-bar { ${cs.font.right} ${cs.m.t20} ${cs.w.full} ${cs.disp.block} .button { ${cs.m.r0} } .delete, .cancel { ${cs.m.r10} } }
      }
      
      .frame .ul {
        .li { ${cs.font.center} ${cs.pos.relative} ${cs.border.bottom} ${cs.p.b40} ${cs.p.h30}
          ${cs.m.t30} ${cs.border.width(3)} ${cs.border.darkgray}
          &:last-child { ${cs.border.none} }
          .txt { ${cs.font.lg} ${cs.font.preline} ${cs.m.b30} ${cs.font.center}
            .label { ${cs.font.bold} ${cs.border.bottom} ${cs.border.width(3)} ${cs.font.color(cs.color.lightorange)}
              ${cs.w.fit} ${cs.m.center(0)} ${cs.m.b30} ${cs.border.orange} ${cs.font.t0}
            }
          }
          .cont { ${cs.max.wmd} ${cs.w.full} ${cs.m.center()}
            .image-box { ${cs.m.b20} }
          }
          .button.edit { ${cs.align.rbottom} ${cs.right(30)} ${cs.bottom(10)} }
          .button.new { ${cs.align.lbottom} ${cs.left(30)} ${cs.bottom(10)} }
        }
      }
    }
    
    // .new { ${cs.align.lbottom} ${cs.bottom(60)} ${cs.left(30)} }
  }

  @media screen and (max-width : 1024px) { }
  @media screen and (max-width : 800px) { 
    &.product-detail { ${cs.p.h10} ${cs.h.calc('100% - 50px')} ${cs.over.hidden} ${cs.over.yauto} ${cs.border.bottom} ${cs.border.alphablack}
      .prod-head { ${cs.p.b0}
        .pr, .pl { ${cs.float.none} ${cs.disp.block} ${cs.w.full} ${cs.p.a0} ${cs.p.b20} ${cs.m.b20} ${cs.border.none} }
        .pl { .pl-img { ${cs.p.r0} .image-box { ${cs.h.get(320)} } } }
        .pr { .pr-d.pr-rest { ${cs.disp.none} } }
        .btn-bar { ${cs.font.right} ${cs.p.r0} .upload { ${cs.m.r0} } .cancel { ${cs.float.left} } }
      }
      .page-comment, .page-refund {
        ${cs.p.a0} ${cs.p.v20}
      }
      .p-line { ${cs.p.t5} ${cs.m.b15} }
      
      .prod-prefix { ${cs.p.t0}
        .i-box { ${cs.m.t50}
          .btn-grp { ${cs.top(-30)} }
          .i-tit { ${cs.w.full}
            & > .edit-box { ${cs.p.t50} ${cs.w.full} }
            .i-history { ${cs.disp.block} ${cs.w.full} ${cs.m.l0} ${cs.left(0)} ${cs.top(10)} }
          }
        }
      }
      .page-body .b-frame .edit-form { ${cs.p.h5} }
      .page-body .b-frame .frame .ul .li { ${cs.p.h0} .button.edit { ${cs.right(0)} } .button.new { ${cs.left(0)} } }

      .prod-refund .btn-bar { ${cs.p.l0} .button { ${cs.w.full} } }
      .preview { ${cs.pos.fixed} ${cs.bottom(10)} }
    }

  }
  @media screen and (max-width : 480px) { }
}`;

const STPR = ST.ADMIN.PRODUCT;
const STCN = ST.ADMIN.CONTENT;

const ProductDetail = (props) => {
  const param = actions.getParam();
  const [item, setItem] = useState(null);
  const [list, setList] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    doReload(param.rowid);

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.rowid]);

  const doReload = (rowid = param.rowid) => {
    setLoaded(false);

    actions.doSelect(URL.API.PRODUCT, { rowid: rowid }, true).then(({ result }) => {
      setItem(result);
    });

    actions.doList(URL.API.CONTENT, { rowid: rowid }, true).then(({ result }) => {
      setList(result ? result.data : null);
      setLoaded(true);
    });
  }

  const onPreview = () => {
    window.open(URL.PRODUCT + `/preview#${item.menuid}&${param.rowid}`);
  }

  if (!list || !item) return null;

  return <StyledObject className={cx('product-detail')}>
    <div className={'p-frame'}>
      <div className={'cwl prod-head'}>
        <PageHead item={item} list={list && list.filter(a => a.stype === 'thumb')} openConfirm={props.openConfirm} doReload={doReload} />
        <HeadItem item={item} doReload={doReload} showAlert={props.showAlert} openConfirm={props.openConfirm} />
      </div>
      {/* <PageHead item={item} list={list && list.filter(a => a.stype === 'thumb')} openConfirm={props.openConfirm} doReload={doReload} /> */}
      <div className={'p-line'} />
      <PagePrefix item={item} doReload={doReload} showAlert={props.showAlert} openConfirm={props.openConfirm} />
      <div className={'p-line'} />
      <PageBody item={item} list={list && list.filter(a => a.stype === 'desc')} openConfirm={props.openConfirm} doReload={doReload} />
      <div className={'p-line'} />
      <PageRefund item={item} />
    </div>
    <Button className={'preview yellow'} title={ST.PREVIEW} onClick={onPreview} />
    {!loaded && <Loading type={'ring'} />}
  </StyledObject>
};

export default ProductDetail;

export const PageHead = (props) => {
  var object = null;
  const [thumb, setThumb] = useState(null);
  const [modified, setModified] = useState(false);
  const [list, setList] = useState(props.list);

  const head = [
    { key: 'url', title: 'url', flex: '4 1 200px', align: 'left' },
    { key: 'title', title: 'title', flex: '3 1 100px', align: 'left' },
  ];

  useEffect(() => {
    if (!props.item || !props.list) return;
    setThumb(props.list ? props.list[0] : null);
    setList(props.list);
    setModified(false);

    return () => {
    }
  }, [props.item, props.list]);

  const onSelect = (rowid, e, item) => {
    e.stopPropagation();
    setThumb(item);
  }

  const onNew = (eid, e) => {
    const arrays = [...list, { url: '', title: '' }];
    setList(arrays);
    setThumb(arrays[arrays.length - 1]);
    setModified(true);
  }

  const onDelete = (e, item) => {
    if (!item.rowid) {
      const arrays = [...list.slice(0, list.length - 1)];
      setList(arrays);
      setThumb(arrays[arrays.length - 1]);
      setModified(false);
    } else {
      props.openConfirm({
        type: 'info', className: 'dark', size: 'sm', onClicked: (isOk) => {
          (isOk) && actions.doDelete(URL.API.ADMIN.CONTENT, { rowid: item.rowid }, null).then(({ code, result }) => {
            props.doReload && props.doReload();
          });
        }
      });
    }
  }

  const onUpload = () => {
    const image = object.getValue(); //base64
    const value = { prodid: props.item.rowid, rowid: thumb ? thumb.rowid : null, stype: 'thumb', title: thumb.title, image: image }
    actions.doInsert(URL.API.ADMIN.UPLOAD_CONTENT, value).then(({ code, result }) => {
      props.doReload && props.doReload();
    });
  }

  const onCancel = () => {
    setThumb(null);
    props.doReload && props.doReload();
  }

  const { item } = props;
  if (!item) return null;
  return <div className={'pl'}>
    {list && list.length > 0 ?
      <React.Fragment>
        <div className={'pl-img'}>
          {thumb && <Uploadbox ref={(ref) => object = ref} className={cx('full')} fit={'contain'} size={'full'} inline={false}
            onChange={() => setModified(true)} value={thumb && thumb.url} title={ST.ADMIN.HOME.TITLE}
            eid={'Uploadbox'} image_check={{ width: 1200, height: 800 }} onDelete={(e) => onDelete(e, thumb)} />}
        </div>
        <p className={'p-noti'}>{STCN.THUMB_GUIDE}</p>
        <div className={'pl-list'}>
          {list && <Thumblist className={''} itemClassName={""} uuid={'1'} list={list} head={head} rowid={"rowid"} size={'md'}
            onSelect={onSelect} />}
        </div>
        <div className={'btn-bar'}>
          <Button className={'gd-gray cancel'} title={ST.CANCEL} eid={'cancel'} onClick={onCancel} disabled={!modified} />
          <Button className={'green new'} title={ST.ADD} eid={EID.NEW} onClick={onNew} disabled={modified} />
          <Button className={'red upload'} title={ST.ADMIN.UPLOAD} eid={'upload'} onClick={onUpload} disabled={!modified} />
        </div>
      </React.Fragment> :
      <div className={'no-thumb'} onClick={onNew}>
        <span className={'no-item'}>{STCN.THUMB_NOITEM}</span>
      </div>}
  </div>
}

const Label = ({ className, label, value, children }) => {
  return <div className={cx('pr-d', className)}>
    <span className={'prd-tl'}>{label}</span>
    <span className={'prd-val'}>{children || value}</span>
  </div>
}

const HeadItem = (props) => {
  var refs = {};
  const { item } = props;
  const [count, setCount] = useState(1);
  const [title, setTitle] = useState(item && item.title);
  const [label, setLabel] = useState(item && item.label);
  const [cost, setCost] = useState(item && item.cost);
  const [price, setPrice] = useState(item && item.price);
  const [capacity, setCapacity] = useState(item && item.capacity);
  const [modified, setModified] = useState(false);
  const [issale, setSale] = useState(item && item.price ? true : false);
  // const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!props.item) return;

    setCount(1);
    setLabel(props.item.label);
    setTitle(props.item.title);
    setCost(props.item.cost);
    setPrice(props.item.price);
    setCapacity(props.item.capacity);
    setSale(props.item.price ? true : false);
    setModified(false);

    return () => {
    }
  }, [props.item]);

  const onClick = (eid, e) => {
    e.stopPropagation();
    let value = eid === '+' ? count + 1 : count - 1;
    if (value <= 1) value = 1;
    if (value >= 99) value = 99;

    setCount(value);
  }

  const onSave = (eid, e) => {
    e.stopPropagation();

    const value = {
      rowid: item.rowid,
      title: refs.title ? refs.title.getValue() : null,
      label: refs.label ? refs.label.getValue() : null,
      cost: refs.cost ? refs.cost.getValue() : 0,
      price: issale ? refs.price ? refs.price.getValue() : 0 : 0,
      capacity: refs.capacity ? refs.capacity.getValue() : null,
    };

    if (issale && Number(value.cost) <= Number(value.price)) {
      props.openConfirm({ type: 'info', size: 'sm', className: 'dark', cancel: false, msg: STPR.SALE_G });
      return;
    }

    actions.doUpdate(URL.API.ADMIN.PRODUCT, value).then(({ code, result }) => {
      props.doReload && props.doReload();
    });
  }

  const onCancel = () => {
    props.doReload && props.doReload();
  }

  return <div className={'pr'}>
    {/* 기본 항목 */}
    <div className={'pr-d pr-label'}>
      <Editbox className={"in-tit label md left"} ref={(ref) => { refs.label = ref }} value={label} name="label"
        onChange={() => setModified(true)} type="text" helper={STPR.LABEL} focus={true} />
    </div>
    <div className={'pr-d pr-title'}>
      <Editbox className={"in-tit title md left"} ref={(ref) => { refs.title = ref }} value={title} name="title"
        onChange={() => setModified(true)} type="text" helper={STPR.NAME} />
    </div>
    <div className={'pr-d pr-cap'}>
      {/* <span className={'tag'}>{STPR.CAPACITY}</span> */}
      <Editbox className={"in-val tag sm center"} ref={(ref) => { refs.capacity = ref }} value={capacity} name="capacity"
        onChange={() => setModified(true)} type="text" />
      <p className={'p-noti sm'}>{STPR.CAPACITY_G}</p>
    </div>
    <div className={'p-line'} />
    <Label className={'pr-cost'} label={STPR.COST} value={1}>
      <Editbox className={"in-val cost sm right"} ref={(ref) => { refs.cost = ref }} value={cost} name="cost"
        onChange={() => setModified(true)} type="number" />
      <span className={'unit'}>{ST.WON}</span>
    </Label>
    <Label className={'pr-price'} label={STPR.PRICE} value={1}>
      {issale > 0 ?
        <React.Fragment>
          <Editbox className={"in-val cost sm right"} ref={(ref) => { refs.price = ref }} value={price} name="price"
            onChange={() => setModified(true)} type="number" />
          <span className={'unit'}>{ST.WON}</span>
          <Button className={'gd-gray sm sale-clear'} title={STPR.PRICE_CLEAR} onClick={() => { setModified(true); setSale(false); }} />
        </React.Fragment> :
        <Button className={'green sm sale-add'} title={STPR.PRICE_ADD} onClick={() => { setModified(true); setSale(true); }} />}
    </Label>
    <p className={'p-noti'}>{STPR.PRICE_G}</p>
    <div className={'p-line'} />
    {/* 옵션 항목 */}
    <OptionItem item={item} showAlert={props.showAlert} onChange={() => setModified(true)} openConfirm={props.openConfirm} />
    {/* 결제 항목 */}
    <Label className={'pr-count'} label={STPR.COUNT}>
      <span className={'icon'} onClick={(e) => onClick("-", e)}>{"-"}</span>
      <span className={'count'}>{count}</span>
      <span className={'icon'} onClick={(e) => onClick("+", e)}>{"+"}</span>
    </Label>
    <Label className={'pr-total'} label={STPR.PAYMENT}>
      <span className={'num'}>{Util.commas((price > 0 ? price : cost) * count)}</span>
      <span className={'unit'}>{ST.WON}</span>
    </Label>
    <Formgroup className={'pr-d pr-button'} inline={true}>
      {/* <Button className={'gd-gray full'} title={STPR.CARTIN} onClick={onClickSave} /> */}
      <Button className={'gd-gray full cancel'} title={ST.CANCEL} onClick={onCancel} disabled={!modified} />
      <Button className={'red full save'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
    </Formgroup>
    <div className={'pr-d pr-rest'}></div>
  </div>
}

const OptionItem = (props) => {
  // const { item } = props;
  const NEWFORM = { key: '', title: '', value: '' };
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    const { opts = null } = props.item;
    if (!opts) return;

    const temps = JSON.parse(opts);
    setList(temps);
    setModified(false);
    return () => { }
  }, [props.item])

  const onNew = () => {
    let array = list ? [...list, NEWFORM] : [NEWFORM];
    setList(array);
    setModified(true);
    props.onChange && props.onChange('new');
  }

  const onSave = () => {
    // const item = list && list[list.length - 1];
    if (!props.item || !props.item.rowid) return null;

    const isempty = list.every(a => !a.title || !a.value);
    if (isempty) return;

    //\!@#$%^&*()~_+{}|[]-=;',./:"<>?
    const data = Util.stringify(list);
    const isval = list.every(a => {
      return (Util.checkJson(a.title) || Util.checkJson(a.value)) ? false : true;
    })

    if (!isval) {
      props.openConfirm({ type: 'info', className: 'dark', size: 'sm', msg: STCN.OPT_ERROR });
      return;
    }

    const value = {
      rowid: props.item.rowid,
      opts: data,
    };

    actions.doUpdate(URL.API.ADMIN.PRODUCT_OPTS, value).then(({ code, result }) => {
      Util.showAlert(props, code);
      setModified(false);
    });
  }

  const onClick = (eid, index) => {
    if (!props.item || !props.item.rowid) return null;

    const isempty = list.every(a => !a.title || !a.value);
    if (isempty) return;

    let temps = '';
    if (eid === EID.DELETE) {
      temps = list && [...list.slice(0, index), ...list.slice(index + 1)];
    } else if (eid === EID.SAVE) {
      temps = [...list];
    } else {
      return;
    }

    const value = {
      rowid: props.item.rowid,
      opts: JSON.stringify(temps),
    };

    actions.doUpdate(URL.API.ADMIN.PRODUCT_OPTS, value).then(({ code, result }) => {
      setList(temps);
      setModified(false);
      Util.showAlert(props, code);
    });
  };

  const onChange = () => {
    props.onChange && props.onChange('change');
    setModified(true);
  }

  return <div className={'pr-opts'}>
    <p className={'p-noti sm'}>{STPR.OPT_GUIDE}</p>
    <ul className={'opts-ul'}>
      {list && list.map((item, i) => {
        return <li className={'opts-li'} key={i}>
          <Editform item={item} index={i} onClick={onClick} onChange={onChange} />
        </li>
      })}
    </ul>
    <Formgroup inline={true}>
      {list && list.length >= 3 ? <p className={'p-noti'}>{STPR.OPT_LIMIT}</p> :
        <Button className={'green full sm'} title={STPR.NEW_OPTS} onClick={onNew} disabled={modified} />
      }
      <Button className={'red full sm'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
    </Formgroup>
  </div>
}

const Editform = (props) => {
  var refs = {};
  const { title, value, rowid } = props.item;
  const [modified, setModified] = useState(false);

  useEffect(() => {
    setModified(false);
    return () => { }
  }, [props.item])

  const onChangeTitle = (v) => {
    props.item.title = v;
    setModified(true);
    props.onChange && props.onChange(v);
  }

  const onChangeValue = (v) => {
    props.item.value = v;
    setModified(true);
    props.onChange && props.onChange(v);
  }

  const onClick = (eid) => {
    props.onClick && props.onClick(eid, props.index);
    setModified(false);
  }

  return <div className={cx('pr-d pr-opt-itm')} rowid={rowid}>
    <Editbox className={"in-val prd-tl sm left"} ref={(ref) => { refs.title = ref }} value={title} name="title"
      onChange={onChangeTitle} type="text" guide={STPR.OPT_LABEL} validate={true} />
    <Editbox className={"in-val prd-val sm right"} ref={(ref) => { refs.value = ref }} value={value} name="value"
      onChange={onChangeValue} type="text" guide={STPR.OPT_VALUE} validate={true} />
    <span className={'btns'}>
      <Svg className={'save sm'} icon={'save'} onClick={() => onClick(EID.SAVE)} disabled={!modified} />
      <Svg className={'delete sm'} icon={'delete'} onClick={() => onClick(EID.DELETE)} />
    </span>
  </div>
}

const PagePrefix = (props) => {
  const StyledObject = styled.div`{
    &.prod-prefix { ${cs.h.fit} ${cs.w.full} ${cs.pos.relative} 
      ${cs.m.center(0)} ${cs.max.wlg} ${cs.h.full} ${cs.pos.relative} ${cs.disp.block}

      .view-form { ${cs.p.b20} ${cs.m.b10} ${cs.min.h(40)}
        .edit-btn { ${cs.align.rbottom} ${cs.bottom(0)} ${cs.right(0)} }
      }

      .edit-form {
        ${cs.min.h(80)} ${cs.p.b50}
        
        .new { ${cs.align.lbottom} ${cs.left(0)} ${cs.z.over} }
        // .save { ${cs.align.rbottom} ${cs.right(0)} ${cs.z.over} }
        .close { ${cs.align.rbottom} ${cs.right(0)} ${cs.z.over} }
      }
    }
  }`;

  const { item } = props;
  const [modified, setModified] = useState(false);
  const [history, setHistory] = useState(null);
  const [config, setConfig] = useState(item && item.cfgs && JSON.parse(item.cfgs));

  useEffect(() => {
    const temp = props.item && props.item.cfgs && JSON.parse(props.item.cfgs);
    setConfig(temp)
    setModified(false);

    actions.doSelect(URL.API.APPINFO, { stag: 'product', skey: 'cfghist' }).then(({ result }) => {
      setHistory(result && result[0]);
    });

    return () => { }
  }, [props.item]);

  const onCancel = () => {
    props.doReload && props.doReload();
  }

  const onNew = () => {
    if (config && config.length >= 5) {
      props.openConfirm({ type: 'info', className: 'dark', size: 'sm', msg: STCN.MAX_CONFIG() });
      return;
    }
    setConfig(config ? [...config, { title: '', value: '' }] : [{ title: '', value: '' }]);
    setModified(true);
  }

  const onClickEditor = (eid, index, json) => {
    if (eid === 'delete') {
      props.openConfirm({
        type: 'info', className: 'dark', size: 'sm', onClicked: (isOk) => {
          const value = [...config.slice(0, index), ...config.slice(index + 1)];
          (isOk) && actions.doUpdate(URL.API.ADMIN.PRODUCT_CFGS, { rowid: item.rowid, config: JSON.stringify(value) }, null).then(({ code, result }) => {
            setConfig(JSON.parse(JSON.stringify(value)));
            // props.doReload && props.doReload();
          });
        }
      });
    } else if (eid === 'save') {
      const value = JSON.parse(JSON.stringify(config));
      value[index] = json;
      actions.doUpdate(URL.API.ADMIN.PRODUCT_CFGS, { rowid: item.rowid, config: JSON.stringify(value) }, null).then(({ code, result }) => {
        setConfig(value);
        Util.showAlert(props, code);
      });

      // 히스토리에 추가하자..
      if (json && json.title) {
        const arrays = history && history.sval && JSON.parse(history.sval);
        const item = arrays ? arrays.find(a => a.title === json.title) : null;
        if (!item) {
          let temps = arrays ? [...arrays, json] : [json];

          // 최대 10개까지만
          if (temps && temps.length > 10) temps = temps.slice(temps.length - 10);

          temps = JSON.stringify(temps);
          const params = { rowid: history ? history.cfgid : null, stag: 'product', skey: 'cfghist', sval: temps };
          actions.doInsert(URL.API.ADMIN.SETTING, params).then(({ code, result }) => {
            setHistory({ ...params, cfgid: result });
          });
        }
      }
    }
  }

  return <StyledObject className={'prod-prefix'}>
    {!modified ?
      (config ? <div className={'view-form'}>
        <View.PagePrefix item={item} />
        <Button className={'red edit-btn'} title={ST.EDIT} eid={EID.EDIT} onClick={() => setModified(true)} />
      </div> :
        <div className={'no-item-box'}>
          <div className={'no-item'} onClick={onNew}>
            <p className={'p-guide'}>{STCN.NO_CONFIG}</p>
          </div>
        </div>) :
      <div className={'edit-form'}>
        <ul>
          {config && config.map((item, i) => {
            return <TitleEditor key={i} history={history} rowid={item.rowid} item={item} index={i} onClick={onClickEditor} />
          })}
        </ul>

        <div>
          <Button className={'green new left'} title={ST.ADD} eid={EID.ADD} onClick={onNew} />
          <Button className={'gd-gray close'} title={ST.CLOSE} eid={EID.CLOSE} onClick={onCancel} />
        </div>
      </div>}
  </StyledObject>
}

const TitleEditor = (props) => {
  var refs = {};
  const { item, index = 0, history = null } = props;
  const { title = null, value = null } = item;

  const StyledObject = styled.div`{
    &.i-box { ${cs.pos.relative} ${cs.m.t20}
      .i-tit { ${cs.disp.block} ${cs.w.get(500)} ${cs.pos.relative}
        & > .edit-box { ${cs.w.get(300)} ${cs.disp.inblock} } 
        .i-history { ${cs.m.l20} ${cs.align.bottom} ${cs.right(0)} ${cs.w.get(180)} }
      }
      .i-val { ${cs.disp.block} ${cs.w.full} ${cs.m.t5} }

      .btn-grp { ${cs.disp.inblock} ${cs.align.rtop} ${cs.top(10)}
        & > span { ${cs.m.l10} }
      }
    }
  }`;

  const onClick = (eid) => {
    if (eid === 'delete') {
      props.onClick && props.onClick(eid, index);
      return;
    }

    if (eid === 'save') {
      if (!refs.title.isValidate()) return;
      if (!refs.value.isValidate()) return;

      const t = refs.title.getValue();
      const v = refs.value.getValue();
      if (t === title && v === value) return;

      if (!refs.title.isValidateJson()) { return; }
      if (!refs.value.isValidateJson()) { return; }

      props.onClick && props.onClick(eid, index, { title: t, value: v });
    } else if (eid === 'cancel') {
      const t = refs.title.getValue();
      const v = refs.value.getValue();
      if (t === title && v === value) return;

      // refs.title.setValue('');
      // refs.value.setValue('');
      // setTimeout(() => {
      //   refs.title.setValue(title);
      //   refs.value.setValue(value);
      // }, 100);
      refs.title.setValue(title);
      refs.value.setValue(value);
    } else {
      // props.onClick && props.onClick(eid);
    }
  };

  const onChange = (eid, e, item, pos) => {
    if (pos <= 0 || !item) return;

    const { name, value } = item;
    refs.title.setValue(name);
    refs.value.setValue(value);
  }

  let list = history && history.sval && JSON.parse(history.sval);
  list = list && [{ id: -1, name: STCN.SELECT }, ...list.map((a, i) => { return { id: i + 1, name: a.title, value: a.value } })];

  return <StyledObject className={'i-box'}>
    <div className={"i-tit"}>
      <Editbox ref={(ref) => { refs.title = ref }} value={title || ''}
        type="text" validate={true} label={STCN.TITLE} maxLength={20} guide={ST.MAXLEN(ST.ADMIN.CONTENT.TITLE, 20)} />
      {list && <Combobox className={'sm dark i-history'} list={list} options={{ text: { align: 'left' } }} onChange={onChange} />}
    </div>
    <Editbox className={"i-val"} ref={(ref) => { refs.value = ref }} value={value || ''}
      multi={true} height={80} minHeight={60} maxHeight={120}
      maxLength={300} guide={ST.MAXLEN(ST.ADMIN.CONTENT.TEXT, 300)}
      type="text" validate={true} label={STCN.TEXT} />

    <div className={'btn-grp'}>
      {/* <Button className={'green i-new sm'} title={ST.NEW} eid={EID.NEW} onClick={() => onClick('new')} /> */}
      <Button className={'black i-delete sm'} title={ST.DELETE} eid={EID.DELETE} onClick={() => onClick('delete')} />
      <Button className={'gd-gray i-cancel sm'} title={ST.CANCEL} eid={EID.CANCEL} onClick={() => onClick('cancel')} />
      <Button className={'red i-save sm'} title={ST.SAVE} eid={EID.SAVE} onClick={() => onClick('save')} />
    </div>
  </StyledObject>
}

const PageBody = (props) => {
  const { item } = props;
  const [modified, setModified] = useState(false);
  const [isnew, setNew] = useState(false);
  const [list, setList] = useState(props.list || null);
  const [select, setSelect] = useState(null);

  useEffect(() => {
    setModified(false);
    setSelect(null);
    setNew(false);
    setList(props.list)
    return () => { }
  }, [props.item, props.list]);

  const onNew = () => {
    setNew(true);
  }

  const onEdit = (item, e) => {
    setSelect(item);
    setModified(true);
    setNew(false);
  }

  const onCancel = () => {
    setModified(false);
    setSelect(null);
    setNew(false);
  }

  const isdata = list && item && list.length > 0;

  return <StyledObject className={'cwl d-layer page-body'}>
    <div className={'b-frame'}>
      {isdata && <div className={'frame'}>
        <ul className={'ul'}>
          {list && list.map((item, i) => {
            // if (select && select.rowid === item.rowid) return null;

            if (select && select.rowid === item.rowid) {
              return <ContentEditor doReload={props.doReload} item={select || { prodid: item.rowid }} onCancel={onCancel} openConfirm={props.openConfirm} />
            }

            return <li key={item.rowid} className={'li'}>
              <div className={'txt'}>
                {item.label && <p className={'label'}>{item.label}</p>}
                {item.stext && <p className={'desc'}>{item.stext}</p>}
              </div>
              <div className={'cont'}>
                {item.url && <Imagebox className={"img"} size={"full"} src={item.url} />}
              </div>
              {!modified && <Button className={'primary edit'} title={ST.EDIT} eid={EID.EDIT} onClick={(e) => onEdit(item, e)} />}
              {!modified && i >= list.length - 1 && <Button className={'green new'} title={ST.ADD} eid={EID.NEW} onClick={() => onNew(i)} />}
            </li>
          })}
        </ul>
      </div>}
      {!isdata && !modified && <div className={'no-item-box'}>
        <div className={'no-item'} onClick={() => onNew(0)}>
          <p className={'p-guide'}>{STCN.NO_CONTENT}</p>
        </div>
      </div>}
      {isnew && <ContentEditor doReload={props.doReload} item={{ prodid: item.rowid }} onCancel={onCancel} openConfirm={props.openConfirm} />}
      {/* {modified && <ContentEditor doReload={props.doReload} item={select || { prodid: item.rowid }}
        onCancel={onCancel} openConfirm={props.openConfirm} />} */}
    </div>
    {/* <Button className={'green new'} title={ST.ADD} eid={EID.NEW} onClick={onNew} /> */}
  </StyledObject>
}

const ContentEditor = (props) => {
  var refs = {};
  const { item } = props;
  const [modified, setModified] = useState(false);

  const onDelete = (eid, e) => {
    props.openConfirm({
      type: 'info', className: 'dark', size: 'sm', onClicked: (isOk) => {
        (isOk) && actions.doDelete(URL.API.ADMIN.CONTENT, { rowid: item.rowid }, null).then(({ code, result }) => {
          props.doReload && props.doReload();
        });
      }
    });
  };

  const onCancel = () => {
    props.onCancel && props.onCancel();
  }

  const onDeleteImage = (eid, e) => {
    props.openConfirm({
      type: 'info', msg: STCN.DELETE_IMAGE, onClicked: (isOk) => {
        (isOk) && actions.doInsert(URL.API.ADMIN.UPLOAD_CONTENT, { rowid: item.rowid, url: "" }, null).then(({ code, result }) => {
          props.doReload && props.doReload();
        });
      }
    });
  }

  const onSave = () => {
    const value = {
      prodid: item.prodid, rowid: item ? item.rowid : null, title: '', stype: 'desc',
      label: refs.label.getValue(), image: refs.url.getValue(), stext: refs.stext.getValue()
    };

    if (value.image && value.image.indexOf('data:image') !== 0) value.image = null;

    actions.doInsert(URL.API.ADMIN.UPLOAD_CONTENT, value).then(({ code, result }) => {
      props.doReload && props.doReload();
    });
  }

  return <div className={'edit-form'}>
    <div className={"ef-label"}>
      <p className={'p-noti'}>{STCN.LABEL_GUIDE}</p>
      <Editbox className={"label sizefix full"} ref={(ref) => { refs.label = ref }} value={item && item.label} name="label"
        onChange={() => setModified(true)} type="text" focus={true} multi={true} height={'100px'} minheight={'100px'} />
    </div>
    <div className={"ef-text"}>
      <p className={'p-noti'}>{STCN.STEXT_GUIDE}</p>
      <Editbox className={"text sizefix full"} ref={(ref) => { refs.stext = ref }} value={item && item.stext} name="stext"
        onChange={() => setModified(true)} type="text" focus={false} multi={true} height={'300px'} />
    </div>
    <div className={"ef-image"}>
      <p className={'p-noti'}>{STCN.IMAGE_GUIDE}</p>
      <Uploadbox ref={(ref) => refs.url = ref} className={cx('full')} fit={'contain'} size={'md'} inline={false} onChange={() => setModified(true)}
        value={item && item.url} title={ST.ADMIN.HOME.TITLE} eid={'Uploadbox'} height={500}
        image_check={{ width: 1600, height: 3000 }} onDelete={onDeleteImage} />
    </div>
    <span className={'btn-bar'}>
      <Button className={'gray cancel'} title={ST.CANCEL} eid={'cancel'} onClick={onCancel} />
      <Button className={'black delete'} title={ST.DELETE} eid={'delete'} onClick={onDelete} />
      <Button className={'red save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
    </span>
  </div>
}

const PageRefund = (props) => {
  const { item } = props;

  const onClick = () => {
    actions.go(URL.ADMIN.SETTING, {});
  }

  return <StyledObject className={'cwl d-layer prod-refund page-refund'}>
    <View.PageRefund item={item} />
    <span className={'btn-bar'}>
      <Button className={'red gd-gray'} title={STCN.REFUND} onClick={onClick} />
    </span>
  </StyledObject>
}