// /*global kakao*/
import React, { PureComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { STAT, ERR, EID, URL, CODE } from 'svc/Enum';
import { ST } from 'svc/Lang';
import cx from 'classnames/bind';
import { AlertActor, ConfirmActor, Editbox, Button, cs, Util, Checkbox, Svg } from 'object/src';
import { Headbar, Tailbar } from './Signin';
import * as actions from 'object/src/actor/Action';
import KaKaoLogin from 'react-kakao-login';

const StyledObject = styled.div`{
  &.signup-frame {
    ${cs.size.full} ${cs.bg.frame} ${cs.h.get('100%')} ${cs.min.h('100vh')} ${cs.noselect}

    .signup-box { 
      ${cs.w.full} ${cs.max.w(500)} ${cs.m.center(20, 10)} ${cs.font.center} ${cs.p.a30} ${cs.p.get('40px 60px')} 
      ${cs.bg.white} ${cs.border.none} ${cs.border.trans}

      .tl-txt { ${cs.font.size(22)} ${cs.font.thickbold} ${cs.font.himelody} ${cs.m.b20} }
      .mem-desc { ${cs.font.sm} ${cs.font.gray} ${cs.font.center} ${cs.m.t20} }

      .line { ${cs.m.v30} ${cs.border.bottom} ${cs.pos.relative} ${cs.font.gray}
        .l-txt { ${cs.bg.white} ${cs.p.h20} ${cs.font.sm} ${cs.align.xcenter} ${cs.top(-5)} } 
        &.member { ${cs.m.v40} }
      }
      
      .edit-box { ${cs.m.t30} 
        .ed-label { ${cs.bg.dark} ${cs.font.white} }
      }

      .kakao-btn { ${cs.pos.relative} }

      .button {
        ${cs.font.thickbold} ${cs.border.radius(5)} ${cs.m.t10} 

        &.kakao {
          ${cs.bg.get("#FFE300")} ${cs.w.get('100% !important')} ${cs.h.get('40px !important')} 
          ${cs.font.line('40px !important')} ${cs.mouse.pointer}
          &.fg { ${cs.align.ltop} ${cs.left(0)} ${cs.top(0)} &:hover { ${cs.opac.visible} } }
        }
        &.naver { ${cs.bg.get("#1ec800")} ${cs.font.white} }
        &.facebook { ${cs.bg.get("#2f52ad")} ${cs.font.white} }
        &.member { ${cs.bg.lightgray} }
        &.regist { ${cs.m.t0} }
      }

      .member-box { ${cs.disp.none} 
        &.show { ${cs.disp.block} }
      }

      .login { ${cs.m.t20} 
        & > span { ${cs.font.sm} ${cs.font.gray} }
        .btn { ${cs.font.orange} ${cs.mouse.pointer} ${cs.m.l10} 
          &:hover { ${cs.font.underline} }
        }
      }
    }

    @media screen and (max-width : 1024px) { }
  
    @media screen and (max-width : 600px) { 
      &.signup-frame { ${cs.bg.white} ${cs.h.get('calc(100vh - 100px)')} ${cs.min.h('calc(100vh - 100px)')}
        .signup-box { ${cs.m.center(50)} ${cs.min.height('calc(100% - 200px)')} ${cs.p.get('10px 20px')}  }
      }
    }
  
    @media screen and (max-width : 480px) { }
  }

  &.policy-box {
    ${cs.font.left} ${cs.m.t40} ${cs.m.b30}
    .pol-itm { ${cs.m.l10} }
    .pol-all { ${cs.m.l0} ${cs.m.b10} ${cs.border.bottom} .chk-txt { ${cs.font.xl} ${cs.font.thickbold} } }
  }

  &.policy-agree {
    ${cs.disp.popup()}

    .bg { }

    .text { ${cs.font.prewrap} }
    .box {
      .head { ${cs.pos.relative}
        .text { ${cs.p.l10} }
        .close { ${cs.align.ycenter} ${cs.right(10)} }
      }

      .body { 
        .text { ${cs.h.full} ${cs.scrollbar.t2} ${cs.over.yauto}  ${cs.p.a10} }
      }
    }
  }
}`;

const { Kakao } = window;

class Signup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userid: '', show: false, isok: false,
      confirm: { show: false },
      alert: { show: false, },
      agree: { show: false, type: 'pri' },
      kakaokey: '',
    };
    this.objs = {};

    this.doReload();
  }

  doReload = () => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'kakaokey_script' }).then(({ result }) => {
      // this.setState({ kakaokey: 'a9b0a9a1bc762acb78b0a121e2f1b892' });
      const kakaokey = result && result[0] && result[0].sval;
      this.setState({ kakaokey: kakaokey });
      Kakao.init(kakaokey);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      this.setState({ company: json, loaded: true });
    });
  }

  registMember = () => {
    var item = { 'state': STAT.I };
    Object.keys(this.objs).map(key => item[key] = this.objs[key].getValue());

    actions.doInsert(URL.SIGNUP, item, null).then(({ code, result }) => {
      if (code === CODE.SUCCESS) {
        this.setState({
          confirm: {
            show: true, msg: ST.SIGNUP.REGIST_MSG, type: 'info', cancel: false,
            onClicked: (isOk) => {
              if (isOk) {
                this.props.history.push(URL.SIGNIN)
              }
            },
          }
        });
      } else if (code === CODE.DUPITEM) {
        this.setState({
          confirm: {
            show: true, msg: ST.SIGNUP.DUPUSERID, type: 'warn', cancel: false,
            onClicked: (isOk) => {
              if (isOk) {
                this.objs.userid.focus()
              }
            },
          }
        });
      } else {
        this.setState({ alert: { show: true, code: code, key: new Date() } });
      }
    });
  }

  onFail = (data) => {
    if (data === ERR.ER_DUP) {
      this.refUserid.showNoti(ERR.STR.ER_DUP_LOGIN_ID);
    }
  }

  isValidate = (object) => {
    const value = object.getValue();
    const name = object.props.name;
    if (name === "userid") {
      if (!Util.isEmail(value)) {
        return object.showNoti(object.props.helper);
      }
    }

    if (name === "password") {
      if (!Util.isPassword(value, 8, 20)) {
        return object.showNoti(object.props.helper);
      }
    }

    if (name === "passcheck") {
      let pwd = this.objs.pwd.getValue();
      if (!Util.isPassword(pwd, 8, 20) || pwd !== value) {
        return object.showNoti(ST.SIGNUP.PASSWORD_NOTI);
      }
    }


    return true;
  };

  onRegist = (eid) => {
    if (!this.state.isok) {
      this.setState({
        confirm: {
          show: true, msg: ST.SIGNUP.POLICY_NOTI, type: 'info', cancel: false, size: 'sm',
          onClicked: (isOk) => {
            this.setState({ isok: false });
          },
        }
      });

      return false;
    }

    if (!this.isValidate(this.objs.userid)) return false;
    if (!this.isValidate(this.objs.pwd)) return false;
    if (!this.isValidate(this.objs.passcheck)) return false;

    this.registMember();
  };

  onClick = (eid) => {
    const elements = document.getElementsByClassName('kakao-org-btn');
    const item = elements && elements.length > 0 && elements[0];
    item.click();
  }

  onClickPolicy = (isok, array) => {
    this.setState({ isok });
  }

  onShowAgree = (eid) => {
    this.setState({ agree: { show: true, type: eid } });
  }

  onSuccess = (res) => {
    if (!res) return;
    const { profile, response } = res;

    const value = { type: 'kakao', id: profile.id, email: profile.kakao_account && profile.kakao_account.email, token: response && response.access_token };
    actions.doInsert(URL.SIGNUP, value, null).then(({ code, result }) => {
      if (code === CODE.SUCCESS) {
        this.setState({
          confirm: {
            show: true, msg: ST.SIGNUP.REGIST_MSG, type: 'info', cancel: false, size: 'sm',
            onClicked: (isOk) => this.props.history.push(URL.SIGNIN)
          }
        });
      } else if (code === CODE.DUPITEM) {
        this.setState({
          confirm: {
            show: true, msg: ST.SIGNUP.DUPMEMBER, type: 'warn', cancel: false, size: 'sm',
            onClicked: (isOk) => this.props.history.push(URL.SIGNIN)
          }
        });
      } else {
        this.setState({ alert: { show: true, code: code, key: new Date() } });
      }
    });

    // profile:
    // connected_at: "2020-11-03T02:36:24Z"
    // id: 1520956741
    // kakao_account: { has_email: true, email_needs_agreement: false, is_email_valid: true, is_email_verified: true, email: "sentron@hanmail.net" }
    // response:
    // access_token: "8ntPySbS61FVutb-UZJB70UCwZR0agW-t7fMtQo9dVsAAAF1i_ZqwA"
    // expires_in: 7199
    // refresh_token: "3zPA3RCT7PjALW5eI8ga-mcaTSVDgXZ80Na0tQo9dVsAAAF1i_Zqvg"
    // refresh_token_expires_in: 5183999
    // scope: "account_email talk_message"
    // token_type: "bearer"

    // fetch(`${API_URL_LOGIN}/user/signin/kakao`, {
    //   //백엔드에서 원하는 형태의 endpoint로 입력해서 fetch한다. 
    //   method: 'GET',
    //   headers: {
    //     Authorization: res.response.access_token,
    //     //받아오는 response객체의 access_token을 통해 유저 정보를 authorize한다. 

    //   },
    // })
    //   .then((res) => res.json())
    //   .then((res) => localStorage.setItem('token', res.token),
    //     //백엔드에서 요구하는 key 값(token)으로 저장해서 localStorage에 저장한다.
    //     //여기서 중요한것은 처음에 console.log(res)해서 들어오는 
    //     //access_token 값을 백엔드에 전달해줘서 백엔드에 저장 해두는 
    //     //절차가 있으므로 까먹지 말 것! 
    //     alert('로그인 성공하였습니다'));
  }


  onFail = (res) => {
  }

  render() {
    const { show, kakaokey, company = null, loaded } = this.state;

    if (!loaded) return null;
    return (
      <StyledObject className={cx('signup-frame')} >
        <Headbar title={company && company.name} />

        <div className="signup-box" onKeyPress={(e) => (e.key === 'Enter' && this.onClick(EID.OK))} >
          <p className={'tl-txt'}>{ST.SIGNUP.WELCOME(company && company.name)}</p>

          {kakaokey ?
            <div className={'kakao-btn'}>
              <KaKaoLogin className={'button kakao kakao-org-btn'} jsKey={kakaokey} buttonText={ST.SIGNUP.KAKAO}
                onSuccess={this.onSuccess} onFailure={this.onFail} getProfile={true} />
              <Button className="full kakao fg lg" title={ST.SIGNUP.KAKAO} eid={'kakao'} onClick={this.onClick} />
            </div> :
            <Button className="full gray lg" title={ST.SIGNUP.KAKAO} onClick={this.onClick} eid={'kakao'} disabled={true} />
          }

          {/* <Button className="full naver lg" title={ST.SIGNUP.NAVER} onClick={this.onClick} eid={'naver'} />
          <Button className="full facebook lg" title={ST.SIGNUP.FACEBOOK} onClick={this.onClick} eid={'facebook'} /> */}
          <div className={cx("line")} ><span className={cx("l-txt")}>{ST.OR}</span></div>
          <Button className="full member lg" title={ST.SIGNUP.MEMBER} onClick={() => this.setState({ show: !this.state.show })} eid={'member'} />

          <div className={cx("member-box", { show })}>
            <div className={cx("line member")} />
            <Editbox className={cx("trans border radius lg")} ref={ref => this.objs['userid'] = ref} name="userid"
              type="text" validate inline label={ST.ID} guide={ST.SIGNUP.ID} helper={ST.SIGNUP.H.ID} />
            <Editbox className={cx("trans border radius lg")} ref={ref => this.objs['pwd'] = ref} name="password"
              type="password" validate inline label={ST.PASSWORD} guide={ST.PASSWORD} helper={ST.SIGNUP.H.PASSWORD} />
            <Editbox className={cx("trans border radius lg")} ref={ref => this.objs['passcheck'] = ref} name="passcheck"
              type="password" validate inline label={ST.SIGNUP.PASSWORD_CHECK} guide={ST.SIGNUP.PASSWORD_CHECK} />
            {/* <Editbox ref={ref => this.objs['name'] = ref} name="username" type="text" validate inline
            label={ST.SIGNUP.NAME} guide={ST.SIGNUP.NAME} /> */}
            {/* <Editbox className={cx("trans border radius lg")} ref={ref => this.objs['phone'] = ref} name="phone"
              type="phone" validate inline label={ST.PHONE} guide={ST.PHONE} helper={ST.SIGNUP.H.PHONE} /> */}

            {/* <div className={cx("line")} /> */}
            <Policy onClick={this.onClickPolicy} onShowAgree={this.onShowAgree} />
            <div className={cx("line")} />

            <Button className="red full regist" onClick={this.onRegist} title={ST.SIGNUP.TITLE} />

            <div className={'login'}>
              <span>{ST.SIGNUP.REGIST}</span>
              <span className={'btn'} onClick={() => window.location.href = URL.SIGNIN}>{ST.SIGNUP.LOGIN}</span>
            </div>
          </div>
        </div>

        <Tailbar title={ST.COPYRIGHT(company.name_eng || company.name, company.year)} />

        <PolicyAgree {...this.state.agree} onClick={() => this.setState({ agree: { show: false } })} />
        <AlertActor {...this.state.alert} />
        <ConfirmActor {...this.state.confirm} />
      </StyledObject >
    );
  };
};

export default Signup;

const Policy = (props) => {

  const [check, setCheck] = useState(false);
  const [list, setList] = useState([
    { id: 'pri', name: ST.SIGNUP.POLICY_PRI, check: false },
    { id: 'pub', name: ST.SIGNUP.POLICY_PUB, check: false },
    { id: 'opt', name: ST.SIGNUP.POLICY_OPT, check: false },
  ]);

  const onClick = (eid, e, array) => {
    const value = array.every(a => a.check);
    setCheck(value);

    const checked = array.find(a => a.id === eid && a.check);

    if (eid === 'pri' && checked) {
      props.onShowAgree && props.onShowAgree(eid);
    }

    if (eid === 'pub' && checked) {
      props.onShowAgree && props.onShowAgree(eid);
    }

    onSelection();
  }

  const onChange = ({ check }) => {
    setList(list.map(a => {
      a.check = check;
      return a;
    }));
    setCheck(check);

    onSelection();
  }

  const onSelection = () => {
    const isok = (list[0].check && list[1].check);
    props.onClick && props.onClick(isok, list);
  }

  return <StyledObject className={"policy-box"}>
    <Checkbox className={'pol-all block md'} list={[{ id: 'all', name: ST.SIGNUP.POLICY_ALL, check: check }]} onChange={onChange} />
    <Checkbox className={'pol-itm block md'} list={list} onClick={onClick} />
  </StyledObject>
}

export const PolicyAgree = (props) => {
  const { type = 'pri', show = false } = props;
  const [pri, setPri] = useState(null);
  const [pub, setPub] = useState(null);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'privacy' }).then(({ result }) => {
      setPri(result ? result[0] : null);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'public' }).then(({ result }) => {
      setPub(result ? result[0] : null);
    });
    return () => { }
  }, []);

  const onClose = () => {
    props.onClick && props.onClick('close', type);
  }

  if (!show) return null;

  return <StyledObject className={'policy-agree'}>
    <div className={'pop-bg'} onClick={onClose} />
    <div className={'pop-box'}>
      <div className={'head'}>
        <p className={'text'}>{type === 'pri' ? ST.COMPANY.PRIVATE : ST.COMPANY.PUBLIC}</p>
        <Svg className={'close lg dark right top'} icon={EID.CANCEL} onClick={onClose} />
      </div>
      <div className={'body'}>
        <p className={'text'}>{type === 'pri' ? (pri && pri.sval) : (pub && pub.sval)}</p>
      </div>
    </div>
  </StyledObject>
}