import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { EID, URL, CODE, SIGN_T } from 'svc/Enum';
import { ST } from 'svc/Lang';
import cx from 'classnames/bind';
import { Editbox, Button, cs, Util, Formgroup } from 'object/src';
import { Headbar, Tailbar } from './Signin';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.signto-frame {
    ${cs.size.full} ${cs.bg.frame} ${cs.h.get('100%')} ${cs.min.h('100vh')} ${cs.noselect}

    .signto-box { 
      ${cs.w.full} ${cs.max.w(500)} ${cs.m.center(20, 10)} ${cs.font.center} ${cs.p.a30} ${cs.p.get('40px 60px')} 
      ${cs.bg.white} ${cs.border.none} ${cs.border.trans}

      .tl-txt { ${cs.font.size(22)} ${cs.font.thickbold} ${cs.font.himelody} ${cs.m.b20} }

      .line { ${cs.m.v5} ${cs.border.bottom} ${cs.pos.relative} ${cs.font.gray} }
      
      .btn-grp { 
        .button { ${cs.border.lightwhite} 
          .btn-label { ${cs.font.gray} &:hover { ${cs.font.underline} } }
          &.active { ${cs.border.dark} 
            .btn-label { ${cs.font.dark} &:hover { ${cs.font.noneline} } }
          } 
        }
      }

      .signin { .btn-label { ${cs.font.primaryhover} } ${cs.m.t20} ${cs.font.lg} 
        &:hover { ${cs.font.primaryhover} }
      }

      .p-frame { ${cs.font.sm} ${cs.font.left} ${cs.p.t50} ${cs.font.dark}
        & > p { ${cs.font.prewrap} }
        .find { ${cs.m.t30} }
        .p-tit { ${cs.font.bold} ${cs.font.center} ${cs.p.b5} ${cs.font.md} }
        .p-txt { ${cs.font.dark} ${cs.m.t10} ${cs.font.keepall} }
        .p-noti { ${cs.font.red} ${cs.p.v10} }
        .p-res { ${cs.m.v10} ${cs.m.t20} ${cs.font.line(24)} ${cs.font.md} ${cs.font.center} ${cs.font.thickbold} 
          ${cs.anim.showin('500ms', 0.2, 1)} ${cs.border.top} ${cs.border.width(10)} ${cs.font.prewrap}
          .p-tit { ${cs.border.bottom} ${cs.p.b5} ${cs.p.t10} ${cs.font.lg} }
          .p-li { ${cs.font.sm} ${cs.border.bottom} ${cs.mouse.pointer}
            ${cs.font.line(20)} ${cs.p.v5}
            .p-bold { ${cs.font.bold} ${cs.font.orange} }
            &:hover { ${cs.bg.lightwhite} }
          }
        }

        .edit-box { ${cs.m.t20} 
          .ed-label { ${cs.bg.dark} ${cs.font.white} }
        }
      }
    }

    @media screen and (max-width : 1024px) { }
  
    @media screen and (max-width : 600px) { 
      &.signto-frame { ${cs.bg.white} ${cs.h.get('calc(100vh - 100px)')} ${cs.min.h('calc(100vh - 100px)')}
        .signto-box { ${cs.m.center(0)} ${cs.min.height('calc(100% - 200px)')} ${cs.p.get('10px 20px')} }
      }
    }
  
    @media screen and (max-width : 480px) { }
  }
}`;

const Signto = (props) => {
  const [company, setCompany] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tab, setTab] = useState('id');

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      setCompany(json);
      setLoaded(true);
    });

    return () => { }
  }, [])

  const onClick = () => {
  }

  if (!loaded) return null;
  return (
    <StyledObject className={cx('signto-frame')} >
      <Headbar title={company && company.name} />

      <div className="signto-box" onKeyPress={(e) => (e.key === 'Enter' && onClick(EID.OK))} >
        <p className={'tl-txt'}>{ST.SIGNTO.TITLE}</p>
        <Formgroup className={'btn-grp'} inline={true}>
          <Button className={cx("white full f-id border", tab === 'id' && 'active')} onClick={() => setTab('id')} title={ST.SIGNTO.FINDID} />
          <Button className={cx("white full f-pwd border", tab === 'pwd' && 'active')} onClick={() => setTab('pwd')} title={ST.SIGNTO.FINDPWD} />
        </Formgroup>

        <div className={cx("line")} />

        {tab === 'id' && <Findid phone={company.phone} />}
        {tab === 'pwd' && <Findpwd />}

        <Button className={'trans md signin'} title={ST.SIGNTO.GOSIGNIN} onClick={() => window.location.href = URL.SIGNIN} />
      </div>


      <Tailbar title={ST.COPYRIGHT(company.name_eng || company.name, company.year)} />

      {/* <ConfirmActor {...confirm} /> */}
    </StyledObject >
  );
};

const Findid = ({ phone }) => {
  const [value, setValue] = useState('');
  const [enable, setEnable] = useState(false);
  const [noti, showNoti] = useState(false);
  const [result, setResult] = useState(null);

  const onClick = () => {
    if (result) {
      setResult(null);
      setValue('')
      showNoti(false);
      setEnable(false);
      return;
    }

    const isPhone = (asValue) => {
      const regexp = /^[0-9]*$/;
      return regexp.test(asValue); // 형식에 맞는 경우 true 리턴
    };

    if (!isPhone(value)) {
      return showNoti(true);
    }

    actions.doSelect(URL.API.SIGNTO, { stag: 'id', value: value }).then(({ code, result }) => {
      if (code === CODE.SUCCESS && result) {
        setResult(result.userid);
      } else {
        setResult(ST.SIGNTO.ID_FAIL(Util.toStringPhone(value)));
      }
    });
  }

  const onChange = (v) => {
    showNoti(false);
    setValue(v);
    setEnable(v && v.length >= 10);
  }

  return <div className={'p-frame pid'}>
    <p className={'p-tit'}>{ST.SIGNTO.ID_TIT}</p>
    <div className={cx("line")} />
    <p className={'p-txt'}>{ST.SIGNTO.ID_TXT(phone)}</p>
    {result
      ? <p className={'p-res'}>{result}</p>
      : <Editbox className={cx("trans border radius lg left")} onChange={onChange} type="phone" validate inline
        guide={ST.SIGNTO.ID_GUIDE} focus={true} max={20} value={value} />
    }

    {noti && <p className={'p-noti'}>{ST.SIGNTO.ID_NOTI}</p>}

    <Button className="red full find" onClick={onClick} title={ST.SIGNTO.FINDID} disable={!enable} />
  </div>
}

const Findpwd = () => {
  const [value, setValue] = useState('');
  const [enable, setEnable] = useState(false);
  const [noti, showNoti] = useState(null);
  const [data, setData] = useState(null);

  const onClick = () => {
    if (data) {
      setData(null);
      setValue('')
      showNoti(null);
      setEnable(false);
      return;
    }

    actions.doSelect(URL.API.SIGNTO, { stag: 'pwd', value: value }).then(({ code, result }) => {
      if (code === CODE.SUCCESS && result && result.length > 0) {
        setData(result);
      } else {
        setData(ST.SIGNTO.PWD_FAIL(value));
      }
      setEnable(false);
    });
  }

  const onSendmail = (item) => {
    showNoti(ST.SIGNTO.SENDEMAIL);
    setEnable(true);
  }

  const onChange = (v) => {
    setValue(v);
    setEnable(Util.isEmail(v));
  }

  return <div className={'p-frame pwd'}>
    <p className={'p-tit'}>{ST.SIGNTO.PWD_TIT}</p>
    <div className={cx("line")} />
    <p className={'p-txt'}>{ST.SIGNTO.PWD_TXT}</p>

    {data
      ? <PassList value={data} onClick={onSendmail} />
      : <Editbox className={cx("trans border radius left lg")} onChange={onChange} type="email" validate inline
        guide={ST.SIGNTO.PWD_GUIDE} focus={true} value={value} />
    }

    {noti && <p className={'p-noti'}>{noti}</p>}
    <Button className="red full find" onClick={onClick} title={ST.SIGNTO.FINDPWD} disable={!enable} />
  </div>
}

const PassList = (props) => {
  const { value = null } = props;

  const onClick = (item) => {
    const { facc = 'Y' } = item;
    const data = {
      userid: item.userid, email: facc === 'Y' ? item.userid : item.email,
      subject: ST.SIGNTO.EMAIL_SUBJECT, title: ST.SIGNTO.EMAIL_TITLE
    };

    actions.doInsert(URL.API.SIGNTOPASS, data).then(({ code, result }) => {
      props.onClick && props.onClick(item);
    });
  };

  if (!value) return null;

  if (value instanceof Array) {
    return <ul className={'p-res'}>
      {value.map((item, i) => {
        const { userid, facc = 'Y', email = '' } = item;
        const type = userid.substr(0, 2);

        if (!email) {
          return <li key={i} className={'p-li'} onClick={() => onClick(item)}>
            {facc === 'N' && <span className={'p-bold'}>{`[${SIGN_T[type]}] ${userid}\n${ST.SIGNTO.PWD_EMAIL_REG}`}</span>}
          </li>
        }

        return <li key={i} className={'p-li'} onClick={() => onClick(item)}>
          {facc === 'Y' && <span className={'p-bold'}>{`${userid}`}</span>}
          {facc === 'N' && <span className={'p-bold'}>{`[${SIGN_T[type]}] ${userid}\n(email:${email})`}</span>}
        </li>

        // // 이메일 형식의 계정으로 검색된 경우
        // if (facc === 'Y') {
        //   return <li key={i} className={'p-li'} onClick={() => onClick(item)}>
        //     <div>
        //       <span className={'p-bold'}>{userid}</span>
        //     </div>
        //   </li>
        // } 
        // // 그외의 경우(kakao/naver 등);
        // else {
        //   const type = userid.substr(0, 2);
        //   return <li key={i} className={'p-li'} onClick={() => window.location.href = URL.SIGNIN}>
        //     <div>
        //       <span className={'p-bold'}>{`${SIGN_T[type]} `}</span>
        //       <span>{ST.SIGNTO.PWD_REST_F(userid)}</span>
        //     </div>
        //     <div>
        //       <span className={'p-bold'}>{`${SIGN_T[type]} `}</span>
        //       <span>{ST.SIGNTO.PWD_REST_S}</span>
        //     </div>
        //   </li>
        // }
      })}
    </ul>
  } else {
    return <p className={'p-res'}>{value}</p>
  }
}


export default Signto;