/* eslint-disable no-unused-vars */
/* eslint-disable react/no-direct-mutation-state */
import React, { useEffect, useState } from 'react';
import { URL } from 'svc/Enum';
import { ST } from 'svc/Lang';
import { Util, Tablebox, Loading, Cardlist, Editbox, TabButton, cs } from 'object/src';
import * as actions from 'object/src/actor/Action';
import styled from 'styled-components';

const StyledObject = styled.div`{
  &.ad-member { ${cs.pos.relative} ${cs.bg.dark} ${cs.p.a10} ${cs.max.w(1600)}
    
    .s-head { ${cs.m.t20} ${cs.pos.relative}
      .btn-mode { ${cs.align.xcenter} ${cs.pos.relative} .button { ${cs.min.w(120)} } }
    }

    .s-body { ${cs.p.h20} ${cs.m.t20} }

    .s-foot { ${cs.m.t0} ${cs.font.right} ${cs.p.r20}
      .btn-new { }
      .btn-save { }
    }    

    @media screen and (max-width : 1024px) {
      .card-list .tline .trow {
        ${cs.w.calc('50% - 10px')}
      }
    }

    @media screen and (max-width : 600px) {
      .card-list .tline .trow {
        ${cs.w.full} ${cs.m.b30}
      }
    }
  }
  
  &.member-modal {
    .inbox { ${cs.m.t10} }
  }
}`;

const API = URL.API.ADMIN;

const Member = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState({ key: '', value: '' });
  const [order, setOrder] = useState('ctime desc');
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(0);
  const [running, setRunning] = useState(false);
  const [viewmode, setViewmode] = useState({ type: 'card', title: ST.CARDVIEW });

  useEffect(() => {
    doReload(1, search, order, limit, () => setLoaded(true));
    return () => {
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const doReload = (p = page, s = search, o = order, l = limit, callback = null) => {
    setRunning(true);

    let value = {};
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`;

      if (s.key === 'ctime' || s.key === 'utime') {
        value['where'] = `DATE_FORMAT(${s.key}, '%Y%m%d') like "%${Util.replaceAll(s.value, '.', '')}%"`;
      }
    }

    value['order'] = o;
    value['page'] = p;
    value['limit'] = l;

    actions.doList(API.MEMBER, value, true).then(({ result }) => {
      if (!result.page) return setRunning(false);

      setTotal(result.page.total)
      setPage(result.page.current);
      setMax(result.page.max);
      setSearch(s);
      setList(result.list);
      setRunning(false);

      callback && callback();
    })
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value });
  }

  const onClickPage = (p) => {
    doReload(p);
  }

  const onChangeView = (eid, item) => {
    setViewmode({ type: item.id, title: item.title });
  }

  const getItem = (id) => list.find(item => Number(item.userid) === Number(id));

  const onSelect = (id) => {
    let data = getItem(id);
    props.openModal({
      ok: false, cancel: ST.OK, title: ST.ADMIN.MEMBER.TITLE, className: 'dark',
      size: 'sm', children: Modal, data: data, onOk: (v) => { },
    });
  }

  if (loaded) {
    const head = [
      { key: 'no', title: ST.NUM, flex: '1 1 60px' },
      { key: 'userid', title: ST.ADMIN.MEMBER.USERID, flex: '3 1 80px', mobile: 'hide' },
      { key: 'accountid', title: ST.ADMIN.MEMBER.ACCOUNTID, flex: '5 1 120px' },
      // { key: 'auth', title: ST.ADMIN.MEMBER.AUTH, flex: '3 1 80px', mobile: 'hide' },
      { key: 'email', title: ST.ADMIN.MEMBER.EMAIL, flex: '5 1 120px', mobile: 'hide' },
      { key: 'name', title: ST.ADMIN.MEMBER.NAME, flex: '3 1 100px', mobile: 'hide' },
      { key: 'phone', title: ST.ADMIN.MEMBER.PHONE, flex: '4 1 100px', mobile: 'hide', type: 'phone' },
      { key: 'ctime', title: ST.ADMIN.MEMBER.SDATE, flex: '4 1 80px', type: 'datetime', format: 'YYYY.MM.DD' }
    ];

    const modes = [
      { id: 'card', title: ST.CARDVIEW, icon: 'list' },
      { id: 'table', title: ST.TABLEVIEW, icon: 'thead' },
    ]

    const searchs = [
      { name: ST.ADMIN.MEMBER.USERID, id: 'userid', check: true },
      { name: ST.ADMIN.MEMBER.ACCOUNTID, id: 'accountid', check: false },
      { name: ST.ADMIN.MEMBER.EMAIL, id: 'email', check: false },
      { name: ST.ADMIN.MEMBER.NAME, id: 'name', check: false },
      { name: ST.ADMIN.MEMBER.PHONE, id: 'phone', check: false },
      { name: ST.ADMIN.MEMBER.SDATE, id: 'ctime', check: false },
    ];

    return <StyledObject className="ad-member">
      <div className={'s-head'}>
        <TabButton className={"btn-mode"} onClick={onChangeView} list={modes} select={viewmode.type} />
      </div>

      <div className={'s-body'}>
        {viewmode.type === "table" && <Tablebox className={'dark'} head={head} list={list} searchkey={searchs[0].id}
          naviClass={'white'} onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
          pos={page} max={max} total={total} searchs={searchs} rowid="userid" />}
        {viewmode.type === "card" && <Cardlist className={'dark shadow'} head={head} list={list} searchkey={searchs[0].id}
          naviClass={'white'} onSelect={onSelect} onClickSearch={onClickSearch} onClickPage={onClickPage}
          pos={page} max={max} total={total} searchs={searchs} rowid="userid"
          width={280} height={20} />}
      </div>
      {running && <Loading type='ring' />}
    </StyledObject>
  } else {
    return <Loading />;
  }
};

export default Member;


/*******************************************************************
 Popup
*******************************************************************/
const Modal = (props) => {
  const { data } = props;

  return (
    <StyledObject className={'member-modal'}>
      <Editbox name="userid" className="right dark" type="text"
        value={data.userid} label={ST.ADMIN.MEMBER.USERID} readonly={true} />
      <Editbox name="accountid" className="inbox right dark" type="text"
        value={data.accountid} label={ST.ADMIN.MEMBER.ACCOUNTID} readonly={true} />
      <Editbox name="name" className="inbox right dark" type="text"
        value={data.name} label={ST.ADMIN.MEMBER.NAME} readonly={true} />
      <Editbox name="phone" className="inbox right dark" type="text"
        value={Util.toStringPhone(data.phone)} label={ST.ADMIN.MEMBER.PHONE} readonly={true} />
      <Editbox className="inbox right dark" value={Util.toStringSymbol(data.ctime)}
        name="ctime" type="text" label={ST.ADMIN.MEMBER.SDATE} readonly={true} />
    </StyledObject>
  );
}