/*global kakao*/
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Editbox, Button, Util, Formgroup, Uploadbox, Postcode, Kakaomap, Error } from 'object/src';
import { EID } from 'object/src/Config';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import { creatKakaomapScript } from 'object/src/Kakaomap';

const StyledObject = styled.div`{
  .p-line { ${cs.w.full} ${cs.border.top} ${cs.border.white} ${cs.h.get(1)} ${cs.max.wmd} ${cs.m.v30} }

  &.ad-about { ${cs.bg.trans}
    .hp-frame { ${cs.p.a20} ${cs.pos.relative} }
  }

  &.sect {
    ${cs.m.v20} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wlg} ${cs.w.full}
    .edit-box { ${cs.m.t10} }
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .box { ${cs.p.a10} ${cs.h.fit} 
      .pl { ${cs.w.get(140)} ${cs.pos.relative} ${cs.disp.inblock} ${cs.float.left} ${cs.m.top(12)}
        .guide { ${cs.font.gray} ${cs.font.sm} ${cs.p.r10} }
      }
      .pr { ${cs.w.full} ${cs.p.left(140)} ${cs.m.t5} }
    }

    .button.save { ${cs.right(10)} }
    .button.upload { ${cs.m.t10} ${cs.w.get(120)} }
    .preview { ${cs.top(0)} ${cs.right(10)} }
    .t-noti { ${cs.font.sm} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} ${cs.font.prewrap} }

    .uploader { ${cs.size.get(120)} ${cs.m.t5} .upf-preview { ${cs.border.trans} } .upv-img { ${cs.bg.color("#20202050")} } }
  }

  &.set-bg {
    .img-frame { ${cs.font.center} 
      .bg-img { ${cs.w.get(640)} ${cs.h.get(360)} }
    }
  }

  &.set-info {
    .top { ${cs.pos.relative} ${cs.font.center} ${cs.m.b20}
      .uploader { ${cs.disp.block} ${cs.align.xcenter} ${cs.pos.relative} .upv-img { ${cs.bg.color("#20202050")} } }
    }
  }

  &.set-slogan, &.set-store {
    .pl { 
      .uploader { .upv-img { ${cs.bg.color("#20202050")} } .cont-frame { ${cs.border.radius(100)} } }
    }
    .pr > p.noti { ${cs.font.sm} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} &:hover { ${cs.font.underline} ${cs.mouse.pointer} } }
  }

  &.set-store {
    .store-box { ${cs.border.top} ${cs.m.t30} ${cs.p.t0} ${cs.pos.relative} ${cs.p.b40}
      .delete { ${cs.align.rbottom} ${cs.right(60)} ${cs.bottom(0)} }
      .save { ${cs.right(0)} ${cs.bottom(0)} }
    }
    .new { ${cs.right(10)} ${cs.top(10)} ${cs.w.get(70)} }
    .preview { ${cs.top(10)} ${cs.right(100)} }

    .map-box { ${cs.h.get(400)} }
    .add-box { ${cs.pos.relative}
      .box .input.readonly { ${cs.bg.lightgray} ${cs.mouse.pointer} } 
      .post-box { ${cs.border.shadow()} ${cs.pos.absolute} ${cs.z.popup} ${cs.top(30)} ${cs.left(10)} ${cs.max.h(400)} }
    }
  }

  @media screen and (max-width : 1024px) {
  }
  
  @media screen and (max-width : 800px) {
    &.ad-about .hp-frame { ${cs.p.h10} }
    &.set-bg .img-frame .bg-img { ${cs.w.full} }
    .sect { ${cs.m.b50} .title { ${cs.font.lg} ${cs.font.bold} ${cs.p.t10} } }
    .sect > .box {
      .pl, .pr { ${cs.float.none} ${cs.disp.block} ${cs.w.full} ${cs.font.center} ${cs.p.a0} }
    }
    &.set-store { .preview, .new { ${cs.top(0)} } }
  }
}`;

const STA = ST.ADMIN.ABOUT;

const runUpload = (thumb, props, list, skey, callback = null) => {
  const image = thumb.getValue(); //base64

  const item = list && list.find(a => a.skey === skey);
  const value = { rowid: item ? item.cfgid : null, stag: 'about', skey: skey, image: image, filename: skey }

  actions.doInsert(URL.API.ADMIN.UPLOAD_APP, value).then(({ code, result }) => {
    callback ? callback(code) : props.doReload && props.doReload(code);
  });
}

const runSave = (refs, list, props, stag = 'about') => {
  if (!refs) return;

  const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
  if (!isvalidate) return;

  const array = Object.keys(refs).map(key => {
    const item = list && list.find(a => a.skey === key);
    return { rowid: item ? item.cfgid : null, stag: stag, skey: key, sval: refs[key].getValue() }
  });

  actions.doInsert(URL.API.ADMIN.SETTING, array).then(({ code, result }) => {
    props.doReload && props.doReload(code);
  });

  return;
}

const About = (props) => {
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [refresh, setRefresh] = useState(new Date());

  const doReload = (code = null) => {
    actions.doSelect(URL.API.APPINFO, { stag: 'about' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      setData(json);
      setList(result && result.length > 0 ? result : null);
      setRefresh(new Date());
    });

    code !== null && Util.showAlert(props, code);
  }

  useEffect(doReload, []);

  const onPreview = () => {
    window.open(URL.ABOUT);
  }

  const issys = Util.isSystemAdmin();
  if (!issys) return <Error className={'t3'} title={ST.LOGINSYSADMIN} />

  if (!data) return null;
  return <StyledObject className={cx('ad-about')}>
    <div className={'hp-frame'}>
      <SetBackimage data={data} list={list} doReload={(code) => doReload(code)} refresh={refresh} onPreview={onPreview} />
      <SetCompany data={data} list={list} doReload={(code) => doReload(code)} refresh={refresh} onPreview={onPreview} />
      <SetSlogan data={data} list={list} doReload={(code) => doReload(code)} refresh={refresh} onPreview={onPreview} />
      <SetStore data={data} list={list} doReload={(code) => doReload(code)} refresh={refresh} onPreview={onPreview} />
    </div>
  </StyledObject>
};

export default About;


const SetBackimage = (props) => {
  var bgimg = null;
  const { data, list } = props;
  const [modified, setModified] = useState(false);

  useEffect(() => {
    setModified(false);
  }, [props.refresh]);

  const onUpload = () => {
    runUpload(bgimg, props, list, 'bg_img');
  }

  const onChange = () => {
    setModified(true);
  }

  if (!data) return null;
  return <StyledObject className={cx('set-bg sect')}>
    <p className={'title'}>{STA.INFO}</p>
    <div className={'box'}>
      <div className={'img-frame'}>
        <p className={'guide'}>{STA.BACK_IMG}</p>
        <Uploadbox ref={(ref) => bgimg = ref} className={cx('bg-img')} fit={'cover'} size={'lg'} inline={false} onChange={onChange}
          value={data.bg_img} title={STA.ICON_IMG} eid={'Uploadbox'} image_check={{ width: 2000, height: 1100 }} />
        <p className={'t-noti'}>{`* ${STA.BACK_IMG_NOTI}`}</p>
      </div>
    </div>
    <Button className={'gd-gray bottom left preview'} title={ST.PREVIEW} onClick={props.onPreview} />
    <Button className={'red bottom right upload'} title={ST.ADMIN.UPLOAD} eid={'upload'} onClick={onUpload} disabled={!modified} />
    {/* <Button className={'red bottom right save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} /> */}
  </StyledObject>
}

const SetCompany = (props) => {
  var refs = {};
  var thumb = null;
  const { data, list } = props;
  const [modified, setModified] = useState(false);
  const [imagechange, setImagechange] = useState(false);

  useEffect(() => {
    setImagechange(false);
    setModified(false);
  }, [props.refresh]);

  const onSave = (eid, e) => {
    runSave(refs, list, props, 'about');
  }

  const onUpload = () => {
    runUpload(thumb, props, list, 'icon_img');
  }

  const onChangeImage = (result, target, item, type) => {
    setImagechange(true);
  }

  if (!data) return null;
  return <StyledObject className={cx('set-info sect')}>
    <p className={'title'}>{STA.INFO}</p>
    <div className={'box'}>
      <div className={'top'}>
        <p className={'guide'}>{STA.BOSS_IMG}</p>
        <Uploadbox ref={(ref) => thumb = ref} className={cx('')} fit={'cover'} size={'full'} inline={false} onChange={onChangeImage}
          value={data.icon_img} title={STA.ICON_IMG} eid={'Uploadbox'} image_check={{ width: 256, height: 256 }} />
        <p className={'t-noti'}>{`${STA.IMG_SIZE}`}</p>
        <Button className={'green upload'} title={ST.ADMIN.UPLOAD} eid={'upload'} onClick={onUpload} disabled={!imagechange} />
      </div>

      <Editbox ref={(ref) => { refs.label = ref }} className={"label"} value={data.label} onChange={() => setModified(true)}
        name="label" type="text" label={STA.LABEL} validate={true} multi={true} maxheight={120} minheight={80} />
      <Editbox ref={(ref) => { refs.company = ref }} className={"company"} value={data.company}
        name="company" type="text" label={STA.COMPANY} validate={true} onChange={() => setModified(true)} />
    </div>
    <Button className={'gd-gray bottom left preview'} title={ST.PREVIEW} onClick={props.onPreview} />
    <Button className={'red bottom right save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetSlogan = (props) => {
  var refs = {};
  var thumb = null;
  const { data, list } = props;
  const [modified, setModified] = useState(false);
  const [imagechange, setImagechange] = useState(false);

  useEffect(() => {
    setImagechange(false);
    setModified(false);
  }, [props.refresh]);

  const onSave = (eid, e) => {
    runSave(refs, list, props, 'about');
  }

  const onUpload = () => {
    runUpload(thumb, props, list, 'boss_img');
  }

  const onChangeImage = (result, target, item, type) => {
    setImagechange(true);
  }

  if (!data) return null;

  return <StyledObject className={cx('set-slogan sect')}>
    <p className={'title'}>{STA.SLOGAN}</p>
    <div className={'box'}>
      <div className={'pl'}>
        <p className={'guide'}>{STA.BOSS_IMG}</p>
        <Uploadbox ref={(ref) => thumb = ref} className={cx('')} fit={'cover'} size={'full'} inline={false} onChange={onChangeImage}
          value={data.boss_img} title={STA.BOSS_IMG} eid={'Uploadbox'} image_check={{ width: 256, height: 256 }} />
        <p className={'t-noti'}>{`${STA.IMG_SIZE}`}</p>
        <Button className={'green upload'} title={ST.ADMIN.UPLOAD} eid={'upload'} onClick={onUpload} disabled={!imagechange} />
      </div>
      <div className={'pr'}>
        <Editbox ref={(ref) => { refs.summary = ref }} className={"summary"} value={data.summary} onChange={() => setModified(true)}
          name="summary" type="text" label={STA.SUMMARY} validate={true} multi={true} maxheight={600} minheight={300} />
      </div>
    </div>
    <Button className={'gd-gray bottom left preview'} title={ST.PREVIEW} onClick={props.onPreview} />
    <Button className={'red bottom right save'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetStore = (props) => {
  var thumb = null;
  var store = Object;

  const { data, list } = props;
  const [imagechange, setImagechange] = useState(false);
  const [company, setCompany] = useState(null);
  const [stores, setStores] = useState(null);
  const [kakaokey, setKakaokey] = useState('');

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setCompany(json);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'store' }).then(({ result }) => {
      setStores([...result]);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'kakaokey' }).then(({ result }) => {
      setKakaokey(result && result[0] && result[0].sval);
    });

    setImagechange(false);
    return () => { }
  }, [props.refresh]);

  const doReload = (code) => {
    actions.doSelect(URL.API.APPINFO, { stag: 'store' }).then(({ result }) => {
      setStores([...result]);
      Util.showAlert(props, code);
    });
  }

  const onClick = (eid, e) => {
    if (eid === EID.NEW) {
      const array = [...stores, {}];
      setStores(array);
    }
  }

  const onUpload = () => {
    runUpload(thumb, props, list, 'store_img');
  }

  const onChangeImage = (result, target, item, type) => {
    setImagechange(true);
  }

  if (!data || !company) return null;

  return <StyledObject className={cx('set-store sect')}>
    <p className={'title'}>{STA.STORE}</p>
    <div className={'box'}>
      <div className={'pl'}>
        <p className={'guide'}>{STA.STORE_IMG}</p>
        <Uploadbox ref={(ref) => thumb = ref} className={cx('')} fit={'cover'} size={'full'} inline={false} onChange={onChangeImage}
          value={data.store_img} title={STA.STORE_IMG} eid={'Uploadbox'} image_check={{ width: 256, height: 256 }} />
          <p className={'t-noti'}>{`${STA.IMG_SIZE}`}</p>
        <Button className={'green upload'} title={ST.ADMIN.UPLOAD} eid={'upload'} onClick={onUpload} disabled={!imagechange} />
      </div>
      <div className={'pr'}>
        <Editbox value={company.phone} type="text" label={ST.ABOUT.PHONE} readonly={true} />
        <Editbox value={company.addr} type="text" label={ST.ABOUT.ADDR} readonly={true} />
        <p className={'noti'} onClick={() => actions.go(URL.ADMIN.SETTING)}>{`* ${STA.STORE_NOTI}`}</p>
        <Button className={'gd-gray bottom left preview sm'} title={ST.PREVIEW} onClick={props.onPreview} />
        <Button className={'green bottom new sm'} title={ST.ADD} eid={EID.NEW} onClick={onClick} />
        {stores && <div>
          {stores.map((item, i) => {
            return <StoreBox key={i} refs={store} index={i + 1} {...item} islast={i === stores.length - 1}
              doReload={doReload} kakaokey={kakaokey} />
          })}
        </div>}
      </div>
    </div>
  </StyledObject>
}

const StoreBox = (props) => {
  var refs = {};
  const { index = 0, skey = 0, stag = 'store', cfgid = null, sval = null, kakaokey = '' } = props;
  let { title = '', phone = '', address = '', open = '', pos = [0, 0] } = sval ? JSON.parse(sval) : {};
  const rowid = cfgid;
  const [modified, setModified] = useState(false);
  const [location, setLocation] = useState(pos);
  const [post, setPost] = useState(false);
  const [addr, setAddr] = useState(address);
  const [ctitle, setCtitle] = useState(title);
  const [cphone, setCphone] = useState(phone);
  const [copen, setCopen] = useState(open);

  useEffect(() => {
    setModified(false);
  }, [kakaokey, props.refresh]);

  const onDelete = (eid, e) => {
    global.openConfirm({
      type: 'info', onClicked: (isOk) => {
        (isOk) && actions.doDelete(URL.API.ADMIN.SETTING, { rowid: rowid }, null).then(({ code, result }) => {
          props.doReload && props.doReload(code);
        });
      }
    });
  }

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    if (!isvalidate) return;

    if (refs.address && !refs.address.getValue()) {
      global.openConfirm({
        type: 'info', cancel: false, msg: ST.REQUMENT_ERROR(STA.ADDRESS), size: 'sm'
      });
      return;
    }

    let temp = { pos: location };
    Object.keys(refs).map(key => {
      temp[key] = refs[key].getValue();
      return null;
    });

    const value = { rowid: rowid || null, stag, skey: skey || index, sval: JSON.stringify(temp) };
    actions.doInsert(URL.API.ADMIN.SETTING, value).then(({ code, result }) => {
      props.doReload && props.doReload(code);
      // setModified(!(code === CODE.SUCCESS));
    });
  }

  const onClickAddress = (eid, e) => {
    setPost(!post);
  }

  const onComplete = (addrs, item) => {
    creatKakaomapScript(kakaokey, () => {
      var geocoder = new kakao.maps.services.Geocoder();

      // 주소로 좌표를 검색합니다
      geocoder.addressSearch(addrs, (result, status) => {
        // 정상적으로 검색이 완료됐으면 
        if (status === kakao.maps.services.Status.OK) {
          setLocation([result[0].y, result[0].x]);
        }
      });
    })

    setTimeout(() => {
      setPost(false);
      setAddr(addrs);
      setModified(true);
    }, 200);
  }

  return <div className={'store-box'}>
    <Formgroup inline={true}>
      <Editbox ref={ref => refs.title = ref} value={ctitle} onChange={(v) => { setModified(true); setCtitle(v); }} label={STA.STORE_TITLE} validate={true} />
      <Editbox ref={ref => refs.phone = ref} value={cphone} onChange={(v) => { setModified(true); setCphone(v); }} label={STA.PHONE} validate={true} />
    </Formgroup>
    <Editbox ref={ref => refs.open = ref} value={copen} onChange={(v) => { setModified(true); setCopen(v); }} label={STA.OPEN} validate={true} />
    <div className={'add-box'} onClick={onClickAddress}>
      <Editbox ref={ref => refs.address = ref} value={addr} label={STA.ADDRESS} validate={true} readonly={true} />
      {post && <Postcode className={'post-box'} onComplete={onComplete} onCancel={() => setPost(false)} />}
    </div>
    <Button className={'black bottom delete sm'} title={ST.DELETE} eid={EID.DELETE} onClick={onDelete} />
    <Button className={'red bottom save sm'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />

    {<Kakaomap className={'map-box'} position={location} kakaokey={kakaokey} />}
  </div>
}