import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Util, Thumbbox, Carousel, Storage } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import Menubar from './Menubar';
import { PopBoard } from '../admin/Board';

const StyledObject = styled.div`{
  &.homepage { ${cs.bg.white}
    .hp-frame { }
    .menu-bar .mb-ul .mb-li { ${cs.m.h20} }
  }
}`;

// const API = URL.API.PAGE;
const Homepage = (props) => {
  const [menus, setMenus] = useState(null);
  const [pos, setPos] = useState(0);

  useEffect(() => {
    actions.doList(URL.API.MENU).then((result) => {
      const { value } = result;
      value.map(a => {
        a['component'] = (a.ishome === "Y") ? Mainpage : Viewpage;
        return null;
      });

      const param = actions.getParam();
      const index = param && param.rowid ? value.findIndex(a => String(a.rowid) === String(param.rowid)) : 0;
      setPos(index < 0 || index >= value.length ? 0 : index);
      setMenus(value);
    });

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickMenu = (eid, e, item) => {
    (item) && actions.go(URL.HOME, { rowid: item.rowid });
  }

  if (!menus || menus.length < 1) return null; //<Nodata title={ST.NO_SERVICE} />;
  const Component = menus[pos].component || Mainpage;
  return <StyledObject className={cx('homepage')}>
    <Menubar list={menus} pos={pos} onClick={onClickMenu} />
    <div className={'hp-frame'}>
      <Component data={menus[pos]} menus={menus} onLoaded={props.onLoaded} />
    </div>
  </StyledObject>
};

export default Homepage;


const StyledPage = styled.div`{
  ${cs.noliststyle} ${cs.noselect}
  
  .phead { 
    ${cs.h.fit} ${cs.min.h(100)} ${cs.font.center} ${cs.p.t40} ${cs.p.b20}
    .label { ${cs.font.md} ${cs.font.darkgray} } 
    .title { ${cs.font.t1} ${cs.m.t10} ${cs.font.orangehover} } 
  }

  .pline { ${cs.disp.block} ${cs.w.full} ${cs.h.get(1)} ${cs.border.bottom} ${cs.m.v5} }

  .pbody { ${cs.h.fit} ${cs.min.h(600)} ${cs.max.w(1200)} ${cs.m.center(20)}
    ${cs.disp.autoflex}
    .ul .li { ${cs.pos.relative}
      &.li { ${cs.mouse.pointer}}
      
      ${cs.w.get(240)} ${cs.disp.inblock} ${cs.p.h10}
      .thumb { ${cs.pos.relative} ${cs.size.get(220)}
        .label {
          ${cs.align.bottom} ${cs.left(0)} ${cs.bottom(0)} ${cs.w.full} ${cs.p.a5} ${cs.font.ellipsis}
          ${cs.bg.get('#d0d0d090')} ${cs.z.front} ${cs.font.sm} ${cs.font.orangehover} ${cs.font.line(14)}
        }
      }

      .foot { ${cs.disp.block} ${cs.w.full} ${cs.h.get(140)} ${cs.over.hidden} 
        .title { ${cs.font.bold} ${cs.font.md} ${cs.font.line(20)} ${cs.m.t10} }
        .sale { ${cs.w.full} ${cs.font.right} ${cs.font.gray} ${cs.font.cancelline} ${cs.font.sm} ${cs.disp.block} }
        .bottom { ${cs.font.right} ${cs.font.line(20)} ${cs.m.t5}
          .price { ${cs.font.xl} ${cs.font.bold} ${cs.font.orangehover} }
          .capacity { ${cs.font.sm} ${cs.font.darkgray} }
        }
      }

      .h-tags { ${cs.w.full} ${cs.align.rtop} ${cs.left(5)} ${cs.top(5)}
        .tag { ${cs.font.sm} ${cs.bg.get('rgba(255, 0, 0, 0.8)')} ${cs.font.white} ${cs.border.radius(3)} ${cs.p.h5} ${cs.p.v5} }
        .sale { }
      }
      .h-status { ${cs.size.full} ${cs.align.top} ${cs.left(0)} ${cs.bg.alphablack} 
        .stat { ${cs.align.center} ${cs.font.color('rgba(250, 0, 0, 0.9)')} ${cs.font.t0} ${cs.font.center} 
          ${cs.p.a5} ${cs.w.full} ${cs.bg.color('#dededed0')} 
        }
      }
    }
  }

  &.p-main {
    .pm-head { 
      .carousel-box { ${cs.max.h(480)} }
    }
  }

  &.p-view {
  }

  @media screen and (max-width : 1280px) { 
    .pbody { ${cs.max.w(960)} }
  }

  @media screen and (max-width : 1024px) { 
    .pbody { ${cs.max.w(720)} }
  }

  @media screen and (max-width : 740px) { 
    .pbody { ${cs.max.w(480)} }
  }
  
  @media screen and (max-width : 600px) { 
    .pbody {
      // .ul { ${cs.w.full}
      //   .li {
      //     ${cs.w.full} ${cs.p.h0}
      //     .thumb { ${cs.m.center()} ${cs.size.get(300)} .thumb-box { ${cs.size.full} } }
      //     .foot { ${cs.p.h20} }
      //   }
      // }
      // .ul { ${cs.w.full}
      //   .li {
      //     ${cs.w.full} ${cs.p.h20}
      //     .thumb { ${cs.m.center()} ${cs.size.get('100%')} .thumb-box { ${cs.size.full} } }
      //     .foot { ${cs.p.h20} }
      //   }
      // }
      .ul {
        .li {
          ${cs.w.r50} ${cs.p.h10}
          .thumb { ${cs.m.center()} ${cs.size.get('100%')} .thumb-box { ${cs.size.full} } }
          .foot { ${cs.p.h20} }
        }
      }
    }
  }
}`;

const Mainpage = (props) => {
  const { menus } = props;
  // const { rowid } = props.data;
  const [list, setList] = useState(null);
  const [slides, setSlides] = useState(null);
  const [show, setShow] = useState(false);
  // const [pop, setPop] = useState(null);

  useEffect(() => {
    if (!props.data || !props.data.rowid) return null;

    // actions.doSelect(URL.API.APPINFO, { stag: 'boardpop', skey: 'show' }).then(({ result }) => {
    //   const data = result ? result[0] : null;
    //   setShow(data && data.sval === 'enable');
    // });

    actions.doList(URL.API.PRODUCT, { menuid: null }, true).then(({ result }) => {
      const sa = result.data.filter(a => {
        if (a.menuid === props.data.rowid) {
          a.label = a.title;
          a.title = '';
          a.url = a.thumb;
          a.rowid = a.menuid;
          return true;
        }

        return false;
      });
      const sb = result.data.filter(a => a.menuid !== props.data.rowid);
      setSlides(sa);
      setList(sb);

      actions.doSelect(URL.API.APPINFO, { stag: 'boardpop', skey: 'show' }).then(({ result }) => {
        const data = result ? result[0] : null;
        setShow(data && data.sval === 'enable');
      });

      setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
    })

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  const onSelect = (e, item, index) => {
    if (!item.opts) return;

    const temps = item.opts && JSON.parse(item.opts);
    if (temps.url === '/menu') {
      actions.go("/", { rowid: temps.rowid })  // homepage menus
    } else {
      actions.go(temps.url, { rowid: temps.rowid })  // product item
    }
  }

  const onClickPop = (eid, data) => {
    setShow(false);

    // 해당 팝업은 더이상 보이지 않는다.
    if (eid === 'exit') {
      const { uuid } = data;
      const puid = Storage.getLocalItem('board-pop-uuid');
      if (!puid && uuid !== puid) {
        Storage.setLocalItem('board-pop-uuid', uuid);
      }
    }
  }

  return <StyledPage className={cx('page p-main')}>
    <div className={'pm-head'}>
      {slides && <Carousel className={"full"} size={"fwide"} list={slides} anim={true} time={'5s'} onSelect={onSelect} />}
    </div>
    <div className={'pm-body'}>
      {menus && list && menus.map((item, i) => {
        const array = list.filter(a => a.menuid === item.rowid);
        if (item.ishome === 'Y') return null;
        return <Fragment key={i} >
          {i > 0 && <div className={'pline'} />}
          <Listview key={i} {...item} list={array} />
        </Fragment>
      })}
    </div>
    {show && <PopBoard onClick={onClickPop} />}
  </StyledPage>
}


const Viewpage = (props) => {
  const [list, setList] = useState(null);
  const { title, label, rowid } = props.data;

  useEffect(() => {
    if (!props.data || !props.data.rowid) return null;

    actions.doList(URL.API.PRODUCT, { menuid: props.data.rowid }, true).then(({ result }) => {
      setList(result.data);
      props.onLoaded && props.onLoaded(true);
      // setTimeout(() => props.onLoaded && props.onLoaded(true), 100);
    })

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  return <StyledPage className={cx('page p-view')}>
    <Listview menuid={rowid} title={title} label={label} list={list} />
  </StyledPage>
}

const Listview = (props) => {
  const { menuid, title, label, list } = props;

  const onClick = (item, e) => {
    e.stopPropagation();
    actions.go(URL.PRODUCT, { rowid: item.rowid, menuid: menuid });
  }

  return <div>
    <div className={'phead'}>
      <p className={'label'}>{label}</p>
      <p className={'title'}>{title}</p>
    </div>
    <div className={'pbody'}>
      <ul className={'ul'}>
        {list && list.map((item, i) => {
          const { status, price = 0, thumb, label, title, cost, capacity } = item;
          const issale = price > 0;

          if (status === 'off') return null;
          return <li key={i} className={'li'} onClick={(e) => onClick(item, e)}>
            <div className={'thumb'}>
              <Thumbbox src={thumb} width={'220px'} height={'220px'} objectFit={'contain'} anim={{ type: 'showin', time: "0.5s" }} />
              {label && <p className={'label'}>{label}</p>}
              <div className={'h-tags'}>
                {issale && <span className={'tag sale'}>{ST.TAG.SALE}</span>}
              </div>
              {status !== 'on' && <div className={'h-status'}>
                <span className={cx('stat', status)}>{ST.TAG[String(status).toUpperCase()]}</span>
                {/* {status === 'hold' && <span className={'stat hold'}>{ST.TAG.HOLD}</span>} */}
              </div>}
            </div>
            <div className={'foot'}>
              <p className={'title'}>{title}</p>
              {issale && <span className={'sale'}>{`${Util.commas(cost)} ${ST.WON}`}</span>}
              <div className={'bottom'}>
                <span className={'capacity'}>{`${capacity} / `}</span>
                <span className={'price'}>{`${Util.commas(issale ? price : cost)} ${ST.WON}`}</span>
              </div>
            </div>
          </li>
        })}
      </ul>
    </div>
  </div>
}