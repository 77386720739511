import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Loading, Button, Util, Tablebox, Editbox, Combobox } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import moment from 'moment';

const StyledObject = styled.div`{
  &.ad-order-batch { ${cs.bg.trans} ${cs.p.a30} ${cs.max.width(1400)}
    .s-head { ${cs.pos.relative}
      .title { ${cs.w.fit} ${cs.disp.inblock} ${cs.m.r20} ${cs.pos.relative}
        .label { ${cs.disp.inblock} ${cs.font.lg} ${cs.font.thickbold} }
        .date { ${cs.m.l20} ${cs.disp.inblock} ${cs.font.gray} ${cs.mouse.pointer}
          ${cs.box.line} ${cs.border.radius(5)} ${cs.over.hidden} ${cs.m.bottom(-7)}
          .d-li { ${cs.disp.inblock} ${cs.w.fit} ${cs.p.h10} ${cs.border.right} 
            ${cs.p.v3}
            &:last-child { ${cs.border.none} }
            &:hover { ${cs.bg.black} }
          } 
          .active { ${cs.font.yellow} ${cs.underline} ${cs.bg.gray} }
        }
      }
      .type { ${cs.align.rbottom} ${cs.bottom(10)} }

      ${cs.border.bottom} ${cs.border.width(3)} ${cs.p.b10} ${cs.border.darkgray}
    }

    .s-body { ${cs.m.t20}
      .button.rowid { ${cs.font.white} ${cs.font.md} .btn-label { ${cs.font.underline} } 
        &:hover { ${cs.font.yellow} }
      }

      .t-btn { ${cs.m.t20} ${cs.pos.relative}
        .tl { ${cs.disp.inblock} ${cs.z.over}
          .title { ${cs.disp.inblock} ${cs.m.r5} ${cs.float.left} ${cs.font.line(30)} } 
          .strans { ${cs.float.none} ${cs.w.get(160)} }
          .status { ${cs.float.none} ${cs.m.l10} ${cs.w.get(160)} }
        }
        .tr { ${cs.align.rbottom} ${cs.bottom(0)} }
      }

      .table-box {
        ${cs.m.t10} ${cs.z.front}
        .link { ${cs.font.underline} ${cs.mouse.pointer} &:hover { ${cs.font.yellow} } }
        .tb-row .tb-col { ${cs.h.get('28px !important')} ${cs.font.line('28px !important')} 
          // .status { ${cs.pos.relative} }
          &.tname, &.status { ${cs.over.get('initial')} ${cs.w.get(160)} ${cs.disp.flex('none !important')} }
          .combo-box .cb-sel .cb-ul .cb-li { ${cs.p.a0} ${cs.p.h5}}
          .edit-box.sm .box input.input { ${cs.h.get(26)} }
        }
      }

      .sbtn { ${cs.font.underline} ${cs.mouse.pointer} &:hover { ${cs.font.primary} } 
        &.cancel .btn-label { ${cs.font.gray} }
      }
    }

    .s-foot { ${cs.disp.block} ${cs.w.full} ${cs.h.get(60)} ${cs.pos.relative} ${cs.m.t10} 
      .s-noti { ${cs.font.yellow} ${cs.m.l10} }
    }
  }
}`;


const API = URL.API.ADMIN;
const STOD = ST.ADMIN.ORDER;

const OrderBatch = (props) => {
  const [select, setSelect] = useState('two');
  const [start, setStart] = useState(moment().add(-2, 'day').format('YYYY-MM-DD'));
  const [end, setEnd] = useState(moment().format('YYYY-MM-DD'));
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  const [alllist, setAlllist] = useState(null);
  const [search,] = useState('');
  const [order,] = useState('odr asc');
  const [limit,] = useState(10);
  const [page, setPage] = useState({ current: 1, total: 1, max: 1 });
  // eslint-disable-next-line no-unused-vars
  const [trans, setTrans] = useState(null);
  const [status, setStatus] = useState(null);
  const [tab, setTab] = useState('0');
  const [ctrans, setComboTrans] = useState(null);
  const [cstatus, setComboStatus] = useState(null);
  const [running, setRunning] = useState(false);
  const [count, setCount] = useState(false);

  const { max, current } = page;
  const total = page ? page.total : 0;

  const head = [
    { key: 'rowid', title: STOD.ODRID, flex: '1 1 120px', align: 'center' },
    { key: 'odrno', title: STOD.ODRNO, flex: '2 1 140px', align: 'center' },
    { key: 'ctime', title: STOD.DATETIME, flex: '1 1 80px', align: 'center', type: 'datetime', format: "YY/MM/DD" },
    { key: 'product', title: STOD.PRODUCT, flex: '4 1 180px', align: 'left' },
    { key: 'price', title: STOD.PRICE, flex: '1 1 80px', align: 'right', type: 'number', unit: ST.WON },
    { key: 'name', title: STOD.NAME, flex: '1 1 80px', align: 'center' },
    {
      key: 'tname', title: STOD.TNAME, flex: '2 1 120px', align: 'center',
      formatter: (v, item, pos) => {

        const onChange = (item, pos) => {
          list[pos].tid = item.id;
          list[pos].tname = item.name;

          setList([...list]);
        }

        return <div className={'r-trans'}>
          <Combobox className={'sm gray full'} list={ctrans} onChange={(eid, e, item) => onChange(item, pos)}
            pos={ctrans && item.tid ? ctrans.findIndex(a => String(a.id) === String(item.tid)) : 0} options={{ text: { align: 'left' } }} />
        </div>
      }
    },
    {
      key: 'tno', title: STOD.TNO, flex: '2 1 100px', align: 'center',
      formatter: (v, item, pos) => {
        const onChange = (value) => {
          list[pos].tno = value;
          setList([...list]);
        };

        if (item.status > "90") {
          return null;
        } else {
          return <div className={'r-trans'}>
            <Editbox className={'sm white'} value={v} onChange={onChange} />
          </div>
        }
      }
    },
    {
      key: 'status', title: ST.STATUS, flex: '2 1 120px',
      formatter: (v, item, pos) => {

        const onChange = (item, pos) => {
          list[pos].status = item.id;

          setList([...list]);
        }

        return <div className={'r-status'}>
          <Combobox className={'sm gray full'} list={cstatus} onChange={(eid, e, item) => onChange(item, pos)}
            pos={cstatus && item.status ? cstatus.findIndex(a => String(a.id) === String(item.status)) : 0} options={{ text: { align: 'left' } }} />
        </div>
      }
    },
  ];

  const searchs = [
    { name: STOD.EMAIL, id: 'email', check: true },
    { name: STOD.NAME, id: 'username', check: false },
    { name: STOD.PHONE, id: 'phone', check: false },
  ];

  const getWhere = (s, p = 1) => {
    return { where: `DATE(ctime) >= ${Util.replaceAll(s, '-', '')} and status='2'`, page: p }
  }

  useEffect(() => {
    const param = actions.getParam();
    const { rowid } = param;

    if (rowid) {
      const { start = moment().add(-3, 'day').format('YYYY-MM-DD') } = param;
      setTab(Number(rowid));
      doReload(getWhere(start), true, tab, () => {
        setSelect('three');
      });
    } else {
      doReload();
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (value = null, loading = true, filter = tab, callback = null) => {
    loading && setLoaded(false);

    if (!value) value = getWhere(start);

    if (!Util.isEmpty(order)) {
      value.order = order;
    }

    if (!Util.isEmpty(search)) {
      value.where = `${search.key} like "%${search.value}%"`;
    }

    value.limit = limit;

    actions.doList(API.ORDER, value, true).then((res) => {
      const { result, tlist, tstatus, count } = res;
      let json = tlist && tlist.sval;
      if (json) {
        const arrays = json && JSON.parse(json);
        setTrans(arrays);
        const list = arrays && arrays.map(a => { return { id: a.id, name: a.title } });
        setComboTrans([{ id: 0, name: STOD.BATCH_CTRANS }, ...list]);
      }

      json = tstatus && tstatus.sval;
      if (json) {
        const arrays = json && JSON.parse(json);
        setStatus(arrays);
        const list = arrays && arrays.map(a => { return { id: a.id, name: a.title } }).filter(a => a.id > 2);
        setComboStatus([{ id: 0, name: STOD.BATCH_CSTATUS }, ...list]);
      }

      setPage(result.page);

      const list = result.data && result.data;
      setAlllist(list.map(a => { a.status = 0; return a; }));
      onClickTab(filter, list);

      setCount(count);
      setLoaded(true);

      callback && callback(JSON.parse(JSON.stringify(list)));
    });
  }

  const onClickTab = (eid, array = alllist, e) => {
    setTab(eid);
    if (!array) return;
    if (String(eid) <= "0") {
      setList(array);
    } else {
      setList(array.filter(a => String(a.status) === String(eid)));
    }
  }

  const onClickDate = (eid, e) => {
    setSelect(eid);

    let value = 0;
    switch (eid) {
      case 'one': value = moment().add(-1, 'day'); break;
      case 'two': value = moment().add(-2, 'day'); break;
      case 'three': value = moment().add(-3, 'day'); break;
      case 'week': value = moment().add(-7, 'day'); break;
      case 'month': value = moment().add(-1, 'month'); break;
      default: value = moment().add(-1, 'day'); break;
    }

    const start = value.format('YYYY-MM-DD');
    setStart(start);
    const end = moment().format('YYYY-MM-DD');
    setEnd(end);
    doReload(getWhere(start));
  }

  const onSelectTrans = (eid, e, item, pos) => {
    const array = JSON.parse(JSON.stringify(list));
    array.map(a => {
      a.tid = item.id;
      a.tname = item.name;
      return null;
    });

    setList(array);
  }

  const onSelectStatus = (eid, e, item, pos) => {
    const array = JSON.parse(JSON.stringify(list));
    array.map(a => {
      a.status = item.id;
      return null;
    });

    setList(array);
  }

  const onClick = () => {
    const tno = list.every(a => a.tno !== null && a.tno.length > 0);
    const tname = list.every(a => a.tid !== null && a.tname !== null);
    const status = list.every(a => String(a.status) > "2");
    const message = !tname ? STOD.BATCH_ERROR(STOD.TNAME) : !tno ? STOD.BATCH_ERROR(STOD.TNO) : !status ? STOD.BATCH_ERROR(STOD.STATUS) : null;
    if (message) {
      props.openConfirm({
        title: ST.WARNING, type: 'warn', msg: message, size: 'sm', className: 'gray', cancel: false,
      });
      return;
    } else {
      // const array = list.map(a => {return })
      const mailtitle = ST.ADMIN.ORDER.TRANS_MAIL;
      setRunning(true);
      actions.doUpdate(API.ORDER_BATCH, { list, mailtitle, checked: true }).then(({ code, result }) => {
        Util.showAlert(props, code);
        doReload(getWhere(start), true, tab, () => { });
        setRunning(false);
      });
    }
  }

  if (loaded && status) {
    const datelist = [
      { id: 'one', title: `1${ST.DAY}` },
      { id: 'two', title: `2${ST.DAY}` },
      { id: 'three', title: `3${ST.DAY}` },
      { id: 'week', title: `1${ST.WEEK}` },
      { id: 'month', title: `1${ST.MONTH}` },
    ];

    return <StyledObject className="ad-order-batch">
      <div className={'s-head'}>
        <div className={'title'}>
          <p className={'label'}>{`${ST.MYPAGE.FIND_DATE}(${start} ~ ${end})`}</p>
          <div className={'date'}>
            {datelist && datelist.map(item => {
              return <span key={item.id} className={cx('d-li', select === item.id && 'active')}
                onClick={(e) => onClickDate(item.id, e)}>{item.title}</span>
            })}
          </div>
        </div>
        <Button className={"btn-back black tr sm right top"} onClick={props.onClick && props.onClick} title={ST.BACK} />
      </div>

      <div className={'s-body'}>
        <div className={'t-btn'}>
          <div className={'tl'}>
            <span className={'title'}>{STOD.BATCH_TRANS}</span>
            <Combobox className={'strans sm gray radius'} list={ctrans}
              options={{ text: { align: 'left' } }} pos={0} onChange={onSelectTrans} />
            <Combobox className={'status sm gray radius'} list={cstatus}
              options={{ text: { align: 'left' } }} pos={0} onChange={onSelectStatus} />
          </div>
          <div className={'tr'}><span>{`TOTAL : ${count}${ST.COUNT}`}</span></div>
        </div>
        <Tablebox className={'dark sm'} head={head} list={list} searchkey={searchs[0].id}
          onPageClick={(page) => doReload({ page: page })}
          pos={current} max={max} total={total} searchs={searchs} rowid="rowid" />
      </div>

      <div className={'s-foot'}>
        <span className={'s-noti'}>{STOD.BATCH_MAX}</span>
        <Button className={"btn-save red right"} onClick={onClick} title={ST.SAVE} />
      </div>
      {running && <Loading className={'fixed'} type="ring" />}
    </StyledObject>
  } else {
    return <Loading />;
  };
};

export default OrderBatch;