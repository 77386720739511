import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ST, PAY } from '../svc/Lang'
import cx from 'classnames/bind'
import { cs, Loading, Button, Util, Tablebox, TabButton, Editbox, Combobox, Formgroup, Checkbox, Svg } from 'object/src'
import { URL } from 'svc/Enum'
import * as actions from 'object/src/actor/Action'
import moment from 'moment'
import OrderBatch from './OrderBatch'
import HistoryDetail from 'view/HistoryDetail'

const StyledObject = styled.div`{
  &.ad-order { ${cs.bg.trans} ${cs.p.a30} ${cs.max.width(1400)}
    .s-head { ${cs.pos.relative}
      .title { ${cs.w.fit} ${cs.disp.inblock} ${cs.m.r20} ${cs.pos.relative}
        .label { ${cs.disp.inblock} ${cs.font.lg} ${cs.font.thickbold} }
        .date { ${cs.m.l20} ${cs.disp.inblock} ${cs.font.gray} ${cs.mouse.pointer}
          ${cs.box.line} ${cs.border.radius(5)} ${cs.over.hidden} ${cs.m.bottom(-7)}
          .d-li { ${cs.disp.inblock} ${cs.w.fit} ${cs.p.h10} ${cs.border.right} 
            ${cs.p.v3}
            &:last-child { ${cs.border.none} }
            &:hover { ${cs.bg.black} }
          } 
          .active { ${cs.font.yellow} ${cs.underline} ${cs.bg.gray} }
        }
      }
      .type { ${cs.align.rbottom} ${cs.bottom(10)} }

      ${cs.border.bottom} ${cs.border.width(3)} ${cs.p.b10} ${cs.border.darkgray}
      .tab-grp { ${cs.align.rtop} ${cs.top(-5)} }
    }

    .s-body { 
      .button.rowid { ${cs.font.white} ${cs.font.md} .btn-label { ${cs.font.underline} } 
        &:hover { ${cs.font.yellow} }
      }

      .t-btn { ${cs.m.t20} ${cs.pos.relative}
        // .tl { ${cs.disp.inblock} }
        .tr { ${cs.float.right} ${cs.top(0)} }
      }

      .table-box { ${cs.m.t10}
        .link { ${cs.font.underline} ${cs.mouse.pointer} &:hover { ${cs.font.yellow} } }
        .tb-row .tb-col { ${cs.h.get('28px !important')} ${cs.font.line('28px !important')} 
          .r-trans { ${cs.pos.relative} .svg-icon { ${cs.top(5)} ${cs.right(-5)} } }
        }
      }

      .sbtn { ${cs.font.underline} ${cs.mouse.pointer} &:hover { ${cs.font.primary} } 
        &.cancel .btn-label { ${cs.font.gray} }
      }
    }
  }

  &.menu-status {
    ${cs.pos.fixed} ${cs.top(0)} ${cs.left(0)} ${cs.size.full} ${cs.disp.block} ${cs.z.top}
    ${cs.anim.showin()}

    .ms-bg { ${cs.size.full} ${cs.disp.block} ${cs.bg.alphablack} }
    
    .ms-ul { ${cs.w.get(300)} ${cs.h.fit} ${cs.p.a30} ${cs.bg.black} ${cs.border.radius(5)} 
      ${cs.align.center}
      .ms-li, .ms-all { ${cs.font.md} ${cs.font.line(40)} ${cs.border.lightgray} ${cs.mouse.pointer} 
        ${cs.p.h10} ${cs.m.t1} ${cs.bg.darkhover}
        &:hover { ${cs.bg.dark} }
        &.ms-all { ${cs.w.full} ${cs.font.line(20)} ${cs.bg.black} ${cs.font.center} }
      }
    }
    .ms-noti { ${cs.font.center} ${cs.font.yellow} ${cs.m.b10} 
      &.ms-all { }
    }
  }

  &.order-modal, &.trans-modal {
    ${cs.m.b30} ${cs.anim.showin()} ${cs.pos.relative}
    .p-line { ${cs.font.line(20)} ${cs.h.fit} ${cs.p.t2}
      .tit { ${cs.disp.inblock} ${cs.w.get(140)} ${cs.h.full} ${cs.font.thickbold} 
        ${cs.font.darkgray} ${cs.font.sm} ${cs.font.right} ${cs.p.r10}
      }
      .val { ${cs.disp.inblock} ${cs.h.full} ${cs.w.calc('100% - 150px')} 
        ${cs.p.h10} ${cs.font.prewrap} ${cs.font.md} ${cs.min.h(24)} ${cs.p.r20} ${cs.p.l10}
        // ${cs.border.bottom} ${cs.border.darkgray}
        &.click { ${cs.font.underline} ${cs.mouse.pointer} &:hover { ${cs.font.yellow} } }
      }
    }

    &.trans-modal {
      ${cs.m.b0}
      .p-line {
        .tit { ${cs.w.get(100)} }
        .val { ${cs.w.calc('100% - 100px')}  }
      }

      .tm-head { ${cs.m.b10} ${cs.p.b20} ${cs.border.bottom} ${cs.over.unset} }
      .url { ${cs.m.b20} ${cs.p.b10} ${cs.border.bottom} }
      .check { ${cs.m.t10} }
    }
  }

  &.ad-payment {
    ${cs.m.t50} ${cs.pos.fixed} ${cs.left(0)} ${cs.top(0)} ${cs.size.full} ${cs.z.popup}
    ${cs.over.hidden}

    .p-bg { ${cs.size.full} ${cs.disp.block} }

    .p-frame { ${cs.align.xcenter} ${cs.top(50)} ${cs.bg.black} ${cs.border.shadow()} 
      ${cs.border.radius(10)} ${cs.box.light} ${cs.over.hidden} ${cs.w.dsmd}
      ${cs.min.h(400)} ${cs.h.full} ${cs.max.h('70%')} 
      .p-head { ${cs.h.get(40)} ${cs.border.bottom} ${cs.border.dark} 
        .cancel { ${cs.top(10)} ${cs.right(10)} } 
        .title { ${cs.font.lg} ${cs.font.white} ${cs.font.thickbold} ${cs.font.line(40)} ${cs.p.l20} }
      }
      .p-foot { ${cs.h.get(40)} ${cs.border.top} ${cs.border.dark} .iamport { ${cs.m.t5} ${cs.m.l10} } }
      .p-body {  ${cs.h.calc('100% - 80px')} ${cs.p.a20} ${cs.scrollbar.t3} ${cs.over.hidden} ${cs.over.yauto}
        li { ${cs.font.line(16)} ${cs.m.t3}
          .p-sl { ${cs.disp.inblock} ${cs.w.get(200)} ${cs.font.sm} 
            ${cs.font.gray} ${cs.p.r20} ${cs.font.right} ${cs.float.left} ${cs.p.t2}
          }
          .p-sr {
            ${cs.disp.inblock} ${cs.font.sm} ${cs.max.width('calc(100% - 200px)')} ${cs.w.fit} ${cs.font.prewrap} 
            &.url, .p-button { ${cs.mouse.pointer} ${cs.p.h10} ${cs.p.v2} ${cs.bg.dark} ${cs.border.radius(3)}
              &:hover { ${cs.bg.alphablack} } 
              &.cancel { ${cs.p.v10} ${cs.p.h30} ${cs.bg.primary} }
            }

            &.cancel { ${cs.m.t20} }

            &.status { ${cs.p.h10} ${cs.border.radius(3)} ${cs.font.thickbold} }
            &.red { ${cs.bg.red} ${cs.font.white} }
            &.green { ${cs.bg.green} ${cs.font.black} }
            &.primary { ${cs.font.lightprimary} }

            .p-link { ${cs.disp.block} ${cs.font.xs} ${cs.font.lightgray} ${cs.bg.dark} 
              ${cs.border.radius(3)} ${cs.mouse.pointer} ${cs.p.h5}
            }
          }

          .cancel-dtl { ${cs.mouse.pointer} ${cs.p.h10} ${cs.p.v1} ${cs.bg.dark} ${cs.border.radius(3)}
            ${cs.font.sm} ${cs.m.l10}
            &:hover { ${cs.bg.alphablack} } 
          }
        }
      }
    }
  }

  @media screen and (max-width : 1400px) {
    &.ad-order {
      .s-head { ${cs.h.fit} ${cs.p.v10} 
        .tab-grp { ${cs.align.unset} ${cs.pos.relative} ${cs.disp.block} ${cs.w.full} ${cs.m.t5} } 
      }
    }
  }
  
  @media screen and (max-width : 1024px) {
    &.ad-order {
      .table-box .tb-line .tb-row .tb-col { ${cs.p.h5} p { ${cs.font.xs} } }
      .s-body .button.rowid .btn-label { ${cs.font.xs} }
    }
  }

  @media screen and (max-width : 860px) {
    &.ad-order {
      .btn-batch { ${cs.disp.hide} }
      .button .btn-label { ${cs.font.xs} }
      .s-head .title .date { ${cs.m.l0} ${cs.m.v10}
        .d-li { ${cs.font.sm} }
      }

      .table-box .tb-line .tb-row .tb-col { ${cs.p.h3} p { ${cs.font.xs} } }
      .search-frame .search-box { ${cs.w.full} }
    }

    &.ad-payment {
      ${cs.m.t0} ${cs.pos.fixed} ${cs.left(0)} ${cs.top(62)} ${cs.size.full} ${cs.z.popup}
      ${cs.over.hidden}
  
      .p-bg { ${cs.size.full} ${cs.disp.block} }
  
      .p-frame { ${cs.align.top} ${cs.top(0)} ${cs.bg.dark} ${cs.border.shadow('none')} 
        ${cs.border.radius(0)} ${cs.border.none} ${cs.over.hidden} ${cs.w.full}
        ${cs.min.h(240)} ${cs.h.full} ${cs.max.h('calc(100% - 63px)')} 
        .p-body li .p-sl { ${cs.w.get(120)} }
      }
    }
  }

  @media screen and (max-width : 600px) {
  }
}`

const API = URL.API.ADMIN
const STOD = ST.ADMIN.ORDER

const gval = { select: 'two', start: moment().add(-2, 'day').format('YYYY-MM-DD'), tab: '0' }

const Order = (props) => {
  const [batch, setBatch] = useState(false)

  if (batch) {
    return <OrderBatch {...props} onClick={() => setBatch(false)} />
  } else {
    return <OrderView {...props} onClick={() => setBatch(true)} />
  }
}

const OrderView = (props) => {
  const [select, setSelect] = useState(gval.select)
  const [start, setStart] = useState(gval.start)
  const [end, setEnd] = useState(moment().format('YYYY-MM-DD'))
  const [loaded, setLoaded] = useState(false)
  const [list, setList] = useState(null)
  const [alllist] = useState(null)
  const [search, setSearch] = useState({ key: '', value: '' })
  const [order] = useState('ctime desc')
  const [limit] = useState(15)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [max, setMax] = useState(0)
  const [trans, setTrans] = useState(null)
  const [status, setStatus] = useState(null)
  const [tab, setTab] = useState(gval.tab)
  const [menu, showMenu] = useState(null)
  const [tablist, setTablist] = useState(null)
  const [payment, setPayment] = useState(null)
  const [running, setRunning] = useState(false)
  const [initstate, setInitstate] = useState(-1)
  const [popup, setPopup] = useState(null)

  const formTrans = (v, item, pos) => {
    // 클릭에 대한 처리
    const onClick = (eid, p, e) => {
      e.stopPropagation()

      const array = trans
        ? [
            ...trans
              .map((a) => {
                return { ...a, name: a.title }
              })
              .sort((a, b) => b.main - a.main)
          ]
        : null
      const slist = status
        ? [
            ...status
              .map((a) => {
                return { ...a, name: a.title }
              })
              .sort((a, b) => a.id - b.id)
          ]
        : null

      let param = JSON.parse(JSON.stringify(p))
      if (initstate && Number(initstate) > 0) {
        param.status = initstate
      }

      props.openModal({
        title: ST.ADMIN.ORDER.TRANS_IN,
        className: 'dark',
        size: 'sm',
        children: TransModal,
        data: { ...param, combo: array, slist: slist, dstate: initstate },
        onOk: (data) => {
          // 배송상태 기본 설정
          if (initstate < 0) {
            const state = status.find((a) => String(a.id) === String(data.status))
            props.openConfirm({
              title: ST.ALARM,
              type: 'warn',
              msg: STOD.TRANS_INIT_STATE(state && state.title),
              size: 'sm',
              className: 'gray',
              ok: ST.YES,
              cancel: ST.NO,
              onClicked: (isOk) => {
                const value = isOk ? data.status : '0'
                const json = { rowid: null, stag: 'trans', skey: 'initstate', sval: value }
                actions.doInsert(URL.API.ADMIN.SETTING, json).then(({ code }) => {
                  setInitstate(value)
                })
              }
            })
          }

          // 배송회사 및 송장번호 업데이트
          const updata = { rowid: p.rowid, ...data, odrid: p.rowid, userid: p.userid, mailtitle: ST.ADMIN.ORDER.TRANS_MAIL }
          actions.doUpdate(API.ORDER_TRANS, updata).then(({ code }) => {
            Util.showAlert(props, code)
            doReload()
          })
        }
      })
    }

    // 주문 취소의 경우
    if (Number(item.status) >= 90) {
      return null
    } else if (item.tno) {
      return (
        <div className={'r-trans'}>
          <p className={'link'} onClick={(e) => onClickLink(item, e)}>{`${item.tname}(${item.tno})`}</p>
          <Svg className={'sm gray right'} icon={'edit'} onClick={(eid, e) => onClick(eid, item, e)}></Svg>
        </div>
      )
    } else {
      return (
        <div className={'r-trans'}>
          <Button className={'gd-gray sm full'} title={STOD.TRANS_IN} onClick={(eid, e) => onClick(eid, item, e)} />
        </div>
      )
    }
  }

  const formStatus = (v, item, pos) => {
    const s = status && status.find((a) => String(a.id) === String(item.status))
    let color = ''
    switch (Number(item.status)) {
      case 1:
        color = 'green'
        break
      case 2:
        color = 'primary'
        break
      default:
        color = 'gd-gray'
        break
    }

    if (Number(item.status) > 90) color = 'black'

    let title = ''
    let func = (e) => {
      e.stopPropagation()
      showMenu(item)
    }
    switch (Number(item.status)) {
      case 91:
        title = STOD.CANCEL_REQ
        color = 'red'
        func = (e) => {
          e.stopPropagation()
          const msg = STOD.IS_CANCEL(item.name, item.product, `${Util.commas(item.price)}${ST.WON}`)
          props.openConfirm({
            title: STOD.CANCEL,
            type: 'warn',
            msg,
            size: 'sm',
            className: 'gray',
            onClicked: (isOk) => {
              const value = { rowid: item.rowid, odrno: item.odrno, reason: STOD.ADMIN_CANCEL }
              isOk &&
                actions.doInsert(API.PAYMENT_CANCEL, value, null).then(({ code, result }) => {
                  doReload(page, search, order, limit, false)
                  // doReload(null, false);
                })
            }
          })
        }
        break
      case 99:
        title = STOD.CANCEL
        color = 'cancel'
        break
      default:
        title = s && s.title
    }

    return (
      <div className={'status'} onClick={(e) => func(e)}>
        <Button className={cx('sbtn sm full', color)} title={title} />
      </div>
    )
  }

  const formOrderno = (v, item, pos) => {
    if (item.odrno && item.odrno !== '0') {
      return <Button className={'sm trans rowid full'} title={v} onClick={(eid, e) => onClickPayment(item, e)} />
    } else {
      return null
    }
  }

  const head = [
    {
      key: 'rowid',
      title: STOD.ODRID,
      flex: '1 1 120px',
      align: 'center',
      mobile: 'hide',
      formatter: (v, item, pos) => {
        return <Button className={'sm trans rowid full'} title={v} onClick={(eid, e) => onClickOrderno(eid, item && item.rowid, e)} />
      }
    },
    { key: 'odrno', title: STOD.ODRNO, flex: '2 1 120px', align: 'center', formatter: formOrderno },
    { key: 'ctime', title: STOD.DATETIME, flex: '1 1 60px', align: 'center', type: 'datetime', format: 'YY/MM/DD' },
    { key: 'product', title: STOD.PRODUCT, flex: '3 1 140px', align: 'left' },
    { key: 'price', title: STOD.PRICE, flex: '1 1 80px', align: 'right', tablet: 'hide', type: 'number', unit: ST.WON },
    { key: 'name', title: STOD.NAME, flex: '1 1 60px', align: 'center', mobile: 'hide' },
    { key: 'phone', title: STOD.PHONE, flex: '2 1 100px', align: 'center', type: 'phone', mobile: 'hide' },
    { key: 'tname', title: `${STOD.TNAME}(${STOD.TNO})`, flex: '1 1 160px', align: 'center', mobile: 'hide', formatter: formTrans },
    { key: 'status', title: ST.STATUS, flex: '1 1 80px', formatter: formStatus }
  ]

  const searchs = [
    { name: STOD.ODRID, id: 'odrid', check: true },
    { name: STOD.ODRNO, id: 'odrno', check: false },
    { name: STOD.TNO, id: 'tno', check: false },
    { name: STOD.NAME, id: 'name', check: false },
    { name: STOD.PHONE, id: 'phone', check: false }
  ]

  useEffect(() => {
    const param = actions.getParam()
    const { rowid, odrid = null } = param

    // 관리자 메인 화면에서 최근 3일 상태별(입금대기중/결제완료) 바로가기 선택시
    if (rowid) {
      const s = param.start || moment().add(-3, 'day').format('YYYY-MM-DD')
      setTab(Number(rowid))
      doReload(1, search, order, limit, true, Number(rowid), () => setSelect('three'), s)
    }
    // 관리자 구매이력 화면에서 항목 선택시 주문번호로 찾기
    else if (odrid) {
      const v = { key: 'odrid', value: odrid }
      setSearch(v)
      doReload(
        1,
        v,
        order,
        limit,
        true,
        tab,
        () => {
          setSelect('')
          setSearch({ key: 'odrid', value: '' })
        },
        false,
        ''
      )
    } else {
      doReload(1, search, order, limit, true, tab, () => setSelect('three'))
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!props.noti || payment) return
    doReload(1, search, order, limit, false, gval.tab, () => {}, false)

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.noti])

  const doReload = (p = page, s = search, o = order, l = limit, loading = true, filter = tab, callback = null, run = true, st = start) => {
    loading ? setLoaded(false) : setRunning(run)

    let value = {}
    if (s && s.value) {
      value['where'] = `${s.key} like "%${s.value}%"`
      value['where'] += st ? ` and DATE(ctime) >= ${Util.replaceAll(st, '-', '')}` : ''
    } else {
      value['where'] = `DATE(ctime) >= ${Util.replaceAll(st, '-', '')}`
    }

    if (Number(filter) >= 90) value.where += ` and status>='${filter}'`
    else if (Number(filter) > 0) value.where += ` and status='${filter}'`

    value['order'] = o
    value['page'] = p
    value['limit'] = l
    value['start'] = st ? `DATE(ctime) >= ${Util.replaceAll(st, '-', '')}` : ''

    actions.doList(API.ORDER, value, true).then(({ result, tlist, tstatus, tinitstate, groupby }) => {
      let json = tlist && tlist.sval
      if (json) {
        const arrays = JSON.parse(json)
        setTrans(arrays)
      }

      if (tinitstate) {
        setInitstate(tinitstate.sval || -1)
      }

      const datas = result && result.list
      json = tstatus && tstatus.sval
      if (json) {
        const arrays = JSON.parse(json)
        setStatus(arrays)

        const arr = JSON.parse(JSON.stringify(arrays))

        let tabs =
          arr &&
          arr.map((a) => {
            const item = groupby && groupby.find((b) => String(b.status) === String(a.id))
            a.title = `${a.title}(${item ? item.count : 0})`
            a.count = item ? item.count : 0
            return a
          })

        const tot = groupby ? groupby.reduce((b, c) => Number(b) + Number(c.count), 0) : 0
        const rest = tot - tabs.reduce((b, c) => Number(b) + Number(c.count), 0)

        setTablist([{ id: '0', title: `${ST.ALL}(${tot})` }, ...tabs, { id: '90', title: `${ST.REST}(${rest})` }])
      }

      setList(datas)
      setLoaded(true)
      setRunning(false)

      setTotal(result.page.total)
      setPage(result.page.current)
      setMax(result.page.max)
      setSearch(s)

      callback && callback(JSON.parse(JSON.stringify(datas)))
    })
  }

  const onClickLink = (item, e = null) => {
    e && e.stopPropagation()
    const t = trans.find((a) => String(a.id) === String(item.tid))
    t && window.open(t.url + item.tno)
  }

  const onSelect = (eid, rowid, e) => {
    e.stopPropagation()
    setPopup(rowid)
  }

  const onClickOrderno = (eid, rowid, e) => {
    e.stopPropagation()
    // setDetail(item);
    const item = list.find((a) => String(a.rowid) === String(rowid))
    let tstate = status.find((a) => String(item.status) === String(a.id))
    tstate = tstate ? tstate.title : ''
    props.openModal({
      ok: false,
      cancel: ST.OK,
      title: ST.ADMIN.ORDER.DETAIL,
      className: 'dark',
      size: 'md',
      children: Modal,
      data: { ...item, tstate },
      onOk: (data) => {},
      onClick: (id, data) => {
        if (id === 'trans') {
          onClickLink(data)
        } else if (id === 'payment') {
          onClickPayment(data)
        }
        return false
      }
    })
  }

  const onClickPayment = (item, e = null) => {
    e && e.stopPropagation()
    setPayment(item)
  }

  const onClickTab = (eid, array, e) => {
    if (!array) array = alllist

    setTab(eid)
    gval.tab = eid

    doReload(1, search, order, limit, false, eid, () => {}, true)
  }

  const onClickDate = (eid, event) => {
    setSelect(eid)

    let value = 0
    switch (eid) {
      case 'one':
        value = moment().add(-1, 'day')
        break
      case 'two':
        value = moment().add(-2, 'day')
        break
      case 'three':
        value = moment().add(-3, 'day')
        break
      case 'week':
        value = moment().add(-7, 'day')
        break
      case 'month':
        value = moment().add(-1, 'month')
        break
      case 'year':
        value = moment().add(-1, 'year')
        break
      default:
        value = moment().add(-1, 'day')
        break
    }

    const s = value.format('YYYY-MM-DD')
    setStart(s)
    gval.start = s
    const e = moment().format('YYYY-MM-DD')
    setEnd(e)
    doReload(1, search, order, limit, false, tab, () => {}, true, s)
  }

  const onClickMenu = (eid, item, e) => {
    if (eid && item) {
      showMenu(false)
      const value = { rowid: item.rowid, ...item, status: eid, checked: false }
      actions.doUpdate(API.ORDER_TRANS, value).then(({ code, result }) => {
        Util.showAlert(props, code)
        // doReload(1, false);
        doReload(1)
      })
    } else {
      showMenu(false)
    }
  }

  const onClickPopup = (eid, e) => {
    setPayment(null)
  }

  const onClickPage = (p) => {
    doReload(p)
  }

  const onClickSearch = (value, key, e) => {
    doReload(1, { key: key, value: value })
  }

  if (loaded) {
    const datelist = [
      { id: 'one', title: `1${ST.DAY}` },
      { id: 'two', title: `2${ST.DAY}` },
      { id: 'three', title: `3${ST.DAY}` },
      { id: 'week', title: `1${ST.WEEK}` },
      { id: 'month', title: `1${ST.MONTH}` },
      { id: 'year', title: `1${ST.YEAR}` }
    ]

    return (
      <StyledObject className='ad-order'>
        <div className={'s-head'}>
          <div className={'title'}>
            <p className={'label'}>{`${ST.MYPAGE.FIND_DATE}(${start} ~ ${end})`}</p>
            <div className={'date'}>
              {datelist &&
                datelist.map((item) => {
                  return (
                    <span key={item.id} className={cx('d-li', select === item.id && 'active')} onClick={(e) => onClickDate(item.id, e)}>
                      {item.title}
                    </span>
                  )
                })}
            </div>
          </div>
          <TabButton className={'tab-grp type'} size={'sm'} onClick={(eid) => onClickTab(eid)} list={tablist} select={tab} color={'gray'} />
        </div>

        <div className={'s-body'}>
          <div className={'t-btn'}>
            <Button className={'btn-batch red tr sm'} onClick={props.onClick} title={STOD.BATCH} />
          </div>
          <Tablebox
            className={'dark sm'}
            head={head}
            list={list}
            searchkey={(search && search.key) || searchs[0].id}
            naviClass={'white'}
            onClickPage={onClickPage}
            onSelect={(rowid, e) => onSelect('select', rowid, e)}
            onClickSearch={onClickSearch}
            pos={page}
            max={max}
            total={total}
            searchs={searchs}
            rowid='rowid'
          />
        </div>

        {status && menu && <Menus list={status} item={menu} onClick={onClickMenu} />}
        {payment && <Payment item={payment} onClick={onClickPopup} openConfirm={props.openConfirm} />}
        {running && <Loading className={'fixed'} type='ring' />}
        {popup && <Popdetail rowid={popup} onClose={() => setPopup(null)} />}
      </StyledObject>
    )
  } else {
    return <Loading />
  }
}

export default Order

const Menus = (props) => {
  const { list, item } = props
  const [all, setAll] = useState(false)

  const onClick = (v, e) => {
    const { id } = v
    props.onClick && props.onClick(id, item, e)
  }

  const onClickAll = () => {
    setAll(!all)
  }

  const last = list.findIndex((a) => Number(a.id) === Number(item.status))
  let shownoti = Number(item.status) >= 90 ? STOD.CANCEL_NOTI : last === list.length - 1 ? `${STOD.TRANS_NOTI(list[last].title)}` : ''
  if (all) shownoti = false
  return (
    <StyledObject className={'menu-status'}>
      <div className={'ms-bg'} onClick={(e) => props.onClick && props.onClick(null)} />
      {item && shownoti ? (
        <div className={'ms-ul'}>
          <p className={'ms-noti'}>{shownoti}</p>
          {Number(item.status) < 90 && (
            <li className={'ms-all'} onClick={onClickAll}>
              <Svg className={'md'} icon={all ? 'top' : 'bottom'} />
            </li>
          )}
        </div>
      ) : (
        <ul className={'ms-ul'}>
          {list &&
            list.map((col) => {
              if (!all && Number(col.id) <= Number(item.status)) return null
              // if (item.id <= 2) return null;
              return (
                <li key={col.id} className={'ms-li'}>
                  <p className={'ms-line'} onClick={(e) => onClick(col, e)}>
                    {col.title}
                  </p>
                </li>
              )
            })}
          <li className={'ms-all'} onClick={onClickAll}>
            <Svg className={'md'} icon={all ? 'top' : 'bottom'} />
          </li>
        </ul>
      )}
    </StyledObject>
  )
}

/*******************************************************************
 Popup
*******************************************************************/

const StyledComp = styled.div`{
  &.popup-detail {
    ${cs.bg.alphablack} ${cs.align.left} ${cs.top(0)} ${cs.size.full} ${cs.z.popup}
    // ${cs.max.wxl} ${cs.max.h(600)}

    .p-box { ${cs.size.fit} ${cs.align.center} ${cs.anim.showin()}
      .close-icon { ${cs.z.over} ${cs.align.rtop} ${cs.top(10)} ${cs.right(10)} }
      & > .hist-detail {
        ${cs.font.dark} ${cs.h.fit} ${cs.bg.frame} ${cs.border.radius(5)} ${cs.border.shadow()}
        ${cs.m.a0} ${cs.p.h20}

        .hd-head { ${cs.font.dark} ${cs.m.t0} ${cs.p.t20} ${cs.h.get(60)} }
        .hd-body { ${cs.bg.frame} 
          .title { ${cs.m.t10} } 
          .b-line .tit { ${cs.bg.lightgray} } 
          .b-list .btn-cmt { ${cs.disp.hide} }
        }
        .hd-foot { ${cs.disp.hide} }
      }
    }

    
  @media screen and (max-width : 1024px) {
    ${cs.z.top}
    .p-box { ${cs.size.full} ${cs.align.unset} ${cs.pos.absolute} ${cs.top(0)} ${cs.left(0)} 
      & > .hist-detail { ${cs.border.radius(0)} }
    }
  }

  @media screen and (max-width : 860px) {
  }

  @media screen and (max-width : 600px) {
  }
  }
}`

const Popdetail = (props) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <StyledComp className={'popup-detail'} onClick={props.onClose}>
      <div className={'p-box'}>
        {loaded && <Svg className={'close-icon'} icon={'cancel'} />}
        <HistoryDetail rowid={props.rowid} onClick={props.onClose} onLoaded={() => setLoaded(true)} />
      </div>
    </StyledComp>
  )
}

const Modal = (props) => {
  const { data } = props

  const onClickTrans = (e) => {
    props.onClick && props.onClick('trans', e, data)
  }

  const onClickPayment = (e) => {
    props.onClick && props.onClick('payment', e, data)
  }

  // const title = data.odrno ? `${data.rowid}(${data.odrno})` : data.rowid;
  return (
    <StyledObject className={'order-modal'}>
      {/* <Line title={`${STOD.ODRID}(${STOD.ODRNO})`} value={title} onClick={data.odrno ? onClickPayment : null} /> */}
      <Line title={STOD.ODRID} value={data.rowid} />
      {data.odrno && <Line title={STOD.ODRNO} value={data.odrno === '0' ? '' : data.odrno} onClick={onClickPayment} />}
      {/* {data.odrno && <Button className={'sm black right top'} title={STOD.PGLINK} onClick={onClickPayment} />} */}
      <Line title={STOD.DATETIME} value={Util.toStringSymbol(data.ctime)} />
      <Line title={STOD.PRODUCT} value={data.product} />
      <Line title={`${STOD.PRICE}(${STOD.TCOST})`} value={`${Util.commas(data.price)}${ST.WON}(${Util.commas(data.tprice)}${ST.WON})`} />
      <Line title={STOD.NAME} value={data.name} />
      <Line title={STOD.STATUS} value={data.tstate} />
      <Line title={STOD.PHONE} value={Util.toStringPhone(data.phone)} />
      <Line title={`${STOD.ADDRESS}`} value={`(${data.zipcode})${data.address}`} />
      <Line title={STOD.STEXT} value={data.stext || ''} />
      <Line title={`${STOD.TNAME}(${STOD.TNO})`} value={data.tno ? `${data.tname}(${data.tno})` : ''} onClick={onClickTrans} />
    </StyledObject>
  )
}

/*******************************************************************
 Popup
*******************************************************************/
const TransModal = (props) => {
  var refs = {}
  const { data } = props
  const [item, setItem] = useState(data && data.combo[0])
  const [url, setUrl] = useState(item.url)
  const [status, setStatus] = useState(data.status || 4)
  const [checked, setChecked] = useState(true)

  // 팝업의 ok이 버튼일 클릭되면 여기에서 return할 데이터를 만들어 return한다.
  props.act.getData = (checkValidate) => {
    // validate 체크하여 통과하지 못하면 false를 리턴(창이 닫히지 않는다.)
    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate())
    if (!isvalidate) return false

    const { state } = props
    let datas = {}
    Object.keys(refs).map((key) => (datas[key] = refs[key].getValue()))

    const { id, title } = item
    return { state: state, ...datas, tid: id, tname: title, status, checked }
  }

  // useEffect(() => {
  //   const { dstate } = props.data;
  //   if (dstate && Number(dstate) > 0) {
  //     let index = props.data.slist.findIndex(a => String(a.id) === String(dstate));
  //     setStatus(dstate);
  //   }

  //   return () => { }
  // })

  const onChange = (eid, e, value) => {
    setItem(value)
    refs.tno && refs.tno.focus()
  }

  const onChangeNo = (value) => {
    props.data.tno = value
    setUrl(item.url + value)
  }

  const onChangeStatus = (eid, e, value) => {
    setStatus(value.id)
  }

  // if (!props.data) return;

  // const { dstate } = props.data;
  let pos = props.data.slist.findIndex((a) => String(a.id) === String(status))
  // if (dstate && Number(dstate) > 0) {
  //   pos = props.data.slist.findIndex(a => String(a.id) === String(dstate));
  //   // setStatus(dstate);
  // }

  return (
    <StyledObject className={'trans-modal'}>
      <Formgroup className={'tm-head'} inline={true}>
        <Combobox
          className={'sm dark full'}
          label={ST.ADMIN.ORDER.TNAME}
          list={props.data.combo}
          options={{ text: { align: 'left' } }}
          pos={0}
          onChange={onChange}
        />
        <Combobox
          className={'sm dark full'}
          label={ST.ADMIN.ORDER.STATUS}
          list={props.data.slist}
          options={{ text: { align: 'left' } }}
          pos={pos}
          onChange={onChangeStatus}
        />
      </Formgroup>
      {/* <Line title={STOD.TNAME} value={item ? item.title : ''} /> */}
      <Line className={'link'} title={STOD.TLINK} value={item ? STOD.THOME : ''} onClick={() => window.open(item.link)} />
      <Line className={'phone'} title={STOD.TPHONE} value={item ? item.phone : ''} />
      <Line className={'url'} title={STOD.TURL} value={STOD.TURL_LINK} onClick={() => window.open(url)} />
      <Editbox
        name='tno'
        className='right white'
        type='text'
        ref={(ref) => {
          refs.tno = ref
        }}
        validate={true}
        value={data.tno || ''}
        label={ST.ADMIN.ORDER.TNO}
        focus={true}
        onChange={onChangeNo}
      />
      <Checkbox
        className={'check block md'}
        list={[{ id: 'check', name: ST.ADMIN.ORDER.SENDMAIL, check: checked }]}
        onChange={() => setChecked(!checked)}
      />
    </StyledObject>
  )
}

const Payment = (props) => {
  const { onClick } = props
  const [list, setList] = useState(null)
  const [cancel, setCancel] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [status, setStatus] = useState(null)
  const [detail, setDetail] = useState(false)

  useEffect(() => {
    if (!props.item) return
    doReload()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item])

  const doReload = () => {
    actions.doSelect(API.PAYMENT, { rowid: props.item.rowid, odrno: props.item.odrno }).then(({ result }) => {
      const keys = result ? Object.keys(result).map((key) => key) : null
      makeData(result, keys)
      setStatus(result && result.status)
      setLoaded(true)
    })
  }

  const makeData = (data, keys) => {
    const array =
      keys &&
      keys.map((key, i) => {
        if (!PAY[key]) {
          console.warn('Order item is not item key! ==> ' + key)
          return null
        }
        const { title, show = false, type = 'string', unit = '' } = PAY[key]
        if (!show || !title) return null

        let value = ''
        switch (type) {
          case 'number':
            value = `${Util.commas(data[key])}${unit}`
            break
          case 'array':
            value = JSON.stringify(data[key])
            break
          case 'phone':
            value = `${Util.toStringPhone(data[key])}`
            break
          case 'date':
            value = data[key] > 0 ? `${moment(Number(data[key]) * 1000).format('YY/MM/DD HH:mm:ss')}` : ''
            break
          case 'url':
            value = ST.ADMIN.ORDER.LINK_URL
            break
          default:
            value = data[key]
            break
        }

        switch (key) {
          case 'cancel_history':
            if (value === '[]') value = ''
            break
          case 'cancel_receipt_urls':
            if (value === '[]') value = ''
            break
          default:
            break
        }

        if (key === 'card_quota' && String(data[key]) === '0') value = ST.ADMIN.ORDER.DIRECT_PAY

        return { ...PAY[key], key, value, url: type === 'url' ? data[key] : '' }
      })

    // 캔슬 정보만 따로
    let iarray = array && array.filter((a) => a !== null && !a.cancel)
    let carray = array && array.filter((a) => a !== null && a.cancel)

    setList(iarray)

    carray &&
      carray.map((a, i) => {
        if (a.key === 'cancel_receipt_urls') {
          a['links'] = a.value ? JSON.parse(a.value) : ''
        }
        return null
      })

    setCancel(carray)

    return list
  }

  const onClickLink = (url, type, e) => {
    if (type === 'url') {
      window.open(url)
    }
  }

  const onClickCancel = () => {
    const msg = STOD.IS_CANCEL(props.item.name, props.item.product, `${Util.commas(props.item.price)}${ST.WON}`)
    props.openConfirm({
      title: STOD.CANCEL,
      type: 'warn',
      msg,
      size: 'sm',
      className: 'gray',
      onClicked: (isOk) => {
        const value = { rowid: props.item.rowid, odrno: props.item.odrno, reason: ST.ADMIN.ORDER.ADMIN_CANCEL }
        isOk &&
          actions.doInsert(API.PAYMENT_CANCEL, value, null).then(({ code, result }) => {
            setLoaded(false)
            doReload()
          })
      }
    })
  }

  const onClickDetail = () => {
    setDetail(!detail)
  }

  // const keys = data ? Object.keys(data).map(key => key) : null;
  return (
    <StyledObject className={'ad-payment'}>
      <div className={'p-bg'} onClick={(e) => onClick('cancel', e)} />
      <div className={'p-frame'}>
        <div className={'p-head'}>
          <p className={'title'}>{ST.ADMIN.ORDER.PAYMENT_DETAIL}</p>
          <Svg className={'cancel right'} icon={'cancel'} onClick={(e) => onClick('cancel', e)} />
        </div>
        <ul className={'p-body'}>
          {list && (
            <React.Fragment>
              {list.map((item, i) => {
                let { key, title, type, value, url = null, child = null } = item
                const color = value === 'paid' ? 'green' : value === 'ready' ? '' : 'red'
                return (
                  <li key={i}>
                    <span className={'p-sl'}>{title}</span>
                    {key === 'status' ? (
                      <span className={cx('p-sr status', type, color)}>{child[value]}</span>
                    ) : (
                      <span className={cx('p-sr', type)} url={url} onClick={url ? (e) => onClickLink(url, type, e) : null}>
                        {value}
                      </span>
                    )}
                    {key === 'status' && status === 'cancelled' && (
                      <span className={'cancel-dtl'} onClick={onClickDetail}>
                        {ST.ADMIN.ORDER.CANCEL_DTL}
                      </span>
                    )}
                  </li>
                )
              })}
              {status === 'paid' && (
                <li className={'cancel'}>
                  <span className={'p-sl'}></span>
                  <span className={cx('p-sr cancel')}>
                    <span className={'p-button cancel'} onClick={(e) => onClickCancel()}>
                      {ST.ADMIN.ORDER.CANCEL}
                    </span>
                  </span>
                </li>
              )}
              {detail &&
                cancel &&
                cancel.map((item, i) => {
                  const { title, url, type, value, links = null } = item
                  return (
                    <li key={i} className={'li-cancel'}>
                      <span className={'p-sl p-mt'}>{title}</span>
                      {/* <span className={cx('p-sr', type)} url={url && url} onClick={url ? (e) => onClickLink(url, type, e) : null}>{value}</span> */}
                      {/* {clist && <span className={'p-sr'}>
                {clist.map(b => {
                  return <span className={'p-child'}>{`${b.title} : ${b.value}`}</span>
                })}
              </span>} */}
                      {links && (
                        <span className={'p-sr'}>
                          {links.map((b, j) => {
                            return <span key={j} className={'p-link'} onClick={(e) => window.open(b)}>{`${ST.ADMIN.ORDER.LINK_URL}`}</span>
                          })}
                        </span>
                      )}
                      {!links && (
                        <span className={cx('p-sr', type)} url={url} onClick={url ? (e) => onClickLink(url, type, e) : null}>
                          {value}
                        </span>
                      )}
                    </li>
                  )
                })}
            </React.Fragment>
          )}
          {!loaded && <Loading type='ring' />}
        </ul>
        <div className={'p-foot'}>
          <Button
            className={'gd-gray sm left iamport'}
            icon={'link'}
            title={ST.ADMIN.ORDER.LINK_IAMPORT}
            onClick={(e) => window.open(URL.IAMPORT_PAYMENT)}
          />
          <Button className={'right'} icon={'exit'} title={ST.CLOSE} onClick={(e) => onClick('cancel', e)} />
        </div>
      </div>
    </StyledObject>
  )
}

const Line = ({ title, value, onClick = null, className = '' }) => {
  return (
    <div className={cx('p-line', className)}>
      <p className={'tit'}>{title}</p>
      <p className={cx('val', onClick && 'click')} onClick={onClick || null}>
        {value}
      </p>
    </div>
  )
}
