import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Loading } from 'object/src';
import { URL, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.paycomp { ${cs.pos.relative} ${cs.m.center()} ${cs.size.full} ${cs.max.wlg} ${cs.font.md} ${cs.noliststyle}
    ${cs.noselect} ${cs.disp.block} ${cs.h.calc('100vh - 100px')}

    .pc-body { ${cs.h.fit} ${cs.bg.white} ${cs.w.full} ${cs.m.b30} ${cs.p.a30} ${cs.align.ycenter} }

    .d-status { ${cs.font.center} ${cs.m.center()} ${cs.m.v50} }
    .d-line { ${cs.border.bottom} ${cs.border.width(2)} ${cs.border.gray} ${cs.m.b20} }
  }
}`;

const API = URL.API.PAGE;
const Paycomp = (props) => {
  // const [loaded, setLoaded] = useState(false);
  // const [data, setData] = useState(null);
  // const [company, setCompany] = useState(null);
  // const [status, setStatus] = useState('S');
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(ST.PORDER.PROGRESS);

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = () => {
    // const { rowid } = actions.getParam();

    // https://www.myservice.com/paycomp?imp_uid=imp_286679515820&merchant_uid=ODR1605590678999 redirect
    const { search } = window.location;
    const items = search && search.replace("?", "").split("&");

    let json = {};
    items && items.map(a => {
      const temps = a.split("=");
      if (temps && temps.length === 2) json[temps[0]] = temps[1];
      return null;
    });

    if (json.merchant_uid) {
      const value = { rowid: json.merchant_uid.replace('ODR', ''), status: '2', mid: json.merchant_uid, odrno: json.imp_uid, msg: ST.PORDER.SUCCESS, ok: true };
      actions.doUpdate(API.ORDER, value).then(({ code, result }) => {
        if (code === CODE.SUCCESS) {
          setLabel(ST.PORDER.SUCCESS);
          setTimeout(() => {
            actions.go(URL.MYPAGE, { page: 'history' });
            setLoading(false);
          }, 1000);
        } else {
          setLoading(false);
        }
      });
    }

    // actions.doSelect(API.ORDER, { impuid: json.imp_uid }, true).then(({ result, company }) => {
    //   setData(result);
    //   setCompany(company);
    //   setLoaded(true);
    //   setLoading(false);
    // });
  }

  // if (!loaded || !data || !company) return null;

  return <StyledObject className={cx('paycomp')}>
    <div className={'pc-body'}>
      <div className={'d-line'} />
      <div className={'d-status'}>
        <Button className={'status orange xl'} icon={'credit'} title={label} />
      </div>
      <div className={'d-line'} />
    </div>
    {loading && <Loading className={'fixed'} type='ring' />}
  </StyledObject >
};

export default Paycomp;