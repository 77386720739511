import React, { useEffect, useState } from 'react';
import style from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Editbox, Button, Util, TabButton, Formgroup, Svg, Error } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
// import { GuideBox } from './Webset';
// import ReactDOMServer from "react-dom/server";

const StyledObject = style.div`{
  .p-line { ${cs.w.full} ${cs.border.top} ${cs.border.darkgray} ${cs.h.get(1)} ${cs.max.wmd} ${cs.align.bottom} }
  .mobile-noti { ${cs.disp.none} }

  &.ad-set { ${cs.bg.trans}
    .tab-grp { ${cs.m.t30} ${cs.p.l20} }
    .hp-frame { ${cs.p.a20} }
  }

  &.sect {
    ${cs.m.v20} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wmd} ${cs.w.full}
    .edit-box { ${cs.m.b10} ${cs.m.t3} .guide { ${cs.font.xs} ${cs.font.lightprimary} ${cs.m.t3} } }
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .sec-box { ${cs.p.a10} }
    p.noti { ${cs.font.sm} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} ${cs.font.line(16)}
      ${cs.font.prewrap}
      &.hover:hover { ${cs.font.underline} ${cs.mouse.pointer} } 
    }
    .g-btn { ${cs.align.rtop} ${cs.top(0)} }
  }

  &.mset-def {
    ${cs.max.wlg}
    .sec-box {
      ${cs.max.wlg}
      .d-pass { ${cs.pos.relative} 
        .svg-icon { ${cs.align.rtop} ${cs.top(20)} }
      }
    }

    .p-noti { ${cs.font.yellow} ${cs.font.md} ${cs.m.t20} ${cs.font.prewrap} ${cs.font.line(18)} }
    .p-guide { }
  }

  &.mset-email, &.mset-pass, &.mset-alarm {
    .sec-box { ${cs.pos.relative} ${cs.p.bottom(60)} & > .button { ${cs.right(10)} } }
    .label { ${cs.p.l20} ${cs.font.lightgray} ${cs.font.md} }
    p.noti { ${cs.m.t20} }

    .prev-frame { ${cs.pos.relative} ${cs.p.a10}
      p.noti { ${cs.font.red} ${cs.align.lbottom} ${cs.bottom(20)} ${cs.left(20)} }
      & > .button { ${cs.bottom(20)} ${cs.right(20)} }
    }

    .timebar { ${cs.h.get(40)} ${cs.disp.block}
      .on-off { ${cs.m.r20} ${cs.float.left} ${cs.m.t3} }
      .dash { ${cs.font.gray} ${cs.p.h10} }
    }
  }

  &.mset-order {
    .sec-box { ${cs.pos.relative} ${cs.p.bottom(60)} & > .button { ${cs.right(10)} } }
    .label { ${cs.p.l20} ${cs.font.lightgray} ${cs.font.md} }

    .prev-frame { ${cs.pos.relative} ${cs.p.a10}
      p.noti { ${cs.font.red} ${cs.align.lbottom} ${cs.bottom(20)} ${cs.left(20)} }
      & > .button { ${cs.bottom(20)} ${cs.right(20)} }
    }
  }

  &.guide-box {
    ${cs.w.full} ${cs.max.w(400)} ${cs.max.h(600)} ${cs.z.popup}
    ${cs.bg.lightgray} ${cs.border.radius(10)} ${cs.border.lightgray} ${cs.border.shadow()}
    ${cs.font.dark} ${cs.font.md} ${cs.align.rtop} ${cs.p.a20} ${cs.p.b50} ${cs.right(20)}

    .g-frame {
      ${cs.over.hidden} ${cs.over.yauto} ${cs.scrollbar.t1} ${cs.font.prewrap} ${cs.font.line(20)}
      .title { ${cs.font.lg} ${cs.font.thickbold} ${cs.m.b20} }
      .cont { ${cs.p.a10} }
      .text { ${cs.p.h5} ${cs.font.sm} ${cs.m.t10} }
      .url { ${cs.m.v2} ${cs.font.primaryhover} ${cs.font.underline} ${cs.mouse.pointer}
        ${cs.p.l10} ${cs.m.b5}
        &:hover { ${cs.font.primary} } 
      }
    }
  }

  @media screen and (max-width : 600px) {
    .mobile-noti { ${cs.disp.block} ${cs.bg.alphablack} ${cs.w.full} ${cs.align.top} 
      ${cs.left(0)} ${cs.font.yellow} ${cs.p.a10}
    }
  }

}`;

const STM = ST.ADMIN.MAILER;
const API = URL.API.ADMIN;

const Setting = (props) => {
  const [refresh, setRefresh] = useState(new Date());
  const [tab, setTab] = useState('alarm');

  const doReload = (code) => {
    Util.showAlert(props, code);
    setRefresh(new Date());
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
  }

  const TABS = [
    { id: 'info', title: STM.DEFULTSET },
    { id: 'email', title: STM.EMAIL.TITLE },
    { id: 'pass', title: STM.PASS.TITLE },
    { id: 'order', title: STM.ORDER.TITLE },
    { id: 'alarm', title: STM.ALARM.TITLE },
  ]

  const issys = Util.isSystemAdmin();
  if (!issys) return <Error className={'t3'} title={ST.LOGINSYSADMIN} />

  return <StyledObject className={cx('ad-set')}>
    <TabButton className={"tab-grp"} size={'md'} onClick={onClick}
      list={TABS} select={tab} color={'gd-gray'} />
    {tab === 'info' && <div className={cx('hp-frame', tab)}>
      <SetAppinfo doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'email' && <div className={cx('hp-frame', tab)}>
      <SetMailer doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'pass' && <div className={cx('hp-frame', tab)}>
      <SetPass doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'order' && <div className={cx('hp-frame', tab)}>
      <SetOrder doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'alarm' && <div className={cx('hp-frame', tab)}>
      <SetAlarm doReload={doReload} refresh={refresh} />
    </div>}
  </StyledObject>
};

export default Setting;

const SetAppinfo = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [rowid, setRowid] = useState(null);
  const [noti, setNoti] = useState(null);
  const [show, setShow] = useState(false);
  // const [guide, setGuide] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'mailer', skey: 'sender' }).then(({ result }) => {
      const { sval, cfgid } = result && result.length > 0 && result[0];
      const temps = sval && JSON.parse(sval);
      setData(temps || {});
      setRowid(cfgid);
    });
    return () => { }
  }, [props.refresh]);


  const checkValidat = (obj = null, regexp = /^[0-9a-zA-Z.-_]*$/gi, notify = '') => {
    if (!obj) {
      console.error('object error');
      return false;
    }

    const isValidate = (v, r) => r.test(v); // ok=true

    const value = obj.getValue();
    if (!isValidate(value, regexp)) {
      obj.showNoti(notify);
      return false;
    }
    return true;
  }

  const onSave = (eid, e) => {
    if (!refs) return;

    // 메일 서비스 입력오류 체크
    if (!checkValidat(refs.service, /^[0-9a-zA-Z.-_]*$/gi, STM.SERVICE_N)) return;

    // 호스트 입력오류 체크
    if (!checkValidat(refs.host, /^[0-9a-zA-Z.]*$/gi, STM.HOST_N)) return;

    // 포트 입력오류 체크
    if (!checkValidat(refs.port, /^[0-9]*$/gi, STM.PORT_N)) return;

    // 사용자아이디 입력오류 체크
    if (!Util.isEmail(refs.user.getValue())) {
      refs.user.showNoti(STM.USER_N);
      return;
    };

    // 패스워드 입력오류 체크
    if (Util.inChar(refs.pass.getValue(), /"/)) {
      refs.pass.showNoti(STM.PWD_N);
      return;
    }

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return;

    //vyfxeegjgbdtjaon
    let json = {};
    Object.keys(refs).map(key => {
      json[key] = refs[key].getValue();
      return null;
    });

    const value = JSON.stringify(json);
    actions.doInsert(API.SETTING, { rowid: rowid || null, stag: 'mailer', skey: 'sender', sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onTest = (eid, e) => {
    actions.doInsert(API.MAIL_SEND, { to: data.user, text: STM.TEST }).then(({ code, result }) => {
      setNoti(STM.TEST_EMAIL(data.user));
    });
  }

  // service = 'gmail', host = 'smtp.gmail.com', port = 587, secure = false, user = 'test', pass = 'test'
  if (!data) return null;
  const { service = '', host = '', port = '', user = '', pass = '' } = data;
  return <StyledObject className={cx('mset-def sect')}>
    <p className={'title'}>{STM.DEFULTSET}</p>
    {/* <Button className={'gd-gray top right'} title={'GMAIL GUIDE'} onClick={() => setGuide(!guide)} /> */}
    {/* <GuideBox className={'right top'} list={GMAIL_HELP} /> */}
    <Button className={'md gd-gray g-btn'} title={ST.HELPER} onClick={() => window.open(`${URL.ADMIN.GUIDE}/gmail`)} />
    <div className={'sec-box'}>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.service = ref }} className={"service"} value={service}
          label={STM.SERVICE} validate={true} helper={STM.SERVICE_H} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.host = ref }} className={"host"} value={host}
          label={STM.HOST} validate={true} helper={STM.HOST_H} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.port = ref }} className={"port"} value={port} type="number"
          label={STM.PORT} validate={true} helper={STM.PORT_H} onChange={() => setModified(true)} />
      </Formgroup>

      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.user = ref }} className={"user"} value={user}
          label={STM.USER} validate={true} helper={STM.USER_H} onChange={() => setModified(true)} />
        <div className={'d-pass'}>
          <Editbox ref={(ref) => { refs.pass = ref }} className={"pass"} value={pass} type={show ? "text" : "password"}
            label={STM.PWD} validate={true} helper={STM.PWD_H} onChange={() => setModified(true)} />
          <Svg className={'md'} icon={'show'} onClick={() => setShow(!show)}></Svg>
        </div>
      </Formgroup>
      {noti && <div className={'p-noti'}><p>{noti}</p></div>}
    </div>

    {/* {guide && <Guidebox onClick={() => setGuide(false)} />} */}

    <Button className={'green bottom left'} title={'EMAIL TEST'} onClick={onTest} disabled={!rowid} />
    <Button className={'red bottom right'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetMailer = (props) => {
  var refs = {};
  const stag = 'mailer', skey = 'mail';
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [rowid, setRowid] = useState(null);
  const [noti, setNoti] = useState(null);
  const [company, setCompany] = useState(null);
  const [mailer, setMailer] = useState(null);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setCompany(json);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'mailer', skey: 'sender' }).then(({ result }) => {
      const { sval } = result && result.length > 0 && result[0];
      const temps = sval && JSON.parse(sval);
      setMailer(temps || {});
    });

    actions.doSelect(URL.API.APPINFO, { stag, skey }).then(({ result }) => {
      const { sval, cfgid } = result && result.length > 0 && result[0];
      const temps = sval && Util.parseJson(sval);
      setData(temps || {});
      setRowid(cfgid);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return;

    let json = {};
    Object.keys(refs).map(key => {
      json[key] = refs[key].getValue();
      return null;
    });

    const value = JSON.stringify(json);
    actions.doInsert(API.SETTING, { rowid: rowid || null, stag, skey, sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onTest = (eid, e) => {
    if (!(mailer && mailer.host)) {
      setNoti(STM.MEIL_SET_ERROR);
      return;
    }

    // const html = ReactDOMServer.renderToString(<Mailbox data={{ ...company, ...data }} />);
    actions.doInsert(API.MAIL_SEND, { to: mailer.user, form: 'mail' }).then(({ code, result }) => {
      setNoti(STM.TEST_EMAIL(mailer.user));
    });
  }

  const onImport = () => {
    const { DEFAULT } = STM.EMAIL;
    const { name = '' } = company;
    const temp = { subject: DEFAULT.subject, title: DEFAULT.title, text: DEFAULT.text(name), comment: DEFAULT.comment(name) };
    setData(temp);
    setModified(true);
  }

  const onChange = (name, value) => {
    let temp = { ...data };
    temp[name] = value;
    setData(temp);
    setModified(true);
  }

  if (!data || !company) return null;
  const { subject = '', title = '', text = '', comment = '' } = data;
  return <StyledObject className={cx('mset-email sect')}>
    <p className={'title'}>{STM.EMAIL.TITLE}</p>
    <p className={'label'}>{STM.EMAIL.GUIDE}</p>
    <Button className={'gd-gray top right'} title={ST.ADMIN.MAILER.IMPORT} onClick={onImport} />

    <div className={'sec-box'}>
      <Editbox ref={(ref) => { refs.subject = ref }} value={subject} label={STM.EMAIL.SUBJECT} validate={true}
        maxLength={20} helper={ST.MAXLEN(STM.EMAIL.SUBJECT, 20)} onChange={(v) => onChange('subject', v)} />
      <Editbox ref={(ref) => { refs.title = ref }} value={title} label={STM.EMAIL.LABEL} validate={true}
        maxLength={10} helper={ST.MAXLEN(STM.EMAIL.LABEL, 10)} onChange={(v) => onChange('title', v)} />
      <Editbox ref={(ref) => { refs.text = ref }} value={text} label={STM.EMAIL.TEXT} validate={true}
        maxLength={500} helper={ST.MAXLEN(STM.EMAIL.TEXT, 500)} onChange={(v) => onChange('text', v)}
        multi={true} maxheight={300} minheight={80} height={100} />
      <Editbox ref={(ref) => { refs.comment = ref }} value={comment} label={STM.EMAIL.COMMENT}
        maxLength={100} helper={ST.MAXLEN(STM.EMAIL.COMMENT, 100)} onChange={(v) => onChange('comment', v)}
        multi={true} maxheight={200} minheight={60} height={80} />

      <Button className={'red bottom right'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
    </div>

    <p className={'title'}>{STM.PREVIEW}</p>
    <div className={'prev-frame'}>
      <Mailbox data={{ ...company, ...data }} />
      {noti && <p className={'noti'}>{noti}</p>}
      <Button className={'green bottom right'} title={'EMAIL TEST'} onClick={onTest} />
      {/* <div>
        {ReactDOMServer.renderToString(<Mailbox data={{ ...company, ...data }} />)}
      </div> */}
    </div>
  </StyledObject >
}


const SetPass = (props) => {
  var refs = {};
  const stag = 'mailer', skey = 'pass';
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [rowid, setRowid] = useState(null);
  const [noti, setNoti] = useState(null);
  const [company, setCompany] = useState(null);
  const [mailer, setMailer] = useState(null);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setCompany(json);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'mailer', skey: 'sender' }).then(({ result }) => {
      const { sval } = result && result.length > 0 && result[0];
      const temps = sval && JSON.parse(sval);
      setMailer(temps || {});
    });

    actions.doSelect(URL.API.APPINFO, { stag, skey }).then(({ result }) => {
      const { sval, cfgid } = result && result.length > 0 && result[0];
      const temps = sval && Util.parseJson(sval);
      setData(temps || {});
      setRowid(cfgid);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return;

    let json = {};
    Object.keys(refs).map(key => {
      json[key] = refs[key].getValue();
      return null;
    });

    const value = JSON.stringify(json);
    actions.doInsert(API.SETTING, { rowid: rowid || null, stag, skey, sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onTest = (eid, e) => {
    if (!(mailer && mailer.host)) {
      setNoti(STM.MEIL_SET_ERROR);
      return;
    }

    // const html = ReactDOMServer.renderToString(<Mailbox data={{ ...company, ...data }} />);
    actions.doInsert(API.MAIL_SEND, { to: mailer.user, form: 'pass' }).then(({ code, result }) => {
      setNoti(STM.TEST_EMAIL(mailer.user));
    });
  }

  const onImport = () => {
    const { DEFAULT } = STM.PASS;
    const { name = '' } = company;
    const temp = { subject: DEFAULT.subject, title: DEFAULT.title, text: DEFAULT.text(name), comment: DEFAULT.comment(name) };
    setData(temp);
    setModified(true);
  }

  const onChange = (name, value) => {
    let temp = { ...data };
    temp[name] = value;
    setData(temp);
    setModified(true);
  }

  if (!data || !company) return null;

  const { subject = '', title = '', text = '', comment = '' } = data;

  return <StyledObject className={cx('mset-pass sect')}>
    <p className={'title'}>{STM.PASS.TITLE}</p>
    <p className={'label'}>{STM.PASS.GUIDE}</p>
    <Button className={'gd-gray top right'} title={ST.ADMIN.MAILER.IMPORT} onClick={onImport} />

    <div className={'sec-box'}>
      <Editbox ref={(ref) => { refs.subject = ref }} value={subject} label={STM.PASS.SUBJECT} validate={true}
        maxLength={20} helper={ST.MAXLEN(STM.PASS.SUBJECT, 20)} onChange={(v) => onChange('subject', v)} />
      <Editbox ref={(ref) => { refs.title = ref }} value={title} label={STM.PASS.LABEL} validate={true}
        maxLength={10} helper={ST.MAXLEN(STM.PASS.LABEL, 10)} onChange={(v) => onChange('title', v)} />
      <Editbox ref={(ref) => { refs.text = ref }} value={text} label={STM.PASS.TEXT} validate={true}
        maxLength={500} helper={ST.MAXLEN(STM.PASS.TEXT, 500)} onChange={(v) => onChange('text', v)}
        multi={true} maxheight={300} minheight={80} height={100} />
      <Editbox ref={(ref) => { refs.comment = ref }} value={comment} label={STM.PASS.COMMENT}
        maxLength={100} helper={ST.MAXLEN(STM.PASS.COMMENT, 100)} onChange={(v) => onChange('comment', v)}
        multi={true} maxheight={200} minheight={60} height={80} />

      <Button className={'red bottom right'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
    </div>

    <p className={'title'}>{STM.PREVIEW}</p>
    <div className={'prev-frame'}>
      <Mailbox data={{ ...company, ...data }} />
      {noti && <p className={'noti'}>{noti}</p>}
      <Button className={'green bottom right'} title={'EMAIL TEST'} onClick={onTest} />
    </div>
  </StyledObject >
}

const Mailbox = ({ data }) => {
  if (!data) return;

  const styled = {
    frame: {
      'background': '#ffffff',
      'width': '100%',
      'height': '100%',
      'padding': '30px',
      'color': '#000000',
      'minHeight': 'fit-content'
    },
    box: {
      'maxWidth': '400px',
      'width': '100%',
      'border': 'solid 1px #dedede',
      'borderRadius': '5px',
      'padding': '20px 20px 10px 20px',
      'margin': '0px auto 0px auto',
      'minHeight': '200px',
      'marginTop': '50px',
      'marginBottom': '50px',
      'whiteSpace': 'pre-wrap',
      'wordBreak': 'break-all'
    },
    company: {
      'textAlign': 'center',
      'fontSize': '22px',
      'fontWeight': '550',
      'marginTop': '20px',
      'color': '#da3e00',
    },
    subject: {
      'fontSize': '18px',
      'textAlign': 'center',
      'marginTop': '20px',
      'marginBottom': '20px',
      'borderBottom': 'solid 1px #dedede',
      'fontWeight': '600',
      'paddingBottom': '5px'
    },
    text: {
      'fontSize': '14px',
      'borderBottom': 'solid 1px #dedede',
      'paddingBottom': '20px',
      'textAlign': 'left',
      'whiteSpace': 'pre-wrap',
      'wordBreak': 'break-all'
    },
    code: {
      'paddingTop': '30px',
      'paddingBottom': '30px',
      'fontSize': '16px',
      'color': '#ff692d',
      'fontWeight': '600',
      'textAlign': 'center',
    },
    comment: {
      'fontSize': '12px',
      'color': '#909090',
      'textAlign': 'center',
      'paddingTop': '5px',
    },
    foot: {
      'fontSize': '12px',
      'color': '#909090',
      'paddingTop': '20px',
      'textAlign': 'center',
    }
  };

  const { name, subject, text, title, comment, name_eng, year } = data;
  return <div style={{ ...styled.frame }}>
    <div style={{ ...styled.box }}>
      <div style={{ ...styled.company }}>
        {name && <p>{name}</p>}
      </div>
      <div style={{ ...styled.subject }}>
        {subject && <p>{subject}</p>}
      </div>
      <div style={{ ...styled.text }}>
        {text && <p>{text}</p>}
        {text && <p style={{ ...styled.code }}>{`${title} : 12345`}</p>}
      </div>
      <div style={{ ...styled.comment }}>
        {comment && <p>{comment}</p>}
      </div>
      <div style={{ ...styled.foot }}>
        <span>{`${ST.COPYRIGHT(name_eng || name, year, true)}`}</span>
      </div>
    </div>
    <div className={'mobile-noti'}><span>{ST.MOBILE_NOTI}</span></div>
  </div>
}

const SetAlarm = (props) => {
  var refs = {};
  const stag = 'mailer', skey = 'alarm';
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [rowid, setRowid] = useState(null);
  const [noti, setNoti] = useState(null);
  const [company, setCompany] = useState(null);
  const [mailer, setMailer] = useState(null);
  const [guide, setGuide] = useState('');
  const url = window.location.origin + URL.ADMIN.ORDER;

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setCompany(json);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'mailer', skey: 'sender' }).then(({ result }) => {
      const { sval } = result && result.length > 0 && result[0];
      const temps = sval && JSON.parse(sval);
      setMailer(temps || {});
    });

    actions.doSelect(URL.API.APPINFO, { stag, skey }).then(({ result }) => {
      const { sval, cfgid } = result && result.length > 0 && result[0];
      const temps = sval && Util.parseJson(sval);
      setData({ url, ...temps } || { url });
      setRowid(cfgid);
    });
    return () => { }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return;

    let json = { cycle: '5', ...data };
    Object.keys(refs).map(key => {
      json[key] = refs[key].getValue();
      return null;
    });

    json.email = json.email && json.email.replace(/\n/gi, '').replace(/ /gi, '').replace(/;/gi, ';\n');
    const temps = json.email.split(';');
    const count = temps.length;
    if (count > 5 + 1) {
      return refs.email.showNoti(STM.EMAIL_LIMIT);
    }

    let t = '';
    if (json.from) {
      t = json.from.split(':');
      json.from = `${t[0].padStart(2, "0")}:${t[1].padStart(2, "0")}`;
    }

    if (json.to) {
      t = json.to.split(':');
      json.to = `${t[0].padStart(2, "0")}:${t[1].padStart(2, "0")}`;
    }

    if (json.time === 'true' && (!json.from || !json.to)) {
      return setGuide(STM.TIME_SET);
    }

    const value = JSON.stringify(json);
    actions.doInsert(API.SETTING, { rowid: rowid || null, stag, skey, sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onTest = (eid, e) => {
    if (!(mailer && mailer.host)) {
      setNoti(STM.MEIL_SET_ERROR);
      return;
    }

    // const html = ReactDOMServer.renderToString(<Mailbox data={{ ...company, ...data }} />);
    actions.doInsert(API.MAIL_SEND, { to: mailer.user, form: 'pass' }).then(({ code, result }) => {
      setNoti(STM.TEST_EMAIL(mailer.user));
    });
  }

  const onImport = () => {
    const { DEFAULT } = STM.ALARM;

    const temp = { subject: DEFAULT.subject, title: DEFAULT.title, text: DEFAULT.text, email: `${company.email};` };
    setData(temp);
    setModified(true);
  }

  const onChange = (name, value) => {
    let temp = { ...data };
    temp[name] = value;
    setData(temp);
    setModified(true);
  }

  if (!data || !company) return null;

  const { subject = '', text = '', email = '', cycle = '5', from = '', to = '', time = 'false' } = data;
  const timelist = [
    { id: '5', title: STM.ALARM.STIME },
    { id: '10', title: STM.ALARM.DTIME },
    { id: '20', title: STM.ALARM.MTIME },
    { id: '30', title: STM.ALARM.LTIME }
  ];

  return <StyledObject className={cx('mset-alarm sect')}>
    <p className={'title'}>{STM.ALARM.TITLE}</p>
    <p className={'label'}>{STM.ALARM.GUIDE}</p>
    <Button className={'gd-gray top right'} title={ST.ADMIN.MAILER.IMPORT} onClick={onImport} />

    <div className={'sec-box'}>
      <Editbox ref={(ref) => { refs.subject = ref }} value={subject} label={STM.ALARM.SUBJECT} validate={true}
        maxLength={20} helper={ST.MAXLEN(STM.ALARM.SUBJECT, 20)} onChange={(v) => onChange('subject', v)} />
      <Editbox ref={(ref) => { refs.text = ref }} value={text} label={STM.ALARM.TEXT} validate={true}
        maxLength={100} helper={ST.MAXLEN(STM.ALARM.TEXT, 100)} onChange={(v) => onChange('text', v)}
        multi={true} maxheight={200} minheight={80} height={100} />
      <p className={'noti'}>{STM.ALARM.CYCLE}</p>
      <TabButton size={'md'} list={timelist} onClick={(v) => onChange('cycle', v)} select={cycle} />

      <p className={'noti'}>{STM.ALARM.EMAIL}</p>
      <Editbox ref={(ref) => { refs.email = ref }} value={email} validate={true}
        maxLength={200} guide={STM.ALARM.EMAIL} onChange={(v) => onChange('email', v)}
        multi={true} maxheight={200} minheight={80} height={100} />

      <p className={'noti'}>{STM.ALARM.OFFTIME}</p>
      <div className={'timebar'}>
        <TabButton className={'on-off'} size={'sm'} list={[{ id: 'false', title: STM.ALARM.TIMEOFF }, { id: 'true', title: STM.ALARM.TIMEON }]}
          onClick={(v) => onChange('time', v)} select={time} />

        {String(time) === 'true' && <React.Fragment>
          <Timebox time={from || 0} onChange={(h, m) => onChange('from', `${h}:${m}`)} />
          <span className={'dash'}>{STM.ALARM.FROM}</span>
          <Timebox time={to || 0} onChange={(h, m) => onChange('to', `${h}:${m}`)} />
          <span className={'dash'}>{`${STM.ALARM.TO}`}</span>
        </React.Fragment>}
      </div>
      {guide && <p className={'noti'}>{guide}</p>}

      <Button className={'red bottom right'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
    </div>

    <p className={'title'}>{STM.PREVIEW}</p>
    <div className={'prev-frame'}>
      <Alarmbox data={{ ...company, ...data }} />
      {noti && <p className={'noti'}>{noti}</p>}
      <Button className={'green bottom right'} title={'EMAIL TEST'} onClick={onTest} />
    </div>
  </StyledObject >
}


const StyledComp = style.div`{
  &.time-box { ${cs.disp.inblock} ${cs.font.line(20)}
    .e-hour, .e-min { ${cs.disp.inblock} ${cs.w.get(60)} ${cs.font.right}
      &.e-min { }
    }
    & > span { ${cs.disp.inblock} ${cs.w.fit} ${cs.font.gray} ${cs.font.sm} ${cs.p.h5} }
  }
}`;
const Timebox = (props) => {
  var refs = {};
  const [hour, setHour] = useState(props.hour);
  const [min, setMin] = useState(props.min);
  // const [sec, setSec] = useState(0);

  useEffect(() => {
    if (!props.time) return;

    const temps = props.time.split(":");
    setHour((temps && temps[0]) || 0);
    setMin((temps && temps[1]) || 0);
    return () => {
    }
  }, [props.time]);

  const onChange = (eid, v) => {
    if (eid === 'hour') {
      setHour(v);
      props.onChange && props.onChange(v, min);
    } else if (eid === 'min') {
      setMin(v);
      props.onChange && props.onChange(hour, v);
    }
  }

  // const istime = (String(props.istime) === 'true');

  return <StyledComp className={'time-box'}>
    <Editbox className={'e-hour'} ref={(ref) => { refs.hour = ref }} value={hour} validate={true}
      maxLength={2} guide={'0~23'} onChange={(v) => onChange('hour', v)} type={'number'} min={0} max={23} />
    <span>{":"}</span>
    <Editbox className={'e-min'} ref={(ref) => { refs.min = ref }} value={min} validate={true}
      maxLength={2} guide={'0~59'} onChange={(v) => onChange('min', v)} type={'number'} min={0} max={59} />
  </StyledComp>
}

const Alarmbox = ({ data }) => {
  if (!data) return;

  const styled = {
    frame: {
      'background': '#ffffff',
      'width': '100%',
      'height': '100%',
      'padding': '30px',
      'color': '#000000',
      'minHeight': 'fit-content'
    },
    box: {
      'maxWidth': '400px',
      'width': '100%',
      'border': 'solid 1px #dedede',
      'borderRadius': '5px',
      'padding': '20px 20px 10px 20px',
      'margin': '0px auto 0px auto',
      'minHeight': '200px',
      'marginTop': '50px',
      'marginBottom': '50px',
      'whiteSpace': 'pre-wrap',
      'wordBreak': 'break-all'
    },
    company: {
      'textAlign': 'center',
      'fontSize': '22px',
      'fontWeight': '550',
      'marginTop': '20px',
      'color': '#da3e00',
    },
    subject: {
      'fontSize': '18px',
      'textAlign': 'center',
      'marginTop': '20px',
      'marginBottom': '20px',
      'borderBottom': 'solid 1px #dedede',
      'fontWeight': '600',
      'paddingBottom': '5px'
    },
    text: {
      'fontSize': '14px',
      'borderBottom': 'solid 1px #dedede',
      'paddingBottom': '20px',
      'textAlign': 'center',
      'whiteSpace': 'pre-wrap',
      'cursor': 'pointer',
      'textDecoration': 'underline',
      'color': '#0050ab',
      'wordBreak': 'break-all'
    },
    code: {
      'paddingTop': '30px',
      'paddingBottom': '30px',
      'fontSize': '16px',
      'color': '#ff692d',
      'fontWeight': '600',
      'textAlign': 'center',
    },
    comment: {
      'fontSize': '12px',
      'color': '#909090',
      'textAlign': 'center',
      'paddingTop': '5px',
    },
    foot: {
      'fontSize': '12px',
      'color': '#909090',
      'paddingTop': '20px',
      'textAlign': 'center',
    }
  };

  const { name, subject, text, name_eng, year, url } = data;
  return <div style={{ ...styled.frame }}>
    <div style={{ ...styled.box }}>
      <div style={{ ...styled.company }}>
        {name && <p>{name}</p>}
      </div>
      <div style={{ ...styled.subject }}>
        {subject && <p>{subject}</p>}
      </div>
      <div style={{ ...styled.text }}>
        {url ? <a style={{ color: '#0050ab' }} href={url}>{text}</a> : <p style={{ color: '#0050ab' }}>{text}</p>}
      </div>
      <div style={{ ...styled.foot }}>
        <span>{`${ST.COPYRIGHT(name_eng || name, year, true)}`}</span>
      </div>
    </div>
    <div className={'mobile-noti'}><span>{ST.MOBILE_NOTI}</span></div>
  </div>
}


const SetOrder = (props) => {
  var refs = {};
  const stag = 'mailer', skey = 'order';
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [rowid, setRowid] = useState(null);
  const [noti, setNoti] = useState(null);
  const [company, setCompany] = useState(null);
  const [mailer, setMailer] = useState(null);
  const orderlist = [{ title: '상품', price: 23000, count: 2 }];

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setCompany(json);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'mailer', skey: 'sender' }).then(({ result }) => {
      const { sval } = result && result.length > 0 && result[0];
      const temps = sval && JSON.parse(sval);
      setMailer(temps || {});
    });

    actions.doSelect(URL.API.APPINFO, { stag, skey }).then(({ result }) => {
      const { sval, cfgid } = result && result.length > 0 && result[0];
      const temps = sval && Util.parseJson(sval);
      setData(temps || {});
      setRowid(cfgid);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return;

    let json = {};
    Object.keys(refs).map(key => {
      json[key] = refs[key].getValue();
      return null;
    });

    const value = JSON.stringify(json);
    actions.doInsert(API.SETTING, { rowid: rowid || null, stag, skey, sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onTest = (eid, e) => {
    if (!(mailer && mailer.host)) {
      setNoti(STM.MEIL_SET_ERROR);
      return;
    }

    // const html = ReactDOMServer.renderToString(<Orderbox data={{ ...company, ...data }} list={orderlist} />);
    actions.doInsert(API.MAIL_SEND, { to: mailer.user, form: 'order' }).then(({ code, result }) => {
      setNoti(STM.TEST_EMAIL(mailer.user));
    });
  }

  const onImport = () => {
    const { DEFAULT } = STM.ORDER;
    const { name = '' } = company;
    const temp = { subject: DEFAULT.subject, title: DEFAULT.title, text: DEFAULT.text(name), comment: DEFAULT.comment(name) };
    setData(temp);
    setModified(true);
  }

  const onChange = (name, value) => {
    let temp = { ...data };
    temp[name] = value;
    setData(temp);
    setModified(true);
  }

  if (!data || !company) return null;

  const { subject = '', title = '', text = '', comment = '' } = data;

  return <StyledObject className={cx('mset-order sect')}>
    <p className={'title'}>{STM.ORDER.TITLE}</p>
    <p className={'label'}>{STM.ORDER.GUIDE}</p>
    <Button className={'gd-gray top right'} title={ST.ADMIN.MAILER.IMPORT} onClick={onImport} />

    <div className={'sec-box'}>
      <Editbox ref={(ref) => { refs.subject = ref }} value={subject} label={STM.ORDER.SUBJECT} validate={true}
        maxLength={20} helper={ST.MAXLEN(STM.ORDER.SUBJECT, 20)} onChange={(v) => onChange('subject', v)} />
      <Editbox ref={(ref) => { refs.title = ref }} value={title} label={STM.ORDER.LABEL} validate={true}
        maxLength={10} helper={ST.MAXLEN(STM.ORDER.LABEL, 10)} onChange={(v) => onChange('title', v)} />
      <Editbox ref={(ref) => { refs.text = ref }} value={text} label={STM.ORDER.TEXT} validate={true}
        maxLength={500} helper={ST.MAXLEN(STM.ORDER.TEXT, 500)} onChange={(v) => onChange('text', v)}
        multi={true} maxheight={300} minheight={80} height={100} />
      <Editbox ref={(ref) => { refs.comment = ref }} value={comment} label={STM.ORDER.COMMENT}
        maxLength={100} helper={ST.MAXLEN(STM.ORDER.COMMENT, 100)} onChange={(v) => onChange('comment', v)}
        multi={true} maxheight={200} minheight={60} height={80} />

      {/* {noti && <p className={'noti'}>{noti}</p>} */}

      <Button className={'red bottom right'} title={ST.SAVE} onClick={onSave} disabled={!modified} />
    </div>

    <p className={'title'}>{STM.PREVIEW}</p>
    <div className={'prev-frame'}>
      <div className={'mobile-noti'}><span>{ST.MOBILE_NOTI}</span></div>
      <Orderbox data={{ ...company, ...data }} list={orderlist} />
      {noti && <p className={'noti'}>{noti}</p>}
      <Button className={'green bottom right'} title={'EMAIL TEST'} onClick={onTest} />
    </div>
  </StyledObject >
}

const Orderbox = ({ data, list }) => {
  if (!data) return;

  const styled = {
    frame: {
      'background': '#ffffff',
      'width': '100%',
      'height': '100%',
      'padding': '30px',
      'color': '#000000',
      'minHeight': 'fit-content'
    },
    box: {
      'maxWidth': '600px',
      'width': '100%',
      'border': 'solid 1px #dedede',
      'borderRadius': '5px',
      'padding': '20px 20px 10px 20px',
      'margin': '0px auto 0px auto',
      'minHeight': '200px',
      'marginTop': '50px',
      'marginBottom': '50px',
      'whiteSpace': 'pre-wrap',
      'wordBreak': 'break-all'
    },
    company: {
      'textAlign': 'left',
      'fontSize': '22px',
      'fontWeight': '550',
      'marginTop': '10px',
      'marginBottom': '20px',
      'color': '#da3e00'
    },
    subject: {
      'fontSize': '18px',
      'textAlign': 'center',
      'marginTop': '20px',
      'marginBottom': '20px',
      'borderTop': '3px solid rgb(197 0 0)',
      'borderBottom': '3px solid rgb(197 0 0)',
      'fontWeight': '600',
      'padding': '20px 0 20px 0'
    },
    list: {
      'fontSize': '14px',
      'borderTop': 'solid 20px #dedede',
      'borderBottom': 'solid 20px #dedede',
      'paddingBottom': '20px',
      'textAlign': 'left',
      'whiteSpace': 'pre-wrap',
      'wordBreak': 'break-all',
      'padding': '20px 60px',
    },
    hbox: {
      'marginBottom': '30px',
    },
    hrow: {
      'marginTop': '20px',
      'padding': '10px',
      'borderTop': 'solid 3px #222',
      'borderBottom': 'solid 1px #aeaeae',
      'width': '100%',
      'fontWeight': '600',
      'textAlign': 'left',
      'fontSize': '16px',
    },
    hc1: {
      'width': 'calc(100% - 240px)',
      'display': 'inline-block',
    },
    hc2: {
      'width': '140px',
      'display': 'inline-block',
      'textAlign': 'center',
    },
    hc3: {
      'width': '100px',
      'display': 'inline-block',
      'textAlign': 'right',
    },
    row: {
      'padding': '10px',
      'borderBottom': 'solid 1px #dedede',
      'width': '100%',
      'fontWeight': '500',
      'textAlign': 'center',
      'fontSize': '12px',
    },
    c1: {
      'width': 'calc(100% - 240px)',
      'display': 'inline-block',
      'textAlign': 'left',
    },
    c2: {
      'width': '140px',
      'display': 'inline-block',
      'textAlign': 'center',
    },
    c3: {
      'width': '100px',
      'display': 'inline-block',
      'textAlign': 'right',
    },
    total: {
      'fontWeight': '600',
      'fontSize': '16px',
      'width': '50%',
      'padding': '10px 0',
      'textAlign': 'center',
    },
    text: {
      'fontSize': '14px',
      'borderBottom': 'solid 1px #dedede',
      'paddingBottom': '20px',
      'textAlign': 'left',
      'whiteSpace': 'pre-wrap',
      'wordBreak': 'break-all'
    },
    code: {
      'paddingTop': '30px',
      'paddingBottom': '30px',
      'fontSize': '16px',
      'color': '#ff692d',
      'fontWeight': '600',
      'textAlign': 'center',
    },
    comment: {
      'fontSize': '12px',
      'color': '#909090',
      'textAlign': 'center',
      'paddingTop': '5px',
    },
    foot: {
      'fontSize': '12px',
      'color': '#909090',
      'paddingTop': '20px',
      'textAlign': 'center',
    }
  };

  const { name, subject, text, comment, name_eng, year } = data;
  const total = list ? list.reduce((a, b) => (a + b.price), 0) : 0;
  return <div style={{ ...styled.frame }}>
    <div style={{ ...styled.box }}>
      <div style={{ ...styled.company }}>
        {name && <p>{name}</p>}
      </div>
      <div style={{ ...styled.subject }}>
        {subject && <p>{subject}</p>}
      </div>
      <div style={{ ...styled.text }}>
        {text && <p>{text}</p>}
      </div>
      <div style={{ ...styled.list }}>
        <div style={{ ...styled.hbox }}>
          <div style={{ ...styled.hrow }}>
            <span style={{ ...styled.hc1 }}>{ST.MYPAGE.PRODUCT}</span>
            <span style={{ ...styled.hc2 }}>{ST.MYPAGE.CSH_COUNT}</span>
            <span style={{ ...styled.hc3 }}>{ST.MYPAGE.CSH_PRICE}</span>
          </div>
          {list && list.map((item, i) => {
            return <div style={{ ...styled.row }} key={i}>
              <span style={{ ...styled.c1 }}>{item.title}</span>
              <span style={{ ...styled.c2 }}>{`${Util.commas(item.count)}${ST.COUNT}`}</span>
              <span style={{ ...styled.c3 }}>{`${Util.commas(item.price)}${ST.WON}`}</span>
            </div>
          })}
          <div style={{ ...styled.row }}>
            <span style={{ ...styled.c1, ...styled.total }}>{ST.MYPAGE.TOTAL}</span>
            <span style={{ ...styled.c3, ...styled.total }}>{`${Util.commas(total)}${ST.WON}`}</span>
          </div>
        </div>
      </div>
      <div style={{ ...styled.comment }}>
        {comment && <p>{comment}</p>}
      </div>
      <div style={{ ...styled.foot }}>
        <span>{`${ST.COPYRIGHT(name_eng || name, year, true)}`}</span>
      </div>
    </div>
  </div>
}

// const Guidebox = (props) => {
//   return <StyledObject className={'guide-box'}>
//     <div className={'g-frame'}>
//       <p className={'title'}>{STM.SET_GUIDE_TITLE}</p>
//       <p className={'cont'}>{STM.SET_GUIDE_TEXT}</p>
//       <p className={'text'}>{STM.SET_GUIDE_1}</p>
//       <p className={'url'} onClick={() => window.open(STM.GOOGLE_URL_1)}>{STM.GOOGLE_URL_1}</p>
//       <p className={'text'}>{STM.SET_GUIDE_2}</p>
//       <p className={'url'} onClick={() => window.open(STM.GOOGLE_URL_2)}>{STM.GOOGLE_URL_2}</p>
//     </div>

//     <Button className={'trans bottom right'} title={ST.CLOSE} onClick={props.onClick && props.onClick} />
//   </StyledObject>
// };