import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Nodata, Tablebox, Cardlist, Editbox, Postcode, Svg, Thumbbox, Loading } from 'object/src';
import { URL, EID, CODE } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import Menubar from './Menubar';
import moment from 'moment';
import { Util } from 'object/src/Utils';
import HistoryDetail from './HistoryDetail';

const StyledObject = styled.div`{
  &.mypage { ${cs.bg.white} ${cs.noselect}
    .mp-frame { ${cs.max.wlg} ${cs.m.center(20)} ${cs.min.h(600)} }
  }
}`;

const API = URL.API.PAGE;

const Mypage = (props) => {
  const MENUS = [
    { id: 'history', title: ST.MYPAGE.HISTORY, icon: '', component: Myhistory },
    { id: 'refund', title: ST.MYPAGE.REFUND, icon: '', component: Myhistory },
    { id: 'address', title: ST.MYPAGE.ADDRESS, icon: '', component: Myaddress },
    { id: 'member', title: ST.MYPAGE.MEMBER, icon: '', component: Myinfo },
    // { id: 'cashing', title: ST.MYPAGE.CASHING, icon: '', component: Mycash },
    // { id: 'comment', title: ST.MYPAGE.COMMENT, icon: '', component: Mycomment },
    // { id: 'coupon', title: ST.MYPAGE.COUPON, icon: '', component: Mycoupon },
    { id: 'detail', title: ST.MYPAGE.DETAIL, icon: '', component: HistoryDetail, hide: true },
  ];

  const param = actions.getParam();
  let index = param && param.page ? MENUS.findIndex(a => String(a.id) === String(param.page)) : 0;
  const [pos] = useState(index);

  const onLoaded = () => {
    setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
  }

  const onClickMenu = (eid) => {
    const item = MENUS.find(a => a.id === eid);
    (item) && actions.go(URL.MYPAGE, { page: eid });
  }

  const Component = MENUS[pos].component || Myhistory;

  return <StyledObject className={cx('mypage')}>
    <Menubar list={MENUS.filter(a => !a.hide)} pos={param && param.page === 'detail' ? 0 : pos} onClick={onClickMenu} />
    <div className={'mp-frame'}>
      <Component type={MENUS[pos].id} onLoaded={onLoaded} {...props} />
    </div>
  </StyledObject>
};

const StyledPage = styled.div`{
  &.tbl { ${cs.pos.relative}
    .tbl-head { ${cs.disp.block} ${cs.h.fit} ${cs.bg.box} ${cs.p.a20} ${cs.pos.relative}
      .buttons { ${cs.group(5)} ${cs.w.full} }
      .title { ${cs.disp.block} ${cs.m.b5}
        .label { ${cs.disp.inblock} ${cs.m.r5} ${cs.font.md} ${cs.font.bold} }
        .date { ${cs.disp.inblock} ${cs.font.darkgray} ${cs.font.sm} ${cs.font.right} }
      }
    }

    .tbl-body { ${cs.disp.block} ${cs.min.h(600)} ${cs.m.t20} ${cs.border.top} ${cs.border.dark} ${cs.border.width(3)} 
      ${cs.h.fit} ${cs.m.b40}

      .trow .tcol { ${cs.h.fit} }
    }

    .table-list { ${cs.disp.block} }
    .card-list { ${cs.disp.none} }

    @media screen and (max-width : 600px) {
      .table-list { ${cs.disp.none} }
      .card-list { ${cs.disp.block} }
    }
  }

  &.my-info {
    ${cs.disp.block} ${cs.h.fit} ${cs.m.b30} ${cs.box.light} 
    .frame { ${cs.disp.block} ${cs.min.h(500)} ${cs.p.a20} ${cs.max.w(600)} ${cs.m.center(0)} 
      .frow { ${cs.disp.block} ${cs.pos.relative} ${cs.w.full} ${cs.m.t30} ${cs.font.md}
        .fl { ${cs.w.get(140)} ${cs.font.gray} ${cs.font.sm} ${cs.disp.inblock} ${cs.font.line(20)} }
        .fv { ${cs.w.calc('100% - 160px')} ${cs.m.l20} ${cs.border.bottom} ${cs.disp.inblock} ${cs.font.line(20)} 
          &.ph { ${cs.p.h10} ${cs.font.ellipsis} }
          &.button { ${cs.border.gray} }
          &.edit-box { ${cs.p.h0} }
        }
        .fnoti { ${cs.font.sm} ${cs.font.gray} ${cs.font.line(18)} ${cs.p.l5} 
          ${cs.disp.block} ${cs.m.t3} ${cs.font.right} ${cs.font.prewrap}
          &.click:hover { ${cs.mouse.pointer} }
          &.left { ${cs.font.left} }
          & > span { ${cs.font.orange} ${cs.p.h5} ${cs.font.underline} ${cs.font.bold} }
        }
        .button { ${cs.pos.relative} }
        .user-info { ${cs.align.lbottom} ${cs.left(5)} ${cs.bottom(-10)} ${cs.font.primary} ${cs.font.xs} ${cs.font.right} 
          ${cs.disp.inblock} ${cs.w.fit}
        }

        &.pwd { ${cs.m.t30} ${cs.bg.sky} ${cs.border.radius(5)} ${cs.over.hidden}
          ${cs.p.a10} ${cs.p.b50}
          .fnoti { ${cs.font.darkgray} }
          .pwd-find { ${cs.font.sm} ${cs.align.lbottom} ${cs.left(10)} 
            ${cs.font.underline} ${cs.mouse.pointer} ${cs.bottom(10)} ${cs.font.gray}
          }
          .change { ${cs.align.xcenter} ${cs.bottom(10)} ${cs.m.r20} } 
        }
      }
    }
    .change { .box input.input { ${cs.bg.white} &.readonly:focus, &.readonly:hover { ${cs.bg.white} } } }
    .foot { ${cs.p.a10} ${cs.p.h20} ${cs.font.right} }

    .line { ${cs.border.bottom} ${cs.pos.relative} ${cs.font.lightgray} 
      .txt { ${cs.bg.white} ${cs.p.h20} ${cs.font.sm} ${cs.align.xcenter} ${cs.top(-5)} } 
    }

    .cancel { ${cs.m.r10} }
  }

  &.my-coupon {
    ${cs.disp.block} ${cs.h.fit} ${cs.m.b30} ${cs.box.light} 
    .frame { ${cs.min.h(500)} ${cs.p.a20} }
    .foot { ${cs.p.a10} ${cs.p.h20} ${cs.font.right} }

    .line { ${cs.border.bottom} ${cs.pos.relative} ${cs.font.lightgray} 
      .txt { ${cs.bg.white} ${cs.p.h20} ${cs.font.sm} ${cs.align.xcenter} ${cs.top(-5)} } 
    }
  }

  // &.my-comment {
  //   ${cs.disp.block} ${cs.h.fit} ${cs.m.b30} ${cs.box.light} 
  //   .frame { ${cs.min.h(500)} ${cs.p.a20} }
  //   .foot { ${cs.p.a10} ${cs.p.h20} ${cs.font.right} }

  //   .line { ${cs.border.bottom} ${cs.pos.relative} ${cs.font.lightgray} 
  //     .txt { ${cs.bg.white} ${cs.p.h20} ${cs.font.sm} ${cs.align.xcenter} ${cs.top(-5)} } 
  //   }
  // }

  &.my-cash {
    .tbl-head { 
      .title {
        .txt { ${cs.font.t1} ${cs.disp.inblock} ${cs.m.l30} }
      } 
    }
    .history { ${cs.bottom(5)} ${cs.right(-5)} ${cs.font.darkgray} .btn-label { ${cs.font.underline} } }
  }

  &.pwd-layer { 
    ${cs.pos.relative} ${cs.over.hidden} ${cs.m.t30}

    .pwd-box { ${cs.pos.relative} ${cs.m.h30} ${cs.box.line} ${cs.box.radius} ${cs.p.a20} ${cs.bg.white} ${cs.anim.slidedown('200ms')} 
      .frow { ${cs.m.t20} }
      .cancel { ${cs.right(10)} }
      .ok { ${cs.right(-20)} }
    }

    .noti { ${cs.font.xs} ${cs.font.orange} ${cs.p.t10} ${cs.font.center} ${cs.w.full} 
      ${cs.disp.hidden} ${cs.h.get(14)}
      &.show { ${cs.disp.visible} }
    }
  }

  &.mail-layer, .phone-layer {
    ${cs.pos.relative} ${cs.over.hidden} ${cs.m.t30}

    .mail-box, .phone-box { ${cs.pos.relative} ${cs.m.h30} ${cs.box.line} ${cs.box.radius} ${cs.p.a20} ${cs.bg.white} ${cs.anim.slidedown('200ms')} 
      .frow { ${cs.m.t20} ${cs.pos.relative} 
        .code { ${cs.w.calc('100% - 80px')} ${cs.disp.inblock} }
        .ok { ${cs.w.get(80)} ${cs.pos.absolute} ${cs.right(0)} ${cs.bottom(0)} ${cs.disp.inblock} }
        .timer { ${cs.font.sm} ${cs.font.red} ${cs.m.left(3)} }
      }
      .p-noti { ${cs.font.red} ${cs.font.md} ${cs.m.t10} ${cs.font.center} 
        ${cs.disp.invisible} ${cs.font.line(20)}
        &.show { ${cs.anim.showin()} ${cs.disp.visible} }
      }
      .cancel { ${cs.pos.absolute} ${cs.right(0)} }
    }
  }

  &.my-address {
    .tbl-btn { .button { ${cs.m.h5} .btn-label { ${cs.font.red} } } }

    .tbl-body { ${cs.h.fit} ${cs.p.b50} .table-box { .tb-body { ${cs.min.h(400)} } } }
    .table-box.trans .tb-body .tb-row .tb-col {
      ${cs.h.fit} ${cs.font.line(20)} ${cs.p.v10} ${cs.font.prewrap} ${cs.font.sm}
    }

    .t-noti { ${cs.font.red} ${cs.font.sm} ${cs.m.l10} ${cs.disp.inblock} ${cs.w.fit} ${cs.font.prewrap} ${cs.pos.absolute} }
  }

  &.my-history {
    .tbl-body { ${cs.h.fit} ${cs.p.b50}
      .table-box { ${cs.min.h(200)}
        .tb-body, .card-list .tline { ${cs.min.h(400)}
          .tb-row .tb-col, .trow .tcol .tcol-txt { ${cs.h.fit} ${cs.font.prewrap} ${cs.font.line(20)}  
            ${cs.min.h(80)} ${cs.p.v10}
            .tb-p { ${cs.font.prewrap} }
            &.price { ${cs.font.thickbold} }
            &.product { 
              .p-box { 
                .li-r { ${cs.w.calc('100% - 80px')} ${cs.disp.inblock} ${cs.float.right} ${cs.top(0)}
                  .title { ${cs.disp.block} ${cs.font.left} ${cs.w.full} }
                  &.full { ${cs.w.full} }
                } 
              }
            }

            .p-box {
              ${cs.disp.block} ${cs.size.full}
              .p-line { ${cs.disp.block} }
            }
          }
        }
      }
    }

    .t-status { ${cs.font.sm} ${cs.mouse.pointer} 
      .t-link { ${cs.font.line(14)} ${cs.m.t5} ${cs.font.underline} ${cs.font.primaryhover}
        &:hover { ${cs.font.primary} }
      }
    }
  }

  &.addr-box {
    ${cs.pos.absolute} ${cs.top(40)} ${cs.left(50)} ${cs.anim.showin()} ${cs.disp.block}
    ${cs.bg.white} ${cs.z.popup} ${cs.border.radius(5)} ${cs.box.line}
    ${cs.size.full} ${cs.max.w(420)} ${cs.min.h(300)} ${cs.h.fit}

    .ab-frame { ${cs.pos.relative} ${cs.disp.block} ${cs.size.full} ${cs.p.a20} 
      .ab-row { ${cs.pos.relative} ${cs.m.t20} 
        &.post {
          .edit-box { ${cs.w.calc('100% - 120px')} }
          .button { ${cs.align.right} ${cs.bottom(0)} ${cs.z.front} } 
        }
        &.foot { ${cs.font.right}
          .button { ${cs.m.l10} }
        }

      }

      .edit-box .box .input.readonly { ${cs.bg.frame} }
      .in-val { ${cs.m.t10} ${cs.border.bottom} }
    }

    .post-box { ${cs.pos.absolute} ${cs.top(90)} ${cs.left(10)} ${cs.z.over} }
  }

  @media screen and (max-width : 1024px) { }
  
  @media screen and (max-width : 600px) { 
    &.tbl
      .tbl-body { 
        .trow .tcol { ${cs.h.fit} ${cs.font.line(20)} }
      }

      .card-list {
        .tline .trow .tcol { ${cs.m.v5} }
        .tcol-label { ${cs.float.left} ${cs.top(0)} }
        .tbl-btn { ${cs.font.right} }
      }
    }

    &.my-history {
      .card-list .tline .trow .tcol {
        .tcol-label { ${cs.w.get(100)} }
        .tcol-txt { ${cs.w.calc('100% - 100px')}
          .p-box {
            .p-line { ${cs.disp.inblock} ${cs.m.r5} ${cs.w.fit} }
            .btn-detail { ${cs.align.rtop} ${cs.top(12)} ${cs.right(10)} }
            .thumb-box { ${cs.align.rbottom} }
          }
          .t-status {
            .p-tit { ${cs.disp.inblock} ${cs.font.bold} ${cs.w.fit} ${cs.m.r10} }
            .t-link { ${cs.disp.inblock} ${cs.w.fit} }
          }
        }
      }
    }

    &.my-info {
      .frame {
        ${cs.p.a10}
        .frow { 
            .fl { ${cs.w.get(100)} }
            .fv { ${cs.w.calc('100% - 120px')} }
          }
        }
      }
    }

    &.pwd-layer { 
      .pwd-box { ${cs.m.h10} ${cs.p.a10} }
    }

    &.mail-layer, .phone-layer {
      .mail-box, .phone-box { ${cs.m.h10} ${cs.p.a10} 
        .mail-frame, .phone-frame { .frow .fl { ${cs.w.get(100)} } }
      }
    }

    &.addr-box {
      ${cs.align.ctop} ${cs.w.full} ${cs.max.w('calc(100% - 20px)')} ${cs.box.shadow}

      .post-box { ${cs.w.get('calc(100% - 20px)')}

      }
    }
  }

  @media screen and (max-width : 480px) { }
}`;

/*************************************************
 * 주문/배송조회
**************************************************/
const Myhistory = (props) => {
  const [select, setSelect] = useState('week');
  const [start, setStart] = useState(moment().add(-7, 'day').format('YYYY-MM-DD'));
  const [end, setEnd] = useState(moment().format('YYYY-MM-DD'));
  const [list, setList] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState(null);
  const [trans, setTrans] = useState(null);

  const head = [
    {
      key: 'odrno', title: ST.MYPAGE.CARTNO, flex: '1 1 100px', align: 'center',
      formatter: (cell, item) => {
        return <div className={'p-box'}>
          <p className={'p-line'}>{Util.toStringSymbol(item.ctime, '-').substr(0, 10)}</p>
          <p className={'p-line'}>{`(${item.rowid})`}</p>
          <Button className={'xs gd-gray border btn-detail'} title={ST.MYPAGE.DETAIL} onClick={() => onClickDetail(item)} />
        </div>
      }
    },
    {
      key: 'product', title: ST.MYPAGE.PRODUCT, flex: '3 1 0', align: 'left',
      formatter: (cell, item) => {
        return <span className={'p-box'}>
          {item.thumb && <Thumbbox className={'border radius'} src={item.thumb} width={'70px'} height={'70px'} objectFit={'cover'} />}
          <span className={cx('li-r', !item.thumb && 'full')}>
            <p className={'title'}>{item.product}</p>
          </span>
        </span>
      }
    },
    {
      key: 'price', title: ST.MYPAGE.PRICE, flex: '1 1 100px', align: 'center', type: 'number',
      formatter: (cell) => `${Util.commas(cell)}${ST.WON}`
    },
    {
      key: 'status', title: ST.MYPAGE.STATUS, flex: '1 1 100px', align: 'center',
      formatter: (cell, item) => {
        const s = status && status.find(a => String(item.status) === String(a.id));
        let title = '';
        switch (Number(item.status)) {
          case 91: title = ST.PORDER.CANCEL_READY; break;
          case 99: title = ST.PORDER.CANCEL_OK; break;
          default: title = s && s.title;
        }
        return <div className={cx('t-status', item.tno && 'link')}>
          <div className={'p-tit'}>{title}</div>
          {/* 주문 취소 요청(배송전일 경우 표시) */}
          {!item.tno && Number(item.status) <= 2 && <div className={'t-link cancel'} onClick={() => onClickCancel(item)}>{`${ST.PORDER.CANCEL}`}</div>}
          {/* 배송시작이면 택배사 정보 표시 */}
          {item.tno && <div className={'t-link'} onClick={() => onClickStatus(item)}>{`${item.tname}\n(${item.tno})`}</div>}
        </div>
      }
    },
  ];

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'trans', skey: 'status' }).then(({ result }) => {
      const { sval } = result && result.length > 0 && result[0];
      if (sval) {
        const arrays = JSON.parse(sval);
        setStatus(arrays);
      }
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'trans', skey: 'list' }).then(({ result }) => {
      const { sval } = result && result.length > 0 && result[0];
      if (sval) {
        const arrays = JSON.parse(sval);
        setTrans(arrays);
      }
    });

    doReload(start);

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (value = start) => {
    const { type } = props;

    setLoaded(false);
    actions.doList(API.ORDER, { start: value, type }, true).then(({ result }) => {
      setList(result && result.data);
      setLoaded(true);
      setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
    })
  }

  const onClickDetail = (item) => {
    actions.go(URL.DETAIL, { page: 'detail', rowid: item.rowid });
  };

  const onClickStatus = (item) => {
    const data = trans && trans.find(a => String(item.tid) === String(a.id));
    let url = null;
    if (data) {
      url = data.url + item.tno;
    } else {
      url = URL.TRANS.NAVER(`${item.tname}+${ST.T_SEARCH}&no=${item.tno}`);
    }

    window.open(url);
  };

  const onClickCancel = (item) => {
    const title = `${item.product} ${Util.commas(item.price)}${ST.WON}`;
    props.openConfirm({
      size: 'md', type: 'info', title: ST.PORDER.CANCEL, msg: ST.PORDER.CANCEL_MSG(title), onClicked: (isOk) => {
        (isOk) && actions.doUpdate(API.ORDER_CANCEL, { rowid: item.rowid, msg: ST.PORDER.CANCEL_REASON }, null).then(() => {
          doReload();
        });
      }
    });
  }

  const onClick = (eid) => {
    setSelect(eid);

    let value = 0;
    switch (eid) {
      case 'month': value = moment().add(-1, 'month'); break;
      case 'quart': value = moment().add(-3, 'month'); break;
      default: value = moment().add(-7, 'day'); break;
    }

    const cur = value.format('YYYY-MM-DD');
    setStart(cur);
    setEnd(moment().format('YYYY-MM-DD'));
    doReload(cur);
  }

  const color = (s) => s ? 'dark' : 'white';
  const nodata = props.type === 'history' ? ST.NOLIST(ST.MYPAGE.HISTORY) : ST.NOLIST(ST.MYPAGE.REFUND);

  if (!loaded) return <Loading className={'fixed'} />
  return <StyledPage className={cx('tbl my-history')}>
    <div className={'tbl-head'}>
      <div className={'title'}>
        <p className={'label'}>{ST.MYPAGE.FIND_DATE}</p>
        <p className={'date'}>{`(${start} ~ ${end})`}</p>
      </div>
      <div className={'buttons'}>
        <Button className={cx('week border', color(select === 'week'))} title={`1${ST.WEEK}`} eid={'week'} onClick={onClick} />
        <Button className={cx('month border', color(select === 'month'))} title={`1${ST.MONTH}`} eid={'month'} onClick={onClick} />
        <Button className={cx('quart border', color(select === 'quart'))} title={`3${ST.MONTH}`} eid={'quart'} onClick={onClick} />
      </div>
    </div>
    <div className={'tbl-body'}>
      <Cardlist className={cx('card-list md')} max={1} head={head} list={list} rowid={'rowid'} />
      <Tablebox className={cx('table-list md trans')} max={1} head={head} list={list} rowid={'rowid'} nodata={nodata} />
    </div>
  </StyledPage>
}

/*************************************************
 * 배송주소록
**************************************************/
const Myaddress = (props) => {
  const [list, setList] = useState(null);
  const [box, showBox] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [noti, setNoti] = useState(null);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    showBox(false);
    setNoti(null);
    setLoaded(false);
    doReload(() => {
      setLoaded(true);
      setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
    })

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (callback = null) => {
    setRunning(true);
    showBox(false);
    setNoti(null);
    actions.doSelect(API.ADDRESS).then(({ result }) => {
      setList(result);
      setRunning(false);
      setLoaded(true);
      callback && callback();
    });
  }

  const onClickPrimary = (item) => {
    actions.doUpdate(API.ADDRESS_PRIM, { rowid: item.rowid, prim: "Y" }).then(() => {
      doReload();
    });
  }

  const onClickDelete = (item) => {
    props.openConfirm({
      size: 'sm', type: 'info', onClicked: (isOk) => {
        (isOk) && actions.doDelete(API.ADDRESS, { rowid: item.rowid }, null).then(() => {
          doReload();
        });
      }
    });
  }

  const head = [
    { key: 'name', title: ST.MYPAGE.REC_USER, flex: '1 1 100px', align: 'center' },
    {
      key: 'addr', title: ST.MYPAGE.REC_ADDR, flex: '3 1 200px', align: 'left',
      formatter: (v, item) => { return `(${item.zipcode})${item.addr} ${item.addr_rest}` }
    },
    { key: 'phone', title: ST.MYPAGE.REC_PHONE, flex: '1 1 100px', align: 'center' },
    {
      key: 'set', title: ST.MYPAGE.REC_SET, flex: '2 1 160px', align: 'right',
      formatter: (v, item) => {
        return <div className={'tbl-btn'}>
          {item.prim === "N" && <Button className={'sm border white'} title={ST.MYPAGE.REC_ADD} onClick={() => onClickPrimary(item)} />}
          <Button className={'sm border white'} title={ST.DELETE} onClick={() => onClickDelete(item)} />
        </div>
      }
    },
  ]

  const onClick = () => {
    if (list && list.length >= 10) {
      setNoti(ST.MYPAGE.ADDRESS_MAX);
    } else {
      showBox(true);
      setNoti(null);
    }
  }

  const onClickBox = (eid) => {
    if (eid === EID.OK) {
      doReload();
    } else {
      showBox(false);
    }
  }

  if (!loaded) return <Loading className={'fixed'} />
  return <StyledPage className={cx('tbl my-address')}>
    <div className={'tbl-head'}>
      <Button className={cx('add border white')} icon={'new'} title={ST.MYPAGE.REC_NEW} eid={'new'} onClick={onClick} />
      {noti && <p className={'t-noti'}>{noti}</p>}
    </div>
    <div className={'tbl-body'}>
      {list.length > 0 && <React.Fragment>
        <Cardlist className={cx('card-list md')} max={1} head={head} list={list} rowid={'rowid'} />
        <Tablebox className={cx('table-list md trans')} max={1} head={head} list={list} rowid={'rowid'} />
      </React.Fragment>}
      {(list.length < 1) && <Nodata title={ST.NOLIST(ST.MYPAGE.ADDRESS)} />}
    </div>
    {box && <AddressBox onClick={onClickBox} />}
    {running && <Loading className={'fixed'} type="ring" />}
  </StyledPage>
}

const AddressBox = (props) => {
  var refs = {};
  const [post, setPost] = useState(false);
  const [code, setCode] = useState('');
  const [addr, setAddr] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [phone] = useState('');
  const [modified, setModified] = useState(false);

  const onCancel = () => {
    props.onClick && props.onClick(EID.CANCEL);
  }

  const onSave = () => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidate());
    if (!isvalidate) return;

    const zipcode = refs.zipcode.getValue();
    if (!zipcode || zipcode.length < 1) { refs.addr.showNoti(ST.MYPAGE.ADDRESS_NOTI); return; }

    let value = {};
    Object.keys(refs).map(key => {
      value[key] = refs[key].getValue();
      return null;
    });

    actions.doInsert(API.ADDRESS, value).then(() => {
      props.onClick && props.onClick(EID.OK);
    });
  }

  const onComplete = (address, item) => {
    setTimeout(() => {
      setPost(false);
      setAddr(address);
      setCode(item.zonecode);
      setModified(true);
    }, 200);
  }

  return <StyledPage className={'addr-box'}>
    <div className={'ab-frame'}>
      <Editbox ref={(ref) => { refs.name = ref }} className={"in-val trans"} value={''} onChange={() => setModified(true)}
        type="text" guide={ST.MYPAGE.REC_USER} validate={true} focus={true} noupdate />
      <div className={'ab-row post'}>
        <Editbox ref={(ref) => { refs.zipcode = ref }} className={"in-val trans"} value={code} onChange={() => setModified(true)}
          type="number" guide={ST.MYPAGE.POST_CODE} validate={true} focus={false} preview={false} min={0} max={999999} maxLength={6}
          readonly={true} />
        <Button className={'sm gray-line post'} title={ST.MYPAGE.POST_FIND} onClick={() => setPost(true)} />
      </div>
      <Editbox ref={(ref) => { refs.addr = ref }} className={"in-val trans"} value={addr} onChange={() => setModified(true)}
        type="text" guide={ST.MYPAGE.ADDR_F} validate={true} focus={false} readonly={true} />
      <Editbox ref={(ref) => { refs.addr_rest = ref }} className={"in-val trans"} value={''} onChange={() => setModified(true)}
        type="text" guide={ST.MYPAGE.ADDR_D} validate={true} focus={false} noupdate />
      <Editbox ref={(ref) => { refs.phone = ref }} className={"in-val trans"} value={phone} onChange={() => setModified(true)}
        type="phone" guide={ST.MYPAGE.REC_PHONE} validate={false} focus={false} noupdate min={0} max={999999999999999999} maxLength={18} />
      <div className={'ab-row foot'}>
        <Button className={'md gd-gray cancel'} title={ST.CANCEL} onClick={onCancel} />
        <Button className={'md gd-gray ok'} title={ST.OK} onClick={onSave} disabled={!modified} />
      </div>
    </div>
    {post && <Postcode className={'post-box'} onComplete={onComplete} onCancel={() => setPost(false)} />}
  </StyledPage>
}

/*************************************************
 * 회원정보
**************************************************/
const Myinfo = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [passbox, showPassbox] = useState(false);
  const [phonebox, showPhonebox] = useState(false);
  const [mailbox, showMailbox] = useState(false);
  const [modified, setModified] = useState(false);
  const [info, setInfo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [iamport, setIamport] = useState(null);

  useEffect(() => {
    doReload(() => {
      setLoaded(true);
      setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
    });

    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const doReload = (callback = null) => {
    actions.doSelect(API.MYINFO).then((result) => {
      const { value } = result;
      setData(value);
      const { kakaoid = null, faceid = null, naverid = null } = value;
      if (kakaoid) setInfo(ST.MYINFO.KAKAO);
      else if (faceid) setInfo(ST.MYINFO.FACEBOOK);
      else if (naverid) setInfo(ST.MYINFO.NAVER);
      else setInfo(null);
      setModified(false);

      let iamkey = result && result.iamport && result.iamport.find(a => a.skey === 'pgid');
      iamkey = iamkey ? iamkey.sval : null;
      iamkey && window.IMP && window.IMP.init(iamkey);
      setIamport(iamkey);

      callback && callback();
    })
  }

  const onClick = (eid) => {
    if (eid === EID.SAVE) {
      let value = {};
      Object.keys(refs).map(key => {
        value[key] = refs[key].getValue();
        return null;
      });

      const isname = refs.name.isValidate(refs.name.checkFunc(Util.getSpecialCharPattern()));
      if (!isname) { refs.name.showNoti(ST.MYPAGE.NAME_ERROR); return; }

      actions.doUpdate(API.MYINFO, value).then(() => {
        doReload();
      });
    } else if (eid === EID.CANCEL) {
      doReload();
    }
  }

  const onClickEmail = (eid, e, value) => {
    showMailbox(eid === EID.SHOW);
    if (eid === EID.OK) {
      setData({ ...data, email: value });
    }
  }

  const onClickPass = (eid) => {
    showPassbox(eid === EID.SHOW);
  }

  const onClickPhone = (eid, e, value) => {
    showPhonebox(eid === EID.SHOW);
    if (eid === EID.OK) {
      setData({ ...data, phone: value });
    }
  }

  if (!loaded) return <Loading className={'fixed'} />
  return <StyledPage className={cx('my-info')}>
    <div className={'frame'}>
      <div className={'frow'}>
        <span className={'fl'}>{ST.MYINFO.USERID}</span><span className={'fv ph'}>{`${data.accountid}(${data.rowid})`}</span>
        {info && <span className={'user-info'}>{info}</span>}
      </div>
      <div className={'frow name'}>
        <span className={'fl'}>{ST.MYINFO.NAME}</span>
        <Editbox ref={(ref) => { refs.name = ref }} className={'fv md white change'}
          guide={ST.MYINFO.NAME_IN} value={data.name} onChange={passbox ? null : () => setModified(true)} readonly={passbox} />
      </div>
      <div className={'frow phone'}>
        <span className={'fl'}>{ST.MYINFO.PHONE}</span>
        {data.phone && <span className={'fv ph'}>{data.phone}</span>}
        {!data.phone && <Button className={'fv sm black border change'} title={ST.MYINFO.PHONE_IN} eid={EID.SHOW} onClick={onClickPhone} />}
        <span className={'fnoti click'} onClick={(e) => onClickPhone('show', e)}>{ST.MYINFO.PHONE_F}<span>{ST.MYINFO.PHONE_M}</span>{ST.MYINFO.PHONE_L}</span>
        {phonebox && <PhoneBox onClick={onClickPhone} iamkey={iamport} openConfirm={props.openConfirm} />}
      </div>
      <div className={'frow'}>
        <span className={'fl'}>{ST.MYINFO.EMAIL}</span>
        {data.email && <span className={'fv ph'}>{data.email}</span>}
        {!data.email && <Button className={'fv sm black border change'} title={ST.MYINFO.EMAIL_IN} eid={EID.SHOW} onClick={onClickEmail} />}
        <span className={'fnoti click'} onClick={(e) => onClickEmail('show', e)}>{ST.MYINFO.EMAIL_F}<span>{ST.MYINFO.EMAIL_M}</span>{ST.MYINFO.EMAIL_L}</span>
        {mailbox && <MailBox onClick={onClickEmail} />}
      </div>
      <div className={'frow pwd'}>
        <span className={'fnoti left'}>{ST.MYINFO.PASSWORD_H}</span>
        {!passbox && <Button className={'sm black border change'} title={ST.MYINFO.PASSWORD} eid={EID.SHOW} onClick={onClickPass} />}
        {info && <span className={'pwd-find'} onClick={() => window.location.href = URL.SIGNTO}>{ST.MYINFO.PREPASS_FIND}</span>}
        {passbox && <PasswordBox onClick={onClickPass} openConfirm={props.openConfirm} />}
      </div>
    </div>
    <div className={'line'} />
    <div className={'foot'}>
      <Button className={cx('cancel border white')} icon={'cancel'} title={ST.CANCEL} eid={EID.CANCEL} onClick={onClick} disabled={!modified} />
      <Button className={cx('save border white')} icon={'save'} title={ST.SAVE} eid={EID.SAVE} onClick={onClick} disabled={!modified} />
    </div>
    {/* {running && <Loading className={'fixed'} type="ring" />} */}
  </StyledPage >
}

const PasswordBox = (props) => {
  var refs = {};
  const [data, setData] = useState({});
  const [isshow, setShow] = useState(false);
  const [noti, setNoti] = useState(null);

  useEffect(() => {
    return () => {
    }
  }, [])

  const onCancel = () => {
    props.onClick && props.onClick(EID.CANCEL);
  }


  const isValidate = (object) => {
    const value = object.getValue();
    const name = object.props.name;

    if (name === "pwd" || name === "pwd_prev" || name === "pwd_check") {
      if (!Util.isPassword(value, 8, 20)) {
        setNoti(ST.SIGNUP.H.PASSWORD);
        object.focus();
        return false;
      }
    }

    if (name === "pwd_check") {
      let pwd = refs.pwd.getValue();
      if (!Util.isPassword(pwd, 8, 20) || pwd !== value) {
        setNoti(ST.SIGNUP.PASSWORD_NOTI);
        object.focus();
        return false;
      }
    }

    return true;
  };

  const onSave = () => {
    if (!isValidate(refs.pwd_prev) || !isValidate(refs.pwd) || !isValidate(refs.pwd_check)) {
      return;
    }

    actions.doUpdate(API.REPASS, { ...data }).then(({ code }) => {
      let msg = '';
      if (code === CODE.SUCCESS) {
        msg = ST.MYPAGE.PASSWORD_SUCCESS;
      } else {
        msg = ST.MYPAGE.PASSWORD_FAIL;
      }

      props.openConfirm({
        size: 'sm', type: 'alarm', title: ST.PORDER.CANCEL, msg, cancel: false,
        onClicked: () => { props.onClick && props.onClick(EID.OK); }
      });
    });
  }

  const onChange = (name, value) => {
    const json = { ...data };
    json[name] = value;
    setData({ ...json });
    setNoti(null);
  }

  return <StyledPage className={'pwd-layer'}>
    <div className={'pwd-box'}>
      <div className={'pwd-frame'}>
        <div className={'frow'}>
          <span className={'fl'}>{ST.MYINFO.PREPASS}</span>
          <Editbox ref={ref => refs['pwd_prev'] = ref} className={'fv md'} validate={true} name={'pwd_prev'}
            value={data ? data.pwd_prev : ''} focus={true} type={isshow ? 'text' : 'password'} maxLength={20}
            guide={ST.MYINFO.PREPASS_H} onChange={(v) => onChange('pwd_prev', v)} />
          <Svg className={'right'} icon={'show'} onClick={() => setShow(!isshow)} />
        </div>
        <div className={'frow'}>
          <span className={'fl'}>{ST.MYINFO.NEWPASS}</span>
          <Editbox ref={ref => refs['pwd'] = ref} className={'fv md'} validate={true} name={'pwd'}
            value={data ? data.pwd : ''} type={isshow ? 'text' : 'password'} maxLength={20}
            guide={ST.MYINFO.NEWPASS_H} onChange={(v) => onChange('pwd', v)} />
          <Svg className={'right'} icon={'show'} onClick={() => setShow(!isshow)} />
        </div>
        <div className={'frow'}>
          <span className={'fl'}>{ST.MYINFO.NEWPASSCHK}</span>
          <Editbox ref={ref => refs['pwd_check'] = ref} className={'fv md'} validate={true} name={'pwd_check'}
            value={data ? data.pwd_check : ''} type={isshow ? 'text' : 'password'} maxLength={20}
            guide={ST.MYINFO.NEWPASSCHK_H} onChange={(v) => onChange('pwd_check', v)} />
          <Svg className={'right'} icon={'show'} onClick={() => setShow(!isshow)} />
        </div>
        <p className={cx('noti', noti && 'show')}>{noti}</p>
        <div className={'frow foot'}>
          <Button className={'sm gd-gray cancel right bottom'} title={ST.CANCEL} onClick={onCancel} />
          <Button className={'sm gd-gray ok right bottom'} title={ST.OK} onClick={onSave} />
        </div>
      </div>
    </div>
  </StyledPage>
}


var limit = 0;
var timer = null;

const MailBox = (props) => {
  var refs = {};
  const [enable, setEnable] = useState(false);
  const [email, setEmail] = useState(null);
  const [, setRefresh] = useState(new Date());
  const [count, setCount] = useState(0);
  const [noti, setNoti] = useState(null);

  useEffect(() => {
    limit = 0;
    setEmail('');
    setCount(0);
    setNoti(null);
    clearInterval(timer);
    timer = null;

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    }
  }, []);

  const onCancel = () => {
    props.onClick && props.onClick(EID.CANCEL);
  }

  const onSend = () => {
    if (!email) return;

    if (timer) {
      clearInterval(timer);
      timer = null;
    }

    actions.doInsert(API.MAIL_SET, { subject: ST.MYINFO.MAIL_TITLE, to: email }).then(({ result }) => {
      setEnable(true);
      setEmail(email);
      setNoti(null);
      setCount(0);
      limit = Number(result ? result.limit : 5 * 60 * 1000);
      timer = setInterval(() => {
        if (limit <= 0) {
          clearInterval(timer);
          timer = null;
          setNoti(ST.MYINFO.TIME_OUT);
          setTimeout(() => {
            props.onClick && props.onClick(EID.CANCEL);
          }, 1000);
          return;
        }
        limit = limit - 1000;
        setRefresh(new Date());
      }, 1000);
    });
  }

  const onOk = (eid, e) => {
    if (!refs.emcode) return;

    const mcode = refs.emcode.getValue();
    actions.doUpdate(API.MAIL_SET, { email, mcode }).then(({ code }) => {
      if (code === CODE.SUCCESS) {
        setNoti(ST.MYINFO.CODE_SUCCESS);
        setTimeout(() => {
          props.onClick && props.onClick(EID.OK, e, email);
          clearInterval(timer);
          timer = null;
        }, 1000);
      } else {
        const v = count + 1;
        setCount(v);
        setNoti(ST.MYINFO.CODE_ERROR);
        if (v >= 5) {
          setNoti(ST.MYINFO.CODE_FAIL);
          setTimeout(() => {
            props.onClick && props.onClick(EID.CANCEL);
            clearInterval(timer);
            timer = null;
          }, 1000);
        }
      }
    });
  }

  return <StyledPage className={'mail-layer'}>
    <div className={'mail-box'}>
      <div className={'mail-frame'}>
        <Svg className={'md cancel right top'} icon={EID.CANCEL} onClick={onCancel} />
        <div className={'frow'}>
          <span className={'fl'}>{ST.MYINFO.EMAIL}</span>
          <div className={'fv'}>
            <Editbox className={'md code'} value={email} focus={true} guide={ST.MYINFO.EMAIL} onChange={(v) => setEmail(v)} readonly={enable} />
            <Button className={'sm gd-gray ok'} title={ST.SEND} onClick={onSend} disabled={!email} />
          </div>
        </div>
        <div className={'frow'}>
          <span className={'fl'}>{`${ST.MYINFO.EMAIL_CODE}`}
            {limit > 0 && <span className={'timer'}>{`(${moment(limit).format("mm:ss")})`}</span>}
          </span>
          <div className={'fv'}>
            <Editbox ref={ref => refs['emcode'] = ref} className={'md code'} value={''} guide={ST.MYINFO.EMAIL_CODE_H} maxLength={5} />
            <Button className={'sm gd-gray ok'} title={ST.OK} onClick={onOk} disabled={!enable} />
          </div>
        </div>
        <div className={cx('p-noti', noti && 'show')}><p>{noti}</p></div>
      </div>
    </div>
  </StyledPage>
}


var plimit = 0;
var ptimer = null;
var pcount = 5;

const PhoneBox = (props) => {
  var refs = {};
  const [enable, setEnable] = useState(false);
  const [phone, setPhone] = useState(null);
  const [, setRefresh] = useState(new Date());
  const [count, setCount] = useState(0);
  const [noti, setNoti] = useState(null);
  const [code, setCode] = useState(null);

  useEffect(() => {
    plimit = 0;
    setPhone('');
    setCount(0);
    setNoti(null);
    setCode(null);
    clearInterval(ptimer);
    ptimer = null;
    pcount = 5;

    return () => {
      if (ptimer) {
        clearInterval(ptimer);
        ptimer = null;
      }
    }
  }, []);

  const onCancel = () => {
    props.onClick && props.onClick(EID.CANCEL);
  }

  const onSend = () => {
    // props.onClick && props.onClick(EID.OK);
    if (!phone) return;

    if (ptimer) {
      clearInterval(ptimer);
      ptimer = null;
    }

    const doSuccess = ({ scode = null, stime = null }) => {
      setCode(scode);
      setEnable(true);
      setPhone(phone);
      setNoti(null);
      setCount(0);
      plimit = Number(stime || 5 * 60 * 1000);
      ptimer = setInterval(() => {
        if (plimit <= 0) {
          clearInterval(ptimer);
          ptimer = null;
          setNoti(ST.MYINFO.TIME_OUT);
          setTimeout(() => {
            props.onClick && props.onClick(EID.CANCEL);
          }, 1000);
          return;
        }
        plimit = plimit - 1000;
        setRefresh(new Date());
      }, 1000);
    }

    actions.doInsert(URL.API.PAGE.PHONEREGIST, { phone }).then((result) => {
      if (result && result.code === CODE.SUCCESS) {
        const { statusCode = 0 } = result ? result.value : { statusCode: 0 };
        // 발송이 성공할 경우
        if (Number(statusCode) === 202) {
          doSuccess(result ? result.value : {});
        } else {
          props.openConfirm({ title: ST.ALARM, type: 'info', msg: ST.MYPAGE.SMS_FAIL, size: 'sm', cancel: false });
        }
      } else {
        props.openConfirm({ title: ST.ALARM, type: 'info', msg: ST.MYPAGE.SMS_FAIL, size: 'sm', cancel: false });
      }
    });
    setEnable(true);
  }

  const onOk = (eid, e) => {
    if (!refs.emcode) return;
    const mcode = refs.emcode.getValue();
    pcount--;

    // 5회시도 실패시 화면 종료
    if (pcount <= 0) {
      setTimeout(() => props.onClick && props.onClick(EID.CANCEL), 2000);
      setNoti(ST.MYINFO.PHONE_FAIL);
      return;
    }

    // 5회까지 코드 검증
    if (Number(mcode) !== Number(code) && pcount > 0) {
      setNoti(ST.MYINFO.CODE_ERROR);
      setTimeout(() => setNoti(null), 2000);
      return;
    }

    // 코드가 검증될경우...
    actions.doUpdate(API.PHONE_SET, { code, mcode, phone }).then((result) => {
      if (result && result.code === CODE.SUCCESS) {
        setNoti(ST.MYINFO.CODE_SUCCESS);
        setTimeout(() => {
          props.onClick && props.onClick(EID.OK, e, phone);
          clearInterval(ptimer);
          ptimer = null;
        }, 1000);
      } else {
        const v = count + 1;
        setCount(v);
        setNoti(ST.MYINFO.CODE_ERROR);
        if (v >= 5) {
          setNoti(ST.MYINFO.CODE_FAIL);
          setTimeout(() => {
            props.onClick && props.onClick(EID.CANCEL);
            clearInterval(ptimer);
            ptimer = null;
          }, 1000);
        }
      }
    });
    // props.onClick && props.onClick(EID.OK);
  }

  return <StyledPage className={'phone-layer'}>
    <div className={'phone-box'}>
      <div className={'phone-frame'}>
        <Svg className={'md cancel right top'} icon={EID.CANCEL} onClick={onCancel} />
        <div className={'frow'}>
          <span className={'fl'}>{ST.MYINFO.PHONE}</span>
          <div className={'fv'}>
            <Editbox className={'md code'} value={phone} type={'phone'} focus={true} guide={ST.MYINFO.PHONE} onChange={(v) => setPhone(v)} readonly={enable} />
            <Button className={'sm gd-gray ok'} title={ST.SEND} onClick={onSend} disabled={!phone || phone.length < 8} />
          </div>
          {/* <Editbox className={'fv md'} value={''} focus={true} guide={ST.MYINFO.EMAIL} /> */}
        </div>
        <div className={'frow'}>
          <span className={'fl'}>{`${ST.MYINFO.PHONE_CODE}`}
            {plimit > 0 && <span className={'timer'}>{`(${moment(plimit).format("mm:ss")})`}</span>}
          </span>
          <div className={'fv'}>
            <Editbox ref={ref => refs['emcode'] = ref} className={'md code'} value={''} guide={ST.MYINFO.PHONE_CODE_H} maxLength={6} />
            <Button className={'sm gd-gray ok'} title={ST.OK} onClick={onOk} disabled={!enable} />
          </div>
        </div>
        <div className={cx('p-noti', noti && 'show')}><p>{noti}</p></div>
      </div>
    </div>
  </StyledPage>
}


/*************************************************
 * 적립금 내역
**************************************************/
// const Mycash = (props) => {
//   const [list, setList] = useState(null);

//   useEffect(() => {
//     setList(null)
//     setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
//     return () => { }
//   }, [props])

//   const head = [
//     { key: 'date', title: ST.MYPAGE.CSH_DATE, flex: '2 1 0', align: 'center', type: 'date' },
//     { key: 'limit', title: ST.MYPAGE.CSH_LIMIT, flex: '3 1 0', align: 'center', type: 'date' },
//     { key: 'price', title: ST.MYPAGE.CSH_PRICE, flex: '1 1 0', align: 'center', type: 'number', unit: ST.WON },
//     { key: 'status', title: ST.MYPAGE.CSH_STATUS, flex: '1 1 0', align: 'center' },
//     { key: 'desc', title: ST.MYPAGE.CSH_DESC, flex: '1 1 0', align: 'center' },
//   ];


//   const onClick = (eid, e) => {
//   }

//   return <StyledPage className={cx('tbl my-cash')}>
//     <div className={'tbl-head'}>
//       <div className={'title'}>
//         <p className={'label'}>{ST.MYPAGE.CSH_TITLE}</p>
//         <p className={'txt'}>{`${2000}${ST.WON}`}</p>
//       </div>
//       <Button className={cx('history trans right')} title={`${ST.MYPAGE.CSH_LIST}(${1})`} eid={'history'} onClick={onClick} />
//     </div>
//     <div className={'tbl-body'}>
//       <Cardlist className={cx('card-list md')} max={1} head={head} list={list} rowid={'rowid'} />
//       <Tablebox className={cx('table-list md trans')} max={1} head={head} list={list} rowid={'rowid'} />
//       {!list && <Nodata className={'sm'} title={ST.NOLIST(ST.MYPAGE.CASHING)} />}
//       {/* <Nodata title={ST.MYPAGE.HISTORY_NO} /> */}
//     </div>
//   </StyledPage>
// }

// const Mycomment = () => {
//   const [data, setData] = useState(null);

//   const onClick = (eid, e) => {
//   }

//   return <StyledPage className={cx('my-comment')}>
//     <div className={'frame'}>
//     </div>
//     <div className={'line'} />
//     <div className={'foot'}>
//       <Button className={cx('save border white')} icon={'save'} title={ST.SAVE} eid={'save'} onClick={onClick} />
//     </div>
//   </StyledPage>
// }

/*************************************************
 * 쿠폰등록/사용
**************************************************/
// const Mycoupon = (props) => {
//   // const [data, setData] = useState(null);

//   useEffect(() => {
//     setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
//     return () => { }
//   }, [props])

//   const onClick = (eid, e) => {
//   }

//   return <StyledPage className={cx('my-coupon')}>
//     <div className={'frame'}>
//     </div>
//     <div className={'line'} />
//     <div className={'foot'}>
//       <Button className={cx('save border white')} icon={'save'} title={ST.SAVE} eid={'save'} onClick={onClick} />
//     </div>
//   </StyledPage>
// }

export default Mypage;