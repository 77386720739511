import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Editbox, Button, Util, TabButton, Loading, Formgroup, Checkbox, Postcode, Svg, Combobox, Error } from 'object/src';
import { EID } from 'object/src/Config';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';
import { PolicyAgree } from '../view/Signup';
import { TransBox } from '../view/HistoryDetail';
import { PRIVACY, PUBLIC } from '../svc/Privacy';
import moment from 'moment';

const StyledObject = styled.div`{
  .p-line { ${cs.w.full} ${cs.border.top} ${cs.border.darkgray} ${cs.h.get(1)} ${cs.max.wmd} ${cs.align.bottom} }

  &.ad-set { ${cs.bg.trans}
    .tab-grp { ${cs.m.t30} ${cs.p.l20} }
    .hp-frame { ${cs.p.a20} }
  }

  &.sect {
    ${cs.m.v20} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wmd} ${cs.w.full}
    .edit-box { ${cs.m.t10} }
    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    & > .sec-box { ${cs.p.a10} }
    p.noti { ${cs.font.sm} ${cs.font.bold} ${cs.font.yellow} ${cs.m.t5} ${cs.font.line(16)}
      ${cs.font.prewrap}
      &.hover:hover { ${cs.font.underline} ${cs.mouse.pointer} } 
    }
    .preview { }
  }

  &.set-comp {
    .add-box {
      .add-btn { ${cs.mouse.pointer} ${cs.pos.relative} & > .svg-icon { ${cs.bottom(5)} ${cs.right(5)} } }
      .add-edit { &.edit-box .box .input.readonly { ${cs.bg.frame} ${cs.mouse.pointer} } }
    }
  }

  &.set-trans { ${cs.pos.relative} ${cs.m.b50}
    .button { ${cs.bottom(30)} ${cs.right(5)} }
  }

  &.set-initstate { 
    .l-line { ${cs.disp.block} ${cs.m.t10} ${cs.w.full} ${cs.pos.relative} ${cs.h.get(40)}
      .tit { ${cs.w.fit} ${cs.disp.inblock} ${cs.float.left} }
      .combo { ${cs.w.get(300)} ${cs.m.l10} ${cs.disp.inblock} ${cs.pos.relative} }
    }
  }

  &.set-transtat {
    .s-row {
      .delete { ${cs.left(100)} }
    }
    .preview { ${cs.right(180)} }

    .t-preview { ${cs.align.ltop} ${cs.top(50)} ${cs.w.get(1024)}
      .trans-box { }
      & > .button { ${cs.top(5)} ${cs.right(5)} }
    }
  }

  &.set-tranlist {
    .s-row {
      .delete { ${cs.left(100)} }
    }
    .li {
      ${cs.pos.relative} ${cs.border.bottom} ${cs.border.semiblack} ${cs.p.b0}
      // .fg-col { ${cs.m.get('0 5px 5px 0 !important')} }
      .url { ${cs.m.t0} ${cs.m.b10} }
      .checkbox { ${cs.pos.relative} .chk-box { ${cs.align.rbottom} ${cs.bottom(7)} ${cs.right(0)} } }
      .fg-url .col-0 { ${cs.disp.flex('none !important')} ${cs.w.calc('100% - 140px')} }
    }
  }
  
  &.set-prod-hist {
    .s-row {
      .delete { ${cs.left(100)} }
    }
    .li {
      ${cs.pos.relative} ${cs.border.bottom} ${cs.border.semiblack} ${cs.p.b0}
      // .fg-col { ${cs.m.get('0 5px 5px 0 !important')} }
      .url { ${cs.m.t0} ${cs.m.b10} }
      .checkbox { ${cs.pos.relative} .chk-box { ${cs.align.rbottom} ${cs.bottom(7)} ${cs.right(0)} } }
      .fg-url .col-0 { ${cs.disp.flex('none !important')} ${cs.w.calc('100% - 140px')} }
    }
  }

  &.set-appinfo {
    .s-row { ${cs.pos.relative} ${cs.m.t40}
      & > .button { ${cs.top(-20)} }
    }
  }

  &.set-privacy, .set-public {
    & > .sec-box { ${cs.p.a10} ${cs.min.h(600)} }

    .preview { ${cs.right(180)} }
    .policy-agree { ${cs.font.dark} }
  }

  @media screen and (max-width : 600px) {
    &.ad-set .hp-frame { ${cs.p.h10} ${cs.m.t20} }
    &.sect .edit-box {  }
    &.set-appinfo .s-row { ${cs.m.top(70)} & > .button { ${cs.top(-40)} } }
    &.sect .preview { ${cs.align.top} ${cs.top(-40)} ${cs.right(0)} }
    &.set-transtat .t-preview { ${cs.left(0)} ${cs.w.full} }
    &.set-privacy .policy-agree, &.set-public .policy-agree { ${cs.z.top} }
  }
}`;

const STA = ST.ADMIN.APPSET;
const STR = ST.ADMIN.TRANS;

const Setting = (props) => {
  const [refresh, setRefresh] = useState(new Date());
  const [tab, setTab] = useState('info');

  const doReload = (code) => {
    Util.showAlert(props, code);
    setRefresh(new Date());
  }

  const onClick = (eid, item, e) => {
    setTab(eid);
  }

  const TABS = [
    { id: 'info', title: STA.DEFAULT },
    { id: 'company', title: STA.COMPANY },
    { id: 'trans', title: STA.TRANS },
    { id: 'transtat', title: STA.TRANSTAT },
    { id: 'history', title: STA.PRODHIST },
    { id: 'privacy', title: ST.COMPANY.PRIVATE },
    { id: 'public', title: ST.COMPANY.PUBLIC },
  ]

  const issys = Util.isSystemAdmin();
  if (!issys) return <Error className={'t3'} title={ST.LOGINSYSADMIN} />

  return <StyledObject className={cx('ad-set')}>
    <TabButton className={"tab-grp"} size={'md'} onClick={onClick}
      list={TABS} select={tab} color={'gd-gray'} />
    {tab === 'info' && <div className={cx('hp-frame', tab)}>
      <SetAppinfo doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'company' && <div className={cx('hp-frame', tab)}>
      <SetCompany doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'trans' && <div className={cx('hp-frame', tab)}>
      <SetTrans doReload={doReload} refresh={refresh} />
      <SetTransList doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'transtat' && <div className={cx('hp-frame', tab)}>
      <SetTransStatus doReload={doReload} refresh={refresh} />
      <SetTransInit doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'history' && <div className={cx('hp-frame', tab)}>
      <SetProductHistory doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'privacy' && <div className={cx('hp-frame', tab)}>
      <SetPrivacy doReload={doReload} refresh={refresh} />
    </div>}
    {tab === 'public' && <div className={cx('hp-frame', tab)}>
      <SetPublic doReload={doReload} refresh={refresh} />
    </div>}
  </StyledObject>
};

export default Setting;

const SetAppinfo = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setList(result && result.length > 0 ? result : null);
      if (json['delivery']) json['delivery'] = Util.replaceAll(json['delivery'], '#', '\n');
      setData(json);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => {
      return refs[key].isValidateJson(STA.NOT_TEXT);
    });
    if (!isvalidate) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      let sval = refs[key].getValue();
      if (key === 'delivery') sval = Util.replaceAll(sval, '\n', '#');
      return { rowid: item ? item.cfgid : null, stag: 'app', skey: key, sval: sval }
    });

    actions.doInsert(URL.API.ADMIN.SETTING, array).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onImport = () => {
    setData({ ...data, delivery: STA.DELIVERY_DEFAULT });
    setModified(true);
  }

  const onImportRefund = () => {
    setData({ ...data, refund: STA.REFUND_DEFAULT, comment: STA.COMMENT_DEFAULT, package: STA.TRANS_DEFAULT });
    setModified(true);
  }

  if (!data) return null;

  return <StyledObject className={cx('set-appinfo sect')}>
    <p className={'title'}>{STA.DEFAULT}</p>
    <Button className={'gd-gray top right import'} title={STA.IMPORT} onClick={onImportRefund} />
    <div className={'sec-box'}>
      <Editbox ref={(ref) => { refs.refund = ref }} className={"refund"} value={data.refund}
        name="refund" type="text" label={STA.REFUND} validate={true} helper={STA.REFUND_NOTI}
        multi={true} height={300} minheigh={100} maxheight={400} onChange={() => setModified(true)} />
      <Editbox ref={(ref) => { refs.comment = ref }} className={"comment sizefix"} value={data.comment}
        name="comment" type="text" label={STA.COMMENT_NOTI} validate={true} helper={STA.COMMENT_H}
        multi={true} height={80} minheigh={80} maxheight={80} onChange={() => setModified(true)} />
      <Editbox ref={(ref) => { refs.package = ref }} className={"package sizefix"} value={data.package}
        name="package" type="text" label={STA.PACKAGE_NOTI} validate={true} guide={STA.PACKAGE_DEFAULT}
        multi={true} height={80} minheigh={80} maxheight={80} onChange={() => setModified(true)} />
      <div className={'s-row'}>
        <Button className={'gd-gray top right import mt'} title={STA.IMPORT} onClick={onImport} />
        <Editbox ref={(ref) => { refs.delivery = ref }} className={"comment sizefix"} value={data.delivery}
          name="delivery" type="text" label={STA.DELIVERY_NOTI} validate={true}
          multi={true} height={140} minheigh={140} maxheight={200} onChange={() => setModified(true)} />
        <p className={'noti'}>{`* ${STA.DELIVERY_HELP}`}</p>
      </div>
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}


const SetTrans = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [tmax, setTmax] = useState(0);
  const [tcost, setTcost] = useState(0);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    setModified(false);
    actions.doSelect(URL.API.APPINFO, { stag: 'trans' }).then(({ result }) => {
      let json = {};
      const array = result.filter(a => a.skey !== 'status');
      array.map(a => json[a.skey] = a.sval);
      setList(array && array.length > 0 ? array : null);
      setData(json);

      if (json && json['tmax']) setTmax(json.tmax);
      if (json && json['tcost']) setTcost(json.tcost);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    if (!isvalidate) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      return { rowid: item ? item.cfgid : null, stag: 'trans', skey: key, sval: refs[key].getValue() }
    });

    actions.doInsert(URL.API.ADMIN.SETTING, array).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  if (!data) return null;

  return <StyledObject className={cx('set-trans sect')}>
    <p className={'title'}>{STA.TRANS}</p>
    <div className={'sec-box'}>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.tcost = ref }} className={"tcost right"} value={tcost}
          type="number" label={ST.ADMIN.TRANS.PRICE} validate={true} guide={ST.ADMIN.TRANS.PRICE_H}
          onChange={(v) => { setTcost(v); setModified(true); }} />
        <Editbox ref={(ref) => { refs.tmax = ref }} className={"tmax right"} value={tmax}
          type="number" label={ST.ADMIN.TRANS.MAX} validate={true} guide={ST.ADMIN.TRANS.MAX_H}
          onChange={(v) => { setTmax(v); setModified(true); }} />
      </Formgroup>
      <p className={'noti hover'}>{`* ${STA.EVENT_NOTI(tmax && Util.commas(tmax), tcost && Util.commas(tcost))}`}</p>
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />

  </StyledObject>
}

const SetTransList = (props) => {
  const SFORM = (no = 0) => { return { id: no, title: '', link: '', phone: '', url: '', main: false } };
  // const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);
  const [noti, setNoti] = useState(null);
  const [rowid, setRowid] = useState(null);

  useEffect(() => {
    setNoti(null);
    actions.doSelect(URL.API.APPINFO, { stag: 'trans', skey: 'list' }).then(({ result }) => {
      const { sval, cfgid } = result && result.length > 0 && result[0];
      if (sval) {
        const arrays = JSON.parse(sval);
        setList(arrays);
        setRowid(cfgid);
      } else {
        const array = [ST.ADMIN.TRANSLIST[0]];
        setList(array);  //입금대기중, 결제완료는 무조건 들어가도록...
        const value = JSON.stringify(array);
        actions.doInsert(URL.API.ADMIN.SETTING, { rowid: rowid || null, stag: 'trans', skey: 'list', sval: value }).then(({ code }) => {
        });
      }
    });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const onSave = (eid, e) => {

    const valid = list && list.every(a => a.title && a.url && a.link);
    if (!valid) {
      return setNoti(STA.TRANSTAT_HELP);
    }

    const value = JSON.stringify(list);
    actions.doInsert(URL.API.ADMIN.SETTING, { rowid: rowid || null, stag: 'trans', skey: 'list', sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onImport = () => {
    setList([...ST.ADMIN.TRANSLIST]);
    setModified(true);
  }

  const onClick = (eid) => {
    if (eid === EID.NEW) {
      setList(list ? [...list, { ...SFORM(list.length + 1) }] : [{ ...SFORM(1) }]);
    } else if (eid === EID.DELETE) {
      if (list.length <= 1) {
        setNoti(STA.TRANLIST_HELP);
        setTimeout(() => setNoti(null), 2000);
        return;
      };
      setList(list ? [...list.slice(0, list.length - 1)] : [{ ...SFORM(1) }]);
    } else {
      return;
    }

    setModified(true);
  }

  const onChange = (value, label, pos) => {
    const array = [...list];
    array[pos][label] = value;
    setList(array);
    setModified(true);
  }

  // if (!list) return null;

  return <StyledObject className={cx('set-tranlist sect')}>
    <p className={'title'}>{STA.TRANLIST}</p>
    <div className={'sec-box'}>
      <div className={'s-row'}>
        <Button className={'green bottom new sm'} title={ST.ADD} eid={EID.NEW} onClick={onClick} />
        <Button className={'black bottom delete sm'} title={ST.DELETE} eid={EID.DELETE} onClick={onClick} />
        <Button className={'gd-gray top right import'} title={STA.IMPORT} onClick={onImport} />
        <ul>
          <p className={'noti'}>{`${STA.TRANLIST_NOTI}`}</p>
          {list && list.map((item, i) => {
            const { title = '', link = '', phone = '', url = '', main = false } = item;
            return <li className={'li'} key={i}>
              <Formgroup inline={true}>
                <Editbox type="text" label={STR.NAME} validate={true} onChange={(v, e, l) => onChange(v, 'title', i)}
                  value={title} maxLength={10} flex={"1 1 100px"} guide={STA.TRANSTAT_TITLE} />
                <Editbox type="text" label={STR.PHONE} validate={true} onChange={(v, e, l) => onChange(v, 'phone', i)}
                  value={phone} maxLength={30} flex={"1 1 80px"} />
                <Editbox type="text" label={`${STR.LINK}(URL)`} validate={true} onChange={(v, e, l) => onChange(v, 'link', i)}
                  value={link} maxLength={30} flex={"3 1 200px"} />
              </Formgroup>
              <Formgroup inline={true} className={'fg-url'}>
                <Editbox className={'url'} type="text" label={`${STR.URL}(URL)`} validate={true} onChange={(v, e, l) => onChange(v, 'url', i)}
                  value={url} maxLength={30} flex={"4 1 200px"} />
                <Checkbox className={'md'} list={[{ id: 'check', name: STR.MAIN, check: main }]} onChange={(v) => onChange(v ? v.check : false, 'main', i)} />
              </Formgroup>
            </li>
          })}
        </ul>
        {noti && <p className={'noti'}>{`${noti}`}</p>}
      </div>
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetTransStatus = (props) => {
  const SFORM = (no = 0) => { return { id: no, title: '', text: '' } };
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);
  const [noti, setNoti] = useState(null);
  const [rowid, setRowid] = useState(null);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    setNoti(null);
    actions.doSelect(URL.API.APPINFO, { stag: 'trans', skey: 'status' }).then(({ result }) => {
      const { sval, cfgid } = result && result.length > 0 && result[0];
      if (sval) {
        const arrays = JSON.parse(sval);
        setList(arrays);
        setRowid(cfgid);
      } else {
        const array = [ST.ADMIN.TRANSSTAT[0], ST.ADMIN.TRANSSTAT[1]];
        setList(array);  //입금대기중, 결제완료는 무조건 들어가도록...
        const value = JSON.stringify(array);
        actions.doInsert(URL.API.ADMIN.SETTING, { rowid: rowid || null, stag: 'trans', skey: 'status', sval: value }).then(({ code }) => {
          // props.doReload && props.doReload(code);
        });
      }
    });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const onSave = (eid, e) => {

    let valid = list && list.every(a => a.title && a.text);
    if (!valid) {
      return setNoti(STA.TRANSTAT_HELP);
    }

    valid = list && list.every(a => {
      if (a.title.indexOf('"') >= 0 || a.text.indexOf('"') >= 0) return false;
      if (a.title.indexOf('\\') >= 0 || a.text.indexOf('\\') >= 0) return false;
      return true
    });
    if (!valid) {
      return setNoti(STA.NOT_TEXT);
    }

    // const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    // if (!isvalidate) return;

    const value = JSON.stringify(list);
    actions.doInsert(URL.API.ADMIN.SETTING, { rowid: rowid || null, stag: 'trans', skey: 'status', sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onImport = () => {
    setList([...ST.ADMIN.TRANSSTAT]);
    setModified(true);
  }

  const onClick = (eid) => {
    if (eid === EID.NEW) {
      setList(list ? [...list, { ...SFORM(list.length + 1) }] : [{ ...SFORM(1) }]);
    } else if (eid === EID.DELETE) {
      if (list.length <= 2) return;
      setList(list ? [...list.slice(0, list.length - 1)] : [{ ...SFORM(1) }]);
    } else {
      return;
    }

    setModified(true);
  }

  const onChange = (value, label, pos) => {
    const array = [...list];
    array[pos][label] = value;
    setList(array);
    setModified(true);
  }

  // if (!list) return null;

  return <StyledObject className={cx('set-transtat sect')}>
    <p className={'title'}>{STA.TRANSTAT}</p>
    <Button className={'gd-gray top right preview'} title={ST.PREVIEW} onClick={() => setPreview(!preview)} disabled={modified} />
    <div className={'sec-box'}>
      <div className={'s-row'}>
        <Button className={'green bottom new sm'} title={ST.ADD} eid={EID.NEW} onClick={onClick} />
        <Button className={'black bottom delete sm'} title={ST.DELETE} eid={EID.DELETE} onClick={onClick} />
        <Button className={'gd-gray top right import'} title={STA.IMPORT} onClick={onImport} />
        <ul>
          <p className={'noti'}>{`${STA.TRANSTAT_NOTI}`}</p>
          {list && list.map((item, i) => {
            const { title, text } = item;
            return <li key={i}>
              <Formgroup inline={true}>
                <Editbox type="text" label={ST.TITLE} validate={true} onChange={(v, e, l) => onChange(v, 'title', i)}
                  value={title} maxLength={10} flex={"1 1 100px"} guide={STA.TRANSTAT_TITLE}
                  readonly={i < 2} helper={i < 2 && STA.NO_CHANGE} />
                <Editbox type="text" label={ST.DESC} validate={true} onChange={(v, e, l) => onChange(v, 'text', i)}
                  value={text} maxLength={30} flex={"3 1 200px"} guide={STA.TRANSTAT_DESC} />
              </Formgroup>
            </li>
          })}
        </ul>
        {noti && <p className={'noti'}>{`${noti}`}</p>}
      </div>
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
    {preview && <div className={'t-preview'}><TransBox list={list} status={2} />
      <Button className={'gray bottom right'} title={ST.CLOSE} onClick={() => setPreview(false)} />
    </div>}
  </StyledObject>
}

const SetTransInit = (props) => {
  // var refs = {};
  const [list, setList] = useState(null);
  const [data, setData] = useState(null);
  const [initstate, setInitstate] = useState(-1);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    setModified(false);
    actions.doSelect(URL.API.APPINFO, { stag: 'trans', skey: 'status' }).then(({ result }) => {
      const datas = result && result[0] && JSON.parse(result[0].sval);
      const array = datas.filter(a => Number(a.id) > 2);
      setList(array || null);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'trans', skey: 'initstate' }).then(({ result }) => {
      setData(result && result[0]);
      setInitstate(result && result[0] ? result[0].sval : -1);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    // if (!refs) return;

    // const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    // if (!isvalidate) return;

    const value = { rowid: data ? data.cfgid : null, stag: 'trans', skey: 'initstate', sval: initstate };
    actions.doInsert(URL.API.ADMIN.SETTING, value).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onChange = (eid, e, value) => {
    setInitstate(value.id);
    setModified(true);
  }

  if (!data || !list) return null;

  const pos = list.findIndex(a => String(a.id) === String(initstate));
  return <StyledObject className={cx('set-initstate sect')}>
    <p className={'title'}>{STA.TRANINIT}</p>
    <div className={'sec-box'}>
      <p className={'noti hover'}>{`${STA.TRANINIT_HELP}`}</p>
      <div className={'l-line'}>
        <span className={'tit'}>{ST.ADMIN.ORDER.STATUS}</span>
        <Combobox className={'sm dark full combo'} list={list.map(a => { return { ...a, id: a.id, name: a.title } })}
          options={{ text: { align: 'left' } }} pos={pos} onChange={onChange} />
      </div>
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />

  </StyledObject>
}

const SetProductHistory = (props) => {
  const SFORM = () => { return { title: '', value: '' } };
  const [list, setList] = useState(null);
  const [modified, setModified] = useState(false);
  const [noti, setNoti] = useState(null);
  const [rowid, setRowid] = useState(null);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    setNoti(null);
    actions.doSelect(URL.API.APPINFO, { stag: 'product', skey: 'cfghist' }).then(({ result }) => {
      const { sval, cfgid } = result && result.length > 0 && result[0];
      if (sval) {
        const arrays = JSON.parse(sval);
        setList(arrays);
        setRowid(cfgid);
      } else {
        const array = [...ST.ADMIN.PRODHIST];
        setList(array);
        const value = JSON.stringify(array);
        actions.doInsert(URL.API.ADMIN.SETTING, { rowid: rowid || null, stag: 'product', skey: 'cfghist', sval: value }).then(({ code }) => {
          // props.doReload && props.doReload(code);
        });
      }
    });
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  const onSave = (eid, e) => {

    let valid = list && list.every(a => a.title);
    if (!valid) {
      return setNoti(STA.PRODHIST_HELP);
    }

    valid = list && list.every(a => {
      if (a.title.indexOf('"') >= 0 || a.value.indexOf('"') >= 0) return false;
      if (a.title.indexOf('\\') >= 0 || a.value.indexOf('\\') >= 0) return false;
      return true
    });
    if (!valid) {
      return setNoti(STA.NOT_TEXT);
    }

    const value = JSON.stringify(list);
    actions.doInsert(URL.API.ADMIN.SETTING, { rowid: rowid || null, stag: 'product', skey: 'cfghist', sval: value }).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onClick = (eid) => {
    if (eid === EID.NEW) {
      setList(list ? [...list, { ...SFORM() }] : [{ ...SFORM() }]);
    } else if (eid === EID.DELETE) {
      // if (list.length <= 2) return;
      setList(list ? [...list.slice(0, list.length - 1)] : [{ ...SFORM() }]);
    } else {
      return;
    }

    setModified(true);
  }

  const onChange = (value, label, pos) => {
    const array = [...list];
    array[pos][label] = value;
    setList(array);
    setModified(true);
  }

  // if (!list) return null;

  return <StyledObject className={cx('set-prod-hist sect')}>
    <p className={'title'}>{STA.PRODHIST}</p>
    {/* <Button className={'gd-gray top right preview'} title={ST.PREVIEW} onClick={() => setPreview(!preview)} disabled={modified} /> */}
    <div className={'sec-box'}>
      <div className={'s-row'}>
        <Button className={'green bottom new sm'} title={ST.ADD} eid={EID.NEW} onClick={onClick} />
        <Button className={'black bottom delete sm'} title={ST.DELETE} eid={EID.DELETE} onClick={onClick} />
        <ul>
          <p className={'noti'}>{`${STA.PRODHIST_NOTI}`}</p>
          {list && list.map((item, i) => {
            const { title, value } = item;
            return <li key={i}>
              <Formgroup inline={true}>
                <Editbox type="text" label={ST.ADMIN.CONTENT.TITLE} validate={true} onChange={(v, e, l) => onChange(v, 'title', i)}
                  value={title} maxLength={20} flex={"1 1 100px"} guide={ST.MAXLEN(ST.ADMIN.CONTENT.TITLE, 20)} />
                <Editbox type="text" label={ST.ADMIN.CONTENT.TEXT} validate={true} onChange={(v, e, l) => onChange(v, 'value', i)}
                  value={value} maxLength={300} flex={"3 1 200px"} guide={ST.MAXLEN(ST.ADMIN.CONTENT.TEXT, 300)} multi={true} height={60} maxheight={100} minheigh={40} />
              </Formgroup>
            </li>
          })}
        </ul>
        {noti && <p className={'noti'}>{`${noti}`}</p>}
      </div>
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
    {preview && <div className={'t-preview'}><TransBox list={list} status={2} />
      <Button className={'gray bottom right'} title={ST.CLOSE} onClick={() => setPreview(false)} />
    </div>}
  </StyledObject>
}

const SetCompany = (props) => {
  var refs = {};
  const [data, setData] = useState(null);
  const [list, setList] = useState(null);
  const [post, setPost] = useState(false);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      setList(result && result.length > 0 ? result : null);
      setData(json);
    });
    return () => { }
  }, [props.refresh]);


  // const checkValidat = (obj = null, regexp = /^[0-9a-zA-Z.-_]*$/gi, noti = '') => {
  //   if (!obj) {
  //     console.error('object error');
  //     return false;
  //   }

  //   const isValidate = (v, r) => r.test(v); // ok=true

  //   const value = obj.getValue();
  //   if (!isValidate(value, regexp)) {
  //     obj.showNoti(noti);
  //     return false;
  //   }
  //   return true;
  // }

  const onSave = (eid, e) => {
    if (!refs) return;

    const isvalidate = Object.keys(refs).every((key) => refs[key].isValidateJson());
    if (!isvalidate) return;

    // 회사이름(영문) 입력오류 체크
    // if (!checkValidat(refs.name_eng, /^[0-9a-zA-Z.]*$/gi, STA.NAMEE_N)) return;

    const array = Object.keys(refs).map(key => {
      const item = list && list.find(a => a.skey === key);
      return { rowid: item ? item.cfgid : null, stag: 'company', skey: key, sval: refs[key].getValue() }
    });

    actions.doInsert(URL.API.ADMIN.SETTING, array).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }


  const onClickAddress = (eid, e) => {
    let temps = { ...data };
    Object.keys(refs).map(key => temps[key] = refs[key].getValue());
    setData({ ...temps });
    setPost(!post);
  }

  const onComplete = (address, item) => {
    setTimeout(() => {
      setPost(false);
      setData({ ...data, addr: address, zipcode: item.zonecode });
      setModified(true);
    }, 200);
  }

  if (!data) return null;
  return <StyledObject className={cx('set-comp sect')}>
    <p className={'title'}>{STA.COMPANY}</p>
    <div className={'sec-box'}>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.name = ref }} className={"name"} value={data.name}
          name="name" type="text" label={STA.COMPANY_NAME} validate={true} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.name_eng = ref }} className={"name_eng"} value={data.name_eng}
          name="name_eng" type="text" label={STA.COMPANY_NAME_ENG} validate={true} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.year = ref }} className={"year"} value={data.year}
          name="year" type="number" label={STA.COMPANY_YEAR} validate={true} onChange={() => setModified(true)} />
      </Formgroup>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.phone = ref }} className={"phone"} value={data.phone} name="phone" type="text"
          label={STA.COMPANY_PHONE} validate={true} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.email = ref }} className={"email"} value={data.email} name="email" type="text"
          label={STA.COMPANY_EMAIL} validate={true} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.fax = ref }} className={"fax"} value={data.fax} name="fax" type="text"
          label={STA.COMPANY_FAX} validate={false} onChange={() => setModified(true)} />
      </Formgroup>
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.bank = ref }} className={"bank"} value={data.bank} name="fax" type="text"
          flex={'2 1 100px'} label={STA.COMPANY_BANK} validate={false} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.boss = ref }} className={"boss"} value={data.boss} name="boss" type="text"
          flex={'1 1 100px'} label={STA.COMPANY_BOSS} validate={true} onChange={() => setModified(true)} />
      </Formgroup>
      <div className={'add-box'}>
        <Formgroup inline={true}>
          <div className={'add-btn'} flex={'2 1 100px'} onClick={onClickAddress}>
            <Editbox className={'add-edit'} ref={(ref) => { refs.addr = ref }} value={data.addr}
              label={STA.COMPANY_ADDR} validate={true} readonly={true} />
            <Svg className={'sm right'} icon={'find'} />
          </div>
          <Editbox ref={(ref) => { refs.addr_rest = ref }} value={data.addr_rest} label={STA.COMPANY_ADDR_REST} type="text"
            flex={'1 1 100px'} validate={true} onChange={() => setModified(true)} />
        </Formgroup>
        {post && <Postcode className={'post-box'} onComplete={onComplete} onCancel={() => setPost(false)} />}
      </div>
      {/* <Editbox ref={(ref) => { refs.addr = ref }} className={"addr"} value={data.addr} name="addr" type="text"
        label={STA.COMPANY_ADDR} validate={true} onChange={() => setModified(true)} /> */}
      <Formgroup inline={true}>
        <Editbox ref={(ref) => { refs.bizlicense = ref }} className={"bizlicense"} value={data.bizlicense} name="bizlicense" type="text"
          flex={'2 1 100px'} label={STA.COMPANY_BIZ_LICENSE} validate={false} onChange={() => setModified(true)} />
        <Editbox ref={(ref) => { refs.license = ref }} className={"license"} value={data.license} name="license" type="text"
          flex={'1 1 100px'} label={STA.COMPANY_LICENSE} validate={true} onChange={() => setModified(true)} />
      </Formgroup>
      <Editbox ref={(ref) => { refs.bizurl = ref }} className={"bizurl"} value={data.bizurl} name="bizurl" type="text"
        label={STA.COMPANY_BIZ_URL} validate={false} onChange={() => setModified(true)} />

    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
  </StyledObject>
}

const SetPrivacy = (props) => {
  var refs = null;
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [popup, setPopup] = useState(null);

  useEffect(() => {
    setLoaded(false);
    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'privacy' }).then(({ result }) => {
      setData(result ? result[0] : null);
      setLoaded(true);
      setModified(false);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;
    const value = { rowid: data ? data.cfgid : null, stag: 'app', skey: 'privacy', sval: refs.getValue() };
    actions.doInsert(URL.API.ADMIN.SETTING, value).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onImport = () => {
    setData({ sval: PRIVACY('[*****]', moment().format('YYYY년 MM월 DD일')) });
    setModified(true);
  }

  return <StyledObject className={cx('set-privacy sect')}>
    <p className={'title'}>{ST.COMPANY.PRIVATE}</p>
    <Button className={'gd-gray top right import'} title={STA.IMPORT} onClick={onImport} />
    <Button className={'gd-gray top right preview'} title={ST.PREVIEW} onClick={() => setPopup(true)} disabled={modified} />
    <div className={'sec-box'}>
      <p className={'noti'}>{`${STA.PRIVACY}`}</p>
      {loaded && <Editbox ref={(ref) => { refs = ref }} className={"privacy"} value={data && data.sval}
        type="text" guide={ST.COMPANY.PRIVATE} validate={true} onChange={() => setModified(true)}
        multi={true} height={"1000px"} minheight={400} maxheight={1600} />}
      {!loaded && <Loading className={''} />}
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
    {popup && <PolicyAgree type={'pri'} show={popup} onClick={() => setPopup(false)} />}
  </StyledObject>
}


const SetPublic = (props) => {
  var refs = null;
  const [data, setData] = useState(null);
  const [modified, setModified] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [popup, setPopup] = useState(null);

  useEffect(() => {
    setLoaded(false);
    actions.doSelect(URL.API.APPINFO, { stag: 'app', skey: 'public' }).then(({ result }) => {
      setData(result ? result[0] : null);
      setLoaded(true);
      setModified(false);
    });
    return () => { }
  }, [props.refresh]);

  const onSave = (eid, e) => {
    if (!refs) return;
    const value = { rowid: data ? data.cfgid : null, stag: 'app', skey: 'public', sval: refs.getValue() };
    actions.doInsert(URL.API.ADMIN.SETTING, value).then(({ code, result }) => {
      props.doReload && props.doReload(code);
    });

    return;
  }

  const onImport = () => {
    const { origin } = window.location;
    setData({ sval: PUBLIC('[*****]', origin, moment().format('YYYY년 MM월 DD일')) });
    setModified(true);
  }

  return <StyledObject className={cx('set-public sect')}>
    <p className={'title'}>{ST.COMPANY.PUBLIC}</p>
    <Button className={'gd-gray top right import'} title={STA.IMPORT} onClick={onImport} />
    <Button className={'gd-gray top right preview'} title={ST.PREVIEW} onClick={() => setPopup(true)} disabled={modified} />
    <div className={'sec-box'}>
      <p className={'noti'}>{`* ${STA.PUBLIC}`}</p>
      {loaded && <Editbox ref={(ref) => { refs = ref }} className={"privacy"} value={data && data.sval}
        type="text" guide={ST.COMPANY.PUBLIC} validate={true} onChange={() => setModified(true)}
        multi={true} height={"1000px"} minheight={400} maxheight={1600} />}
      {!loaded && <Loading className={''} />}
    </div>
    <Button className={'red bottom right'} title={ST.SAVE} eid={EID.SAVE} onClick={onSave} disabled={!modified} />
    {popup && <PolicyAgree type={'pub'} show={popup} onClick={() => setPopup(false)} />}
  </StyledObject>
}