import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Util, Thumbbox, Storage, Loading } from 'object/src';
import { EID } from 'object/src/Config';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.cart { ${cs.pos.relative} ${cs.m.center()} ${cs.size.full} ${cs.max.wlg} ${cs.font.md} ${cs.noliststyle}
    ${cs.noselect}

    .ct-head { ${cs.h.get(40)} ${cs.pos.relative} ${cs.m.t40} ${cs.m.b10} 
      .title { ${cs.font.t0} } 
      .button { ${cs.font.darkgray} } 
    }
    .ct-body { ${cs.h.fit} ${cs.bg.white} ${cs.w.full} ${cs.h.fit} ${cs.m.b30} ${cs.p.a30}

      .ct-title { ${cs.disp.block} ${cs.border.bottom} ${cs.border.width(3)} ${cs.border.dark}
        & > span { ${cs.disp.inblock} ${cs.font.line(30)} ${cs.font.center} ${cs.font.thickbold} }
      }

      .product { ${cs.w.calc('100% - 340px')} ${cs.font.left} ${cs.disp.inblock} }
      .count { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.font.center} ${cs.z.over} }
      .price { ${cs.disp.inblock} ${cs.w.get(240)} ${cs.font.center} 
        .pr-sale { ${cs.font.line(20)} ${cs.w.fit} ${cs.align.rtop} ${cs.right(45)} ${cs.top(0)}
          .ps-label { ${cs.font.sm} ${cs.bg.red} ${cs.font.white} ${cs.border.radius(3)} ${cs.p.h5} ${cs.p.v2} }
          .ps-box { ${cs.w.fit} ${cs.pos.relative} ${cs.p.l10} ${cs.font.gray} ${cs.font.sm} ${cs.font.cancelline} ${cs.p.h10} ${cs.p.v2} }
        }
      }

      .ct-list { ${cs.pos.relative} ${cs.min.h(300)} ${cs.p.b50}
        .ul { ${cs.h.calc('100% - 60px')}
          .li { ${cs.disp.block} ${cs.pos.relative} ${cs.disp.autoflex}
            ${cs.h.get(110)} ${cs.border.bottom} ${cs.p.v10}

            .product { ${cs.p.l10} ${cs.h.full}
              .thumb-box { ${cs.w.get(70)} }
              .li-r { ${cs.disp.inblock} ${cs.float.right} ${cs.w.calc('100% - 80px')} ${cs.p.l10} }
            }
            .count { ${cs.h.full} ${cs.pos.relative} ${cs.font.line(70)}
              .box { ${cs.group(5)} 
                & > span { ${cs.w.get(30)} ${cs.h.get(30)} ${cs.font.line(30)} ${cs.disp.inblock} ${cs.font.center} ${cs.box.line} 
                  &.icon { ${cs.bg.lightgray} ${cs.font.dark} ${cs.font.md} ${cs.noselect} ${cs.mouse.pointer} }
                }
              }
              .btn-count { ${cs.align.cbottom} ${cs.bottom(0)} ${cs.font.dark} } 
            }
            .price { ${cs.h.full} ${cs.font.xl} ${cs.font.right} ${cs.p.r50} ${cs.font.orangehover} ${cs.pos.relative} 
              ${cs.font.line(70)} ${cs.font.thickbold}
            }

            .btn-del { ${cs.align.rbottom} ${cs.bottom(0)} ${cs.right(20)} ${cs.font.gray} }
          }
        }
      }
      
      .ct-noti {
        ${cs.min.h(100)} ${cs.box.light} ${cs.bg.color(cs.color.frame)} ${cs.p.a20} 
        ${cs.font.gray} ${cs.font.md} ${cs.font.line(30)} ${cs.m.b30}
        .title { ${cs.font.lg} ${cs.font.dark} ${cs.font.thickbold} }
        .label { ${cs.m.l20} }
        .desc { ${cs.font.orange} ${cs.m.l10} }

        &.nodata { ${cs.align.bottom} ${cs.w.full} ${cs.bottom(0)}}
      }

      .ct-total { ${cs.h.get(60)} ${cs.font.bold} ${cs.font.orangehover} ${cs.font.line(48)} ${cs.p.t10}
        .count { ${cs.disp.inblock} ${cs.w.get(100)} ${cs.p.l20} ${cs.font.xl} }
        .price { ${cs.disp.inblock} ${cs.w.calc('100% - 120px')} ${cs.float.right} ${cs.p.r20} ${cs.font.t1} ${cs.font.right} }
      }

      .ct-btn {
        ${cs.p.v20} ${cs.border.top} ${cs.border.width(3)}
        .shoping { &:hover { .btn-label { ${cs.font.primaryhover} ${cs.font.underline} } } }
        .payment { ${cs.align.right} ${cs.right(40)} }
      }

      .ct-nodata { ${cs.min.h(400)} ${cs.pos.relative}
        .txt { ${cs.align.xcenter} ${cs.font.lg} ${cs.font.gray} ${cs.top(80)} }
        .shoping { ${cs.top(140)} }
      }
    }
  }

  &.pr-noti { ${cs.align.ltop} ${cs.bg.get('#eaeaeaf0')} ${cs.border.shadow()} ${cs.anim.showin()} 
    ${cs.font.md} ${cs.p.a15} ${cs.font.orange} ${cs.border.radius('5px !important')} ${cs.left(0)} ${cs.top(15)}
    ${cs.z.over} ${cs.font.line(16)} ${cs.font.left} ${cs.size.full} ${cs.mouse.pointer}
    ${cs.max.width('calc(100% - 4px)')} ${cs.max.height('calc(100% - 30px)')}
    & > span { ${cs.font.sm} ${cs.align.rbottom} ${cs.bottom(10)} ${cs.right(15)} ${cs.font.darkgray} ${cs.font.underline} }

    &.dupbox { ${cs.max.height('calc(100% - 40px)')} ${cs.top(30)} }
  }

  @media screen and (max-width : 1024px) { 
    .ct-head { ${cs.p.h10} .clear { ${cs.right(10)} } }
  }
  
  @media screen and (max-width : 600px) { 
    &.cart {
      .ct-body { ${cs.p.h10} 
        .ct-list {
          .ul {
            .li { ${cs.p.t50} ${cs.h.get(140)}
              .product { ${cs.w.calc('100% - 200px')}
                .li-r { ${cs.align.ltop} ${cs.left(0)} ${cs.top(10)} ${cs.font.lg} ${cs.font.thickbold} }
              }

              .count { ${cs.align.rbottom} ${cs.w.get(200)} ${cs.h.get(40)} ${cs.bottom(10)} ${cs.right(60)}
                .box { ${cs.pos.relative} ${cs.right(20)} ${cs.bottom(10)} } 
                .btn-count { ${cs.align.unset} ${cs.pos.relative} ${cs.right(0)} } 
              }
              .pr-sale { ${cs.right(10)} }
              .price { ${cs.w.get(200)} ${cs.p.r10} }
              .btn-del { ${cs.right(10)} }
              
            }
          }
        }
      }
    }
  }

  @media screen and (max-width : 480px) { }
}`;

// const API = URL.API.PAGE;

const Cart = (props) => {
  const [list, setList] = useState(null);
  const [total, setTotal] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [trans, setTrans] = useState(null);
  const [running, setRunning] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    doReload();
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doReload = (reload = true, callback = null) => {
    const guid = Storage.getLocalItem('guid');
    let set = new Set();
    actions.doList(URL.API.CART, { guid }, true).then(({ result }) => {
      let value = 0;
      const array = result && result.data.map(a => {
        a.total = a.price * a.count;
        value += a.total;
        a.modified = false;
        a['over'] = (a.rcount < a.count); // 구매량이 재고량을 넘을경우.
        a['dup'] = set.has(a.prodid);
        set.add(a.prodid);
        return a;
      });

      const disable = array.find(a => a.over || a.dup);
      setDisable(disable);

      setList(array);
      setTotal(value);
      reload && setLoaded(true);
      reload && setTimeout(() => props.onLoaded && props.onLoaded(true), 300);
      callback && callback(array);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'trans' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      setTrans(json);
    });
  }

  const onClear = (eid, e) => {
    const guid = Storage.getLocalItem('guid');
    actions.doDelete(URL.API.CART, { isall: true, guid }, list).then(({ code, result }) => {
      doReload();
    });
  }

  const onClickCount = (e, eid, item) => {
    e.stopPropagation();

    item.modified = true;
    item.count = eid === '+' ? item.count + 1 : item.count - 1;
    if (item.count <= 1) item.count = 1;
    if (item.count >= 99) item.count = 99;

    const array = list.map(a => (String(a.rowid) === String(item.rowid)) ? item : a);

    setList(array);
  }

  const onClickUpdate = (e, item) => {
    const { rowid, count } = item;
    actions.doUpdate(URL.API.CART, { rowid, count }, null).then(({ result }) => {
      doReload();
    });
  }

  const onClickDelete = (e, item) => {
    actions.doDelete(URL.API.CART, { rowid: item.rowid }, list).then(({ code, result }) => {
      doReload();
    });
  }

  const onCloseNoti = (item) => {
    const { rowid, rcount } = item;  // rcount = 재고량
    const temp = list.find(a => a.rowid === rowid);
    temp.over = false;
    temp.count = rcount;

    if (rcount <= 0) {
      actions.doDelete(URL.API.CART, { rowid: item.rowid }, list).then(({ code, result }) => {
        setRunning(true);
        doReload(false, () => {
          setRunning(false);
        });
      });
    } else {
      actions.doUpdate(URL.API.CART, { rowid, count: rcount }, null).then(({ result }) => {
        setRunning(true);
        doReload(false, () => {
          setRunning(false);
        });
        // setList([...list]);
      });
    }
  }

  const onCloseDup = (item) => {
    actions.doDelete(URL.API.CART, { rowid: item.rowid }, list).then(({ code, result }) => {
      setRunning(true);
      doReload(false, () => {
        setRunning(false);
      });
    });
  }

  const onClickPayment = () => {
    actions.go(URL.PAYMENT);

    // 테스트
    // const onPayTest = () => {
    //   const guid = Storage.getLocalItem('guid');
    //   const product = `${list[0].title} ${ST.PAYMENT.ETC(list.length)}`;
    //   const data = {
    //     guid, address: `지불도 결제시 테스트하면 잘하리 140-10`,
    //     price: 10000, product, tcost: 0, tkind: 'card'
    //   };
  
    //   actions.doInsert(URL.API.PAGE.ORDER, data).then(({ code, result }) => {
    //     if (code === 0 && result) {
    //       const value = { rowid: result, status: '2', mid: '1', odrno: 'imp_test000001', price: '10000', cardno: '0', msg: ST.PORDER.SUCCESS, ok: true };
    //       actions.doUpdate(URL.API.PAGE.ORDER, value).then(() => { });
    //     } else {
    //       props.showAlert({ msg: ST.NOTI.FAILUER, type: 'err' });
    //     }
    //   });
    // }

    // onPayTest();
  }

  if (!loaded) return null;
  return <StyledObject className={cx('cart')}>
    <div className={'ct-head'}>
      <p className={'title'}>{ST.CART.TITLE}</p>
      <Button className={'clear white box radius right top'} icon={'delete'} iconcolor={'gray'}
        border={{ color: cs.color.lightgray }} eid={EID.CLEAR} title={ST.CART.CLEAR} onClick={onClear} />
    </div>
    <div className={'ct-body'}>
      <div className={'ct-title'}>
        <span className={'product'}>{ST.CART.PRODUCT}</span>
        <span className={'count'}>{ST.CART.COUNT}</span>
        <span className={'price'}>{ST.CART.PRICE}</span>
      </div>
      {list && list.length > 0 ? <React.Fragment>
        <div className={'ct-list'}>
          <ul className={'ul'}>
            {list.map(item => {
              const issale = item.price < item.cost;
              return <li className={'li'} key={item.rowid}>
                <span className={'product'}>
                  <Thumbbox src={item.thumb} width={'70px'} height={'70px'} objectFit={'cover'} />
                  <span className={'li-r'}>
                    <span className={'title'}>{item.title}</span>
                    <span className={'guide'}>{''}</span>
                  </span>
                </span>
                <span className={'count'}>
                  <span className={'box'}>
                    <span className={'icon'} onClick={(e) => onClickCount(e, "-", item)}>{"-"}</span>
                    <span className={'value'}>{item.count}</span>
                    <span className={'icon'} onClick={(e) => onClickCount(e, "+", item)}>{"+"}</span>
                  </span>
                  <Button className={'btn-count box radius white xs'} border={{ color: cs.color.gray }}
                    title={ST.CART.UPDATE} onClick={(e) => onClickUpdate(e, item)} disable={!item.modified} />
                </span>
                <span className={'price'}>
                  {issale && <div className={'pr-sale'}>
                    <span className={'ps-label'}>{ST.TAG.SALE}</span>
                    <span className={'ps-box'}>{`${Util.commas(item.cost)} ${ST.WON}`}</span>
                  </div>}
                  <span className={'pv'}>{`${Util.commas(item.price)}`}</span>
                  <span className={'pu'}>{ST.WON}</span>
                  <Button className={'btn-del box radius white xs'} border={{ color: cs.color.gray }} title={ST.DELETE} onClick={(e) => onClickDelete(e, item)} />
                </span>
                {item.over && <Notibox item={item} onClose={onCloseNoti} />}
                {item.dup && <Dupbox item={item} onClose={onCloseDup} />}
              </li>
            })}
          </ul>
        </div>
        <TransEvent className={''} {...trans} price={total} />
        <div className={'ct-total'}>
          <span className={'count'}>{`${ST.CART.TOTAL(list ? list.length : 0)}`}</span>
          <span className={'price'}>{`${Util.commas(total)} ${ST.WON}`}</span>
        </div>
        <div className={'ct-btn'}>
          <Button className={'shoping left lg'} title={ST.CART.SHOPING} onClick={() => props.history.push(URL.ROOT)} />
          <Button className={'payment orange box radius lg'} title={ST.CART.CARTPAY} onClick={onClickPayment} disabled={disable} />
        </div>
      </React.Fragment> : <div className={'ct-nodata'}>
          <span className={'txt'}>{ST.CART.NODATA}</span>
          <Button className={'shoping orange box radius center lg'} title={ST.CART.SHOPING} onClick={() => props.history.push(URL.ROOT)} />
          <TransEvent className={'nodata'} {...trans} price={total} />
        </div>}
    </div>
    {running && <Loading className={'fixed'} type={'ring'} />}
  </StyledObject >
};

export default Cart;

const TransEvent = (props) => {
  const { className, tmax, price = 0, tcost = 0 } = props;

  // 배송비 무료 이벤트가 없으면...
  if (!tmax || Number(tmax) <= 0) return null;

  const trest = Number(tmax) - Number(price); // 배송비무료 - 구매금액
  return <div className={cx('ct-noti', className)}>
    <div>
      <span className={'title'}>{ST.CART.EVENT_T}</span>
    </div>
    <div>
      <span className={'label'}>{ST.CART.EVENT_L(Util.commas(tmax), Util.commas(tcost))}</span>
      {trest > 0 && <span className={'desc'}>{ST.CART.EVENT_D(Util.commas(trest))}</span>}
    </div>
  </div>
}

const Notibox = ({ item, onClose }) => {
  let noti = ST.ADMIN.PRODUCT.RCOUNT_NOTI(item && item.rcount);
  if (item && item.rcount <= 0) noti = ST.ADMIN.PRODUCT.RCOUNT_ZERO();
  return <StyledObject className={'pr-noti'} onClick={(e) => onClose && onClose(item)}>
    <p>{noti}</p><span>{ST.CLOSE}</span>
  </StyledObject>
}

const Dupbox = ({ item, onClose }) => {
  return <StyledObject className={'pr-noti dupbox'} onClick={(e) => onClose && onClose(item)}>
    <p>{ST.CART.DUP_NOTI}</p><span>{ST.CLOSE}</span>
  </StyledObject>
}