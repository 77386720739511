import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Thumbbox, Kakaomap } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.about {
    .bg { ${cs.z.back} ${cs.pos.relative} ${cs.bg.frame}
      ${cs.h.get('100vh')} ${cs.w.get('100%')} ${cs.pos.fixed} ${cs.top(0)} ${cs.bg.size('cover')}
      .bg-img { ${cs.size.full} ${cs.object.cover}}
      .copy { ${cs.font.white} ${cs.align.cbottom} ${cs.bottom(30)} ${cs.opac.alpha} ${cs.font.md} }
    }

    .ab-navi { ${cs.w.full} ${cs.h.get(60)} ${cs.bg.alphablack} ${cs.pos.fixed} ${cs.top(0)} ${cs.z.header}
      .p-frame { ${cs.align.xcenter} ${cs.max.wxl} ${cs.font.right} ${cs.p.t5} ${cs.w.full}
        .title { ${cs.align.left} ${cs.font.t3} ${cs.font.himelody} ${cs.font.orange} ${cs.mouse.pointer} }
        .button { ${cs.font.white} ${cs.font.lg} ${cs.font.bold} ${cs.p.v5} ${cs.border.bottom} ${cs.border.width(3)}
          ${cs.border.trans} ${cs.border.radius(0)} ${cs.m.t10}
          &:hover, &.active { ${cs.opac.visible} ${cs.border.orange} ${cs.font.orange} }
          .btn-label { ${cs.font.deco('none')} }
        }
      }
    }
    
    .ab-body { ${cs.min.h(600)} ${cs.h.full} ${cs.z.front} }
  }

  &.section { ${cs.bg.trans} ${cs.h.get('100vh')} ${cs.p.get(80)} ${cs.pos.relative}
    .box { ${cs.w.dslg} ${cs.min.h(480)} ${cs.h.fit} ${cs.align.center} ${cs.box.line} ${cs.box.radius} ${cs.bg.white} 
      .pl { ${cs.w.get(160)} ${cs.pos.relative}
        .img { ${cs.align.xcenter} ${cs.top(40)} .tmb-img { ${cs.radius.all(50)} } }
      }
      .pr { ${cs.w.calc('100% - 160px')} ${cs.p.l20} ${cs.p.right(120)} ${cs.float.right} 
        .tit { ${cs.font.t2} ${cs.font.himelody} ${cs.font.line(80)} ${cs.p.t20} }
        .txt { ${cs.font.sm} ${cs.font.prewrap} ${cs.font.line(18)} ${cs.p.t10} ${cs.font.bold} }
      }
    }

    &.s-info { 
      .label { ${cs.font.t5} ${cs.font.center} ${cs.font.preline} ${cs.font.white} ${cs.align.center}
        ${cs.font.himelody} ${cs.top('calc(50% - 160px)')} ${cs.max.w('100%')}
      }
      .logo { ${cs.size.get(120)} ${cs.align.center}
        & > img { ${cs.pos.relative} ${cs.size.get('100%')} }
      }
      .title { ${cs.font.t1} ${cs.font.center} ${cs.font.preline} ${cs.font.white} ${cs.align.center}
        ${cs.font.himelody} ${cs.top('calc(50% + 100px)')}
      }
    }

    &.s-about { 
      .pl { ${cs.w.get(160)} ${cs.pos.relative}
        .boss-img { ${cs.align.xcenter} ${cs.top(40)} .tmb-img { ${cs.radius.all(50)} } }
      }
      .pr { ${cs.w.calc('100% - 160px')} ${cs.p.l20} ${cs.p.right(120)} ${cs.float.right} 
        .tit { ${cs.font.t2} ${cs.font.himelody} ${cs.font.line(80)} ${cs.p.t20} }
        .txt { ${cs.font.sm} ${cs.font.prewrap} ${cs.font.line(18)} ${cs.p.t10} ${cs.font.bold} }
      }
    }
    &.s-menu { }
    &.s-store {
      .pl { ${cs.w.get(160)} ${cs.pos.relative}
        .boss-img { ${cs.align.xcenter} ${cs.top(40)} .tmb-img { ${cs.radius.all(50)} } }
      }
      .pr { ${cs.w.calc('100% - 160px')} ${cs.p.l20} ${cs.p.right(120)} ${cs.float.right} 
        .tit { ${cs.font.t2} ${cs.font.himelody} ${cs.font.line(80)} ${cs.p.t20} }
        .txt { ${cs.font.sm} ${cs.font.prewrap} ${cs.font.line(18)} ${cs.p.t10} ${cs.font.bold} }

        .body { ${cs.m.b50}
          .store-box { ${cs.m.t30} ${cs.border.top} ${cs.border.darkgray}
            .map { ${cs.w.full} ${cs.h.get(260)} ${cs.m.t10} }
          }
          .title { ${cs.font.black} ${cs.font.thickbold} ${cs.font.md} }
          .addr { ${cs.p.l5} ${cs.font.dark} }
        }
      }
    }
    &.s-shop { }
  }

  @media screen and (max-width : 1024px) { }
  
  @media screen and (max-width : 600px) { 
    &.about {
      .ab-navi { ${cs.h.get(80)}
        .p-frame { ${cs.h.full} ${cs.p.a0}
          .title { ${cs.pos.relative} ${cs.disp.block} ${cs.w.full} ${cs.font.center} ${cs.font.t2} ${cs.h.get(40)} } 
          .menus { ${cs.pos.relative} ${cs.font.center} ${cs.w.full}
            .button { ${cs.p.h5} ${cs.m.h5} ${cs.m.t0} ${cs.font.md} }
          } 
        }
      }
    }

    &.section {
      ${cs.p.top(100)} ${cs.p.h20}
      .box { ${cs.w.full} ${cs.pos.relative} ${cs.align.unset}
        .pl, .pr { ${cs.disp.block} ${cs.w.full} ${cs.pos.relative} ${cs.float.none} }
        .pl { ${cs.h.get(120)} }
        .pr { ${cs.p.h20} ${cs.h.fit} .tit { ${cs.font.t1} ${cs.font.center} } }
      }
      &.s-info {
        .label { ${cs.font.t3} }
      }
      &.s-store {
        ${cs.h.fit}
      }
    }
  }

  @media screen and (max-width : 480px) { }
}`;

// const API = URL.API.PAGE;

const moveToScroll = (id = null, margin = 0, smooth = true) => {
  const node = id ? document.getElementById(id) : null;
  if (node) {
    if (smooth) {
      window.scrollTo({ top: node.offsetTop - margin, behavior: 'smooth' });
    } else {
      window.scrollTo(0, node.offsetTop - margin);
    }
  }
};

const About = (props) => {
  const [app, setApp] = useState(null);
  const [data, setData] = useState(null);
  const [select, setSelect] = useState('s-info');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'app' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      setApp(json);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'about' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      setData(json);
      setLoaded(true);
    });

    return () => { }
  }, []);

  const onClick = (eid) => {
    moveToScroll(eid, 0);
    setSelect(eid);
  }

  if (!loaded) return null;
  return <StyledObject className={cx('about')}>
    <div className={'bg'}>
      <span className={'copy'}>{ST.COPYRIGHT(data.name)}</span>
      {data.bg_img && <img className={'bg-img'} src={data.bg_img} alt={'bg-img'} />}
    </div>
    <div className={'ab-navi'}>
      <div className={'p-frame'}>
        <div className={'title'} onClick={() => actions.go(URL.ROOT, {})}>{data && data.company}</div>
        <div className={'menus'}>
          <Button className={cx('trans', select === 's-info' && 'active')} title={ST.ABOUT.INFO} onClick={() => onClick('s-info')} />
          <Button className={cx('trans', select === 's-about' && 'active')} title={ST.ABOUT.SLOGAN} onClick={() => onClick('s-about')} />
          <Button className={cx('trans', select === 's-store' && 'active')} title={ST.ABOUT.STORE} onClick={() => onClick('s-store')} />
          <Button className={'trans'} title={ST.ABOUT.SHOP} onClick={() => actions.go(URL.ROOT, {})} />
        </div>
      </div>
    </div>
    <div className={'ab-body'}>
      <SecInfo data={data} />
      <SecAbout data={data} />
      {/* <SecMenu data={data} /> */}
      <SecStore data={data} kakaokey={app && app.kakaokey} />
    </div>
  </StyledObject>
};

const SecInfo = (props) => {
  const { data } = props;

  if (!data) return null;
  return <StyledObject className={'section s-info'} id={'s-info'} >
    <div className={'label'}>{data.label}</div>
    <div className={'logo'}><Thumbbox className={'full'} src={data.icon_img} alt='icon' /></div>
    <div className={'title'}>{data.company}</div>
  </StyledObject>
}

const SecAbout = (props) => {
  const { data } = props;

  if (!data) return null;
  return <StyledObject className={'section s-about'} id={'s-about'} >
    <div className={'box'}>
      <div className={'pl'}>
        <Thumbbox className={'img'} src={data.boss_img} objectFit={'cover'} width={'100px'} height={'100px'} alt='icon' />
      </div>
      <div className={'pr'}>
        <p className={'tit'}>{data.label}</p>
        <p className={'txt'}>{data.summary}</p>
      </div>
    </div>
  </StyledObject>
}

// const SecMenu = (props) => {
//   return <StyledObject className={'section s-menu'}>
//     <div className={'box'}></div>
//   </StyledObject>
// }

const SecStore = (props) => {
  const [data, setData] = useState(null);
  const [store, setStore] = useState(null);

  useEffect(() => {
    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {}
      result.map(a => json[a.skey] = a.sval);
      setData(json);
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'store' }).then(({ result }) => {
      const array = result && result.map(a => JSON.parse(a.sval));
      setStore(array);
    });

    return () => { }
  }, [])

  if (!data || !props.data) return null;
  return <StyledObject className={'section s-store'} id={'s-store'} >
    <div className={'box'}>
      <div className={'pl'}>
        <Thumbbox className={'img'} src={props.data.store_img} objectFit={'cover'} width={'100px'} height={'100px'} alt='icon' />
      </div>
      <div className={'pr'}>
        <p className={'tit'}>{ST.ABOUT.STORE_COME}</p>
        <div className={'head'}>
          <p className={'txt'}>{`${ST.ABOUT.PHONE}: ${data.phone || ''}`}</p>
          <p className={'txt'}>{`${ST.ABOUT.ADDR}: ${data.addr || ''}`}</p>
        </div>
        <div className={'body'}>
          {store && store.map((item, i) => <StoreBox key={i} rowid={`kmap-${i}`} data={item} kakaokey={props.kakaokey} />)}
        </div>
      </div>
    </div>
  </StyledObject>
}

export default About;

const StoreBox = (props) => {
  const { data, kakaokey = null, rowid = 0 } = props;

  if (!data) return null;
  return <StyledObject className={'store-box'}>
    <div className={'txt'}>
      <span className={'title'}>{data && data.title}</span>
      <span className={'addr'}>{data && `[${data.address}]`}</span>
    </div>
    <p className={'txt'}>{`${ST.ABOUT.STORE_OPEN}: ${data && data.open}`}</p>
    <div className={'map'} >
      {rowid && <Kakaomap position={data && data.pos} kakaokey={kakaokey} rowid={rowid} />}
    </div>
  </StyledObject>
}