import React from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs } from 'object/src';
import { URL } from 'svc/Enum';

const StyledObject = styled.div`{
  &.sys-init { ${cs.bg.trans} ${cs.max.wmd} ${cs.w.full}
    ${cs.m.left(100)} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(30)}

    .title { ${cs.font.t1} ${cs.font.center} ${cs.w.full} ${cs.m.t20} ${cs.m.b5} }
    .p-gbook { ${cs.font.md} ${cs.underline} ${cs.mouse.pointer} ${cs.m.b40}
      ${cs.font.green} ${cs.w.full} ${cs.font.center} ${cs.disp.block}
    }

    .p-line { ${cs.pos.relative} ${cs.noselect} ${cs.mouse.pointer} ${cs.m.b30} 
      .p-tit { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} 
        .p-st { ${cs.font.sm} ${cs.p.l5} ${cs.font.gray} }
      }
      .p-txt { ${cs.font.lg} ${cs.font.bold} ${cs.font.prewrap} ${cs.font.lightgray} ${cs.p.l30} ${cs.p.b5} 
        &:hover { ${cs.font.primary} ${cs.font.underline} }
      }
    }
  }

  &.p-line { ${cs.pos.relative} ${cs.noselect} ${cs.mouse.pointer} ${cs.m.b30}
    .p-tit { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} }
    .p-txt { ${cs.font.md} ${cs.font.prewrap} ${cs.font.gray} ${cs.p.l30} ${cs.p.b5} }
    .p-gde { ${cs.font.sm} ${cs.font.prewrap} ${cs.font.gray} ${cs.p.l40} ${cs.font.line(18)} }
    .p-btn { ${cs.font.md} ${cs.font.gray} ${cs.align.rtop} ${cs.top(20)} ${cs.w.get(100)} ${cs.font.right} ${cs.p.r10} }
    &:hover { 
      .p-tit, .p-btn { ${cs.font.yellow} ${cs.border.color(cs.color.yellow)} } 
      .p-gde, .p-txt { ${cs.font.lightgray} }
    }
  }

  @media screen and (max-width : 860px) {
    &.sys-init { ${cs.p.h10} ${cs.m.l0}
      .title { ${cs.font.t0} }
      .p-line { 
        .p-tit { ${cs.font.lg} ${cs.font.bold}
          .p-st { ${cs.font.sm} }
        }
        .p-txt { ${cs.font.sm} }
        .p-btn { ${cs.top(10)} }
      }
    }
  }
}`;

const STA = ST.ADMIN.SYSINIT;
const UA = URL.ADMIN;

const SysInits = (props) => {
  return <StyledObject className={cx('sys-init')}>
    <p className={'title'}>{STA.TITLE}</p>
    <a className={'p-gbook'} href='/guidebook.pdf' download>{STA.GBOOK}</a>
    {/* <p className={'p-gbook'} onClick={() => window.open('/guidebook.pdf')}>{STA.GBOOK}</p> */}
    <div className={'p-head'}>
      <div className={'p-line'}>
        <div className={'p-tit'}>
          <span>{`${STA.HEAD}`}</span>
          <span className={'p-st'}>{`(${STA.HEAD_G})`}</span>
        </div>
        <p className={'p-txt link'} onClick={() => window.open(URL.GMAIL)}>{`1. ${STA.GMAIL}`}</p>
        <p className={'p-txt link'} onClick={() => window.open(URL.KAKAO_DEVELOP)}>{`2. ${STA.KAKAO}`}</p>
        <p className={'p-txt link'} onClick={() => window.open(URL.NAVER_DEVELOP)}>{`3. ${STA.NAVER}`}</p>
        <p className={'p-txt link'} onClick={() => window.open(URL.IAMPORT_DEVELOP)}>{`4. ${STA.IAMPORT}`}</p>
        <p className={'p-txt link'} onClick={() => window.open(URL.NCP_DEVELOP)}>{`5. ${STA.NCP}`}</p>
      </div>
    </div>
    <Linebox title={`${STA.ABOUT}`} text={STA.ABOUT_H} guide={STA.ABOUT_G} url={UA.ABOUT} />
    <Linebox title={`${STA.WEBAPP}`} text={STA.WEBAPP_H} guide={STA.WEBAPP_G} url={UA.WEBSET} />
    <Linebox title={`${STA.SETTING}`} text={STA.SETTING_H} guide={STA.SETTING_G} url={UA.SETTING} />
    <Linebox title={`${STA.MAILER}`} text={STA.MAILER_H} guide={STA.MAILER_G} url={UA.MAILER} />
    <Linebox title={`${STA.PASS}`} text={STA.PASS_H} guide={STA.PASS_G} url={UA.MANAGEMENT} />
  </StyledObject>
};

const Linebox = (props) => {
  const { title = '', text = '', guide = '', button = STA.LINK, eid = 'comp', url = null } = props;

  const onClick = (e, eventid, param) => {
    if (props.onClick) props.onClick(e, eventid, param);
    else if (url) {
      window.location.href = url;
    }
  }

  return <StyledObject className={'p-line'} onClick={(e) => onClick(e, eid)}>
    <p className={'p-tit'}>{title}</p>
    <p className={'p-txt'}>{text}</p>
    <p className={'p-gde'}>{guide}</p>
    <p className={'p-btn'}>{button}</p>
  </StyledObject>
}

export default SysInits;