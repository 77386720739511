import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { openConfirm, openModal, openSidemenu, showAlert, eventLayout } from 'object/src/actor/Reducer';
import { connect } from 'react-redux';
import * as actions from 'object/src/actor/Action';
import axios from 'axios';

// 기본적인 스타일 선언.
import './css/Default.css';
import './css/react-draft-wysiwyg.css';

import { Header, Footer, Storage, cs, Button, Sidebar, Layout, Svg, Error } from 'object/src';
import { ST } from 'svc/Lang';
import { URL, CODE } from 'svc/Enum';
import cx from 'classnames/bind';
import { STAT } from './object/src/Config';
import styled from 'styled-components';

// import socketio from 'socket.io-client';

import {
  Signin, Signup, Homepage, Cart, Mypage, Product, About, Board, Payment, Order, Paycomp, Signto, Privacy, Qna
} from 'view';

import * as adm from 'admin';
import { Util } from 'object/src/Utils';

const StyledObject = styled.div`{
  &.user-layout { ${cs.bg.white} ${cs.min.height('100vh')} ${cs.disp.autoflex} ${cs.disp.flexgrow()}
    .ft-frame { ${cs.pos.relative} 
      .ft-box { ${cs.pos.relative} 
        ${cs.w.full} ${cs.max.wxl} ${cs.m.center()} ${cs.font.left}
        .menu-bar { 
          ${cs.border.bottom} ${cs.m.v10} ${cs.p.b0} ${cs.font.right} ${cs.font.color(cs.color.lightprimary)}
          ${cs.font.thickbold}
        }
        .info-bar { ${cs.font.sm} ${cs.font.line(20)} ${cs.p.bottom(100)} ${cs.font.lightgray} ${cs.m.t30} ${cs.p.h10}
          .ib-title { ${cs.p.r5} }
          .ib-text { ${cs.p.r20} }
          .ib-link { ${cs.font.white} ${cs.m.r20} ${cs.mouse.pointer} &:hover { ${cs.font.underline} ${cs.font.primary} }  }
          .copyright { ${cs.m.t5} }
        }
        .comp-name { ${cs.align.ltop} ${cs.font.t0} ${cs.font.lightprimary} }
      }
    }
    .m-footer {
      ${cs.anim.showin('200ms', 0, 1, 'm-foot-anim-show')} 
    }

    @media screen and (max-width : 1024px) { 
    }
    
    @media screen and (max-width : 600px) { 
      .m-footer .ft-frame .ft-box {
        .comp-name { ${cs.disp.none} }
        .menu-bar { ${cs.font.center}
          .button { ${cs.p.h10} 
            .btn-label { ${cs.font.sm} } 
          } 
        } 
      }
    }
  
    @media screen and (max-width : 480px) { }
  }

  &.no-admin { ${cs.bg.white}
    .p-noti { ${cs.w.full} ${cs.font.t3} ${cs.font.center} ${cs.align.ycenter} ${cs.font.darkgray} ${cs.font.himelody} 
      ${cs.mouse.pointer}
    }
  }

  &.admin-layout { ${cs.bg.dark}
    .admin-frame { ${cs.bg.dark} ${cs.font.white} ${cs.min.wxl}
      &.menuout { .ad-page { ${cs.m.h0} ${cs.p.h10} } }
    }
    .guide-btn { ${cs.pos.fixed} ${cs.z.get(199999)} ${cs.left(0)} ${cs.bottom(20)} 
      ${cs.font.white} ${cs.font.thickbold} ${cs.font.lg} ${cs.opac.get(0.3)}
      &:hover { ${cs.opac.show} }
    }

    @media screen and (max-width : 1024px) { 
      .admin-frame { ${cs.min.width('100%')} }
      .guide-btn { ${cs.left('none')} ${cs.right(0)}  ${cs.bottom(5)} }
    }
  
    @media screen and (max-width : 600px) { 
    }
  
    @media screen and (max-width : 480px) { }
    }
  }

  &.noti-box {
    ${cs.pos.fixed} ${cs.right(0)} ${cs.top(0)} ${cs.disp.inblock}
    ${cs.float.right} ${cs.z.popup} ${cs.noselect}

    .nt-button {
      ${cs.pos.relative} ${cs.right(20)} ${cs.top(10)} ${cs.disp.inblock}
      ${cs.size.get(40)} ${cs.float.right} ${cs.border.radius(50)} 
      ${cs.p.get(6)} ${cs.box.line} ${cs.anim.slidein('200ms', '120%', '0', 'noti-btn')} 
      .nt-count { 
        ${cs.align.rtop} ${cs.left(-2)} ${cs.top(0)} ${cs.bg.red} ${cs.font.white}
        ${cs.font.thickbold} ${cs.font.center} ${cs.border.radius(50)} ${cs.font.xs}
        ${cs.h.get(16)} ${cs.font.line(16)} ${cs.w.get(24)} ${cs.z.over}
      }

      .svg-path { ${cs.fill.get('yellow !important')} ${cs.opac.get("1 !important")} }

      .alarm { ${cs.anim.flicking("500ms", '1', '0.3')} }
    }

    .nt-frame {
      ${cs.font.xs} ${cs.font.white} ${cs.w.get(200)} ${cs.z.over}
      ${cs.bg.get('rgba(0,0,0,0.9)')} ${cs.h.fit} ${cs.p.a10} ${cs.align.rbottom}
      ${cs.top(30)} ${cs.right(50)} ${cs.border.radius(5)} ${cs.mouse.pointer}
      ${cs.disp.none}

      .n-li { ${cs.h.get(34)} ${cs.border.bottom} ${cs.pos.relative} ${cs.p.t5} ${cs.border.dark}
        .n-title { ${cs.disp.block} }
        .n-date { ${cs.disp.inblock} ${cs.align.rbottom} ${cs.bottom(2)} ${cs.font.gray} }
        &:last-child { ${cs.border.none} }
      }

      &.show { ${cs.disp.block} ${cs.anim.slideup('200ms', '-120%', '0', 'noti-anim')} }
    }
  }
}`;

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    const guid = Storage.getLocalItem('guid');
    if (!guid) {
      Storage.setLocalItem('guid', Util.getUuid());
    }

    this.menu = {
      home: [
        { name: ST.MENU.HOME, url: URL.ROOT, comp: Homepage, title: ST.MENU.HOME, hide: true },
        { name: ST.MENU.SIGNIN, url: URL.SIGNIN, comp: Signin, title: ST.MENU.SIGNIN },
        { name: ST.MENU.SIGNUP, url: URL.SIGNUP, comp: Signup, title: ST.MENU.SIGNUP },
        { name: ST.MENU.CART, url: URL.CART, comp: Cart, title: ST.MENU.CART },
        { name: ST.MENU.PRODUCT, url: URL.PRODUCT, comp: Product, title: ST.MENU.PRODUCT, hide: true },
        { name: ST.MENU.BOARD, url: URL.BOARD, comp: Board, title: ST.MENU.BOARD, hide: true },
        { name: ST.MENU.QNA, url: URL.QNA, comp: Qna, title: ST.MENU.QNA, hide: true },
        { name: ST.MENU.PAYMENT, url: URL.PAYMENT, comp: Payment, title: ST.MENU.PAYMENT, hide: true },
        { name: '', url: URL.PRIVACY, comp: Privacy, title: '', hide: true },
        // { name: 'icons', url: URL.ICONS, comp: Icons, title: 'icons' },
      ],
      member: [
        { name: ST.MENU.HOME, url: URL.ROOT, comp: Homepage, title: ST.MENU.HOME, hide: true },
        { name: ST.MENU.MYPAGE, url: URL.MYPAGE, comp: Mypage, title: ST.MENU.MYPAGE },
        { name: ST.MENU.CART, url: URL.CART, comp: Cart, title: ST.MENU.CART },
        { name: ST.MENU.LOGOUT, url: URL.LOGOUT, comp: null, title: ST.MENU.LOGOUT },
        { name: ST.MENU.PRODUCT, url: URL.PRODUCT, comp: Product, title: ST.MENU.PRODUCT, hide: true },
        { name: ST.MENU.DETAIL, url: URL.DETAIL, comp: Mypage, title: ST.MENU.DETAIL, hide: true },
        { name: ST.MENU.BOARD, url: URL.BOARD, comp: Board, title: ST.MENU.BOARD, hide: true },
        { name: ST.MENU.QNA, url: URL.QNA, comp: Qna, title: ST.MENU.QNA, hide: true },
        { name: ST.MENU.PAYMENT, url: URL.PAYMENT, comp: Payment, title: ST.MENU.PAYMENT, hide: true },
        { name: '', url: URL.PAYCOMP, comp: Paycomp, title: '', hide: true },
      ],
      admin: [
        { name: ST.ADMIN.NAVI.MAIN, url: URL.ADMIN.MAIN, comp: adm.Main, title: ST.ADMIN.NAVI.MAIN },
        { name: ST.ADMIN.NAVI.MENU, url: URL.ADMIN.MENU, comp: adm.Menu, title: ST.ADMIN.NAVI.MENU },
        { name: ST.ADMIN.NAVI.PRODUCT, url: URL.ADMIN.PRODUCT, comp: adm.Product, title: ST.ADMIN.NAVI.PRODUCT },
        { name: ST.ADMIN.NAVI.CONTENT, url: URL.ADMIN.CONTENT, comp: adm.Content, title: ST.ADMIN.NAVI.CONTENT, hide: true },
        { name: ST.ADMIN.NAVI.ORDER, url: URL.ADMIN.ORDER, comp: adm.Order, title: ST.ADMIN.NAVI.ORDER },
        { name: ST.ADMIN.NAVI.HISTORY, url: URL.ADMIN.HISTORY, comp: adm.History, title: ST.ADMIN.NAVI.HISTORY },
        { name: ST.ADMIN.NAVI.HISTORY, url: URL.ADMIN.HISTORY_ORDER, comp: adm.HistoryOrder, title: ST.ADMIN.NAVI.HISTORY, hide: true },
        { name: ST.ADMIN.NAVI.BOARD, url: URL.ADMIN.BOARD, comp: adm.Board, title: ST.ADMIN.NAVI.BOARD },
        { name: ST.ADMIN.NAVI.QNA, url: URL.ADMIN.QNA, comp: adm.Qna, title: ST.ADMIN.NAVI.QNA },
        { name: ST.ADMIN.NAVI.HOME, url: URL.ADMIN.HOME, comp: adm.Home, title: ST.ADMIN.NAVI.HOME },
        { name: ST.ADMIN.NAVI.MEMBER, url: URL.ADMIN.MEMBER, comp: adm.Member, title: ST.ADMIN.NAVI.MEMBER },
        { name: ST.ADMIN.NAVI.SYSINIT, url: URL.ADMIN.SYSINIT, comp: adm.SysInits, title: ST.ADMIN.NAVI.SYSINIT, hide: true },
        { name: ST.ADMIN.NAVI.ABOUT, url: URL.ADMIN.ABOUT, comp: adm.About, title: ST.ADMIN.NAVI.ABOUT, hide: true, type: 'system' },
        { name: ST.ADMIN.NAVI.WEBSET, url: URL.ADMIN.WEBSET, comp: adm.Webset, title: ST.ADMIN.NAVI.WEBSET, hide: true, type: 'system' },
        { name: ST.ADMIN.NAVI.SETTING, url: URL.ADMIN.SETTING, comp: adm.Setting, title: ST.ADMIN.NAVI.SETTING, hide: true, type: 'system' },
        { name: ST.ADMIN.NAVI.MAILER, url: URL.ADMIN.MAILER, comp: adm.Mailer, title: ST.ADMIN.NAVI.MAILER, hide: true, type: 'system' },
        { name: ST.ADMIN.NAVI.MANAGEMENT, url: URL.ADMIN.MANAGEMENT, comp: adm.Management, title: ST.ADMIN.NAVI.MANAGEMENT, hide: true, type: 'system' },
        // { name: ST.ADMIN.NAVI.GUIDE, url: URL.ADMIN.Guide, comp: adm.GuideBox, title: ST.ADMIN.NAVI.GUIDE, hide: true },
        { name: ST.ADMIN.NAVI.LOGOUT, url: URL.LOGOUT, comp: null, title: ST.ADMIN.NAVI.LOGOUT },
      ]
    };

    const { hash = null, pathname = null, search = null } = window.location;
    const isadmin = pathname.indexOf('/admin') === 0;
    if (isadmin) {
      this.state = { menus: [...this.menu.admin], loaded: true, isadmin };
    } else {
      if (hash && search && search.indexOf("?naver=true") === 0) {
        // 로그인 실패 재로그인
        this.onNaverSuccess(hash, () => {
          // let menu = Storage.getToken() ? [...this.menu.member] : [...this.menu.home];
          // this.setState({ menus: [...this.menu.member], loaded: true, isadmin: false });
          window.location.href = URL.ROOT;
        });
        this.state = { menus: [...this.menu.home], loaded: false, isadmin: false };
      } else {
        let menu = Storage.getToken() ? [...this.menu.member] : [...this.menu.home];
        this.state = { menus: menu, loaded: true, isadmin: false };
      }
    }
  }

  onNaverSuccess = (res, callback = null) => {
    const temps = res.split("&");
    const token = temps[0].substr(1).replace('access_token=', '');
    const state = temps[1].replace('state=', '');

    const value = { type: 'naver', userid: token, password: state, token };
    if (value.token) {

      axios.post(URL.SIGNIN_NAVER, value).then((result) => {
        const { data } = result;
        if (data && data.code === CODE.SUCCESS) {
          Storage.setLocalItem(Storage.key.userinfo, data.value);
          const { userid } = data.value;
          const guid = Storage.getLocalItem('guid');
          callback && callback(result);
          actions.doUpdate(URL.API.CART_CLEAR, { userid, guid }, null).then(() => { });
        } else {
          // 로그인 실패시
          actions.go(URL.SIGNIN, { type: 'naver', tag: 'loginerror' });
        }
      }).catch((err) => {
        // 로그인 실패시
        console.error(err);
        // actions.go(URL.SIGNIN, { type: 'naver', url: URL.SIGNUP, time: 3, tag: 'signup' });
      });
    }
  }

  doLoadPageAndMenu = () => {
    actions.doSelect(URL.API.TOKEN).then(({ result, config }) => {
      this.setState({ loaded: true });
    })
  }

  render() {
    const props = this.props;
    const { menus = null, isadmin = false, loaded = false } = this.state;

    if (!menus) return null;

    const user = Storage.getLocalItem(Storage.key.userinfo);
    const { pathname } = window.location;

    return (
      <Router>
        <Switch>
          {/* 로그인 권한이 필요하지 않은 화면들... */}
          <Route exact path={URL.SIGNIN} render={(matchProps) => <Signin {...matchProps} onLoaded={() => { }} />} {...props} /> {/* 로그인 */}
          <Route exact path={URL.SIGNUP} render={(matchProps) => <Signup {...matchProps} onLoaded={() => { }} />} /> {/* 회원가입 */}
          <Route exact path={URL.SIGNTO} render={(matchProps) => <Signto {...matchProps} onLoaded={() => { }} />} /> {/* 아이디 및 비밀번호 찾기 */}
          <Route exact path={URL.ORDER} render={(matchProps) => <Order {...matchProps} onLoaded={() => { }} />} /> {/* 결제 및 주문 */}
          <Route exact path={URL.PRIVACY} render={(matchProps) => <Privacy {...matchProps} onLoaded={() => { }} />} /> {/* 개인정보취금방침 및 이용약관 */}
          <Route exact path={URL.LOGOUT} render={(matchProps) => { Storage.logout(); window.location.href = URL.ROOT; return null; }} /> {/* 로그아웃 */}
          <Route exact path={URL.ADMIN.ROOT} render={(matchProps) => <adm.Login {...matchProps} onLoaded={() => { }} />} /> {/* 관리자 로그인 */}
          <Route exact path={URL.ADMIN.LOGIN} render={(matchProps) => <adm.Login {...matchProps} onLoaded={() => { }} />} /> {/* 관리자 로그인 */}
          <Route exact path={URL.ABOUT} render={(matchProps) => <About {...matchProps} onLoaded={() => { }} />} /> {/* 회사소개 */}

          {/* 관리자 메뉴 */}
          {isadmin &&
            menus.map((item, index) => {
              // 로그인을 하지 않은 상태면 로그인 화면으로 이동
              if (!user && pathname.indexOf(URL.ADMIN.ROOT) >= 0 && pathname !== URL.ADMIN.ROOT) {
                setTimeout(() => {
                  Util.logout();
                  window.location.href = URL.ADMIN.ROOT;
                }, 1000);
                return <Error className={'t3'} title={ST.LOGINADMIN} />
              }

              // 사용자 권한으로 관리자 메뉴를 진입할 경우...
              if (user && user.auth === 'user') {
                return (
                  <StyledObject className={'no-admin'}>
                    <span className={'p-noti'} onClick={() => window.location.href = URL.ADMIN.ROOT}>{ST.NOADMIN}</span>
                  </StyledObject>
                )
              }
              // 관리자의 정상적인 진입
              else {
                if (user && user.auth === 'system') menus.map(a => a['type'] && a['type'] === 'system' && (a.hide = false));
                return (<LayoutAdmins key={String(index)} exact path={item.url} component={item.comp} {...item} {...props} menus={menus} />)
              }
            })}

          {/* 사용자 메뉴 */}
          {!isadmin && menus.map((item, index) => {
            // url이 product의 경우 관리자의 미리보기 기능을 위해 사용된다.
            if (item.url === URL.PRODUCT) {
              return (<LayoutUsers key={String(index)} path={item.url} component={item.comp} {...item} {...props} menus={menus} />)
            }
            // 정상적인 사용자 접근
            else {
              return (<LayoutUsers key={String(index)} exact path={item.url} component={item.comp} {...item} {...props} menus={menus} loaded={loaded} />)
            }
          })
          }

          <Route path={URL.ADMIN.GUIDE} render={(matchProps) => <adm.GuideBox {...matchProps} onLoaded={() => { }} />} /> {/* 도움말 */}
          <Route render={(matchProps) => { window.location.href = URL.SIGNIN; return <div></div>; }} />

          {/* 그외의 화면 접근 에러 */}
          <Route component={Error} {...props} />
        </Switch>
      </Router>
    );
  };
};

/**
 * 사용자 레이아웃
 */
class LayoutUsers extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = { loaded: false, theme: 'white-theme', config: [], company: {} };

    actions.doSelect(URL.API.APPINFO, { stag: 'company' }).then(({ result }) => {
      let json = {};
      result.map(a => json[a.skey] = a.sval);
      this.setState({ company: json });
      if (!result || result.length < 1) {
        this.openConfirm({
          type: 'info', cancel: false, msg: ST.ADMIN_LOGIN, size: 'sm',
          onClicked: (isOk) => {
            (isOk) && (window.location.href = URL.ADMIN.ROOT);
          }
        });
      }
    });
  }

  openConfirm = (value) => {
    value.type = value.type == null ? '' : value.type;
    value.show = true;
    value.size = value.size == null ? '' : value.size;
    value.className = value.className == null ? '' : value.className;
    value.title = value.title == null ? ST.ALARM : value.title;
    value.ok = value.ok == null ? ST.OK : value.ok;
    value.cancel = value.cancel == null ? ST.CANCEL : value.cancel;
    value.msg = value.msg == null ? ST.IS_DELETE : value.msg;
    value.children = value.children || null;
    value.onClicked = value.onClicked == null ? null : value.onClicked;
    this.props.openConfirm(value);
  }

  openModal = (value) => {
    value.show = true;
    value.className = value.className == null ? '' : value.className;
    value.title = value.title == null ? ST.ALARM : value.title;
    value.children = value.children == null ? null : value.children;
    value.data = value.data == null ? {} : value.data;
    value.size = value.size == null ? '' : value.size;
    value.desc = value.desc == null ? '' : value.desc;
    value.state = value.state == null ? STAT.N : value.state;
    value.ok = value.ok == null ? ST.OK : value.ok;
    value.cancel = value.cancel == null ? ST.CANCEL : value.cancel;
    this.props.openModal(value);
  }

  onLoaded = (isok) => {
    this.setState({ loaded: isok });
    this.props.onReady && this.props.onReady();
  }

  onLogin = () => {
    window.location.href = URL.SIGNIN;
  }

  render() {
    const { theme, company } = this.state;
    const Component = this.props.component;
    const { loaded = true, menus = null } = this.props;

    return (
      <Route render={matchProps => {
        global.hist = matchProps.history;

        matchProps.history.listen((location, action) => {
          window.location.href = location.pathname;
        })

        return <StyledObject className={cx("user-layout", theme)}>
          <Header title={company.name} onClick={() => actions.go('/', {})} align={'right'}
            maxwidth={'1280px'} menus={'show'} options={{ font: { color: cs.color.gray, type: 'Hi Melody' } }}
            list={loaded && menus} location={this.props.location.pathname} root={URL.ROOT} history={matchProps.history} />
          <Component {...matchProps} onLoaded={this.onLoaded}
            showAlert={(value) => this.props.showAlert(value)}
            openConfirm={this.openConfirm}
            openModal={this.openModal}
            closeModal={(value) => this.props.closeModal({ ...value, show: false })}
            closeConfirm={(value) => this.props.openConfirm({ ...value, show: false })} />
          {this.state.loaded &&
            <Footer className={'dark m-footer'} value={company.name || ''} height={'fit-content'}>
              <div className={'ft-box'}>
                <span className={'comp-name'}>{`${company.name || ''}`}</span>
                <div className={'menu-bar'}>
                  <Button className={'menu'} title={ST.COMPANY.INFO} onClick={(e) => window.location.href = URL.ABOUT} />
                  <Button className={'menu'} title={ST.COMPANY.BOARD} onClick={(e) => actions.go(URL.BOARD, { type: null })} />
                  <Button className={'menu'} title={ST.COMPANY.PRIVATE} onClick={(e) => actions.go(URL.PRIVACY, { type: 'pri' })} />
                  <Button className={'menu'} title={ST.COMPANY.PUBLIC} onClick={(e) => actions.go(URL.PRIVACY, { type: 'pub' })} />
                  <Button className={'menu'} title={ST.COMPANY.QNA} onClick={(e) => actions.go(URL.QNA, { type: null })} />
                </div>
                <div className={'info-bar'}>
                  <div><span>{company.addr}</span><span>{`(${ST.COMPANY.BOSS}: ${company.boss || ''})`}</span></div>
                  <div>{`${ST.COMPANY.LICENSE}${company.license || ''}`}</div>
                  {company.bizlicense && <div>
                    <span className={'ib-title'}>{`${ST.COMPANY.BIZ_LINCESE}${company.bizlicense || ''}`}</span>
                    {company.bizurl && <span className={'ib-link'} onClick={() => window.open(company.bizurl)}>{ST.COMPANY.BIZ_URL}</span>}
                  </div>}
                  <div className={'as'}>
                    <span><span className={'ib-title'}>{ST.COMPANY.TEL}</span><span className={'ib-text'}>{company.phone || ''}</span></span>
                    <span><span className={'ib-title'}>{ST.COMPANY.EMAIL}</span><span className={'ib-text'}>{company.email || ''}</span></span>
                    {company.fax && <span><span className={'ib-title'}>{ST.COMPANY.FAX}</span><span className={'ib-text'}>{company.fax || ''}</span></span>}
                  </div>
                  {company.bank && <div>{`${ST.COMPANY.BANK}: ${company.bank || ''}`}</div>}
                  <div className={'copyright'}>{`${ST.COPYRIGHT(company.name_eng || '', company.year || '', true)}`}</div>
                </div>
              </div>
            </Footer>
          }
        </StyledObject>
      }} />
    )
  }
};

/**
 * 관리자 레이아웃
 */
class LayoutAdmins extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loaded: false, sidebar: '', showguide: false, noti: null };

    actions.doSelect(URL.API.APPINFO, { stag: 'company', skey: 'name' }).then(({ result }) => {
      this.setState({ company: result && result.length > 0 ? result[0].sval : '' });
    });

    actions.doSelect(URL.API.APPINFO, { stag: 'system', skey: 'init' }).then(({ result }) => {
      const { sval } = result && result.length > 0 && result[0];
      if (sval !== 'success') { this.setState({ showguide: true }) }
    });
  }

  componentDidMount() {
    document.getElementById("body-frame").classList.add("admin-body");
    this.setState({ loaded: true });
  }

  onLoaded = (isok) => {
    // this.setState({ loaded: isok });
    this.props.onReady && this.props.onReady();
  }

  openConfirm = (value) => {
    value.type = value.type == null ? '' : value.type;
    value.show = true;
    value.size = value.size == null ? '' : value.size;
    value.className = value.className == null ? '' : value.className;
    value.title = value.title == null ? ST.ALARM : value.title;
    value.ok = value.ok == null ? ST.OK : value.ok;
    value.cancel = value.cancel == null ? ST.CANCEL : value.cancel;
    value.msg = value.msg == null ? ST.IS_DELETE : value.msg;
    value.children = value.children || null;
    value.onClicked = value.onClicked == null ? null : value.onClicked;
    this.props.openConfirm(value);
  }

  openModal = (value) => {
    value.show = true;
    value.className = value.className == null ? '' : value.className;
    value.title = value.title == null ? ST.ALARM : value.title;
    value.children = value.children == null ? null : value.children;
    value.data = value.data == null ? {} : value.data;
    value.size = value.size == null ? '' : value.size;
    value.desc = value.desc == null ? '' : value.desc;
    value.state = value.state == null ? STAT.N : value.state;
    value.ok = value.ok == null ? ST.OK : value.ok;
    value.cancel = value.cancel == null ? ST.CANCEL : value.cancel;
    this.props.openModal(value);
  }

  render() {
    const { ADMIN } = URL;
    const { props, state } = this;
    const { company } = state;
    const Component = props.component;

    const onEvent = (value) => {
      // console.dir(value);
      this.setState({ noti: value });
    }

    const onClickSysinit = () => {
      actions.doUpdate(URL.API.ADMIN.SETTING, { stag: 'system', skey: 'init', sval: 'popclose' }).then(() => {
        window.location.href = URL.ADMIN.SYSINIT;
      });
    }

    return (
      <Route {...this.props.computedMatch} render={matchProps => {
        global.hist = matchProps.history;
        return <AdminLayout title={company ? company.toUpperCase() : ''} menus={this.props.menus}
          root={ADMIN.MAIN} sidebar={'240px'} topbar={'60px'} onEvent={onEvent}
          eventLayout={props.eventLayout} matchProps={matchProps} onLoaded={this.onLoaded}>
          <Component {...matchProps} noti={this.state.noti} onLoaded={this.onLoaded}
            showAlert={(value) => this.props.showAlert(value)}
            openModal={this.openModal}
            closeModal={(value) => this.props.closeModal({ ...value, show: false })}
            openConfirm={this.openConfirm}
            closeConfirm={(value) => this.props.openConfirm({ ...value, show: false })} />
          <Button className={'guide-btn trans bottom right'} title={ST.ADMIN.SYSINIT.GUIDE} onClick={onClickSysinit} />
        </AdminLayout>
      }} />
    )
  }
};

const AdminLayout = (props) => {
  const { title, menus, root, sidebar, topbar } = props;
  return <StyledObject className="admin-layout" >
    <Sidebar title={title} list={menus} root={root} sidebarW={sidebar} topbarH={topbar}
      eventLayout={(value) => props.eventLayout && props.eventLayout(value)} />
    <Layout className="admin-frame" leftPadding={sidebar} topPadding={topbar}>
      {props.children}
    </Layout>
    <Notibox onEvent={props.onEvent} />
  </StyledObject>
}

const INTERVAL = 1 * 60 * 1000;
var timer = null;
var pcount = 0;

const Notibox = (props) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState(null);
  const [count, setCount] = useState(0);
  const [alarm, setAlarm] = useState(false);

  useEffect(() => {
    let body = document.getElementById('body');
    body && body.addEventListener('mouseup', onResize);
    doReload();
    timer = setInterval(() => doReload(), INTERVAL);  // 1분마다

    return () => {
      body = document.getElementById('body');
      body && body.removeEventListener('mouseup', onResize);
      timer && clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResize = (e) => {
    // const { type } = Util.getScreenType();
    const a = Util.isSelfClick(e, (item) => {
      return item.indexOf("n-li") >= 0;
    });
    if (a) return;

    setShow(false);
  }

  const doReload = () => {
    actions.doSelect(URL.API.ADMIN.NOTI).then((res) => {
      if (!res || res.count < 1 || res.count === pcount) return;
      pcount = res.count;
      const array = res && res.value.map(a => {
        if (Util.isJson(a.sval)) {
          a['value'] = a.sval && Util.parseJson(a.sval);
          a['title'] = a.value && a.value.title;
        } else {
          a['value'] = a.sval;
          a['title'] = a.sval;
        }
        a['time'] = a.ctime && Util.toStringSymbol(a.ctime, "/").substr(5, 18);
        return a;
      })
      setList(array);
      setCount(res ? res.count : 0);

      props.onEvent && props.onEvent({ list: array, count: count });

      setAlarm(true);
      setTimeout(() => setAlarm(false), 1000);
    })
  }

  const onClick = (e) => {
    e.stopPropagation();
    setShow(true);
  }

  const onSelect = (item, e) => {
    if (item && item.value) {
      actions.doUpdate(URL.API.ADMIN.NOTI, { ...item }).then(({ code, result }) => { });

      const { url = null, param = null } = item.value;
      if (url && url.indexOf('http') === 0) {
        window.open(url);
      } else {
        url && actions.go(url, param && { ...param });
      }
      setCount(count > 0 ? count - 1 : 0);
      props.onEvent && props.onEvent({ count: count > 0 ? count - 1 : 0 });
    }
    setShow(false);
  }

  const onClear = (eid, e) => {
    e.stopPropagation();
    const values = list.map(a => a.rowid);
    actions.doUpdate(URL.API.ADMIN.NOTI_CLEAR, { values }).then(({ code, result }) => {
      const { err } = result;

      if (err) return;
      if (result && result.list) {
        const array = result.list.map(a => {
          if (Util.isJson(a.sval)) {
            a['value'] = a.sval && Util.parseJson(a.sval);
            a['title'] = a.value && a.value.title;
          } else {
            a['value'] = a.sval;
            a['title'] = a.sval;
          }
          a['time'] = a.ctime && Util.toStringSymbol(a.ctime, "/").substr(5, 18);
          return a;
        })
        setList(array);
        setShow(false);
        setTimeout(() => setShow(true), 200);

        setCount(result.count || 0);
        props.onEvent && props.onEvent({ list: result.list, count: result.count || 0 });
      } else {
        setList(null);
        setShow(false);
        setCount(0);
        props.onEvent && props.onEvent({ list: null, count: 0 });
      }
    });
  }

  return <StyledObject className={'noti-box'}>
    {/* noti 메시지 개수 표시 아이콘 */}
    {count > 0 && <div className={cx("nt-button")}>
      <span onClick={onClick}>
        <Svg className={cx("md black")} icon='alarm' onClick={(eid, e) => onClick(e)} />
        {count > 0 && <span className={cx('nt-count', { alarm })}>{count > 99 ? "99+" : count}</span>}
      </span>
    </div>}
    {/* noti 리스트 프레임 */}
    <div className={cx("nt-frame", { show })}>
      <ul>
        {list && list.map((item, i) => {
          const { title, time } = item;
          return <li key={i} className={'n-li'} onClick={(e) => onSelect(item, e)}>
            <span className={'n-title'}>{`${title}`}</span>
            <span className={'n-date'}>{`${time}`}</span>
          </li>
        })}
      </ul>
      <div className={"nt-foot"}>
        {list && list.length > 0 && <Button className={'n-li sm full'} onClick={onClear} title={ST.ADMIN.NOTI.CLEAR(list.length)} />}
      </div>
    </div>
  </StyledObject>
}

const mapDispatchToProps = (dispatch) => {
  return {
    openConfirm: (obj) => dispatch(openConfirm(obj)),
    openModal: (obj) => dispatch(openModal(obj)),
    showAlert: (obj) => dispatch(showAlert(obj)),
    openSidemenu: (obj) => dispatch(openSidemenu(obj)),
    eventLayout: (obj) => dispatch(eventLayout(obj)),
  };
};

export default connect(null, mapDispatchToProps)(App);