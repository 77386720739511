import * as OBJ from 'object/src/Config';

export const EID = OBJ.EID;
export const KEY = OBJ.KEY;
export const CODE = OBJ.CODE;
export const CONT_TYPE = OBJ.CONT_TYPE;
export const SCREEN = OBJ.SCREEN;
export const STAT = OBJ.STAT;
export const AUTH = OBJ.AUTH;

export const SIZE = {
  full: { label: 'full', width: '100%' },
  lg: { label: 'lg', width: '1200px' },
  md: { label: 'md', width: '1000px' },
  sm: { label: 'sm', width: '800px' },
  none: { label: 'none', width: 'AUTO' },
}

export const ERR = {
  ER_DUP: 'ER_DUP_ENTRY',
  STR: {
    ER_DUP_LOGIN_ID: '중복된 아이디 입니다.',
  },
}

// export const SIGN_T = {
//   'ka': '카카오',
//   'fa': '페이스북',
//   'na': '네이버',
// }

export const SIGN_T = {
  'ka': '카카오 등록',
  'fa': '페이스북 등록',
  'na': '네이버 등록',
}

// export const ODR_STAT = {
//   R: '입금대기중',
//   S: '주문/결제완료',
//   T: '배송준비중',
//   F: '배송완료',
// }

export const EVENT = {
  CLICKED: 'clicked',
}

const ROOT = {
  PAGE: '/page',
  ADMIN: '/admin',
  API: {
    ROOT: '/api',
    PAGE: '/api/page',
    ADMIN: '/api/admin',
  }
}

export const URL = {
  KAKAO_DEVELOP: 'https://developers.kakao.com/console/app',
  NAVER_DEVELOP: 'https://developers.naver.com/apps/#/list',
  NCP_DEVELOP: 'https://www.ncloud.com/',
  IAMPORT_DEVELOP: 'https://admin.iamport.kr/settings#tab_profile',
  IAMPORT_PAYMENT: 'https://admin.iamport.kr/payments',
  GMAIL: 'https://accounts.google.com/signup/v2/webcreateaccount?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F%3Ftab%3Dwm%26ogbl&ltmpl=default&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp&hl=ko',
  SOCKPORT: '9100',

  ROOT: '/',
  HOME: '/',
  
  LOGOUT: '/logout',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  SIGNTO: '/signto',
  SIGNIN_NAVER: `/signin/naver`,

  CART: '/cart',
  RESTORE: '/restore',
  MYPAGE: '/mypage',
  PAYCOMP: '/paycomp',
  PASSWORD: '/password',
  DAILYLIST: '/daily',
  PRODUCT: '/product',
  ICONS: '/icons',
  RULE: '/rule',
  BOARD: '/board',
  QNA: '/qna',
  PAYMENT: '/payment',
  DETAIL: '/detail',
  ORDER: '/order',
  // PRIVATE: '/private',
  PRIVACY: '/privacy',
  PUBLIC: '/public',
  ABOUT: '/about',
  PREVIEW: '/preview',
  NAVERLOGIN: '/signin/naver',

  TRANS: {
    DAUM: (p) => `https://search.daum.net/search?w=tot&q=${p}`,
    NAVER: (p) => `https://search.naver.com/search.naver?where=nexearch&query=${p}`,
  },

  ADMIN: {
    ROOT: `/admin`,
    LOGIN: `/adminlogin`,
    MAIN: `${ROOT.ADMIN}/main`,
    MENU: `${ROOT.ADMIN}/menu`,
    BOARD: `${ROOT.ADMIN}/board`,
    ABOUT: `${ROOT.ADMIN}/about`,
    WEBSET: `${ROOT.ADMIN}/webset`,
    SETTING: `${ROOT.ADMIN}/setting`,
    MAILER: `${ROOT.ADMIN}/mailer`,
    MANAGEMENT: `${ROOT.ADMIN}/mgmt`,
    MEMBER: `${ROOT.ADMIN}/member`,
    PRODUCT: `${ROOT.ADMIN}/product`,
    CONTENT: `${ROOT.ADMIN}/content`,
    ORDER: `${ROOT.ADMIN}/order`,
    HISTORY: `${ROOT.ADMIN}/hist`,
    HISTORY_ORDER: `${ROOT.ADMIN}/historder`,
    PAYMENT: `${ROOT.ADMIN}/payment`,
    HOME: `${ROOT.ADMIN}/home`,
    GUIDE: `${ROOT.ADMIN}/guide`,
    QNA: `${ROOT.ADMIN}/qna`,
    SYSINIT: `${ROOT.ADMIN}/sysinit`,
  },

  API: {
    LOGOUT: `${ROOT.API.ROOT}/logout`,
    APPINFO: `${ROOT.API.ROOT}/appinfo`,
    MENU: `${ROOT.API.ROOT}/menu`,
    BOARD: `${ROOT.API.ROOT}/board`,
    QNA: `${ROOT.API.ROOT}/qna`,
    QNA_REQ: `${ROOT.API.ROOT}/qna/req`,
    QNA_CHECK: `${ROOT.API.ROOT}/qna/check`,
    PRIVACY: `${ROOT.API.ROOT}/privacy`,
    TOKEN: `${ROOT.API.ROOT}/token`,
    PRODUCT: `${ROOT.API.ROOT}/product`,
    CONTENT: `${ROOT.API.ROOT}/content`,
    CART: `${ROOT.API.ROOT}/cart`,
    CART_CLEAR: `${ROOT.API.ROOT}/cart/clear`,
    SIGNTO: `${ROOT.API.ROOT}/signto`,
    SIGNIN: `${ROOT.API.ROOT}/signin`,
    // SIGNIN_NAVER: `${ROOT.API.ROOT}/signin/naver`,
    SIGNTOPASS: `${ROOT.API.ROOT}/signto/repass`,
    COMMENT: `${ROOT.API.ROOT}/comment`,
    COMMENT_MORE: `${ROOT.API.ROOT}/comment/more`,
    UPLOAD_IMAGE: `${ROOT.API.ADMIN}/upload/image`,

    PAGE: {
      MYINFO: `${ROOT.API.PAGE}/myinfo`,
      ADDRESS: `${ROOT.API.PAGE}/address`,
      ADDRESS_PRIM: `${ROOT.API.PAGE}/address/prim`,
      PAYMENT: `${ROOT.API.PAGE}/payment`,
      ORDER: `${ROOT.API.PAGE}/order`,
      DETAIL: `${ROOT.API.PAGE}/order/detail`,
      MAIL_SET: `${ROOT.API.PAGE}/user/mail`,
      PHONE_SET: `${ROOT.API.PAGE}/user/phone`,
      ORDER_CANCEL: `${ROOT.API.PAGE}/order/cancel`,
      REPASS: `${ROOT.API.PAGE}/repass`,
      PHONEREGIST: `${ROOT.API.PAGE}/phone/regist`
    },

    ADMIN: {
      LOGOUT: `${ROOT.API.ADMIN}/logout`,
      STATE: `${ROOT.API.ADMIN}/state`,
      MAIN: `${ROOT.API.ADMIN}/main`,
      MEMBER: `${ROOT.API.ADMIN}/member`,
      MANAGER: `${ROOT.API.ADMIN}/manager`,
      MENU: `${ROOT.API.ADMIN}/menu`,
      BOARD: `${ROOT.API.ADMIN}/board`,
      HOME: `${ROOT.API.ADMIN}/home`,
      ADMINS: `${ROOT.API.ADMIN}/admins`,
      ADMINS_PASS: `${ROOT.API.ADMIN}/admins/pass`,
      MGMT_SYS: `${ROOT.API.ADMIN}/mgmt/sys`,
      MGMT_WEBSET: `${ROOT.API.ADMIN}/mgmt/webset`,
      MGMT_SEARCH: `${ROOT.API.ADMIN}/mgmt/search`,
      MGMT_LOGS: `${ROOT.API.ADMIN}/mgmt/logs`,
      MGMT_TABLE: `${ROOT.API.ADMIN}/mgmt/table`,
      MGMT_SCHEMA: `${ROOT.API.ADMIN}/mgmt/schema`,
      MGMT_CONN_TEST: `${ROOT.API.ADMIN}/mgmt/conn/test`,
      PRODUCT: `${ROOT.API.ADMIN}/product`,
      PRODUCT_LIST: `${ROOT.API.ADMIN}/product/list`,
      PRODUCT_COUNT: `${ROOT.API.ADMIN}/product/count`,
      PRODUCT_COUNT_BATCH: `${ROOT.API.ADMIN}/product/count/batch`,
      PRODUCT_ORDERING: `${ROOT.API.ADMIN}/product/ordering`,
      ORDER: `${ROOT.API.ADMIN}/order`,
      PAYMENT: `${ROOT.API.ADMIN}/payment`,
      PAYMENT_CANCEL: `${ROOT.API.ADMIN}/payment/cancel`,
      ORDER_TRANS: `${ROOT.API.ADMIN}/order/trans`,
      ORDER_BATCH: `${ROOT.API.ADMIN}/order/batch`,
      PRODUCT_OPTS: `${ROOT.API.ADMIN}/product/options`,
      PRODUCT_CFGS: `${ROOT.API.ADMIN}/product/configs`,
      PRODUCT_STATUS: `${ROOT.API.ADMIN}/product/status`,
      CONTENT: `${ROOT.API.ADMIN}/content`,
      CART: `${ROOT.API.ADMIN}/cart`,
      SETTING: `${ROOT.API.ADMIN}/setting`,
      UPLOAD_APP: `${ROOT.API.ADMIN}/upload/app`,
      UPLOAD_PRODUCT: `${ROOT.API.ADMIN}/upload/product`,
      UPLOAD_CONTENT: `${ROOT.API.ADMIN}/upload/content`,
      MENU_ORDER: `${ROOT.API.ADMIN}/menu/order`,
      MAIL_SEND: `${ROOT.API.ADMIN}/mail/send`,
      NOTI: `${ROOT.API.ADMIN}/noti`,
      NOTI_CLEAR: `${ROOT.API.ADMIN}/noti/clear`,
      RELOAD: `${ROOT.API.ADMIN}/reload`,
      HISTORY: `${ROOT.API.ADMIN}/hist`,
      HISTORY_ORDER: `${ROOT.API.ADMIN}/hist/order`,
    }
  }
};