import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ST } from '../svc/Lang';
import cx from 'classnames/bind';
import { cs, Button, Loading, Tablebox } from 'object/src';
import { URL } from 'svc/Enum';
import * as actions from 'object/src/actor/Action';

const StyledObject = styled.div`{
  &.ad-payment { ${cs.bg.trans} ${cs.p.h30} ${cs.pos.relative} ${cs.min.height(100)} ${cs.p.bottom(80)}
    ${cs.max.wxl} ${cs.w.full} ${cs.noselect}

    .title { ${cs.font.t0} ${cs.border.bottom} ${cs.border.darkgray} ${cs.p.a5} ${cs.m.b20} ${cs.m.t30} }

    .button.rowid { ${cs.font.white} ${cs.font.md} .btn-label { ${cs.font.underline} } 
      &:hover { ${cs.font.yellow} }
    }

    .table-box {
      .link { ${cs.font.underline} ${cs.mouse.pointer} &:hover { ${cs.font.yellow} } }
      .tb-row .tb-col { ${cs.h.get('28px !important')} ${cs.font.line('28px !important')} 
      }
    }
  }

  &.pay-detail {
    ${cs.m.t50} ${cs.pos.fixed} ${cs.left(0)} ${cs.top(0)} ${cs.size.full} ${cs.z.top}
    ${cs.over.hidden}

    .pd-bg { ${cs.size.full} ${cs.disp.block} }

    .pd-frame { ${cs.align.xcenter} ${cs.top(50)} ${cs.bg.black} ${cs.border.shadow()} 
      ${cs.border.radius(10)} ${cs.box.light} ${cs.over.hidden} ${cs.w.dslg}
      ${cs.min.h(400)} ${cs.h.full} ${cs.max.h('80%')} 
      .p-head { ${cs.h.get(40)} ${cs.border.bottom} ${cs.border.dark} }
      .p-foot { ${cs.h.get(40)} ${cs.border.top} ${cs.border.dark} }
      ul {  ${cs.h.calc('100% - 80px')} ${cs.p.a50} ${cs.scrollbar.t3} ${cs.over.hidden} ${cs.over.yauto}
        li { ${cs.font.line(26)} 
          .pd-sl { ${cs.disp.inblock} ${cs.w.get(200)} }
          .pd-sr { ${cs.disp.inblock} ${cs.w.calc('100% - 200px')} ${cs.font.prewrap} }
        }
      }
    }
  }
}`;

const API = URL.API.ADMIN;
const STOD = ST.ADMIN.ORDER;

const Payment = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(null);
  // const [payments, setPayments] = useState(null);
  const [detail, setDetail] = useState(null);

  const head = [
    {
      key: 'rowid', title: STOD.ODRID, flex: '1 1 120px', align: 'center',
      formatter: (v, item, pos) => {
        return <Button className={'sm trans rowid'} title={v} onClick={(eid, e) => onClickOrder(item, e)} />
      }
    },
    {
      key: 'odrno', title: STOD.ODRID, flex: '2 1 160px', align: 'center',
      formatter: (v, item, pos) => {
        if (item.odrno && item.odrno !== '0') {
          return <Button className={'sm trans rowid'} title={v} onClick={(eid, e) => onClickPayment(item, e)} />
        } else {
          return null;
        }
      }
    },
    { key: 'ctime', title: STOD.DATETIME, flex: '1 1 80px', align: 'center', type: 'datetime', format: "YY/MM/DD" },
    { key: 'product', title: STOD.PRODUCT, flex: '3 1 140px', align: 'left' },
    { key: 'price', title: STOD.PRICE, flex: '1 1 80px', align: 'right', type: 'number', unit: ST.WON },
    { key: 'name', title: STOD.NAME, flex: '1 1 80px', align: 'center' },
    { key: 'phone', title: STOD.PHONE, flex: '2 1 100px', align: 'center', type: 'phone' },
  ];

  useEffect(() => {
    doReload();
    return () => {
    }
  }, []);

  const doReload = () => {
    actions.doList(API.PAYMENT, {}).then((result) => {
      const { value, payments } = result;
      const list = value && value.map(a => {
        const item = payments.find(b => a.odrno === b.imp_uid);
        return item ? { ...a, payment: item } : { ...a, payment: {} };
      })
      setList(list);
      setLoaded(true);
    });
  }

  const onClickOrder = (item, e) => {
    e.stopPropagation();
    actions.go(URL.ADMIN.ORDER, { rowid: item.rowid });
  }

  const onSelect = (eid, rowid, e) => {
    e.stopPropagation();
    const item = list.find(a => String(a.rowid) === String(rowid));
    (item && item.payment) && setDetail(item.payment);
  }

  const onClickPayment = (item, e = null) => {
    e && e.stopPropagation();
  }

  const onClickPopup = (eid, e) => {
    setDetail(null);
  }

  if (loaded) {
    return <StyledObject className="ad-payment">
      <div className={'s-head'}>
        <div className={cx('title')}>
        </div>
        <div>
        </div>
      </div>

      <div className={'s-body'}>
        <Tablebox className={'dark sm'} head={head} list={list} onSelect={(rowid, e) => onSelect('select', rowid, e)} pos={1} rowid="rowid" />
      </div>

      {detail && <Detail data={detail} onClick={onClickPopup} />}

      <div className={'s-foot'}>
      </div>
    </StyledObject >
  } else {
    return <Loading />;
  }
};

export default Payment;

const Detail = (props) => {
  const { data, onClick } = props;

  if (!data) return null;
  const keys = Object.keys(data).map(key => key);
  return <StyledObject className={'pay-detail'} >
    <div className={'pd-bg'} onClick={(e) => onClick('cancel', e)} />
    <div className={'pd-frame'} >
      <div className={'p-head'}></div>
      <ul onClick={(e) => onClick('cancel', e)}>
        {keys && keys.map((key, i) => {
          return <li key={i}><span className={'pd-sl'}>{key}</span><span className={'pd-sr'}>{data[key] instanceof Array ? JSON.stringify(data[key]) : data[key]}</span></li>
          // if(!item.value) return null;
          // return <li key={i}><span className={'pd-sl'}>{Util.replaceAll(item.name, "_", " ")}</span><span className={'pd-sr'}>{item.value}</span></li>
        })}
      </ul>
      <div className={'p-foot'}></div>
    </div>
  </StyledObject>
}